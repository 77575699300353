import React, {
  useEffect, useRef, useState
} from 'react';
import {
  Col, Form,
  Image, Row
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CharacterCounter from 'react-character-counter';
import { CardImage, TrashFill, X } from 'react-bootstrap-icons';
import './QuestionForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Button from '../Button/Button';
import trueOrFalseIcon from '../../Assets/Images/true-or-false.png';
import multipleChoiceIcon from '../../Assets/Images/multiple-choice.png';
import fillTheBlankIcon from '../../Assets/Images/fill-the-blank.png';
import { submitQuestion, uploadImageToS3 } from '../../Actions/Question';
import { generateRandomString } from '../../Helpers/Common';
import {
  multipleChoiceField,
  trueOrFalseField
} from '../../Helpers/StaticField';
import {
  ANSWERS_URL, QUESTIONS_URL, QUESTION_IMAGE_WIDTH, QUESTION_IMAGE_HEIGHT,
  QUESTION_DIFFICULTY
} from '../../Constants/Constants';
import { useFetch } from '../../Hooks/UseFetch/UseFetch';
import ConfirmationModal from '../Modal/ConfirmationModal';

const QuestionForm = (props) => {
  const dispatch = useDispatch();
  const questionList = useSelector((state) => state.questions);
  // const errorUploadImage = useSelector((state) => state.questions.errorUploadImage);
  const [errorUploadImage, setErrorUploadImage] = useState(false);
  const [trueOrFalseAnswer, setTrueOrFalseAnswer] = useState(cloneDeep(trueOrFalseField));
  const [multipleChoiceAnswer, setMultipleChoiceAnswer] = useState(cloneDeep(multipleChoiceField));
  const [fillTheBlankAnswer, setFillTheBlankAnswer] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [onClickEvent, setOnClickEvent] = useState();
  const inputFile = useRef(null);
  const {
    questionData, questionIndex, setPreviewCorrectAnswer,
    previewContent, setPreviewContent, setLoading
  } = props;
  const fetchAnswerURL = `${QUESTIONS_URL}/${questionData.id}/connected_answers?order%5Bordering%5D=asc`;
  const { fetchedData: { data: answerData } } = useFetch(fetchAnswerURL);

  useEffect(() => {
    if (answerData && questionData.type === 'TYPE_TRUE_OR_FALSE') {
      const trueOrFalse = [...trueOrFalseAnswer];
      questionList[questionIndex].haveOneCorrectAnswer = false;
      if (answerData['hydra:member'].length > 0) {
        answerData['hydra:member'].map((answer, index) => {
          trueOrFalseAnswer[index]['@id'] = answer['@id'];
          trueOrFalseAnswer[index].id = answer.id;
          trueOrFalseAnswer[index].name = answer.description;
          trueOrFalseAnswer[index].selected = answer.correct;
          if (answer.correct === true) {
            questionList[questionIndex].haveOneCorrectAnswer = true;
          }
          return trueOrFalse;
        });
      } else {
        questionList[questionIndex].answersFailed = true;
      }
      setTrueOrFalseAnswer(trueOrFalse);
    }
  }, [answerData, questionData]);

  useEffect(() => {
    if (answerData && questionData.type === 'TYPE_MULTIPLE_CHOICE') {
      const answers = answerData['hydra:member'].slice(0, 4);
      const multipleChoice = [...multipleChoiceAnswer];
      if (answers.length > 0) {
        questionList[questionIndex].haveOneCorrectAnswer = false;
        questionList[questionIndex].haveEmptyDescription = false;
        answers.map((answer, index) => {
          multipleChoice[index]['@id'] = answer['@id'];
          multipleChoice[index].id = answer.id;
          multipleChoice[index].name = answer.description || '';
          if (answer.correct === true) {
            multipleChoice[index].selected = true;
            questionList[questionIndex].haveOneCorrectAnswer = true;
          }
          if (multipleChoice[index].name === '') {
            questionList[questionIndex].haveEmptyDescription = true;
          }
          return multipleChoice;
        });
      } else {
        questionList[questionIndex].answersFailed = true;
      }
      setMultipleChoiceAnswer(multipleChoice);
    }
  }, [answerData, questionData]);

  useEffect(() => {
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answerPreview = multipleChoiceAnswer;
    dispatch(submitQuestion(listOfQuestion));
  }, [multipleChoiceAnswer]);

  useEffect(() => {
    if (answerData && questionData.type === 'TYPE_FILL_THE_BLANK') {
      const fillTheBlank = [];
      questionList[questionIndex].haveEmptyDescription = false;
      questionList[questionIndex].haveOneCorrectAnswer = false;
      answerData['hydra:member'].map((answer) => {
        const generateAnswer = {
          ...answer,
          name: answer.description
        };
        questionList[questionIndex].haveOneCorrectAnswer = true;
        if (!answer.description) {
          questionList[questionIndex].haveEmptyDescription = true;
        }
        fillTheBlank.push(generateAnswer);
        return fillTheBlank;
      });
      setFillTheBlankAnswer(fillTheBlank);
    }
  }, [answerData, questionData]);

  const updateQuestion = async (updateData) => {
    const listOfQuestion = [...questionList];
    try {
      await axios.put(`${QUESTIONS_URL}/${listOfQuestion[questionIndex].id}`, updateData);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const checkEmptyDescription = (listAnswers) => {
    const emptyAnswers = listAnswers.filter((a) => isEmpty(a.name));
    if (emptyAnswers.length > 0) {
      return true;
    }
    return false;
  };

  const updateAnswer = async (answerId, updateData) => {
    // const listOfQuestion = [...questionList];
    try {
      // if (listOfQuestion[questionIndex].connectedAnswers[answerIndex] === undefined) {
      //   const createAnswer = {
      //     id: updateData.id,
      //     creator: questionData.creator,
      //     parentItem: `/questions/${questionData.id}`,
      //     description: updateData.description,
      //     ordering: updateData.ordering
      //   };
      //   console.log('createAnswer', createAnswer)
      //   return await axios.post(`${ANSWERS_URL}`, createAnswer);
      // }
      const getAnswerId = answerId.split('/');
      return await axios.put(`${ANSWERS_URL}/${getAnswerId[2]}`, { description: updateData.description });
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const handleUpdateCorrectAnswer = async (answerId) => {
    const listOfQuestion = [...questionList];
    try {
      if (listOfQuestion[questionIndex].connectedAnswers) {
        const getAnswerId = answerId.split('/');
        await axios.put(`${ANSWERS_URL}/${getAnswerId[2]}`, { correct: true });
        const updateToFalse = listOfQuestion[questionIndex].connectedAnswers
          .filter((answer) => answer !== answerId);
        updateToFalse.map(async (answer) => {
          const getUpdateToFalseId = answer.split('/');
          await axios.put(`${ANSWERS_URL}/${getUpdateToFalseId[2]}`, { correct: false });
        });
        questionList[questionIndex].haveOneCorrectAnswer = true;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleInputQuestion = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].description = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const handleAnswerKey = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answerKey = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const handleQuestionDifficulty = async (value) => {
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].difficulty = value.value;
    dispatch(submitQuestion(listOfQuestion));
    await updateQuestion({ difficulty: listOfQuestion[questionIndex].difficulty });
  };

  const handleDeleteQuestion = async () => {
    onClickEvent.preventDefault();
    onClickEvent.stopPropagation();
    try {
      const questionId = questionData.id;
      const listOfQuestion = [...questionList];
      const resultQuestion = listOfQuestion.filter((question) => questionId !== question.id);
      if (previewContent > 0) {
        setPreviewContent(resultQuestion.length - 1);
      }
      if (previewContent === 0) {
        setPreviewContent(0);
      }
      const deletedQuestion = listOfQuestion.filter((question) => question.id === questionId);
      if (deletedQuestion[0].connectedAnswers.length !== 0) {
        deletedQuestion[0].connectedAnswers.map(async (answer) => {
          const getAnswerId = answer.split('/');
          await axios.delete(`${ANSWERS_URL}/${getAnswerId[2]}`);
        });
      }
      if (deletedQuestion[0].image) {
        await axios.delete(deletedQuestion[0].image);
      }
      await axios.delete(`${QUESTIONS_URL}/${questionId}`);
      dispatch(submitQuestion(resultQuestion));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const renderQuestionIcon = (questionType) => {
    if (questionType === 'TYPE_TRUE_OR_FALSE') {
      return (
        <Image src={trueOrFalseIcon}/>
      );
    }
    if (questionType === 'TYPE_MULTIPLE_CHOICE') {
      return (
        <Image src={multipleChoiceIcon}/>
      );
    }
    if (questionType === 'TYPE_FILL_THE_BLANK') {
      return (
        <Image src={fillTheBlankIcon} width='18'/>
      );
    }
    return null;
  };

  const handleOnBlurQuestionInput = async () => {
    await updateQuestion({ description: questionList[questionIndex].description });
  };

  const renderQuestionInput = (questionType) => {
    return (
      <div className='question-form-input'>
        <div className='question-form-input-icon'>
          <div className='question-form-input-icon-round'>
            {renderQuestionIcon(questionType)}
          </div>
          <h5>
            {questionIndex + 1}
          </h5>
        </div>
         <CharacterCounter
           value={questionList[questionIndex].description || ''}
           maxLength={100}
         >
          <Form.Control
            className=''
            as='textarea'
            maxLength={100}
            value={questionList[questionIndex].description}
            placeholder='Tulis pertanyaanmu disini'
            onChange={handleInputQuestion}
            onFocus={() => setPreviewContent(questionIndex)}
            onBlur={() => handleOnBlurQuestionInput()}
          />
         </CharacterCounter>
      </div>
    );
  };

  const handleAnswerTrueOrFalseSwitch = async (event, index, answerId) => {
    const listOfTrueOrFalseQuestion = [...trueOrFalseAnswer];
    setPreviewContent(questionIndex);
    if (event.target.value === 'on') {
      if (index === 0) {
        listOfTrueOrFalseQuestion[0].selected = true;
        listOfTrueOrFalseQuestion[1].selected = false;
      }
      if (index === 1) {
        listOfTrueOrFalseQuestion[0].selected = false;
        listOfTrueOrFalseQuestion[1].selected = true;
      }
      await handleUpdateCorrectAnswer(answerId);
      return setTrueOrFalseAnswer(listOfTrueOrFalseQuestion);
    }
    return null;
  };

  const renderAnswerTrueOrFalse = () => {
    return trueOrFalseAnswer.map((answer, index) => (
      <Col xs={6} key={index}>
        <div
          className={`
            question-form-answer
            ${answer.selected && 'question-form-answer-selected'}
          `}
        >
          <div className='question-form-answer-items'>
            <div className='question-form-answer-option'>
              {answer.option}
            </div>
            <div className='question-form-answer-text'>{answer.name}</div>
          </div>
          <div className='question-form-answer-switch'>
             <Form.Check
              onChange={(event) => handleAnswerTrueOrFalseSwitch(event, index, answer['@id'])}
              checked={answer.selected}
              type='switch'
              id={`${generateRandomString(5)}-switch`}
              label=''
             />
          </div>
        </div>
      </Col>
    ));
  };

  const handleOnChangeMultiple = (event, index) => {
    const { target: { value } } = event;
    const listOfMultipleChoice = [...multipleChoiceAnswer];
    const lisOfQuestion = [...questionList];
    setPreviewContent(questionIndex);
    listOfMultipleChoice[index].name = value;
    lisOfQuestion[questionIndex].answerPreview[index].name = value;
    if (checkEmptyDescription(listOfMultipleChoice)) {
      questionList[questionIndex].haveEmptyDescription = true;
    } else {
      questionList[questionIndex].haveEmptyDescription = false;
    }
    setMultipleChoiceAnswer(listOfMultipleChoice);
    dispatch(submitQuestion(lisOfQuestion));
  };

  const handleAnswerMultipleSwitch = async (event, answer, answerId) => {
    const listOfMultipleChoice = [...multipleChoiceAnswer];
    setPreviewContent(questionIndex);
    if (event.target.value === 'on') {
      if (answer === 'A') {
        listOfMultipleChoice[0].selected = true;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'C') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = true;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'B') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = true;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'D') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = true;
      }
      await handleUpdateCorrectAnswer(answerId);
      return setMultipleChoiceAnswer(listOfMultipleChoice);
    }
    return null;
  };

  const handleUpdateAnswerMultiple = async (id, description) => {
    await updateAnswer(id, { description });
  };

  const handleMultipleChoiceTab = (e, option, index) => {
    if (e.key === 'Tab' && option !== 'D') {
      e.preventDefault();
      switch (option) {
        case 'A':
          document.getElementById(`question-${index}-option-B`).focus();
          break;
        case 'B':
          document.getElementById(`question-${index}-option-C`).focus();
          break;
        case 'C':
          document.getElementById(`question-${index}-option-D`).focus();
          break;
        default:
          break;
      }
    }
  };

  const renderAnswerMultiple = () => {
    return (
      <>
        {multipleChoiceAnswer && multipleChoiceAnswer.map((answer, index) => (
          <Col key={index} xs={6}>
            <div
              className={`question-form-answer
                  ${answer.selected && 'question-form-answer-selected'}`}
            >
              <div className='question-form-answer-items'>
                <div className='question-form-answer-option'>
                  {answer.option}
                </div>
                <div className='question-form-answer-textarea'>
                   <CharacterCounter
                    value={answer.name || ''}
                    maxLength={46}
                   >
                    <Form.Control
                      id={`question-${questionIndex}-option-${answer.option}`}
                      maxLength={46}
                      as='textarea'
                      className='question-form-answer-text-input'
                      value={answer.name}
                      onChange={(event) => handleOnChangeMultiple(event, index)}
                      onFocus={() => setPreviewContent(questionIndex)}
                      onBlur={() => handleUpdateAnswerMultiple(answer['@id'], answer.name)}
                      onKeyDown={(e) => handleMultipleChoiceTab(e, answer.option, questionIndex)}
                      style={{ fontSize: 14, resize: 'none' }}
                    />
                   </CharacterCounter>
                </div>
              </div>
              <div className='question-form-answer-switch'>
                 <Form.Check
                  checked={answer.selected}
                  onChange={(event) => handleAnswerMultipleSwitch(event, answer.option, answer['@id'])}
                  type='switch'
                  id={`${generateRandomString(5)}-switch`}
                  label=''
                 />
              </div>
            </div>
          </Col>
        ))}
      </>
    );
  };

  const handleOnChangeOption = (event, index) => {
    const { target: { value } } = event;
    const listOfFillTheBlank = [...fillTheBlankAnswer];
    setPreviewContent(questionIndex);
    listOfFillTheBlank[index].name = value;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answers = listOfFillTheBlank;
    dispatch(submitQuestion(listOfQuestion));
    if (checkEmptyDescription(listOfFillTheBlank)) {
      questionList[questionIndex].haveEmptyDescription = true;
    } else {
      questionList[questionIndex].haveEmptyDescription = false;
    }
    setFillTheBlankAnswer(listOfFillTheBlank);
  };

  const handleGenerateInputAnswer = async () => {
    try {
      const listOfFillTheBlank = [...fillTheBlankAnswer];
      setPreviewContent(questionIndex);
      const createAnswer = {
        id: uuidv4(),
        creator: questionData.creator,
        parentItem: `/questions/${questionData.id}`,
        ordering: listOfFillTheBlank.length,
        correct: true
      };
      await axios.post(`${ANSWERS_URL}`, createAnswer);
      listOfFillTheBlank.push(createAnswer);
      const listOfQuestion = [...questionList];
      listOfQuestion[questionIndex].answers = listOfFillTheBlank;
      dispatch(submitQuestion(listOfQuestion));
      questionList[questionIndex].haveEmptyDescription = true;
      questionList[questionIndex].haveOneCorrectAnswer = true;
      setFillTheBlankAnswer(listOfFillTheBlank);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleCancelAnswer = async (index, answerId) => {
    try {
      setPreviewContent(questionIndex);
      const fillTheBlankAnswerOption = [...fillTheBlankAnswer];
      fillTheBlankAnswerOption.splice(index, 1);
      const listOfQuestion = [...questionList];
      listOfQuestion[questionIndex].answers = fillTheBlankAnswerOption;
      dispatch(submitQuestion(listOfQuestion));
      if (fillTheBlankAnswerOption.length === 0) {
        questionList[questionIndex].haveOneCorrectAnswer = false;
      }
      if (checkEmptyDescription(fillTheBlankAnswerOption)) {
        questionList[questionIndex].haveEmptyDescription = true;
      } else {
        questionList[questionIndex].haveEmptyDescription = false;
      }
      setFillTheBlankAnswer(fillTheBlankAnswerOption);
      await axios.delete(`${ANSWERS_URL}/${answerId}`);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleUpdateAnswerFillBlank = async (index) => {
    try {
      const listOfFillTheBlank = [...fillTheBlankAnswer];
      const updateData = {
        description: listOfFillTheBlank[index].name
      };
      if (questionList[questionIndex].answers[index]) {
        const getAnswerId = questionList[questionIndex].answers[index].id;
        await axios.put(`${ANSWERS_URL}/${getAnswerId}`, updateData);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const renderAnswerFillTheBlank = () => {
    return (
      <Col xs={12}>
        <Row>
          <div className='question-form-answer-fill' id='question-form-answer-fill'>
            {fillTheBlankAnswer.map((answer, index) => (
              <Col xs={12} key={index}>
                <div
                  className='question-form-answer'
                >
                  <div className='question-form-answer-items'>
                    <div className='question-form-answer-option'>
                      {index + 1}
                    </div>
                    <div className='question-form-answer-text'>
                       <CharacterCounter
                        value={answer.name || ''}
                        maxLength={25}
                       >
                        <Form.Control
                          type='text'
                          className='question-form-answer-text-input'
                          onChange={(event) => handleOnChangeOption(event, index)}
                          value={answer.name}
                          maxLength={25}
                          onFocus={() => setPreviewContent(questionIndex)}
                          onBlur={() => handleUpdateAnswerFillBlank(index, answer.name)}
                        />
                       </CharacterCounter>
                    </div>
                  </div>
                  <div
                    className='question-form-answer-cancel'
                    onClick={() => handleCancelAnswer(index, answer.id)}
                  >
                    <X/>
                  </div>
                </div>
              </Col>
            ))}
          </div>
          <Col xs={12}>
            <p
              className='question-form-tooltip'
              onClick={() => handleGenerateInputAnswer()}>
              Klik di sini untuk tambah satu atau lebih jawaban yang benar
            </p>
          </Col>
        </Row>
      </Col>
    );
  };

  const answerKeyOnFocus = () => {
    setPreviewContent(questionIndex);
    setPreviewCorrectAnswer(questionIndex);
  };

  const handleOnBlurAnswerKey = async () => {
    await updateQuestion({ answerKey: questionList[questionIndex].answerKey });
    setPreviewCorrectAnswer(undefined);
  };

  const renderAnswerKeyInput = () => {
    return (
      <div className='question-form-answer-key'>
        <Form.Group>
          <Form.Label>Answer Key</Form.Label>
           <CharacterCounter
            value={questionList[questionIndex].answerKey || ''}
            maxLength={300}>
            <Form.Control
              as="textarea"
              className='question-form-answer-key-textarea'
              maxLength={300}
              placeholder='Jelaskan tentang alasan jawaban yang benar'
              value={questionList[questionIndex].answerKey}
              onChange={handleAnswerKey}
              onFocus={answerKeyOnFocus}
              onBlur={() => handleOnBlurAnswerKey()}
            />
           </CharacterCounter>
        </Form.Group>
      </div>
    );
  };

  const handleVisualBrief = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].visualBrief = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const visualBriefOnFocus = () => {
    setPreviewContent(questionIndex);
  };

  const handleOnBlurVisualBrief = async () => {
    await updateQuestion({ visualBrief: questionList[questionIndex].visualBrief });
  };

  const renderVisualBriefInput = () => {
    return (
      <div className='question-form-answer-key'>
        <Form.Group>
          <Form.Label>
            Arahan visual untuk desainer (opsional)
            <div className='question-form-visual-brief-label-mark' >?
              <div className='question-form-visual-brief-label-mark-tooltip' >
                Bantu desainer untuk memvisualisasikan pelajaran
              </div>
            </div>
          </Form.Label>
          <CharacterCounter
            value={questionList[questionIndex].visualBrief || ''}
            maxLength={150}>
            <Form.Control
              as="textarea"
              className='question-form-answer-key-textarea'
              maxLength={150}
              placeholder='Jelaskan konsep gambar latar belakang'
              value={questionList[questionIndex].visualBrief}
              onChange={handleVisualBrief}
              onFocus={() => visualBriefOnFocus()}
              onBlur={() => handleOnBlurVisualBrief()}
            />
          </CharacterCounter>
        </Form.Group>
      </div>
    );
  };

  const style = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused && '#F2767E',
      boxShadow: state.isFocused && '0 0 0 0.2rem #F2767E',
      '&:hover': {
        border: '1px solid #F2767E'
      }
    })
  };

  const arrayMoveMutate = (array, from, to) => {
    array.splice(
      to < 0 ? array.length + to : to,
      0,
      array.splice(from, 1)[0]
    );
  };

  const arrayMove = (array, from, to) => {
    const newArray = array.slice();
    arrayMoveMutate(newArray, from, to);
    return newArray;
  };

  const handleOrderQuestion = async (value) => {
    setLoading(true);
    try {
      const newArray = arrayMove(questionList, questionIndex, value.value);
      for (let index = 0; index < newArray.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await axios.put(newArray[index]['@id'], {
          ordering: index + 1
        });
      }
      dispatch(submitQuestion(newArray));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoading(false);
  };

  const orderOptions = () => {
    const options = questionList.map((q, i) => {
      return {
        label: i + 1,
        value: i
      };
    });
    return options;
  };

  const renderOrderQuestion = () => {
    return (
      <div className='question-form-position'>
        <Form.Group>
          <Form.Label>
            Posisi Pertanyaan
          </Form.Label>
          <Select
            options={orderOptions()}
            placeholder='Pilih Posisi Pertanyaan'
            className='question-form-position-select'
            classNamePrefix='question-form-position-select'
            styles={style}
            onChange={handleOrderQuestion}
            value={{
              value: questionIndex,
              label: questionIndex + 1,
            }}
            menuPlacement='top'
            maxMenuHeight={300}
          />
        </Form.Group>
      </div>
    );
  };

  const handleOnFocusDiffculty = () => {
    setPreviewContent(questionIndex);
  };

  const renderDifficulty = () => {
    return (
      <div className='question-form-position'>
        <Form.Group>
          <Form.Label>
            Tingkat Kesulitan
          </Form.Label>
          <Select
            options={QUESTION_DIFFICULTY}
            placeholder='Pilih Tingkat Kesulitan'
            className='question-form-position-select'
            classNamePrefix='question-form-position-select'
            styles={style}
            onChange={handleQuestionDifficulty}
            value={QUESTION_DIFFICULTY.find((d) => d.value === questionData.difficulty)}
            menuPlacement='top'
            maxMenuHeight={300}
            onFocus={handleOnFocusDiffculty}
          />
        </Form.Group>
      </div>
    );
  };

  const handleDeleteImage = async () => {
    try {
      await axios.delete(questionList[questionIndex].image);
      const newListOfQuestion = [...questionList];
      newListOfQuestion[questionIndex].image = null;
      dispatch(submitQuestion(newListOfQuestion));
      const updateData = {
        image: null
      };
      await updateQuestion(updateData);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleUploadDeleteFile = async () => {
    if (questionList[questionIndex].image) {
      await handleDeleteImage();
    } else {
      setPreviewContent(questionIndex);
      inputFile.current.click();
    }
  };

  const handleOnChangeFile = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      event.stopPropagation();
      setLoadingFile(true);
      setErrorUploadImage(false);
      const { target: { files } } = event;
      if (files[0] && files[0] !== undefined) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const img = document.createElement('img');
          img.onload = async () => {
            if (img.width !== QUESTION_IMAGE_WIDTH || img.height !== QUESTION_IMAGE_HEIGHT) {
              setErrorUploadImage(true);
              toast.error(
                'Ukuran gambar yang anda unggah salah. Pastikan ukuran gambar kamu 1440x2960.'
              );
            } else {
              const getTypeImage = files[0].type.split('/');
              const fileType = {
                fileExtension: getTypeImage[1]
              };
              const uploadedData = await dispatch(uploadImageToS3(fileType, files[0]));
              if (uploadedData) {
                const newListOfQuestion = [...questionList];
                newListOfQuestion[questionIndex].image = uploadedData['@id'];
                dispatch(submitQuestion(newListOfQuestion));
                const updateData = {
                  image: uploadedData['@id']
                };
                await updateQuestion(updateData);
              }
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      setErrorUploadImage(true);
    }
    setLoadingFile(false);
  };

  const renderUploadAndDeleteButton = () => {
    return (
      <div className='question-form-buttons'>
        <input
          type='file'
          id='upload-file-question'
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleOnChangeFile}
          onFocus={() => setPreviewContent(questionIndex)}
        />
        <div className='question-form-upload'>
          <Button disabled={loadingFile} onClick={handleUploadDeleteFile}>
            {questionList[questionIndex].image
              ? (
                <>
                  <TrashFill/> Delete File
                </>
              )
              : (
                <>
                  <CardImage/> Pilih File (.JPG)
                </>
              )}
          </Button>
          {errorUploadImage && 'Upload Failed!'}
          <span className='help-text'> (Ratio 37:18)</span>
        </div>
        <div
          className='question-form-buttons-remove'
          onClick={(event) => {
            setShowConfirmDelete(true);
            setOnClickEvent(event);
          }}
        >
          <TrashFill/>
        </div>
      </div>
    );
  };

  const optionContents = {
    trueOrFalse: renderAnswerTrueOrFalse(),
    multipleChoice: renderAnswerMultiple(),
    fillTheBlank: renderAnswerFillTheBlank()
  };

  return (
    <div
      className='question-form'
      id={`question-form-id-${questionIndex + 1}`}
      onClick={() => setPreviewContent(questionIndex)}
    >
      <ConfirmationModal
        handleSubmit={handleDeleteQuestion}
        setIsShowModal={setShowConfirmDelete}
        isShowModal={showConfirmDelete}
        doneMsg={'Berhasil Menghapus Pertanyaan!'}
        headerMsg={'Menghapus Pertanyaan'}
      />
      {questionData.answersFailed && (
        <Row>
          <Col xs={12} className='text-center text-danger'>
            Terjadi kesalahan pada saat membuat pertanyaan dan jawaban.
            Mohon hapus pertanyaan ini.
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          {renderQuestionInput(questionData.type)}
        </Col>
      </Row>
      <Row>
        {questionData.type === 'TYPE_TRUE_OR_FALSE' && optionContents.trueOrFalse}
        {questionData.type === 'TYPE_MULTIPLE_CHOICE' && optionContents.multipleChoice}
        {questionData.type === 'TYPE_FILL_THE_BLANK' && optionContents.fillTheBlank}
      </Row>
      <Row>
        <Col xs={12}>
          {renderAnswerKeyInput()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderVisualBriefInput()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderOrderQuestion()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderDifficulty()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderUploadAndDeleteButton()}
        </Col>
      </Row>
    </div>
  );
};

export default QuestionForm;
