import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`creativework_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`creativework_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={this.renderField}
          name="id"
          type="text"
          placeholder=""
          required={true}
        />
        <Field
          component={this.renderField}
          name="creator"
          type="text"
          placeholder="The creator/author of this CreativeWork. This is the same as the Author property for CreativeWork."
          required={true}
        />
        <Field
          component={this.renderField}
          name="createdFor"
          type="text"
          placeholder="the subject matter of the content"
        />
        <Field
          component={this.renderField}
          name="hasParts"
          type="text"
          placeholder="indicates a CreativeWork that is (in some sense) a part of this CreativeWork"
          required={true}
          normalize={v => (v === '' ? [] : v.split(','))}
        />
        <Field
          component={this.renderField}
          name="mediaObjects"
          type="text"
          placeholder="collection of media objects
project_tags"
          normalize={v => (v === '' ? [] : v.split(','))}
        />
        <Field
          component={this.renderField}
          name="isSubmitted"
          type="checkbox"
          placeholder=""
        />
        <Field
          component={this.renderField}
          name="rank"
          type="number"
          placeholder=""
          normalize={v => parseFloat(v)}
        />

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'creativework',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
