import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import Store from './Store/Store';
import * as serviceWorker from './serviceWorker';
import AppRouter from './Routers/AppRouter';
import 'cropperjs/dist/cropper.css';
import 'react-calendar/dist/Calendar.css';
import { API_BASE_URL } from './Constants/Constants';
import 'react-toastify/dist/ReactToastify.css';
import { refreshToken } from './Actions/Auth';

const store = Store();

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/ld+json';

axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('token');
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401
      && error.response.data.code === 401
      && error.response.data.message === 'Expired JWT Token') {
    store.dispatch(refreshToken(store.dispatch));

    // eslint-disable-next-line no-underscore-dangle
    if (!originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      return axios.request(originalRequest);
    }
  }
  return Promise.reject(error);
});

if (localStorage.getItem('imitating_email')) {
  axios.defaults.headers.common.imitate = localStorage.getItem('imitating_email');
}

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
    <ToastContainer />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
