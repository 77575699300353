/* eslint-disable */
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  API_BASE_URL,
  SEND_TOKEN_TO_EMAIL_URL
} from '../Constants/Constants';

export const hasError = (value) => ({
  type: 'HAS_ERROR',
  payload: value
});

export const isSuccessEmailSent = (value) => ({
  type: 'IS_SUCCESS_EMAIL_SENT',
  payload: value
});

export const passwordChangeSuccess = (value) => ({
  type: 'PASSWORD_CHANGE_SUCCESS',
  payload: value
});

export const  forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch(hasError(''));
      const { data } = await axios.post(SEND_TOKEN_TO_EMAIL_URL, email);
      if (data) {
        dispatch(isSuccessEmailSent(true));
      }
      return new Error();
    } catch (error) {
      return dispatch(hasError('Email yang Anda masukkan salah. Silakan coba lagi.'));
    }
  };
};

export const resetPasswordData = (request) => {
  return async (dispatch) => {
    try {
      dispatch(hasError(''));
      if(!isEmpty(request.token) && !isEmpty(request.id)) {
        const userId = request.id;
        const values = {
          newPassword: request.password,
          newRetypedPassword: request.confirmedPassword,
          token: request.token
        }
        const { data: res } = await axios.put(`${API_BASE_URL}/users/${userId}/reset-password-with-token`, values);
        if (res) {
          dispatch(passwordChangeSuccess(true));
        }
      } else {
        return dispatch(hasError('Terjadi kesalahan, Silakan coba lagi'));  
      }
    } catch (error) {
      return dispatch(hasError('Terjadi kesalahan, Silakan coba lagi'));
    }
  };
};
/* eslint-enable */
