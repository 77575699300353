/* eslint-disable no-nested-ternary */
import React from 'react';
import './CheckBox.scss';

const CheckBox = (props) => {
  const {
    label,
    onClick,
    option,
    checked
  } = props;
  return (
    <label className="checkbox-container">
      <span>{option ? option.label ? option.label : label : label}</span>
      <input onChange={onClick} type="checkbox" {...props} checked={checked} />
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckBox;
