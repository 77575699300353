import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const Logout = () => {
  useEffect(() => {
    // localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('avatar');
    window.location.reload();
  }, []);

  return (
    <Redirect to='/'/>
  );
};

export default Logout;
