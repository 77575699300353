import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrganizationCard from '../OrganizationCard/OrganizationCard';
import NoProjectsTab from '../NoProjectsTab/NoProjectsTab';
import GetUserDataBasedOnOrganizations from './GetUserDataBasedOnOrganizations';
import RenderFilterButtons from './RenderFilterButtons';

const ProjectListTab = () => {
  const organizationList = useSelector(
    (state) => state.organization.searchList
  );
  const [refreshUsersOrg, setRefreshUsersOrg] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const data = GetUserDataBasedOnOrganizations(true);

  const [organizations, setOrganizations] = useState({});

  useEffect(() => {
    setOrganizations({ ...organizationList.retrieved });
  }, [organizationList.retrieved]);

  const projectData = useSelector((state) => state.project.list);

  const handleSearchProjectByName = async (projectName = '') => {
    if (projectName === '') {
      setOrganizations({ ...organizationList.retrieved });
    } else {
      const name = projectName.toLowerCase();
      const projectIds = projectData.retrieved['hydra:member']
        .filter((project) => project.title && project.title.toLowerCase().includes(name))
        .map((project) => project['@id']);

      organizations['hydra:member'] = organizationList.retrieved['hydra:member']
        .filter((organization) => organization.project && projectIds.includes(organization.project));

      setOrganizations({ ...organizations });
    }
  };

  return (
    <>
      {/* we get organizations in filter */}
      <RenderFilterButtons
        refreshUsersOrg={refreshUsersOrg}
        setRefreshUsersOrg={setRefreshUsersOrg}
        searchProjectByName={(name) => handleSearchProjectByName(name)}
      />
      {organizations && organizations['hydra:totalItems'] ? (
        <Row className='my-projects-container-row'>
          {organizations && organizations['hydra:member'].map(
            (userOrganization, i) => (
              <Col key={i} xs={6} as='div' className='my-projects-container-row-col'>
                <OrganizationCard
                  key={userOrganization.id}
                  organization={userOrganization}
                  setRefreshUsersOrg={setRefreshUsersOrg}
                />
              </Col>
            )
          )}
        </Row>
      ) : (
        <NoProjectsTab />
      )
      }
    </>
  );
};

export default ProjectListTab;
