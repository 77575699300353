import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ArrowLeft, StarFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Dot } from 'react-animated-dots';
import axios from 'axios';
import './ReviewTeam.scss';
import Rating from 'react-rating';
import Button from '../Button/Button';
import reviewSuccess from '../../Assets/Images/review-success.png';
import TagName from '../Tags/TagName';
import {
  list as reviewActionList,
  reset as reviewActionReset,
} from '../../Generated/actions/review/list';

const ReviewTeam = (props) => {
  const dispatch = useDispatch();
  const {
    show, setShow, teamMembers, organization
  } = props;
  const userList = useSelector((state) => state.user.list);
  const mediaObjectList = useSelector((state) => state.mediaobject.list);
  const reviewList = useSelector((state) => state.review.list);
  const tagList = useSelector((state) => state.tags.list);
  const [users, setUsers] = useState();
  const [isRenderTeamMembers, setIsRenderTeamMembers] = useState(true);
  const [isReviewMember, setIsReviewMember] = useState(false);
  const [member, setMember] = useState({});
  const [ratingValue, setRatingValue] = useState(0);
  const [textArea, setTextArea] = useState('');
  const [isReview, setIsReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const translateTag = {
    COMMUNICATION: 'Komunikasi',
    ORGANIZATION: 'Organisasi',
    FUNCTIONAL_SKILL: 'Keterampilan',
    LEADERSHIP: 'Kepemimpinan',
    TEAMWORK: 'Kerja Sama'
  };

  useEffect(() => {
    if (tagList.retrieved) {
      const reviewTag = tagList.retrieved['hydra:member'].filter((t) => t.role === 'REVIEW_TAG');
      const mappedReviewTag = reviewTag.map((rt) => {
        return {
          id: rt['@id'],
          name: translateTag[rt.value],
          status: false
        };
      });
      setSkills(mappedReviewTag);
    }
  }, [tagList.retrieved]);

  useEffect(() => {
    if (show) {
      dispatch(reviewActionList(`/reviews?organization=${organization.id}&pagination=false`));
    }
    return () => {
      dispatch(reviewActionReset(reviewList.eventSource));
    };
  }, [dispatch, reviewList.eventSource, show]);

  useEffect(() => {
    if (userList.retrieved && mediaObjectList.retrieved && teamMembers && reviewList.retrieved) {
      const loggedUser = localStorage.getItem('id');
      const members = teamMembers.map((t) => {
        const user = userList.retrieved['hydra:member'].find((u) => u['@id'] === t.user);
        const picture = mediaObjectList.retrieved['hydra:member'].find((m) => m['@id'] === t.image);
        const userReviewedList = reviewList.retrieved['hydra:member'].filter((r) => r.itemReviewed === t.user);
        const review = userReviewedList.find((ur) => ur.author === loggedUser);
        return {
          ...t,
          ...user,
          picture,
          people: t['@id'],
          review,
        };
      });
      setUsers(members.filter((m) => m.user !== loggedUser));
    }
  }, [teamMembers, userList.retrieved, mediaObjectList.retrieved, reviewList.retrieved]);

  const handleAddReview = (items) => {
    setIsRenderTeamMembers(false);
    setIsReviewMember(true);
    setMember(items);
  };

  const handleBackReview = () => {
    setIsRenderTeamMembers(true);
    setIsReviewMember(false);
    setIsReview(false);
  };

  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  const handleAddSkill = (item) => {
    const newSkills = skills.map((skill) => {
      if (skill.id === item.id) {
        return {
          ...skill,
          status: !skill.status
        };
      }
      return skill;
    });
    setSkills(newSkills);
  };

  const handleChangeTextArea = (event) => {
    setTextArea(event.target.value);
  };

  const handleCancelReview = () => {
    setIsRenderTeamMembers(false);
    setIsReviewMember(true);
    setIsReview(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const review = {
      reviewBody: textArea,
      reviewRating: ratingValue,
      tags: skills.filter((skill) => skill.status).map((s) => s.id)
    };
    await axios.put(member.review['@id'], review);
    setRatingValue(0);
    setSkills((prevState) => prevState.map((s) => {
      return {
        ...s,
        status: false
      };
    }));
    const updatedUserReview = users.map((u) => {
      if (u.user === member.user) {
        return {
          ...u,
          review: {
            ...u.review,
            isSubmitted: true
          }
        };
      }
      return u;
    });
    setIsLoading(false);
    setUsers(updatedUserReview);
    setIsRenderTeamMembers(false);
    setIsReviewMember(false);
    setIsReview(true);
  };

  const handleAnotherReview = () => {
    setIsReview(false);
    if (users.filter((u) => !u.review.isSubmitted).length > 0) {
      setIsRenderTeamMembers(true);
    } else {
      setIsRenderTeamMembers(true);
      setShow(false);
    }
  };

  const renderTeamMembers = () => {
    return (
      <>
        {users && users.map((item, index) => (
          <div key={index} className='review-team-modal-member-profile'>
            <div className='review-team-modal-member-profile-avatar'>
              <img src={item.picture.contentUrl} alt='' />
            </div>
            <div className='review-team-modal-member-profile-info'>
              <h2>{item.username}</h2>
              <p className='review-team-modal-member-profile-info-company'>
                {item.worksFor}
              </p>
              {item.admin ? (
                <button
                  disabled
                  type='disabled'
                  className='review-team-modal-member-profile-info-admin'
                >
                  ADMIN
                </button>
              ) : null}
            </div>
            <div className='review-team-modal-member-profile-add-review'>
              {item.review && !item.review.isSubmitted && (
                <Button
                  onClick={() => handleAddReview(item)}
                  className='review-team-modal-member-profile-add-review-button'
                >
                  <StarFill /> Ulasan
                </Button>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderReviewMember = () => {
    return (
      <div className='review-team-modal-review'>
        <div className='review-team-modal-review-avatar'>
          <img src={member.picture.contentUrl} alt='' />
        </div>
        <div className='review-team-modal-member-profile-info'>
          <h2>{member.username}</h2>
          <p className='review-team-modal-member-profile-info-company'>
            {member.worksFor} <div className='middle-dot' />{' '}
            {member.tags.length === 1
              ? member.tags.map((tag, i) => <TagName key={i} tag={tag} />)
              : <><TagName tag={member.tags[0]} /> {` + ${member.tags.length - 1} Lainnya`}</>}
          </p>
          <Rating
            onChange={(rate) => handleRating(rate)}
            className='review-team-modal-member-profile-info-rating'
            emptySymbol={
              <StarFill className='review-team-modal-member-profile-info-rating-non-active' />
            }
            fullSymbol={
              <StarFill className='review-team-modal-member-profile-info-rating-active' />
            }
            start={0}
            stop={5}
            step={1}
            initialRating={ratingValue}
          />
        </div>
        {ratingValue === 0 ? null : (
          <div className='review-team-modal-member-profile-review'>
            <p className='review-team-modal-member-profile-review-title'>
              {ratingValue <= 3
                ? 'APA YANG BISA DITINGKATKAN DARI KINERJANYA?'
                : 'APA YANG LUAR BIASA DARINYA?'}
            </p>
            <div className='review-team-modal-member-profile-review-skill'>
              {skills.map((item) => (
                <Button
                  key={item.id}
                  onClick={() =>
                    handleAddSkill(item)
                  }
                  type={item.status ? null : 'secondary'}
                >
                  {item.name}
                </Button>
              ))}
            </div>
            <div className='review-team-modal-member-profile-review-optional'>
              <p className='review-team-modal-member-profile-review-optional-title'>
                BERIKAN PENDAPATMU TENTANG KINERJANYA! (OPSIONAL)
              </p>
              <textarea
                placeholder='Tulis sesuatu disini'
                className='review-team-modal-member-profile-review-optional-textarea'
                onChange={handleChangeTextArea}
              />
              <Button
                type={skills.filter((s) => s.status).length !== 0 ? null : 'disabled'}
                block={true}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    Sedang memulai{' '}
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                    <Dot>.</Dot>
                  </>
                ) : (
                  <>
                    Kirim Ulasan
                  </>
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderReviewDone = () => {
    return (
      <div className='review-team-modal-done'>
        <div className='review-team-modal-done-image'>
          <img src={reviewSuccess} alt='' />
        </div>
        <div className='review-team-modal-done-caption'>
          <h4>Yay! Ulasanmu sudah terkirim!</h4>
          <p>
            Terima kasih sudah terbuka dan objektif satu sama lain. Ulasanmu saat berarti untuk kemajuan timmu :)
          </p>
        </div>
        <div className='review-team-modal-done-button'>
          <Button block={true} onClick={handleAnotherReview}>
            {users.filter((u) => !u.review.isSubmitted).length > 0
              ? 'Berikan ulasan lagi' : 'Tutup'}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className='review-team'>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName='review-team-modal'
        backdrop='static'
        centered
      >
        <Modal.Header closeButton>
          {isReviewMember ? (
            <button
              className='review-team-modal-back'
              onClick={handleBackReview}
            >
              <ArrowLeft />
            </button>
          ) : null}
          {isReview ? (
            <button
              className='review-team-modal-back'
              onClick={handleCancelReview}
            >
              <ArrowLeft />
            </button>
          ) : null}
          <Modal.Title>
            {isRenderTeamMembers ? 'Ulasan Team' : `Ulasan ${member.username}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRenderTeamMembers ? renderTeamMembers() : null}
          {isReviewMember ? renderReviewMember() : null}
          {isReview ? renderReviewDone() : null}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReviewTeam;
