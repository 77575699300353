import axios from 'axios';
import { API_BASE_URL, PERSON_URL } from '../Constants/Constants';

export const isErrorCompleteProfile = (value) => ({
  type: 'IS_ERROR_COMPLETE_PROFILE',
  payload: value
});

export const completeProfile = (userId, roleAndTag, personData, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`${API_BASE_URL}${userId}`, roleAndTag,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/ld+json'
          }
        });
      const id = userId.split('/')[2];
      const { data } = await axios.get(`${PERSON_URL}?user=${id}`);
      const personId = data['hydra:member'][0]['@id'];
      const { data: dataPerson } = await axios.put(personId, personData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/ld+json'
          }
        });
      const { data: dataImage } = await axios.get(`${dataPerson.image}`);
      localStorage.setItem('avatar', dataImage.contentUrl);
      history.push('/papan-pintar');
    } catch (error) {
      dispatch(isErrorCompleteProfile(true));
    }
  };
};
