import React, { useState, useRef, useEffect } from 'react';
import {
  Container, Form, Modal, Dropdown
} from 'react-bootstrap';
import Cropper from 'cropperjs';
import { useFormik } from 'formik';
import axios from 'axios';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import ProfilePicture from '../../Assets/Images/profile-picture.png';
import Image from '../../Assets/Images/image.png';
import Button from '../../Components/Button/Button';
import Input from '../../Components/Input/Input';
import facebook from '../../Assets/Images/facebook.svg';
import behance from '../../Assets/Images/behance.svg';
import linkedin from '../../Assets/Images/linkedin.svg';
import instagram from '../../Assets/Images/instagram.svg';
import twitter from '../../Assets/Images/twitter.svg';
import github from '../../Assets/Images/github.svg';
import other from '../../Assets/Images/checked_icon.svg';
import dribble from '../../Assets/Images/dribble.svg';
import PhoneInput from '../../Components/PhoneInput/PhoneInput';
import './EditProfile.scss';
import { editProfileSchema } from '../../Schemas/EditProfileSchema';
import { editProfile, uploadImageToS3, updateSocialProfile } from '../../Actions/EditProfile';
import { useFetch } from '../../Hooks/UseFetch/UseFetch';
import {
  ADDRESS_REGION, BANKS_URL, OPTION_TAGS_URL, BANK_ACCOUNTS_URL, HASH_DECRYPT
} from '../../Constants/Constants';
import Loading from '../../Components/Loading/Loading';
import { decryptData } from '../../Helpers/Common';

const GenderOptions = [
  { label: 'Laki-Laki', value: 'male' },
  { label: 'Perempuan', value: 'female' },
  { label: 'Lainnya', value: 'other' },
];

const linksOptions = [
  { label: 'Facebook', value: 'facebook', image: facebook },
  { label: 'Twitter', value: 'twitter', image: twitter },
  { label: 'Instagram', value: 'instagram', image: instagram },
  { label: 'LinkedIn', value: 'linkedin', image: linkedin },
  { label: 'Behance', value: 'behance', image: behance },
  { label: 'Dribble', value: 'dribble', image: dribble },
  { label: 'Github', value: 'github', image: github },
  { label: 'Lainnya', value: 'other', image: other },
];

const style = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused && '#f2767e',
    boxShadow: state.isFocused && '0 0 0 0.2rem #f2767e',
    '&:hover': {
      border: '1px solid #ced4da'
    }
  })
};

const SocialProfileTab = ((props) => {
  return (
    <div className='edit-profile-tabs-content-container' >
      <h4 className='edit-profile-form-heading' >Media Sosial</h4>
      <Form>
        {props.socialLinks.map((x) => {
          return (
            <div className="">
              <div className="">
                <Input
                  label={x.name}
                  placeholder='Masukkan Link'
                  onChange={(e) => props.changeMediaLinks(x.name, x.id, e.target.value)}
                  value={x.url}
                />
              </div>
            </div>
          );
        })}
        {props.otherLinks.map((x, i) => {
          return (
            <div className="">
              <div className="row">
                <div className="col-4">
                  {i === 0 ? (
                    <Input
                      label="Lainnya"
                      placeholder='Masukkan nama'
                      onChange={(e) => props.setCustomMediaLink(x.id, e.target.value, '')}
                    />
                  ) : <Input
                    placeholder='Masukkan nama'
                    onChange={(e) => props.setCustomMediaLink(x.id, e.target.value, '')}
                  />
                  }
                </div>
                <div className="col-8">
                  <Input
                    placeholder='Masukkan link'
                    onChange={(e) => props.setCustomMediaLink(x.id, '', e.target.value)} />
                </div>
              </div>
              {/* <Button onClick={() => removeSocialLinks(i)} >
                Remove
            </Button> */}
            </div>
          );
        })}
      </Form>
      <Dropdown>
        <Dropdown.Toggle as={props.CustomToggle}>
          <Button
            bsPrefix='custom-button btn edit-profile-btn-outline-primary'
          >
            <i className='fa fa-plus-circle edit-profile-btn-outline-primary-icon' />
            Tambah Tautan Baru
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu as={props.CustomMenu}>
          <Dropdown.Header>Pilih Link</Dropdown.Header>
          <Dropdown.Divider />
          {props.linksOptions.map((item) => {
            return <Dropdown.Item onSelect={() => props.addSocialLinks(item.value)}><img
              src={item.image}
              alt={item.label}
              className='edit-profile-personal-info-img-container-upload-btn-img'
            />{item.label}</Dropdown.Item>;
          })
          }
        </Dropdown.Menu>
      </Dropdown>
      <div className='edit-profile-social-profile-btn-update' >
        <Button block
          disabled={isEmpty(props.personId)}
          ref={props.buttonRef}
          type='submit'
          onClick={(event) => props.saveSocialMediaLinks(event)}
        >
          Perbarui Profil Sosial
        </Button>
      </div>
    </div>
  );
});

const AccountSettingTab = ((props) => {
  return (
    <div className='edit-profile-tabs-content-container' >
      <Form>
        <h4 className='edit-profile-form-heading' >Akun</h4>
        <Form.Group controlId="instituteInput">
          <Form.Label className='form-input-label'>Nama Pengguna</Form.Label>
          <Form.Control
            className='form-input-control'
            type='text'
            disabled='false'
            placeholder='Masukkan Nama Pengguna'
            isInvalid={props.activeField !== 'username' && !isEmpty(props.errors.username)}
            value={props.profileData.username}
            onFocus={() => props.setActiveField('username')}
            onBlur={() => {
              props.setActiveField('username');
            }}
            autoFocus={props.activeField === 'username'}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="instituteInput">
          <Form.Label className='form-input-label'>EMAIL</Form.Label>
          <Form.Control
            className='form-input-control'
            type='text'
            disabled='false'
            placeholder='Masukkan Email'
            isInvalid={props.activeField !== 'email' && !isEmpty(props.errors.email)}
            value={!isEmpty(props.userData) ? props.userData.email : ''}
            onFocus={() => props.setActiveField('email')}
            onBlur={() => {
              props.setActiveField('email');
            }}
            autoFocus={props.activeField === 'email'}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <PhoneInput
          controlId="phoneInput"
          label='Nomor Telpon' placeholder='Masukkan Nomor Telpon'
          onChange={(e) => props.handleOnChange('phoneNumber', e.target.value)}
          value={!isEmpty(props.profileData) ? props.profileData.phoneNumber.replace('+62', '') : ''}
        />

        <h4 className='edit-profile-form-heading' >Akun Bank</h4>
        <Form.Group controlId="accountNameInput">
          <Form.Label className='form-input-label'>Nama Pemilik</Form.Label>
          <Form.Control
            className='form-input-control'
            type='text'
            placeholder='Masukkan Nama Pemilik'
            isInvalid={props.activeField !== 'holder' && !isEmpty(props.errors.holder)}
            value={!isEmpty(props.profileData) ? props.profileData.holder : ''}
            onFocus={() => props.setActiveField('holder')}
            onBlur={() => {
              props.setActiveField('holder');
            }}
            autoFocus={props.activeField === 'holder'}
            onChange={(e) => props.handleOnChange('holder', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.holder}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className='complete-profile-form-input-label'>Nama Bank</Form.Label>
          <Select
            options={props.banksOptions}
            placeholder='Pilih Nama Bank'
            className='complete-profile-form-select'
            classNamePrefix='complete-profile-form-select'
            styles={style}
            onChange={(e) => props.handleOnChange('bank', e.value)}
            value={props.banksOptions.filter((option) => {
              return option.value === props.profileData.bank;
            })}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.bank}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="accountNameInput">
          <Form.Label className='form-input-label'>Nomor Bank</Form.Label>
          <Form.Control
            className='form-input-control'
            type='number'
            placeholder='Masukkan Nomor Bank'
            isInvalid={props.activeField !== 'accountNumber' && !isEmpty(props.errors.accountNumber)}
            value={!isEmpty(props.profileData) ? props.profileData.accountNumber : ''}
            onFocus={() => props.setActiveField('accountNumber')}
            onBlur={() => {
              props.setActiveField('accountNumber');
            }}
            autoFocus={props.activeField === 'accountNumber'}
            onChange={(e) => props.handleOnChange('accountNumber', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.accountNumber}
          </Form.Control.Feedback>
        </Form.Group>

        <div className='edit-profile-account-setting-btn-update' >
          <Button block
            disabled={isEmpty(props.personId)}
            ref={props.buttonRef}
            type='submit'
            onClick={(event) => props.handleEditProfile(event)}
          >
            Simpan Perubahan
          </Button>
        </div>
      </Form>
    </div>
  );
});
const EditProfile = () => {
  const imgInput = useRef(null);

  const imgToCropRef = useRef(null);

  const [activeTab, setActiveTab] = useState('personalInfo');

  const [showCropPicModal, setShowCropPicModal] = useState(false);

  const [cropper, setCropper] = useState(null);

  const [croppedImgUrl, setShowCroppedImgUrl] = useState('');

  const [imageUrl, setImageUrl] = useState(ProfilePicture);

  const buttonRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [SpecialityOptions, setSpecialityOptions] = useState([]);

  const dispatch = useDispatch();
  const userid = localStorage.getItem('id');
  const { fetchedData: { data: userData } } = useFetch(localStorage.getItem('id'));
  const { fetchedData: { data: personData } } = useFetch(`/people?pagination=false&user[]=${userid}`);
  const { fetchedData: { data: addressRegion } } = useFetch(ADDRESS_REGION);
  const { fetchedData: { data: banksData } } = useFetch(BANKS_URL);
  const banksOptions = !isEmpty(banksData) ? banksData
    && banksData['hydra:member'].map((value) => {
      return {
        value: value['@id'],
        label: value.name
      };
    }) : [];
  const addressRegionOptions = !isEmpty(addressRegion) ? addressRegion
    && addressRegion['hydra:member'].map((value) => {
      return {
        value: value['@id'],
        label: value.name
      };
    }) : [];
  const user = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
  const role = user ? user.roles
    .find((r) => r === 'ROLE_TEACHER' || r === 'ROLE_DESIGNER') : '';
  const { fetchedData: { data: tagData } } = useFetch(OPTION_TAGS_URL, {
    role
  });

  const profileDataStateFields = {
    username: '',
    gender: '',
    jobTitle: '',
    tags: [],
    worksFor: '',
    addressRegion: '',
    phoneNumber: '',
    holder: '',
    accountNumber: '',
    bank: '',
    bankAccount: ''
  };

  const [profileData, setProfileData] = useState(profileDataStateFields);
  const [activeField, setActiveField] = useState('');
  const [personId, setPersonId] = useState('');
  const [imageDetails, setImageDetails] = useState({
    name: '',
    type: '',
    fileExtension: '',
  });
  const [socialLinks, setSocialLinks] = useState([]);
  const [otherLinks, setOtherLinks] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getAccountBank = async () => {
      if (userData) {
        if (!isEmpty(userData.bankAccounts)) {
          const { data } = await axios.get(userData.bankAccounts[0]);
          setProfileData((prevData) => ({
            ...prevData,
            holder: data.holder,
            accountNumber: data.accountNumber,
            bank: data.bank,
            bankAccount: userData.bankAccounts[0]
          }));
        }
      }
    };
    getAccountBank();
  }, [userData]);

  useEffect(() => {
    if (tagData) {
      const specialityOptions = tagData['hydra:member'].map((value) => {
        return {
          value: value['@id'],
          label: value.tag
        };
      });
      setSpecialityOptions(specialityOptions);
    }
  }, [tagData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (personData) {
          setLoading(false);
          const mappedMember = personData['hydra:member'][0];
          if (!isEmpty(mappedMember)) {
            setProfileData({
              ...profileData,
              gender: mappedMember.gender,
              jobTitle: mappedMember.jobTitle,
              addressRegion: mappedMember.addressRegion,
              worksFor: mappedMember.worksFor,
              phoneNumber: mappedMember.phoneNumber.replace('+62', '')
            });
            setPersonId(mappedMember.id);
            setLoading(true);
            try {
              const { data: imageData } = await axios.get(`${mappedMember.image}`);
              if (!isEmpty(imageData && imageData.contentUrl)) {
                setLoading(false);
                setImageUrl(imageData.contentUrl);
              }
            } catch (error) {
              setLoading(false);
            }
            setLoading(true);
            try {
              const { data: contactsData } = await axios.get(`/people/${mappedMember.id}/contacts`);
              if (!isEmpty(contactsData)) {
                setLoading(false);
                const contacts = contactsData['hydra:member'].filter((value, memberIndex) => {
                  if (value.name !== 'other') {
                    contactsData['hydra:member'][memberIndex].url = value.value;
                    contactsData['hydra:member'][memberIndex].isExist = 1;
                    return true;
                  }
                  return false;
                });
                setSocialLinks(contacts);

                const otherContacts = contactsData['hydra:member'].filter((value, memberIndex) => {
                  if (value.name === 'other') {
                    contactsData['hydra:member'][memberIndex].url = value.value;
                    contactsData['hydra:member'][memberIndex].isExist = 1;
                    return true;
                  }
                  return false;
                });
                setOtherLinks(otherContacts);
              }
            } catch (error) {
              setLoading(false);
            }
          }
        } else {
          setPersonId('');
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [personData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (userData) {
        setLoading(false);
        setProfileData({
          ...profileData,
          username: userData.username,
        });
        if (SpecialityOptions) {
          const selectedTags = SpecialityOptions.filter((e) => {
            return userData.tags.some((item) => item === e.value);
          });
          setTags(selectedTags);
        }
      }
    })();
  }, [userData, SpecialityOptions]);

  const initCropper = () => {
    const newCropper = new Cropper(imgToCropRef.current, {
      aspectRatio: 1 / 1,
      background: false,
    });
    return setCropper(newCropper);
  };

  const handleOnChangeFile = async (event) => {
    try {
      if (event && !isEmpty(personId)) {
        setLoading(true);
        const uploadedData = await dispatch(uploadImageToS3(imageDetails, event));
        setLoading(false);
        if (uploadedData) {
          const imageIRI = {
            image: uploadedData['@id']
          };
          setLoading(true);
          localStorage.setItem('avatar', uploadedData.contentUrl);
          return dispatch(editProfile(personId, imageIRI)).then(() => {
            setLoading(false);
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
    return null;
  };

  const onCropClick = () => {
    const imgurl = cropper.getCroppedCanvas().toDataURL();
    setShowCroppedImgUrl(imgurl);
    setShowCropPicModal(false);
    handleOnChangeFile(imgurl);
  };

  const addSocialLinks = (label) => {
    if (label === 'other') {
      setOtherLinks([...otherLinks, { id: uuidv4(), name: label, url: '' }]);
    } else {
      setSocialLinks([...socialLinks, { id: uuidv4(), name: label, url: '' }]);
    }
  };

  // const removeSocialLinks = (index) => {
  //   const list = [...socialLinks];
  //   list.splice(index, 1);
  //   setSocialLinks(list);
  // };

  const formik = useFormik({
    initialValues: {
      username: '',
      gender: '',
      jobTitle: '',
      tags: [],
      worksFor: '',
      addressRegion: '',
    },
    validationSchema: editProfileSchema,
  });

  const {
    errors,
  } = formik;

  // Save personal-info details
  const handleEditProfile = async (event) => {
    try {
      event.preventDefault();
      const data = {
        ...profileData,
        phoneNumber: `+62${profileData.phoneNumber}`
      };
      setLoading(true);
      if (!isEmpty(profileData.holder) && !isEmpty(profileData.bank) && !isEmpty(profileData.accountNumber)) {
        if (isEmpty(profileData.bankAccount)) {
          const { data: dataAccount } = await axios.post(BANK_ACCOUNTS_URL, {
            id: uuidv4(),
            holder: profileData.holder,
            bank: profileData.bank,
            accountNumber: profileData.accountNumber
          });
          setProfileData((prevData) => ({
            ...prevData,
            bankAccount: dataAccount['@id']
          }));
        } else {
          await axios.put(profileData.bankAccount, {
            holder: profileData.holder,
            bank: profileData.bank,
            accountNumber: profileData.accountNumber
          });
        }
      } else {
        setProfileData((prevData) => ({
          ...prevData,
          holder: '',
          bank: '',
          accountNumber: '',
        }));
      }
      await dispatch(editProfile(personId, data));
      toast.success('Perubahan Anda Berhasil Disimpan');
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat melakukan perubahan. Silahkan muat ulang halaman.');
      }
    }
    setLoading(false);
  };

  const saveSocialMediaLinks = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      await dispatch(updateSocialProfile(personId, otherLinks));
      await dispatch(updateSocialProfile(personId, socialLinks));
      toast.success('Perubahan Anda Berhasil Disimpan');
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat melakukan perubahan. Silahkan muat ulang halaman.');
      }
    }
    setLoading(false);
  };

  // Based on field changes
  const handleOnChange = async (name, value) => {
    let listOfTag = [];
    let updatedValue = value;
    if (name === 'tags') {
      setTags(updatedValue);
      if (!isEmpty(value)) {
        listOfTag = updatedValue.map((tag) => tag.value);
      }
      updatedValue = listOfTag;
    }
    const object = { ...profileData, [name]: updatedValue };
    await setProfileData(object);
  };

  const value = '';
  const CustomMenu = React.forwardRef(
    (
      {
        children, customStyle, className, 'aria-labelledby': labeledBy
      }, ref
    ) => {
      return (
        <div
          ref={ref}
          style={customStyle}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  // Based on field changes
  const changeMediaLinks = async (name, index, changedValue) => {
    const existLinkData = socialLinks.filter((option) => {
      return option.id === index;
    });
    if (existLinkData.length) {
      socialLinks.map((option, optionIndex) => {
        if (option.id === index) {
          socialLinks[optionIndex].value = changedValue;
          socialLinks[optionIndex].url = changedValue;
          setSocialLinks([...socialLinks]);
        }
        return false;
      });
    } else {
      const data = {
        id: index,
        name,
        value: changedValue
      };
      setSocialLinks([
        ...socialLinks,
        data,
      ]);
    }
  };

  const setCustomMediaLink = async (index, name, linkValue) => {
    const existLinkData = otherLinks.filter((option) => {
      return option.id === index;
    });
    if (existLinkData.length) {
      otherLinks.map((option, optionIndex) => {
        if (option.id === index) {
          if (name !== '') {
            otherLinks[optionIndex].name = name;
          }
          if (linkValue !== '') {
            otherLinks[optionIndex].value = linkValue;
            otherLinks[optionIndex].url = linkValue;
          }
          setOtherLinks(otherLinks);
        }
        return false;
      });
    } else {
      const data = {
        id: index,
        name: linkValue,
        value: ''
      };
      setOtherLinks([
        ...otherLinks,
        data,
      ]);
    }
  };

  const renderHeader = () => {
    return (
      <div className='edit-profile-header' >
        <h1>Profil Kamu</h1>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className='edit-profile-tabs' >
        <div
          className={`edit-profile-tabs-tab ${activeTab === 'personalInfo' ? 'active' : ''}`}
          onClick={() => setActiveTab('personalInfo')}
        >
          Info Pribadi
        </div>
        <div
          className={`edit-profile-tabs-tab ${activeTab === 'socialProfile' ? 'active' : ''}`}
          onClick={() => setActiveTab('socialProfile')} >Profil Sosial</div>
        <div
          className={`edit-profile-tabs-tab ${activeTab === 'accountSetting' ? 'active' : ''}`}
          onClick={() => setActiveTab('accountSetting')} >Pengaturan Akun</div>
      </div>
    );
  };

  const renderPersonalInfoForm = () => {
    return (
      <div>
        <Form>
          <h4 className='edit-profile-form-heading' >Tentang Kamu</h4>
          <Form.Group controlId="usernameInput">
            <Form.Label className='form-input-label'>Nama</Form.Label>
            <Form.Control
              className='form-input-control'
              type='text'
              key="usernameId"
              placeholder='Masukkan Nama'
              disabled='false'
              isInvalid={activeField !== 'username' && !isEmpty(errors.username)}
              onChange={(e) => handleOnChange('username', e.target.value)}
              value={profileData.username}
              onFocus={() => setActiveField('username')}
              onBlur={() => {
                setActiveField('username');
              }}
              autoFocus={activeField === 'username'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Jenis Kelamin</Form.Label>
            <Select
              options={GenderOptions}
              placeholder='Pilih Jenis Kelamin'
              className='complete-profile-form-select'
              classNamePrefix='complete-profile-form-select'
              styles={style}
              onChange={(e) => handleOnChange('gender', e.value)}
              value={GenderOptions.filter((option) => {
                return option.value === profileData.gender;
              })}
            />
          </Form.Group>
          <h4 className='edit-profile-form-heading' >Pekerjaan Terakhir</h4>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Pekerjaan</Form.Label>
            <Form.Control
              className='form-input-control'
              type='text'
              placeholder='Masukkan Pekerjaan Terakhir'
              isInvalid={activeField !== 'name' && !isEmpty(errors.jobTitle)}
              onChange={(e) => handleOnChange('jobTitle', e.target.value)}
              value={profileData.jobTitle}
              onFocus={() => setActiveField('jobTitle')}
              onBlur={() => {
                setActiveField('jobTitle');
              }}
              autoFocus={activeField === 'jobTitle'}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Spesialisasi</Form.Label>
            <Select
              isMulti
              options={SpecialityOptions}
              placeholder='Pilih Spesialisasi'
              className='complete-profile-form-select'
              classNamePrefix='complete-profile-form-select'
              styles={style}
              onChange={(e) => handleOnChange('tags', e.value)}
              value={tags}
            />
          </Form.Group>
          <Form.Group controlId="instituteInput">
            <Form.Label className='form-input-label'>Institusi</Form.Label>
            <Form.Control
              className='form-input-control'
              type='text'
              placeholder='Masukkan Nama Institusi'
              isInvalid={activeField !== 'name' && !isEmpty(errors.worksFor)}
              onChange={(e) => handleOnChange('worksFor', e.target.value)}
              value={profileData.worksFor}
              onFocus={() => setActiveField('worksFor')}
              onBlur={() => {
                setActiveField('worksFor');
              }}
              autoFocus={activeField === 'worksFor'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.worksFor}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Lokasi</Form.Label>
            <Select
              options={addressRegionOptions}
              placeholder='Pilih Lokasi'
              className='complete-profile-form-select'
              classNamePrefix='complete-profile-form-select'
              styles={style}
              onChange={(e) => handleOnChange('addressRegion', e.value)}
              value={addressRegionOptions.filter((option) => {
                return option.value === profileData.addressRegion;
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.addressRegion}
            </Form.Control.Feedback>
          </Form.Group>
          <div className='edit-profile-personal-info-btn-save' >
            <Button block
              disabled={isEmpty(personId)}
              ref={buttonRef}
              type='submit'
              onClick={(event) => handleEditProfile(event)}
            >
              Simpan Perubahan
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  const PersonalInfoTab = () => {
    return (
      <div className='edit-profile-tabs-content-container' >
        <div className='edit-profile-personal-info-img-container' >
          <img
            src={croppedImgUrl || imageUrl}
            alt=""
            className='edit-profile-personal-info-img-container-img'
          />
          <Button disabled={isEmpty(personId)} onClick={() => imgInput.current.click()} >
            <img
              src={Image}
              alt="add-new"
              className='edit-profile-personal-info-img-container-upload-btn-img'
            />
            Unggah Foto Baru
          </Button>
        </div>
        {renderPersonalInfoForm()}
      </div>
    );
  };

  const ImgFileInput = () => {
    const onImgInputChange = (file) => {
      if (file) {
        const getTypeFile = file.type.split('/');
        setImageDetails({
          ...imageDetails,
          name: file.name,
          type: file.type,
          fileExtension: getTypeFile[1]
        });
        const reader = new FileReader();
        reader.onload = (e) => {
          imgToCropRef.current.setAttribute('src', e.target.result);
        };
        reader.readAsDataURL(file);
        setTimeout(initCropper, 1000);
      }
    };

    return (
      <input
        type='file'
        className='edit-profile-img-file-input'
        ref={imgInput}
        onChange={(e) => {
          onImgInputChange(e.target.files[0]);
          setShowCropPicModal(true);
        }}
      />
    );
  };

  const renderCropImageModal = () => {
    return (
      <Modal
        show={showCropPicModal}
        dialogClassName='edit-profile-modal'
        backdrop='static'
        centered
      >
        <div className='start-proposal-modal-header' >
          <i
            className='fa fa-times start-proposal-modal-header-close'
            onClick={() => setShowCropPicModal(false)}
          />
          <div className='start-proposal-modal-header-team-name' >Potong Gambar</div>
          <div className='edit-profile-modal-header-sub'>
            Potong foto profilmu sesuai persayaratan
          </div>
          <div
            className='start-proposal-modal-header-days'
          >
          </div>
        </div>

        <div className='edit-profile-modal-img-container' >
          <img src='' alt="" ref={imgToCropRef} />
        </div>
        <div className='edit-profile-modal-btn' >
          <Button block onClick={() => onCropClick()}>Potong Gambar</Button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <NavigationBar />
      <Container>
        {loading && <Loading />}
        <div className='edit-profile-width' >
          {renderHeader()}
          {renderTab()}
          {activeTab === 'personalInfo' && <PersonalInfoTab />}
          {activeTab === 'socialProfile' && <SocialProfileTab
            socialLinks={socialLinks}
            changeMediaLinks={changeMediaLinks}
            otherLinks={otherLinks}
            setCustomMediaLink={setCustomMediaLink}
            CustomToggle={CustomToggle}
            addSocialLinks={addSocialLinks}
            saveSocialMediaLinks={saveSocialMediaLinks}
            buttonRef={buttonRef}
            personId={personId}
            linksOptions={linksOptions}
            CustomMenu={CustomMenu}
          />}
          {activeTab === 'accountSetting' && <AccountSettingTab
            activeField={activeField}
            errors={errors}
            profileData={profileData}
            setActiveField={setActiveField}
            userData={userData}
            personId={personId}
            buttonRef={buttonRef}
            handleEditProfile={handleEditProfile}
            handleOnChange={handleOnChange}
            banksOptions={banksOptions}
          />}
        </div>
        {renderCropImageModal()}
      </Container>
      <ImgFileInput />
    </>
  );
};

export default EditProfile;
