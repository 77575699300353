import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  // StarFill, Trophy,
  PersonPlusFill
} from 'react-bootstrap-icons';
import './TeamDetailCard.scss';
import { useSelector } from 'react-redux';
// import avatarImage from '../../Assets/Images/avatar-image.png';
import { Modal } from 'react-bootstrap';
import Button from '../Button/Button';
import Label from '../Label/Label';
import SignInModal from '../SignInModal/SignInModal';
import JoinTeamModal from '../JoinTeamModal/JoinTeamModal';
import UserListWithProfile from '../User/UserListWithProfile';

const TeamDetailCard = ({
  organization,
  isActiveDetail,
  setIsActiveDetail
}) => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isShowJoinTeamModal, setIsShowJoinTeamModal] = useState(false);

  const userList = useSelector((state) => state.user.list);

  const handleOnHideDetail = () => {
    setIsActiveDetail(false);
  };

  const handleLoginModal = () => {
    setIsShowLoginModal(true);
    setIsActiveDetail(false);
  };

  const handleJoinTeamModal = () => {
    setIsShowJoinTeamModal(true);
    setIsActiveDetail(false);
  };

  return (
    <>
      <Modal
        show={isActiveDetail}
        dialogClassName='team-detail-modal'
        backdrop={true}
        onHide={handleOnHideDetail}
        centered
      >
        <div className='team-detail-card'>
          <div className='team-detail-card-headline'>
            <h1>
              {organization.name.replace(' and ', ' dan ')}
            </h1>
            <Label type={organization.status} />
          </div>
          <div className='team-detail-card-scrollable'>
            {organization.members.map((member) => {
              const handleClick = () => null;
              return userList.retrieved
                && userList.retrieved['hydra:member']
                  .filter((userFromList) => userFromList['@id'] === member)
                  .length > 0
                && <UserListWithProfile
                  organization={organization}
                  item={userList.retrieved['hydra:member']
                    .filter((userFromList) => userFromList['@id'] === member)
                    .shift()}
                  key={member} handleClick={handleClick}/>;
            })}
            <div className='team-detail-card-request'>
              {(organization.status === 'STATUS_NEED_PARTNER'
                && !organization.members.includes(localStorage.getItem('id')))
                && (
                  <Button
                    block={true}
                    onClick={
                      !localStorage.getItem('id')
                        ? handleLoginModal
                        : handleJoinTeamModal
                    }
                  >
                    <PersonPlusFill /> Kirim Permintaan Bergabung
                  </Button>
                )}
            </div>
          </div>
        </div>
      </Modal>
      <SignInModal
        isShowModal={isShowLoginModal}
        setIsShowModal={setIsShowLoginModal}
      />
      <JoinTeamModal
        isShowModal={isShowJoinTeamModal}
        setIsShowModal={setIsShowJoinTeamModal}
        organization={organization}
      />
    </>
  );
};

TeamDetailCard.propTypes = {
  organization: PropTypes.object.isRequired,
  mappedMembers: PropTypes.object,
  mappedImages: PropTypes.object,
};

TeamDetailCard.defaultProps = {
  organization: {},
};

export default TeamDetailCard;
