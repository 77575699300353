import React from 'react';
import PropTypes from 'prop-types';
import './ProjectList.scss';
import { Stopwatch, FileEarmarkText, PersonPlusFill, FileEarmarkCheck } from 'react-bootstrap-icons';
import moment from 'moment';
import 'moment/locale/id';
import { useHistory } from 'react-router-dom';
import Tag from '../Tags/Tag';
import EducationalLevel from '../EducationalLevel/EducationalLevel';
import MathSideImg from '../../Assets/Images/math-card-side.png';
import ScienceSideImg from '../../Assets/Images/science-card-side.png';

moment.locale('id');

const ProjectList = (props) => {
  const { projectList, mappedTags, awardList } = props;
  const history = useHistory();

  const getProjectTagValues = (tagsArray) => {
    return tagsArray.map((tag) => mappedTags[tag].value);
  };

  const getLabelImg = (tagsArray) => {
    if (Object.keys(mappedTags).length > 0) {
      const projectTagValues = getProjectTagValues(tagsArray);

      if (projectTagValues.includes('MATH')) {
        return (
          <div className='project-list-items-images math'>
            <img src={MathSideImg} alt='' />
          </div>
        );
      }

      if (projectTagValues.includes('SCIENCE')) {
        return (
          <div className='project-list-items-images science'>
            <img src={ScienceSideImg} alt='' />
          </div>
        );
      }
    }

    return (
      <div className='project-list-items-images science'>
        <img src={ScienceSideImg} alt='' />
      </div>
    );
  };

  const getFirstAward = (project) => {
    const firstAward =
      awardList && awardList['hydra:member'].find((a) => a['@id'] === project.awards[0] && a.rank === 1);
    return firstAward ? firstAward.name : '';
  };

  return (
    <div className='project-list' id='project-list'>
      {projectList &&
        projectList.map((project) => (
          <div className='project-list-items' key={project['@id']}>
            {getLabelImg(project.tags)}
            <div className='project-list-items-description'>
              <div className='project-list-items-description-text'>
                <h1
                  onClick={() => {
                    history.push(`/lesson-request/${project['@id'].split('/')[2]}`);
                  }}
                >
                  {project.title}
                </h1>
                <p>{project.objective}</p>
                {project.lessonType === 3 && (
                  <ul className='project-list-items-description-list'>
                    <li>
                      <span>Sahabat Pintar akan menyediakan Data Pelajaran (Pengisi Suara dan Video)</span>
                    </li>
                  </ul>
                )}
              </div>
              <div className='project-list-items-description-badge'>
                <EducationalLevel educationalLevel={project.educationalLevel} />
                {project.tags.map((tag) => (
                  <Tag tag={tag} key={tag} />
                ))}
              </div>
            </div>
            <div className='project-list-items-info'>
              <h1>
                {/* {(project.price).toLocaleString('id', { */}
                {/* style: 'currency', */}
                {/* currency: 'IDR', */}
                {/* minimumFractionDigits: 0 */}
                {/* })} */}
                {getFirstAward(project)}
              </h1>
              {project.lessonType === 3 && (
                <p>
                  <FileEarmarkCheck /> &nbsp; Data Pelajaran Disediakan
                </p>
              )}
              <p>
                <Stopwatch /> &nbsp;
                {moment(project.endTime).fromNow()}
              </p>
              <p>
                <FileEarmarkText /> &nbsp;
                {project.creativeWorks.length} proposal
              </p>
              <p className={project.teamNeedPartnerCounter === 0 ? 'no-team' : 'available-team'}>
                <PersonPlusFill /> &nbsp;
                {project.teamNeedPartnerCounter} tim butuh partner
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

ProjectList.propTypes = {
  projectList: PropTypes.any,
  projectTags: PropTypes.any,
  awardList: PropTypes.any,
};
Tag.propTypes = { tag: PropTypes.any };

export default ProjectList;
