import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

function TagName(props) {
  const listOfTags = useSelector((state) => state.tags.list);
  const [tag, setTag] = useState('');
  useEffect(() => {
    if (listOfTags.retrieved) {
      setTag(listOfTags.retrieved['hydra:member'].filter((tagFromList) => (tagFromList['@id'] === props.tag)).length > 0
        && listOfTags.retrieved['hydra:member'].filter((tagFromList) => (tagFromList['@id'] === props.tag))
          .shift().name);
    }
  }, [props.tag, listOfTags.retrieved]);

  return tag;
}
export default TagName;
