import React, { useRef } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import RenderRecipients from '../AddFriend/RenderRecipients';
import './AreYouSureSubmitMembersModal.scss';
import Button from '../Button/Button';

const AreYouSureSubmitMembersModal = (props) => {
  const {
    show,
    handleClose,
    handleGoBack,
    recipients,
    handleSubmitConfirmationModal,
    handleCancelRecipient,
    handleClearRecipients,
  } = props;
  const inputEl = useRef(null);

  const handleSubmit = () => {
    handleSubmitConfirmationModal(inputEl.current.checked);
  };

  return (<>
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='add-friend-modal'
      backdrop='static'
      centered
    >
    <Modal.Header closeButton >
      <ArrowLeft
        className='add-friend-modal-back'
        onClick={handleGoBack}
      />
      <Modal.Title>
        Apakah kamu yakin ingin <br />
        mengundang mereka?
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='add-friend-modal-done'>
        <div className={'blue-border'}>
          <div className="clear-all" onClick={handleClearRecipients}>HAPUS SEMUA</div>
          <RenderRecipients recipients={recipients} handleCancelRecipient={handleCancelRecipient}/>
        </div>
        <p className={'invite-disclosure'}>
          Kamu bisa mengundang orang sebanyak-banyaknya, tapi kamu hanya bisa membuat tim terdiri dari <br/>
          maksimal 4 orang untuk setiap proposal.</p>
        <div className={'confirmation-modal-badge bg-light'}>
          <Form.Check
            type="switch"
            label="Saya juga terbuka untuk siapapun yang ingin bergabung"
            id="confirmation-switch"
            className={'confirmation-switch'}
            ref={inputEl}
          />
        </div>
        <Button onClick={handleGoBack} type="secondary" halfWidth={true}>Undang Lebih Banyak</Button>
        <Button onClick={handleSubmit} type="submit" halfWidth={true}>Kirim Undangan</Button>
      </div>
    </Modal.Body>
    </Modal>
  </>);
};

export default AreYouSureSubmitMembersModal;
