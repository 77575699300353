import {
  applyMiddleware,
  combineReducers,
  createStore
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import authReducer from '../Reducers/Auth';
import questionReducer from '../Reducers/Question';
import completeProfileReducer from '../Reducers/CompleteProfile';
import forgotPasswordReducer from '../Reducers/ForgotPassword';
import editProfileReducer from '../Reducers/EditProfile';
import organizationunconfirmedmembers from '../Generated/reducers/organizationunconfirmedmembers';
import answer from '../Generated/reducers/answer';
import bank from '../Generated/reducers/bank';
import userresetpassword from '../Generated/reducers/userresetpassword';
import userconfirmation from '../Generated/reducers/userconfirmation';
import user from '../Generated/reducers/user';
import project from '../Generated/reducers/project';
import educationallevel from '../Generated/reducers/educationallevel';
import tags from '../Generated/reducers/tags';
import creativework from '../Generated/reducers/creativework';
import person from '../Generated/reducers/person';
import question from '../Generated/reducers/question';
import mediaobject from '../Generated/reducers/mediaobject';
import bankaccount from '../Generated/reducers/bankaccount';
import addressregion from '../Generated/reducers/addressregion';
import organization from '../Generated/reducers/organization';
import review from '../Generated/reducers/review';
import contact from '../Generated/reducers/contact';
import archiveobject from '../Generated/reducers/archiveobject';
import award from '../Generated/reducers/award';
import documentobject from '../Generated/reducers/documentobject';
import imageobject from '../Generated/reducers/imageobject';
import lesson from '../Generated/reducers/lesson';
import lessonquestion from '../Generated/reducers/lessonquestion';
import locale from '../Generated/reducers/locale';
import subtheme from '../Generated/reducers/subtheme';
import theme from '../Generated/reducers/theme';
import videoobject from '../Generated/reducers/videoobject';
import { Jwt } from '../Actions/Jwt';

const history = createBrowserHistory();
export default () => {
  return createStore(
    combineReducers({
      router: connectRouter(history),
      auth: authReducer,
      questions: questionReducer,
      completeProfile: completeProfileReducer,
      forgotPassword: forgotPasswordReducer,
      editProfile: editProfileReducer,
      organizationunconfirmedmembers,
      answer,
      bank,
      userresetpassword,
      userconfirmation,
      user,
      project,
      educationallevel,
      tags,
      creativework,
      person,
      question,
      mediaobject,
      bankaccount,
      addressregion,
      organization,
      review,
      contact,
      archiveobject,
      award,
      documentobject,
      imageobject,
      lesson,
      lessonquestion,
      locale,
      subtheme,
      theme,
      videoobject
    }),
    composeWithDevTools(applyMiddleware(Jwt, routerMiddleware(history), thunk))
  );
};
