import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Microscope from '../../Assets/Images/microscope.png';
import Atomic from '../../Assets/Images/atomic.png';
import Button from '../Button/Button';
import './JoinProjectModal.scss';
import HandleEscapeKey from '../Input/HandleEscapeKey';
import UserListWithProfile from '../User/UserListWithProfile';

const JoinProjectModal = ({
  isShowModal,
  setIsShowModal,
  joinProject,
  rejectInvitation,
  organization,
  renderTitle,
  renderViewAndDeadline
}) => {
  const [listOfUsers, setListOfUsers] = useState(false);

  const renderHeader = () => {
    return (
      <div className='start-proposal-modal-header' >
        <i
          className='fa fa-times start-proposal-modal-header-close'
          onClick={() => setIsShowModal(false)}
        />
        <img src={Microscope} alt="" className='start-proposal-modal-header-img-scope' />
        <img src={Atomic} alt="" className='start-proposal-modal-header-img-atoms' />
          {renderTitle()}
        <div
          className='start-proposal-modal-header-days'
        >
            {renderViewAndDeadline()}
        </div>
      </div>
    );
  };
  const userList = useSelector((state) => state.user.list);

  useEffect(() => {
    if (userList.retrieved && organization) {
      setListOfUsers(
        userList.retrieved['hydra:member'].filter(
          (user) => {
            return organization.members.includes(user['@id']);
          }
        )
      );
    }
  }, [userList.retrieved, organization]);

  const renderTeamMembers = () => {
    return (

      <div className='join-project-modal-member'>
          {listOfUsers
          && listOfUsers.map((item) => {
            return <UserListWithProfile
                            organization={organization}
                            key={item.id}
                            item={item }
                            handleClick={() => {}}
                          />;
          }) }
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className='join-project-modal-deadline' >
        <div className='join-project-modal-deadline-btn-flex' >
          <div className='join-project-modal-deadline-btn-flex-btn' >
            <Button type='secondary' block onClick={() => {
              rejectInvitation(true);
            }} >Jangan Bergabung</Button>
          </div>
          <div className='join-project-modal-deadline-btn-flex-btn'>
            <Button block onClick={() => joinProject()} >Gabung Tim</Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={isShowModal}
      dialogClassName='join-project-modal'
      backdrop='static'
      centered
    >
      {renderHeader()}
      <div className='start-proposal-modal-scrollable' >
      {renderTeamMembers()}
      </div>
      {renderButtons()}
      <HandleEscapeKey handleOnEscapeKey={() => setIsShowModal(false)} />
    </Modal>
  );
};

JoinProjectModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  joinProject: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  rejectInvitation: PropTypes.func.isRequired,
  setIsShowModal: PropTypes.func.isRequired
};

export default JoinProjectModal;
