import React from 'react';
import PropTypes from 'prop-types';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { ButtonGroup, Image, ToggleButton } from 'react-bootstrap';
import './ScrollableLessonMateriGratis.scss';
import previousButton from '../../Assets/Images/previous-button.png';
import nextButton from '../../Assets/Images/next-button.png';

const ScrollableLessonMateriGratis = (props) => {
  const {
    filterByLessonOptions,
    filterByLesson,
    setFilterByLesson
  } = props;

  const handleFilterByLesson = (event) => {
    const { currentTarget: { value } } = event;
    let filteredData = [];
    let resultFilteredData = [];
    if (filterByLesson.includes(value)) {
      filteredData = filterByLesson.filter((lesson) => lesson !== value);
      return setFilterByLesson(filteredData);
    }
    filteredData.push(value);
    resultFilteredData = [...filterByLesson, ...filteredData];
    return setFilterByLesson(resultFilteredData);
  };

  const renderFilterByLesson = () => (
    filterByLessonOptions.map((lesson, index) => {
      return (
        <ButtonGroup key={index} toggle>
          <ToggleButton
            type='checkbox'
            variant='secondary'
            checked={filterByLesson.includes(lesson.id)}
            value={lesson.id}
            onChange={(event) => handleFilterByLesson(event)}
          >
            {lesson.name}
          </ToggleButton>
        </ButtonGroup>
      );
    })
  );

  const arrowComponent = ({ text, className }) => {
    return (
      <div className={className}>
        {text}
      </div>
    );
  };

  const renderArrowLeft = arrowComponent({ text: <Image src={previousButton} />, className: 'arrow-prev' });
  const renderArrowRight = arrowComponent({ text: <Image src={nextButton} />, className: 'arrow-next' });

  return (
    <div className='scrollable-lesson'>
      <ScrollMenu
        data={renderFilterByLesson()}
        arrowLeft={renderArrowLeft}
        arrowRight={renderArrowRight}
        wheel={false}
        dragging={true}
        alignCenter={false}
        hideArrows={true}
        hideSingleArrow={true}
      />
    </div>
  );
};

ScrollableLessonMateriGratis.propTypes = {
  filterByLessonOptions: PropTypes.array,
  filterByLesson: PropTypes.array,
  setFilterByLesson: PropTypes.func
};

export default ScrollableLessonMateriGratis;
