import React, { useEffect, useState } from 'react';
import {
  ButtonGroup, Col, Container, Row, Tab, Tabs, ToggleButton
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Countdown from 'react-countdown';
import moment from 'moment';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import AddFriend from '../../Components/AddFriend/AddFriend';
import './ContestantProposal.scss';
import Calender from '../../Assets/Images/calender.png';
import TeamCard from '../../Components/TeamCard/TeamCard';
import Loading from '../../Components/Loading/Loading';
import Button from '../../Components/Button/Button';
import SelectComponent from '../../Components/SelectComponent/SelectComponent';
import ContestOptionModal from '../../Components/ContestOptionModal/ContestOptionModal';
import OpenToAnyoneModal from '../../Components/OpenToAnyoneModal/OpenToAnyoneModal';
import {
  list as organizationActionList,
  reset as organizationActionReset,
} from '../../Generated/actions/organization/list';
import { list as personActionList, reset as personActionReset, } from '../../Generated/actions/person/list';
import { list as mediaActionList, reset as mediaActionReset, } from '../../Generated/actions/mediaobject/list';
import { list as tagActionList, reset as tagActionReset } from '../../Generated/actions/tags/list';
import {
  list as listDesignersActionList,
  reset as listDesignersActionReset
} from '../../Generated/actions/user/listDesigners';
import {
  list as listTeachersActionList,
  reset as listTeachersActionReset
} from '../../Generated/actions/user/listTeachers';
import {
  list as userListAction,
  reset as userListActionReset
} from '../../Generated/actions/user/list';
import { retrieve as userActionRetrieve, reset as userActionRetrieveReset } from '../../Generated/actions/user/show';
import {
  retrieve as projectActionRetrieve,
  reset as projectActionRetrieveReset
} from '../../Generated/actions/project/show';
import {
  create as organizationActionCreate,
} from '../../Generated/actions/organization/create';
import {
  invite as organizationUnconfirmedMembersActionInvite,
} from '../../Generated/actions/organizationunconfirmedmembers/create';
import ContestantProposalAlert from '../../Components/Alert/ContestantProposalAlert';
import EducationalLevel from '../../Components/EducationalLevel/EducationalLevel';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset
} from '../../Generated/actions/educationallevel/list';
import SignInModal from '../../Components/SignInModal/SignInModal';
import Tag from '../../Components/Tags/Tag';
import AreYouSureSubmitMembersModal from '../../Components/Modal/AreYouSureSubmitMembersModal';
import ProposalGuidelines from '../../Components/ProjectBriefTab/ProposalGuidelines';
import ProjectBriefTab from '../../Components/ProjectBriefTab/ProjectBriefTab';

const queryString = require('query-string');

// filter by orgs
const ALL_PROPOSALS = 'ALL_PROPOSALS';
const STATUS_NEED_PARTNER = 'STATUS_NEED_PARTNER';
const ON_PROGRESS = 'ON_PROGRESS';
const COMPLETED = 'COMPLETED';

// order by orgs
const LAST_JOINED = 'LAST_JOINED';
const LAST_CHANGED = 'LAST_CHANGED';
// const MOST_COMPLETED = 'MOST_COMPLETED';

const ContestantProposal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [designer, setDesigner] = useState([]);
  const [teacher, setTeacher] = useState([]);

  const [recipients, setRecipients] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState([ALL_PROPOSALS]);
  const [sortSelected, setSortSelected] = useState({
    label: 'Terakhir Bergabung',
    value: LAST_JOINED,
  });
  const [isAddFriendModal, setIsAddFriendModal] = useState(false);
  const [isContestOptionModal, setIsContestOptionModal] = useState(false);
  const [search, setSearch] = useState('');
  const [tempData, setTempData] = useState([]);
  const [isOpenToAnyone, setIsOpenToAnyone] = useState(false);
  const [isNextScreen, setIsNextScreen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAreYouSureSubmitMembersModal, setAreYouSureSubmitMembersModal] = useState(false);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [lessonTagValues, setLessonTagValues] = useState([]);

  const [submittedOrgWithInvitation, setSubmittedOrgWithInvitation] = useState(false);

  const OrganizationStore = useSelector((state) => state.organization);
  const listOfOrganization = useSelector((state) => state.organization.list);
  const listOfPersons = useSelector((state) => state.person.list);
  const [mappedMembers, setMappedMembers] = useState({});
  const listOfImages = useSelector((state) => state.mediaobject.list);
  const [mappedImages, setMappedImages] = useState({});
  const lessonRequestDetail = useSelector((state) => state.project.show);
  const loggedUser = useSelector((state) => state.user.show);
  const listTeachers = useSelector((state) => state.user.listTeachers);
  const listDesigners = useSelector((state) => state.user.listDesigners);
  const listOfLessonTags = useSelector((state) => state.tags.list);
  const listOfEducationalLevel = useSelector(
    (state) => state.educationallevel.list
  );
  const userList = useSelector((state) => state.user.list);
  const awardList = useSelector((state) => state.award.list);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, []);

  useEffect(() => {
    dispatch(projectActionRetrieve(`/projects/${props.match.params.page}`));
    return () => {
      dispatch(projectActionRetrieveReset(lessonRequestDetail.eventSource));
    };
  }, []);

  // todo:add pagination for teacher and designer list
  useEffect(() => {
    dispatch(listDesignersActionList());
    dispatch(listTeachersActionList());
    return () => {
      dispatch(listDesignersActionReset(listDesigners.eventSource));
      dispatch(listTeachersActionReset(listTeachers.eventSource));
    };
  }, []);
  useEffect(() => {
    if (listDesigners.retrieved) {
      setDesigner(listDesigners.retrieved['hydra:member']);
    }
  }, [listDesigners.retrieved]);
  useEffect(() => {
    if (listTeachers.retrieved) {
      setTeacher(listTeachers.retrieved['hydra:member']);
    }
  }, [listTeachers.retrieved]);

  // receive current logged user
  useEffect(() => {
    if (localStorage.getItem('id')) dispatch(userActionRetrieve(localStorage.getItem('id')));
    return () => {
      dispatch(userActionRetrieveReset(loggedUser.eventSource));
    };
  }, []);

  // invite people
  useEffect(() => {
    if (submittedOrgWithInvitation && OrganizationStore.create.created) {
      // todo change to forEach
      /* eslint-disable-next-line */
      for (const recipient of recipients) {
        dispatch(organizationUnconfirmedMembersActionInvite(
          {
            id: uuidv4(),
            organization: OrganizationStore.create.created['@id'],
            member: recipient['@id']
          }
        ));
      }
      setSubmittedOrgWithInvitation(false);
    }
  }, [submittedOrgWithInvitation, OrganizationStore.create.created]);

  useEffect(() => {
    let filter;
    if (filterByStatus.includes(ALL_PROPOSALS)) {
      filter = {};
    }
    if (filterByStatus.includes(STATUS_NEED_PARTNER)) {
      filter = {
        isOpenForJoin: true,
        isLocked: false
      };
    }
    if (filterByStatus.includes(ON_PROGRESS)) {
      filter = {
        isLocked: true,
        'creativeWork.isSubmitted': false
      };
    }
    if (filterByStatus.includes(COMPLETED)) {
      filter = {
        isLocked: true,
        'creativeWork.isSubmitted': true
      };
    }
    let order;
    if (sortSelected.value === LAST_JOINED) {
      order = {
        order: {
          createdAt: 'desc'
        }
      };
    }
    if (sortSelected.value === LAST_CHANGED) {
      order = {
        order: {
          updatedAt: 'desc'
        }
      };
    }
    const myQueryString = queryString.stringify(
      {
        ...filter,
        ...order
      },
      { arrayFormat: 'bracket' }
    );
    dispatch(
      organizationActionList(
        `organizations?project=${decodeURIComponent(props.match.params.page)}&${myQueryString}`
      )
    );
    return () => {
      dispatch(organizationActionReset(listOfOrganization.eventSource));
    };
    // also we refresh if we crated organization
  }, [OrganizationStore.create.created, filterByStatus, sortSelected]);

  useEffect(() => {
    // todo join 3 requests together in one state, instead of one query or use separated from teams profile store
    // todo or move down to add friend
    if (listOfOrganization.retrieved && listTeachers.retrieved && listDesigners.retrieved) {
      let membersArray = [];
      listOfOrganization.retrieved['hydra:member'].map((organizationFromList) => {
        membersArray = [...membersArray, ...organizationFromList.members, organizationFromList.founder];
        return null;
      });
      dispatch(userListAction(`/users/?id[]=${membersArray.join('&id[]=')}`));
      listTeachers.retrieved['hydra:member'].map((teacherFromList) => {
        membersArray = [...membersArray, teacherFromList.id];
        return null;
      });
      listDesigners.retrieved['hydra:member'].map((designerFromList) => {
        membersArray = [...membersArray, designerFromList.id];
        return null;
      });
      // remove duplicates and empty
      membersArray = [...new Set(membersArray)].filter(Boolean);
      const membersArrayString = membersArray.join('&user[]=');
      dispatch(personActionList(`/people?pagination=false&user[]=${membersArrayString}`));
    }
    return () => {
      dispatch(personActionReset(listOfPersons.eventSource));
      dispatch(userListActionReset(userList.eventSource));
    };
  }, [listOfOrganization.retrieved, listTeachers.retrieved, listDesigners.retrieved]);

  useEffect(() => {
    if (listOfPersons.retrieved) {
      let imagesArray = [];
      const mappedMembersObject = {};
      listOfPersons.retrieved['hydra:member'].map(
        (member) => {
          imagesArray = [...imagesArray, member.image];
          mappedMembersObject[member.user] = member;
          return null;
        }
      );
      setMappedMembers(mappedMembersObject);
      const mediaArrayString = imagesArray.filter(Boolean).join('&id[]=');
      dispatch(mediaActionList(`/media_objects?id[]=${mediaArrayString}`));
    }
    return () => {
      dispatch(mediaActionReset());
    };
  }, [listOfPersons.retrieved]);

  useEffect(() => {
    // we get list after retrieving the listOfPerson
    if (listOfImages.retrieved && listOfPersons.retrieved) {
      const object = {};
      listOfPersons.retrieved['hydra:member'].map(
        (person) => {
          object[person.user] = listOfImages.retrieved['hydra:member'].filter((image) => image['@id'] === person.image)
            .length > 0
            && listOfImages.retrieved['hydra:member'].filter((image) => image['@id'] === person.image)
              .shift().contentUrl;
          return setMappedImages(object);
        }
      );
    }
  }, [listOfImages.retrieved, listOfPersons.retrieved]);

  useEffect(() => {
    dispatch(tagActionList('/tags?role[]=ROLE_TEACHER&role[]=ROLE_DESIGNER&pagination=false'));
    return () => {
      dispatch(tagActionReset(listOfLessonTags.eventSource));
    };
  }, []);

  useEffect(() => {
    if (listOfLessonTags.retrieved && lessonRequestDetail.retrieved) {
      const tagValuesArray = listOfLessonTags.retrieved['hydra:member']
        .filter((tagFromList) => lessonRequestDetail.retrieved.tags.includes(tagFromList['@id'])).length > 0
        && listOfLessonTags.retrieved['hydra:member']
          .filter((tagFromList) => lessonRequestDetail.retrieved.tags.includes(tagFromList['@id']))
          .map((tag) => tag.value);
      setLessonTagValues(tagValuesArray);
    }
  }, [listOfLessonTags.retrieved, lessonRequestDetail.retrieved]);

  const removeDuplicate = (array, key) => {
    return array.reduce((arr, item) => {
      const removed = arr.filter((i) => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  };

  const newRecipients = removeDuplicate(recipients, '@id');

  const dataFilteredDesigner = designer.filter((item) => {
    if (item.username.toLowerCase()
      .includes(search.toLowerCase())) {
      return item;
    }
    return null;
  });

  const dataFilteredTeacher = teacher.filter((item) => {
    if (item.username.toLowerCase()
      .includes(search.toLowerCase())) {
      return item;
    }
    return null;
  });

  const handleClickAddDesignerRecipient = (recipient, id) => {
    setRecipients([...recipients, {
      '@id': id,
      recipient,
      type: 'designer'
    }]);
    const newData = dataFilteredDesigner.filter((item) => item['@id'] === id);
    setTempData([...tempData, ...newData]);
    const resultData = dataFilteredDesigner.filter((item) => item['@id'] !== id);
    return setDesigner(resultData);
  };

  const handleClickAddTeacherRecipient = (recipient, id) => {
    setRecipients([...recipients, {
      '@id': id,
      recipient,
      type: 'teacher'
    }]);
    const newData = dataFilteredTeacher.filter((item) => item['@id'] === id);
    setTempData([...tempData, ...newData]);
    const resultData = dataFilteredTeacher.filter((item) => item['@id'] !== id);
    return setTeacher(resultData);
  };

  const handleCancelRecipient = (id, type) => {
    const restoreData = tempData.filter((item) => item['@id'] === id)
      .shift();
    if (type === 'designer') {
      setDesigner([...dataFilteredDesigner, restoreData]);
    }
    if (type === 'teacher') {
      setTeacher([...dataFilteredTeacher, restoreData]);
    }
    const resultData = recipients.filter((recipient) => recipient['@id'] !== id);
    return setRecipients(resultData);
  };

  const handleClearRecipients = () => {
    setRecipients([]);
    if (listDesigners.retrieved) setDesigner(listDesigners.retrieved['hydra:member']);
    if (listTeachers.retrieved) setTeacher(listTeachers.retrieved['hydra:member']);
  };

  const filterByStatusOptions = [
    {
      name: 'Semua Proposal',
      value: ALL_PROPOSALS,
    },
    {
      name: 'Butuh Anggota',
      value: STATUS_NEED_PARTNER,
    },
    {
      name: 'Dikerjakan',
      value: ON_PROGRESS,
    },
    {
      name: 'Selesai',
      value: COMPLETED,
    },
  ];

  const selectOptions = [
    {
      label: 'Terakhir Bergabung',
      value: LAST_JOINED
    },
    {
      label: 'Terakhir Mengubah',
      value: LAST_CHANGED
    },
    // {
    //   label: 'Paling Selesai',
    //   value: MOST_COMPLETED
    // },
  ];

  const handleFilterByStatus = (event) => {
    const {
      currentTarget: { value },
    } = event;
    let filteredData = [];
    // let resultFilteredData = [];
    if (filterByStatus.includes(value)) {
      filteredData = filterByStatus.filter((time) => time !== value);
      return setFilterByStatus(filteredData);
    }
    filteredData.push(value);
    // resultFilteredData = [...filterByStatus, ...filteredData];
    return setFilterByStatus(filteredData);
  };

  const handleSortSelect = (selectedOptions) => {
    setSortSelected(selectedOptions);
  };

  const handleClickAddFriend = () => {
    setIsAddFriendModal(true);
    setIsContestOptionModal(false);
  };

  const handleCloseModal = () => {
    setIsAddFriendModal(false);
    setIsContestOptionModal(false);
    setAreYouSureSubmitMembersModal(false);
    setSearch('');
    if (isSubmit) {
      history.push('/my-projects');
    }
  };

  const handleGoBackFromAddFriendModal = () => {
    setIsAddFriendModal(false);
    setIsContestOptionModal(true);
  };

  const handleOpenContestOptionModal = () => {
    if (loggedUser.retrieved) {
      setIsContestOptionModal(true);
    } else {
      setIsShowLoginModal(true);
    }
  };

  const handleCloseContestOptionModal = () => {
    setIsContestOptionModal(false);
  };

  const handleClickOpenToAnyone = () => {
    setIsOpenToAnyone(true);
  };

  const handleCloseOpenToAnyone = () => {
    setIsContestOptionModal(false);
    setIsOpenToAnyone(false);
    setIsNextScreen(false);
    setAreYouSureSubmitMembersModal(false);
    if (isNextScreen) {
      history.push('/my-projects');
    }
  };

  const handleNextScreen = () => {
    setIsNextScreen(true);
    dispatch(organizationActionCreate(
      {
        id: uuidv4(),
        name: loggedUser.retrieved ? `${loggedUser.retrieved.username} and team` : 'Team',
        openForJoin: true,
        locked: false,
        project: lessonRequestDetail.retrieved['@id']
      }
    ));
  };

  const handleSubmitRecipients = () => {
    setAreYouSureSubmitMembersModal(true);
    setIsAddFriendModal(false);
  };

  const handleSubmitConfirmationModal = (openForJoin) => {
    setAreYouSureSubmitMembersModal(false);
    setIsAddFriendModal(true);
    setIsSubmit(true);
    const organizationId = uuidv4();
    dispatch(organizationActionCreate(
      {
        id: organizationId,
        name: loggedUser.retrieved ? `${loggedUser.retrieved.username} and team` : 'Team',
        openForJoin,
        project: lessonRequestDetail.retrieved['@id']
      }
    ));

    setSubmittedOrgWithInvitation(organizationId);
  };

  const handleGoBackFromConfirmationModal = () => {
    setAreYouSureSubmitMembersModal(false);
    setIsAddFriendModal(true);
  };

  const renderFilterButton = () => {
    return (
      <div className='contestant-proposal-filter'>
        <div className='contestant-proposal-filter-left'>
          {filterByStatusOptions.map((status, index) => {
            return (
              <ButtonGroup key={index} toggle>
                <ToggleButton
                  type='checkbox'
                  variant='secondary'
                  checked={filterByStatus.includes(status.value)}
                  value={status.value}
                  onChange={(event) => handleFilterByStatus(event)}
                >
                  {status.name}
                </ToggleButton>
              </ButtonGroup>
            );
          })}
        </div>
        <div className='contestant-proposal-filter-right'>
          <SelectComponent
            options={selectOptions}
            value={sortSelected}
            onChange={handleSortSelect}
          />
        </div>
      </div>
    );
  };

  const renderProposalTab = () => {
    return (
      <div className='contestant-proposal-width-container'>
        <Col xs={12}>{renderFilterButton()}</Col>
        <div className='contestant-proposal-proposals-container'>
          {listOfOrganization.retrieved
            && listOfOrganization.retrieved['hydra:member']
              .map((organization, index) => (
                <Col xs={4} key={index}>
                  <TeamCard
                    organization={organization}
                    mappedImages={mappedImages}
                    mappedMembers={mappedMembers}
                    lessonTagValues={lessonTagValues}
                  />
                </Col>
              ))}
        </div>
      </div>
    );
  };

  const renderTimeLeft = ({
    days, hours,
    minutes, completed, seconds
  }) => {
    if (completed) {
      return 'The project submission has been closed';
    }
    return <span>Kamu Punya <b>{days} hari, {hours} jam, {minutes} menit, {seconds} detik</b></span>;
  };

  let endTime;
  if (lessonRequestDetail.retrieved) {
    endTime = moment(lessonRequestDetail.retrieved.endTime)
      .valueOf();
  }
  const renderDeadline = () => {
    return (
      <div className='contestant-proposal-deadline'>
        <Container className="contestant-proposal-deadline-container">
          <Row>
            <Col xs={9}>
              <div className='contestant-proposal-deadline-card'>
                <img src={Calender} alt="calender" className='contestant-proposal-deadline-card-img' />
                <Countdown
                  date={moment(endTime)
                    .valueOf()}
                  renderer={renderTimeLeft}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='contestant-proposal-deadline-join'>
                {lessonRequestDetail.retrieved && <Button onClick={handleOpenContestOptionModal} block={true}>
                  Ikuti Kontes
                </Button>}
                {isContestOptionModal && (
                  <div>
                    <ContestOptionModal
                      show={isContestOptionModal}
                      handleClose={handleCloseContestOptionModal}
                      handleClickAddFriend={handleClickAddFriend}
                      handleClickOpenToAnyone={handleClickOpenToAnyone}
                    />
                  </div>
                )}
                {isAddFriendModal && (
                  <div>
                    <AddFriend
                      show={isAddFriendModal}
                      handleClose={handleCloseModal}
                      dataFilteredDesigner={dataFilteredDesigner}
                      dataFilteredTeacher={dataFilteredTeacher}
                      handleChange={setSearch}
                      search={search}
                      recipients={newRecipients}
                      handleClickAddTeacherRecipient={handleClickAddTeacherRecipient}
                      handleClickAddDesignerRecipient={handleClickAddDesignerRecipient}
                      handleCancelRecipient={handleCancelRecipient}
                      counter={newRecipients.length}
                      handleSubmitRecipients={handleSubmitRecipients}
                      isSubmit={isSubmit}
                      handleGoBack={handleGoBackFromAddFriendModal}
                    />
                  </div>
                )}
                {isAreYouSureSubmitMembersModal && (
                  <div>
                    <AreYouSureSubmitMembersModal
                      show={isAreYouSureSubmitMembersModal}
                      handleClose={handleCloseModal}
                      recipients={newRecipients}
                      handleCancelRecipient={handleCancelRecipient}
                      handleGoBack={handleGoBackFromConfirmationModal}
                      handleSubmitConfirmationModal={handleSubmitConfirmationModal}
                      handleClearRecipients={handleClearRecipients}
                    />
                  </div>
                )
                }
                {isOpenToAnyone && (
                  <div>
                    <OpenToAnyoneModal
                      show={isOpenToAnyone}
                      handleClose={handleCloseOpenToAnyone}
                      handleNextScreen={handleNextScreen}
                      isNextScreen={isNextScreen}
                    />
                  </div>
                )
                }{isShowLoginModal
                  && <SignInModal
                    isShowModal={isShowLoginModal}
                    setIsShowModal={setIsShowLoginModal}
                  />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className='contestant-proposal-tab'>
        <Tabs
          defaultActiveKey='home'
          id='uncontrolled-tab-example'
        >
          <Tab
            eventKey='home'
            title='Instruksi Soal'
            className='contestant-proposal-grey'
          >
            <div className='contestant-proposal-width-container'>
              <ProjectBriefTab projectDetail={lessonRequestDetail.retrieved} />
              <ProposalGuidelines/>
            </div>
          </Tab>
          <Tab
            className='contestant-proposal-grey'
            eventKey='profile'
            title='Proposal Kontestan'
          >
            {renderProposalTab()}
          </Tab>
        </Tabs>
      </div>
    );
  };

  const renderJumbotron = () => {
    const getLabelBg = () => {
      if (lessonTagValues.length > 0) {
        if (lessonTagValues.includes('SCIENCE')) return 'science';
        if (lessonTagValues.includes('MATH')) return 'maths';
      }
      return 'science';
    };
    return (lessonRequestDetail.retrieved
      && <div
        className={`contestant-proposal-jumbotron ${getLabelBg()}`}>
        <Container>
          <div className='contestant-proposal-jumbotron-detail'>
            <div className='contestant-proposal-jumbotron-detail-label'>
              <EducationalLevel educationalLevel={lessonRequestDetail
                .retrieved.educationalLevel} />
              {lessonRequestDetail.retrieved.tags
                .map((tag, i) =>
                  <Tag tag={tag} key={i} />)
              }
            </div>
            <h1>
              {lessonRequestDetail.retrieved.title}
            </h1>
          </div>
        </Container>
      </div>
    );
  };

  return (
      <div className='contestant-proposal'>
        {listOfOrganization.loading
          || lessonRequestDetail.loading
          || awardList.loading
          ? <Loading /> : null}
        <NavigationBar />
        {renderJumbotron()}
        <Container>
          {renderTab()}
          {renderDeadline()}
          <ContestantProposalAlert />
        </Container>
      </div>
  );
};

ContestantProposal.propTypes = {};

export default ContestantProposal;
