import axios from 'axios';
import {
  ARCHIVE_OBJECTS,
  ARCHIVE_OBJECTS_PUT_LINK,
  headerConfig,
  IMAGE_OBJECTS, IMAGE_OBJECTS_PUT_LINK, VIDEO_OBJECTS, VIDEO_OBJECTS_PUT_LINK,
  DOCUMENT_OBJECTS_PUT_LINK, DOCUMENT_OBJECTS, AUDIO_OBJECTS, AUDIO_OBJECTS_PUT_LINK
} from '../Constants/Constants';

export const submitQuestion = (payload) => ({
  type: 'SUBMIT_QUESTION',
  payload
});

export const saveImageData = (payload) => ({
  type: 'SAVE_IMAGE_DATA',
  payload
});

export const saveAnswerData = (payload) => ({
  type: 'SAVE_ANSWER_DATA',
  payload
});

export const errorImageUpload = (payload) => ({
  type: 'ERROR_IMAGE_UPLOAD',
  payload
});

export const uploadImageToS3 = (fileType, file, header = null, customData = null) => {
  return async () => {
    const configHeader = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    };
    if (fileType.fileExtension === 'jpeg' || fileType.fileExtension === 'jpg' || fileType.fileExtension === 'png') {
      const { data: { url } } = await axios.post(IMAGE_OBJECTS_PUT_LINK, fileType, headerConfig);
      await fetch(url, configHeader);
      const urlToBucket = {
        name: file.name,
        url
      };
      let URL = IMAGE_OBJECTS;
      if (customData && customData.method === 'PATCH') {
        URL = customData.id;
      }
      const { data } = await axios({
        method: customData ? customData.method : 'POST',
        url: URL,
        data: urlToBucket,
        headers: header || {}
      });
      return data;
    }
    return null;
  };
};

export const uploadToS3 = (typeUpload, fileType, file, updateProgress, header = null, customData = null) => {
  return async () => {
    // const configHeader = {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': file.type
    //   },
    //   body: file
    // };
    const uninterceptedAxiosInstance = axios.create();
    if (typeUpload === 'archive') {
      const { data: { url } } = await axios.post(ARCHIVE_OBJECTS_PUT_LINK, fileType);
      await uninterceptedAxiosInstance.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (e) => updateProgress(Math.round((100 * e.loaded) / e.total))
      });
      // await fetch(url, configHeader);
      const urlToBucket = {
        name: file.name,
        url
      };
      let URL = ARCHIVE_OBJECTS;
      if (customData && customData.method === 'PATCH') {
        URL = customData.id;
      }
      const { data } = await axios({
        method: customData ? customData.method : 'POST',
        url: URL,
        data: urlToBucket,
        headers: header || {}
      });
      return data;
    }
    if (typeUpload === 'video') {
      const { data: { url } } = await axios.post(VIDEO_OBJECTS_PUT_LINK, fileType);
      await uninterceptedAxiosInstance.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (e) => updateProgress(Math.round((100 * e.loaded) / e.total))
      });
      // await fetch(url, configHeader);
      const urlToBucket = {
        name: file.name,
        url
      };
      let URL = VIDEO_OBJECTS;
      if (customData && customData.method === 'PATCH') {
        URL = customData.id;
      }
      const { data } = await axios({
        method: customData ? customData.method : 'POST',
        url: URL,
        data: urlToBucket,
        headers: header || {}
      });
      return data;
    }
    if (typeUpload === 'pdf') {
      const { data: { url } } = await axios.post(DOCUMENT_OBJECTS_PUT_LINK, fileType);
      await uninterceptedAxiosInstance.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (e) => updateProgress(Math.round((100 * e.loaded) / e.total))
      });
      // await fetch(url, configHeader);
      const urlToBucket = {
        name: file.name,
        url
      };
      const { data } = await axios.post(DOCUMENT_OBJECTS, urlToBucket);
      return data;
    }
    if (typeUpload === 'audio' || typeUpload === 'title') {
      const { data: { url } } = await axios.post(AUDIO_OBJECTS_PUT_LINK, fileType);
      await uninterceptedAxiosInstance.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: (e) => updateProgress(Math.round((100 * e.loaded) / e.total))
      });
      // await fetch(url, configHeader);
      const urlToBucket = {
        name: file.name,
        url
      };
      let URL = AUDIO_OBJECTS;
      if (customData && customData.method === 'PATCH') {
        URL = customData.id;
      }
      const { data } = await axios({
        method: customData ? customData.method : 'POST',
        url: URL,
        data: urlToBucket,
        headers: header || {}
      });
      return data;
    }
    return null;
  };
};
