/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft, CalendarEventFill, CloudArrowDownFill, CardImage, TrashFill } from 'react-bootstrap-icons';
import { Form, ProgressBar } from 'react-bootstrap';
import CharacterCounter from 'react-character-counter';
import Calendar from 'react-calendar';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import _, { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { Dot } from 'react-animated-dots';
import Logo from '../../Assets/Images/logo.png';
import DropdownFormComponent from '../../Components/DropdownFormComponent/DropdownFormComponent';
import Button from '../../Components/Button/Button';
import StartProposalConfirmModal from '../../Components/StartProposalConfirmModal/StartProposalConfirmModal';
import PublishModal from '../../Components/PublishModal/PublishModal';
import './AdminCreateLesson.scss';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset,
} from '../../Generated/actions/educationallevel/list';
import { CREATIVE_WORKS_URL, PROJECT_URL } from '../../Constants/Constants';
import { reset as projectActionReset, retrieve as projectActionDetail } from '../../Generated/actions/project/show';
import { reset as subthemeActionListReset, list as subthemeActionList } from '../../Generated/actions/subtheme/list';
import RenderAwardsForm from './RenderAwardsForm';
import { getFileExt } from '../../Helpers/Common';
import { uploadToS3 } from '../../Actions/Question';

const lessonTypeOptions = [
  {
    value: 1,
    label: 'Normal Lesson Request',
  },
  {
    value: 2,
    label: 'No Animation Lesson Request',
  },
  {
    value: 3,
    label: 'Animation Provided Lesson Request',
  },
];

const AdminCreateLesson = (props) => {
  // States
  const History = useHistory();
  const dispatch = useDispatch();

  // initial setter for project
  const [lessonDetails, setLessonDetails] = useState({
    title: '',
    subjects: '',
    grade: '',
    deadline: null,
    subtheme: '',
    objective: '',
    activity: '',
    assessment: '',
    material: '',
    isDraft: true,
    internal: false,
    lessonType: 1,
    video: null,
  });

  const listOfTags = useSelector((state) => state.tags.list);
  const listOfEducationalLevel = useSelector((state) => state.educationallevel.list);
  const subthemeList = useSelector((state) => state.subtheme.list);
  const projectDetail = useSelector((state) => state.project.show);

  // getting the project
  const [updatePage, setUpdatePage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [awardExists, setAwardExists] = useState();
  const [selectedLessonType, setSelectedLesonType] = useState(null);
  const [fileProgress, setFileProgress] = useState(0);
  const [loadingFile, setLoadingFile] = useState(false);
  const [errorUploadFile, setErrorUploadFile] = useState('');
  const [videoFile, setVideoFile] = useState({ name: '', contentUrl: '' });
  const mp4InputFile = useRef();
  useEffect(() => {
    if (updatePage) {
      dispatch(projectActionDetail(`/projects/${props.match.params.page}`));
      setUpdatePage(false);
    }
    return () => {
      dispatch(projectActionReset(projectDetail.eventSource));
    };
  }, [dispatch, projectDetail.eventSource, updatePage]);

  // setting data form project
  useEffect(() => {
    if (projectDetail.retrieved) {
      const projectDetailData = projectDetail.retrieved && projectDetail.retrieved;
      setLessonDetails({
        title: projectDetailData.title || '',
        subjects: projectDetailData.tags[0] || '',
        grade: projectDetailData.educationalLevel || '',
        deadline: projectDetailData.endTime || '',
        subtheme: projectDetailData.subtheme || '',
        objective: projectDetailData.objective || '',
        activity: projectDetailData.activity || '',
        assessment: projectDetailData.assessment || '',
        material: projectDetailData.material || '',
        isDraft: projectDetailData.isDraft,
        internal: projectDetailData.internal,
        lessonType: projectDetailData.lessonType,
        video: projectDetailData.video,
      });
    }
  }, [projectDetail.retrieved]);

  useEffect(() => {
    const getVideo = async () => {
      if (projectDetail.retrieved) {
        if (projectDetail.retrieved.video) {
          const { data } = await axios.get(projectDetail.retrieved.video);
          setVideoFile(data);
        }
      }
    };
    getVideo();
  }, [projectDetail.retrieved]);

  // handle update todo should be in another component also we should not jump back and forth between redux and axios
  const handleUpdateField = async (updatedData) => {
    try {
      setIsLoading(true);
      await axios.put(`${PROJECT_URL}/${props.match.params.page}`, updatedData);
      setIsLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };
  // handle update

  // subtheme part
  useEffect(() => {
    dispatch(subthemeActionList());
    return () => {
      dispatch(subthemeActionListReset(subthemeList.eventSource));
    };
  }, [dispatch, subthemeList.eventSource]);
  const [subthemeOptions, setSubthemeOptions] = useState({});
  const [selectedSubtheme, setSelectedSubtheme] = useState(null);
  useEffect(() => {
    if (subthemeList.retrieved) {
      const subthemes = subthemeList.retrieved['hydra:member'].map((subtheme) => {
        const label = subtheme.name;
        // todo show theme also
        // if(subtheme.theme){
        //   label +=" | " + subtheme.theme.name;
        // }
        return {
          label,
          value: subtheme['@id'],
        };
      });
      setSubthemeOptions([{ label: 'Not Selected', value: null }, ...subthemes]);
    }
  }, [subthemeList.retrieved]);
  useEffect(() => {
    if (projectDetail.retrieved && !_.isEmpty(subthemeOptions)) {
      if (projectDetail.retrieved.subtheme) {
        setSelectedSubtheme(
          subthemeOptions.filter((subtheme) => {
            return subtheme.value === (projectDetail.retrieved && projectDetail.retrieved.subtheme);
          })
        );
      } else {
        setSelectedSubtheme({ label: 'Not Selected', value: null });
      }
    }
  }, [projectDetail.retrieved, subthemeOptions]);
  const handleChangeSubtheme = async (subtheme) => {
    const object = { ...lessonDetails, subtheme: subtheme.value };
    await setLessonDetails(object);
    await handleUpdateField({ subtheme: subtheme.value });
    setSelectedSubtheme(subtheme);
  };
  // end subtheme part

  // grade part
  useEffect(() => {
    dispatch(educationalLevelActionList('/educational_levels?order%5Bordering%5D=asc&pagination=false'));
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);
  const [gradeOptions, setGradeOptions] = useState({});
  const [selectedGrade, setSelectedGrade] = useState(null);
  useEffect(() => {
    if (listOfEducationalLevel.retrieved) {
      setGradeOptions(
        listOfEducationalLevel.retrieved['hydra:member'].map((educationalLevel) => ({
          label: educationalLevel.level,
          value: educationalLevel['@id'],
        }))
      );
    }
  }, [listOfEducationalLevel.retrieved]);
  useEffect(() => {
    if (projectDetail.retrieved && !_.isEmpty(gradeOptions)) {
      setSelectedGrade(
        gradeOptions.filter((grade) => {
          return grade.value === (projectDetail.retrieved && projectDetail.retrieved.educationalLevel);
        })
      );
    }
  }, [projectDetail.retrieved, gradeOptions]);
  const handleChangeGrade = async (grade) => {
    const object = { ...lessonDetails, grade: grade.value };
    await setLessonDetails(object);
    await handleUpdateField({ educationalLevel: grade.value });
    setSelectedGrade(grade);
  };
  // end grade part

  // subject part
  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);
  const [subjectOptions, setSubjectOptions] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(null);
  useEffect(() => {
    if (listOfTags.retrieved) {
      setSubjectOptions(
        listOfTags.retrieved['hydra:member'].map((tag) => ({
          label: tag.name,
          value: tag['@id'],
        }))
      );
    }
  }, [listOfTags.retrieved]);
  useEffect(() => {
    if (projectDetail.retrieved && !_.isEmpty(subjectOptions)) {
      setSelectedSubject(
        subjectOptions.filter((grade) => {
          return grade.value === (projectDetail.retrieved && projectDetail.retrieved.tags[0]);
        })
      );
    }
  }, [projectDetail.retrieved, subjectOptions]);
  const handleChangeSubject = async (subject) => {
    const object = { ...lessonDetails, subjects: subject.value };
    await setLessonDetails(object);
    await handleUpdateField({ tags: [subject.value] });
    setSelectedSubject(subject);
  };
  // end subject part

  useEffect(() => {
    if (projectDetail.retrieved) {
      setSelectedLesonType(
        lessonTypeOptions.filter((type) => {
          return type.value === (projectDetail.retrieved && projectDetail.retrieved.lessonType);
        })
      );
    }
  }, [projectDetail.retrieved]);

  const handleChangeLessonType = async (lessonType) => {
    const object = { ...lessonDetails, lessonType: lessonType.value };
    await setLessonDetails(object);
    await handleUpdateField({ lessonType: lessonType.value });
    setSelectedLesonType(lessonType);
  };

  const handleInternalSwitch = async () => {
    const oldSwitch = lessonDetails.internal;
    const object = { ...lessonDetails, internal: !oldSwitch };
    setLessonDetails(object);
    await handleUpdateField({ internal: !oldSwitch });
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const [showPublishModal, setShowPublishModal] = useState(false);

  const [showPublishedModal, setShowPublishedModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCreateLesson = async () => {
    try {
      const projectData = {
        id: uuidv4(),
        minimumNumberOfQuestions: 15,
        isDraft: true,
      };
      const { data } = await axios.post(PROJECT_URL, projectData);
      if (data) {
        History.push(`/admin/lesson-request/${data.id}/edit`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const publishedModalDetails = {
    show: showPublishedModal,
    setShow: setShowPublishedModal,
    heading: 'Yay! your request has been made. Hope it’s going well!',
    sub: 'You can check the progress on Ongoing Lesson Request or create another request!',
    isSingleButton: false,
    noText: 'Check Request',
    noClick: () => {
      History.push('/admin/lesson-requests');
      setShowPublishedModal();
    },
    yesText: 'Create Another',
    yesClick: async () => {
      await handleCreateLesson();
      setShowPublishedModal();
    },
  };

  const handleDeleteLesson = async (projectId) => {
    try {
      await axios.delete(`${PROJECT_URL}/${projectId}`);
      History.push('/admin/lesson-requests');
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteModalDetails = {
    show: showDeleteModal,
    setShow: setShowDeleteModal,
    heading: 'Are you sure you want to delete this request?',
    sub: 'You can’t undo your action after you delete it. You can always save it on draft and edit it later',
    isSingleButton: false,
    noText: "No, I'd like to edit",
    yesText: 'Yes, delete please',
    yesClick: async () => {
      await handleDeleteLesson(props.match.params.page);
      setShowDeleteModal();
    },
  };

  // Functions

  const onChange = async (name, value) => {
    const object = { ...lessonDetails, [name]: value };
    await setLessonDetails(object);
  };

  const toggleCalendar = () => {
    if (showCalendar) setShowCalendar();
    else setShowCalendar(true);
  };

  const handlePublish = async () => {
    setShowPublishModal();
    await handleUpdateField({ isDraft: false });
    setShowPublishedModal(true);
  };

  const checkFields = () => {
    if (
      lessonDetails.title === '' ||
      lessonDetails.subjects === '' ||
      lessonDetails.grade === '' ||
      lessonDetails.deadline === '' ||
      lessonDetails.material === '' ||
      lessonDetails.objective === '' ||
      lessonDetails.activity === '' ||
      lessonDetails.assessment === '' ||
      !awardExists
    ) {
      return true;
    }
    return false;
  };

  const generateBlobURL = (data, fileName) => {
    const downloadFile = window.URL.createObjectURL(new Blob([data]));
    const fileURL = document.createElement('a');
    fileURL.href = downloadFile;
    fileURL.setAttribute('download', fileName);
    document.body.appendChild(fileURL);
    return fileURL.click();
  };

  const handleGetFile = async (fileURL, fileName, ref) => {
    try {
      if (fileURL !== '') {
        const data = await fetch(fileURL, {
          method: 'get',
        });
        const blob = await data.blob();
        return generateBlobURL(blob, fileName);
      }
      return ref.current.click();
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const handleUploadFile = async () => {
    try {
      const { contentUrl, name } = videoFile;
      await handleGetFile(contentUrl, name, mp4InputFile);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleDeleteUpload = async () => {
    setLoadingFile(true);
    try {
      await axios.put(`${PROJECT_URL}/${props.match.params.page}`, { video: null });
      await axios.delete(videoFile['@id']);
      setLessonDetails((prevState) => ({
        ...prevState,
        video: null,
      }));
      setVideoFile({
        name: '',
        contentUrl: '',
      });
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
  };

  const validateExtensions = (ext, type) => {
    if (type === 'archive') {
      return ext === 'zip' || ext === 'rar';
    }
    if (type === 'video') {
      return ext === 'mp4';
    }
    if (type === 'pdf') {
      return ext === 'pdf' || ext === 'docx';
    }
    if (type === 'audio') {
      return ext === 'zip' || ext === 'rar';
    }
    return false;
  };

  const handleUpdateUpload = async (typeUpload, uploadedData, files) => {
    const updatedState = {
      name: files[0].name,
      contentUrl: uploadedData.contentUrl || '',
    };
    try {
      setLessonDetails((prevState) => ({
        ...prevState,
        video: uploadedData['@id'],
      }));
      setVideoFile(updatedState);
      const updatedData = {
        video: uploadedData['@id'],
      };
      await axios.put(`${PROJECT_URL}/${props.match.params.page}`, updatedData);
    } catch (error) {
      throw new Error(error);
    }
  };

  const uploadFile = async (files, typeUpload) => {
    try {
      setLoadingFile(true);
      setErrorUploadFile(false);
      let fileType;
      if (!isEmpty(files)) {
        const fileExt = getFileExt(files[0].name);
        fileType = {
          fileExtension: fileExt,
        };
      }
      if (validateExtensions(fileType.fileExtension, typeUpload)) {
        const uploadedData = await dispatch(uploadToS3(typeUpload, fileType, files[0], setFileProgress));
        if (uploadedData !== null) {
          await handleUpdateUpload(typeUpload, uploadedData, files);

          // Update all creative work video
          const { data: creativeWorks } = await axios.get(
            `${CREATIVE_WORKS_URL}?createdFor=${props.match.params.page}`
          );
          const updateCreativeWorks = creativeWorks['hydra:member'].map((c) => {
            return axios.put(`${CREATIVE_WORKS_URL}/${c.id}`, {
              video: uploadedData['@id'],
            });
          });
          await Promise.all(updateCreativeWorks);
        }
      } else {
        setErrorUploadFile(typeUpload);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      setErrorUploadFile(typeUpload);
    }
    setLoadingFile(false);
  };

  const handleOnChangeFile = async (event, typeUpload) => {
    event.preventDefault();
    event.persist();
    event.stopPropagation();
    const {
      target: { files },
    } = event;
    await uploadFile(files, typeUpload);
  };

  // Render Functions
  const renderHeader = () => {
    return (
      <div className='admin-create-lesson-header'>
        <span onClick={() => History.push('/admin/lesson-requests')} className='admin-create-lesson-header-arrow'>
          <ArrowLeft />
        </span>
        <span className='admin-create-lesson-header-text'>Request Form</span>
        <img src={Logo} alt='' className='admin-create-lesson-header-img' />
      </div>
    );
  };

  const renderDownloadButton = (type, file) => {
    if (file.name !== '') {
      return (
        <div className='question-answer-lesson-content-wrapper-button-download' onClick={() => handleUploadFile(type)}>
          <CloudArrowDownFill />
        </div>
      );
    }
    return null;
  };

  const renderUploadButton = (type, file) => {
    return (
      <div style={{ width: '100%' }}>
        {fileProgress !== 0 && fileProgress !== 100 && <ProgressBar animated now={fileProgress} variant='danger' />}
        <Button block={true} disabled={loadingFile} onClick={() => handleUploadFile(type)}>
          {file.name !== '' ? (
            file.name
          ) : loadingFile ? (
            <>
              Sedang memulai <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </>
          ) : (
            <>
              <CardImage /> Pilih file
            </>
          )}
        </Button>
      </div>
    );
  };

  const renderDeleteButton = (type, file) => {
    if (file.name !== '') {
      return (
        <div className='question-answer-lesson-content-wrapper-button-trash' onClick={() => handleDeleteUpload(type)}>
          <TrashFill />
        </div>
      );
    }
    return null;
  };

  const renderHeadlineForm = () => {
    return (
      <div className='admin-create-lesson-headline-form'>
        {isLoading && <div className='alert alert-info'>Loading...</div>}
        <span className='admin-create-lesson-form-heading'>Headline</span>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Lesson Title</span>
            <span className='admin-create-lesson-form-field-label-sub'>What is this lesson about?</span>
          </div>

          <div className='admin-create-lesson-form-field-textarea-container'>
            <CharacterCounter maxLength={80} value={lessonDetails.title}>
              <Form.Control
                className='admin-create-lesson-form-field-textarea'
                maxLength={80}
                value={lessonDetails.title}
                placeholder=''
                onChange={(e) => onChange('title', e.target.value)}
                onBlur={() => handleUpdateField({ title: lessonDetails.title })}
              />
            </CharacterCounter>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <span className='admin-create-lesson-form-field-label'>Subject</span>
          <div className='admin-create-lesson-form-field-dropdown-container'>
            <div className='admin-create-lesson-dropdown'>
              <DropdownFormComponent
                placeholder='Choose Subject'
                options={subjectOptions}
                onChange={handleChangeSubject}
                value={selectedSubject}
              />
            </div>
            <div className='admin-create-lesson-dropdown'>
              <DropdownFormComponent
                placeholder='Choose Grade'
                options={gradeOptions}
                onChange={handleChangeGrade}
                value={selectedGrade}
              />
            </div>
            <div className='admin-create-lesson-dropdown'>
              <DropdownFormComponent
                placeholder='Choose Subtheme'
                options={subthemeOptions}
                onChange={handleChangeSubtheme}
                value={selectedSubtheme}
              />
            </div>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <span className='admin-create-lesson-form-field-label'>Deadline</span>
          <div className='admin-create-lesson-date'>
            <span className={`admin-create-lesson-date-text ${lessonDetails.deadline ? 'active' : ''}`}>
              {lessonDetails.deadline
                ? moment(lessonDetails.deadline).tz('Asia/Jakarta').format('dddd, DD MMMM YYYY')
                : 'Choose Date'}
            </span>
            <span onClick={() => toggleCalendar()} className='admin-create-lesson-date-icon-bg'>
              <CalendarEventFill />
            </span>

            {showCalendar && (
              <div className='admin-lesson-requests-filter-expand period'>
                <Calendar
                  className='admin-lesson-requests-calendar'
                  next2Label={null}
                  prev2Label={null}
                  onChange={async (value) => {
                    onChange('deadline', value);
                    await handleUpdateField({ endTime: value });
                    setShowCalendar();
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Internal Lesson</span>
          </div>

          <div className='admin-create-lesson-form-field-switch-container'>
            <div className='admin-create-lesson-form-field-switch'>
              <div className='onoffswitch'>
                <input
                  type='checkbox'
                  className='onoffswitch-checkbox'
                  checked={lessonDetails.internal}
                  onChange={handleInternalSwitch}
                  id='internalRequestSwitch'
                />
                <label className='onoffswitch-label' htmlFor='internalRequestSwitch'>
                  <span className='onoffswitch-inner'></span>
                  <span className='onoffswitch-switch'></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Lesson Type</span>
          </div>

          <div className='admin-create-lesson-form-field-dropdown-container'>
            <div className='admin-create-lesson-dropdown-2'>
              <DropdownFormComponent
                placeholder='Choose Lesson Type'
                options={lessonTypeOptions}
                onChange={handleChangeLessonType}
                value={selectedLessonType}
              />
            </div>
          </div>
        </div>

        {lessonDetails && lessonDetails.lessonType === 3 && (
          <div className='admin-create-lesson-form-field'>
            <div>
              <span className='admin-create-lesson-form-field-label'>Animation</span>
            </div>

            <div className='admin-create-lesson-form-field-dropdown-container'>
              <input
                type='file'
                id='upload-file-mp4'
                ref={mp4InputFile}
                style={{ display: 'none' }}
                onChange={(event) => handleOnChangeFile(event, 'video')}
              />
              <div
                className={`question-answer-lesson-content-wrapper-button 
              ${videoFile && 'filled-button '}`}
              >
                {renderDownloadButton('video', videoFile)}
                {renderUploadButton('video', videoFile)}
                {renderDeleteButton('video', videoFile)}
              </div>
              {errorUploadFile && <p className='text-center'>Gagal Upload!</p>}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderDetailBriefForm = () => {
    return (
      <div className='admin-create-lesson-detail-form'>
        <span className='admin-create-lesson-form-heading'>Lesson briefing</span>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Material</span>
            <span className='admin-create-lesson-form-field-label-sub'>material</span>
          </div>

          <div className='admin-create-lesson-form-field-textarea-container'>
            <CharacterCounter maxLength={150} value={lessonDetails.material}>
              <Form.Control
                as='textarea'
                className='admin-create-lesson-form-field-textarea'
                maxLength={150}
                rows={3}
                value={lessonDetails.material}
                placeholder=''
                onChange={(e) => onChange('material', e.target.value)}
                onBlur={() => handleUpdateField({ material: lessonDetails.material })}
              />
            </CharacterCounter>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Learning Objectives</span>
            <span className='admin-create-lesson-form-field-label-sub'>
              What are the learning objectives of this lesson?
            </span>
          </div>

          <div className='admin-create-lesson-form-field-textarea-container'>
            <CharacterCounter maxLength={150} value={lessonDetails.objective}>
              <Form.Control
                as='textarea'
                className='admin-create-lesson-form-field-textarea'
                maxLength={150}
                rows={3}
                value={lessonDetails.objective}
                placeholder=''
                onChange={(e) => onChange('objective', e.target.value)}
                onBlur={() => handleUpdateField({ objective: lessonDetails.objective })}
              />
            </CharacterCounter>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Activities</span>
            <span className='admin-create-lesson-form-field-label-sub'>
              What actions or activities are done in the lesson animation?
            </span>
          </div>

          <div className='admin-create-lesson-form-field-textarea-container'>
            <CharacterCounter maxLength={150} value={lessonDetails.activity}>
              <Form.Control
                as='textarea'
                className='admin-create-lesson-form-field-textarea'
                maxLength={150}
                rows={3}
                value={lessonDetails.activity}
                placeholder=''
                onChange={(e) => onChange('activity', e.target.value)}
                onBlur={() => handleUpdateField({ activity: lessonDetails.activity })}
              />
            </CharacterCounter>
          </div>
        </div>

        <div className='admin-create-lesson-form-field'>
          <div>
            <span className='admin-create-lesson-form-field-label'>Assessment</span>
            <span className='admin-create-lesson-form-field-label-sub'>
              How can be assessed if the lesson has been learned? <sup>(Optional)</sup>
            </span>
          </div>

          <div className='admin-create-lesson-form-field-textarea-container'>
            <CharacterCounter maxLength={150} value={lessonDetails.assessment}>
              <Form.Control
                as='textarea'
                className='admin-create-lesson-form-field-textarea'
                maxLength={150}
                rows={3}
                value={lessonDetails.assessment}
                placeholder=''
                onChange={(e) => onChange('assessment', e.target.value)}
                onBlur={() => handleUpdateField({ assessment: lessonDetails.assessment })}
              />
            </CharacterCounter>
          </div>
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className='admin-create-lesson-btn'>
        <div className='admin-create-lesson-btn-publish'>
          <Button
            block
            disabled={checkFields()}
            onClick={() => {
              setUpdatePage(true);
              setShowPublishModal(true);
            }}
          >
            {!lessonDetails.isDraft ? 'Update Request' : 'Publish Request'}
          </Button>
        </div>
        {lessonDetails.isDraft && (
          <div className='admin-create-lesson-btn-delete'>
            <Button type='secondary' block onClick={() => setShowDeleteModal(true)}>
              Delete
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='admin-create-lesson'>
      {renderHeader()}
      {renderHeadlineForm()}
      {renderDetailBriefForm()}
      <RenderAwardsForm projectDetailData={projectDetail.retrieved} setAwardExists={setAwardExists} />
      {renderButtons()}
      <PublishModal
        showModal={showPublishModal}
        setShowModal={setShowPublishModal}
        onPublishClick={handlePublish}
        awardExists={awardExists}
        publishModalData={projectDetail.retrieved}
      />
      <StartProposalConfirmModal
        isShowModal={showPublishedModal}
        setIsShowModal={setShowPublishedModal}
        details={publishedModalDetails}
      />
      <StartProposalConfirmModal
        isShowModal={showDeleteModal}
        setIsShowModal={setShowDeleteModal}
        details={deleteModalDetails}
      />
    </div>
  );
};

export default AdminCreateLesson;
