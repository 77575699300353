import React, { useEffect } from 'react';
import {
  Col, Container, Row
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ModalProposalMember from '../ModalProposalMember/ModalProposalMember';
import {
  list as educationalLevelList
} from '../../Generated/actions/educationallevel/list';
import ProjectBriefTab from '../ProjectBriefTab/ProjectBriefTab';

const ProposalOverview = ({
  projectDetail, isShowModal, mappedImages,
  mappedMembers, setIsShowModal, showGuidelines = false
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(educationalLevelList());
  }, []);

  const handleShowModal = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <Container>
      <div className='question-answer-overview'>
        <Row>
          <Col xs={9}>
            <ProjectBriefTab showGuidelines={showGuidelines} projectDetail={projectDetail} />
          </Col>
          <Col xs={3}>
            <div
              className='question-answer-overview-title'
              onClick={handleShowModal}
            >
              <Row>
                <Col xs={12}>
                  <h1>Anggota <br /> Kelompok</h1>
                </Col>
                <Col xs={12}>
                  <div className='question-answer-overview-member'>
                    {mappedImages && mappedImages['hydra:member'].map((images, i) => (
                      <div className='question-answer-overview-member-avatar' key={i}>
                        <img src={images.contentUrl} alt='' />
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
            <ModalProposalMember
              isShowModal={isShowModal}
              setIsShowModal={setIsShowModal}
              mappedMembers={mappedMembers}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

ProposalOverview.propTypes = {

};

export default ProposalOverview;
