import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';
import { Form } from 'react-bootstrap';
import MultiSelect from 'react-multi-select-component';
import CheckBox from '../../Components/CheckBox/CheckBox';
import AdminSidebar from '../../Components/AdminSidebar/AdminSidebar';
import Folder from '../../Assets/Images/Folder.png';
import ReviewLessonModal from '../../Components/ReviewLessonModal/ReviewLessonModal';
import PreviewLessonModal from '../../Components/PreviewLessonModal/PreviewLessonModal';
import './AdminLessonBank.scss';
import { list as projectActionList, reset as projectActionReset } from '../../Generated/actions/project/list';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';
import { list as orgActionList, reset as orgActionReset } from '../../Generated/actions/organization/list';
import { list as awardActionList, reset as awardActionReset } from '../../Generated/actions/award/list';
import {
  list as creativeWorkActionList,
  reset as creativeWorkActionReset
} from '../../Generated/actions/creativework/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset
} from '../../Generated/actions/educationallevel/list';
import LessonAccordion from '../../Components/LessonAccordion/LessonAccordion';
import { LESSON_URL } from '../../Constants/Constants';
import PublishedLesson from '../../Components/PublishedLesson/PublishedLesson';
import Loading from '../../Components/Loading/Loading';

const AdminLessonBank = () => {
  // States
  const dispatch = useDispatch();
  const History = useHistory();

  const [activeTab, setActiveTab] = useState('toPublish');
  const [activeFilter, setActiveFilter] = useState('');
  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState([]);
  const [showReviewLessonModal, setShowReviewLessonModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPreviewLessonModal, setShowPreviewLessonModal] = useState(false);
  const [previewProposal, setPreviewProposal] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [publishedNextUrl, setPublishedNextUrl] = useState('');
  const [publishedPrevUrl, setPublishedPrevUrl] = useState('');
  const [allNextUrl, setAllNextUrl] = useState('');
  const [allPrevUrl, setAllPrevUrl] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [publishedLessons, setPublishedLessons] = useState();
  const [allLessons, setAllLessons] = useState();
  const [allPages, setAllPages] = useState([]);
  const [allActivePage, setAllActivePage] = useState(1);
  const [publishedPages, setPublishedPages] = useState([]);
  const [publishedActivePage, setPublishedActivePage] = useState(1);
  const [selectedFilterByGrade, setSelectedFilterByGrade] = useState([]);
  const [filterByGrade, setFilterByGrade] = useState([]);
  const [selectedFilterBySubject, setSelectedFilterBySubject] = useState([]);
  const [filterBySubject, setFilterBySubject] = useState([]);

  const listOfAward = useSelector((state) => state.award.list);
  const listOfProject = useSelector((state) => state.project.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const listOfEducationalLevel = useSelector((state) => state.educationallevel.list);
  const listOfOrganization = useSelector((state) => state.organization.list);
  const listOfCreativeWorks = useSelector((state) => state.creativework.list);

  useEffect(() => {
    const getLessons = async () => {
      setLoading(true);

      const { data: dataPublished } = await axios.get(
        `${LESSON_URL}?${querystring.stringify({
          published: true,
          itemsPerPage: 500,
          'createdFor.tags[]': filterBySubject,
          'createdFor.educationalLevel[]': filterByGrade,
          'createdFor.title': lessonTitle
        })}&order%5BcreatedAt%5D=desc`
      );
      setPublishedLessons(dataPublished);
      const pageCountPublished = querystring.parse(dataPublished['hydra:view']['hydra:last']).page;
      const pagesPublished = [];
      for (let index = 0; index < pageCountPublished; index++) {
        pagesPublished.push(
          `${LESSON_URL}?${querystring.stringify({
            published: true,
            itemsPerPage: 500,
            'createdFor.tags[]': filterBySubject,
            'createdFor.educationalLevel[]': filterByGrade,
            'createdFor.title': lessonTitle,
            page: index + 1
          })}&order%5BcreatedAt%5D=desc`
        );
      }
      setPublishedPages(pagesPublished);

      const { data: dataAll } = await axios.get(`${LESSON_URL}?${querystring.stringify({
        itemsPerPage: 500,
        'createdFor.tags[]': filterBySubject,
        'createdFor.educationalLevel[]': filterByGrade,
        'createdFor.title': lessonTitle
      })}&order%5BcreatedAt%5D=desc`);
      setAllLessons(dataAll);
      const pageCountAll = querystring.parse(dataAll['hydra:view']['hydra:last']).page;
      const pagesAll = [];
      for (let index = 0; index < pageCountAll; index++) {
        pagesAll.push(`${LESSON_URL}?${querystring.stringify({
          itemsPerPage: 500,
          'createdFor.title': lessonTitle,
          page: index + 1,
          'createdFor.tags': filterBySubject,
          'createdFor.educationalLevel': filterByGrade,
        })}&order%5BcreatedAt%5D=desc`);
      }
      setAllPages(pagesAll);

      setLoading(false);
    };
    getLessons();
  }, [lessonTitle, filterByGrade, filterBySubject]);

  useEffect(() => {
    if (publishedLessons && allLessons) {
      setPublishedNextUrl(publishedLessons['hydra:view']['hydra:next']);
      setPublishedPrevUrl(publishedLessons['hydra:view']['hydra:previous']);
      setAllNextUrl(allLessons['hydra:view']['hydra:next']);
      setAllPrevUrl(allLessons['hydra:view']['hydra:previous']);
    }
  }, [publishedLessons, allLessons]);

  useEffect(() => {
    dispatch(projectActionList());
    return () => {
      dispatch(projectActionReset(listOfProject.eventSource));
    };
  }, [dispatch, listOfProject.eventSource]);

  useEffect(() => {
    dispatch(creativeWorkActionList());
    return () => {
      dispatch(creativeWorkActionReset(listOfCreativeWorks.eventSource));
    };
  }, [dispatch, listOfCreativeWorks.eventSource]);

  useEffect(() => {
    dispatch(awardActionList());
    return () => {
      dispatch(awardActionReset(listOfAward.eventSource));
    };
  }, [dispatch, listOfAward.eventSource]);

  useEffect(() => {
    dispatch(orgActionList());
    return () => {
      dispatch(orgActionReset(listOfOrganization.eventSource));
    };
  }, [dispatch, listOfOrganization.eventSource]);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);

  useEffect(() => {
    if (listOfProject.retrieved) {
      setProjectList(listOfProject.retrieved['hydra:member']);
    }
  }, [listOfProject.retrieved]);

  const toggleActiveFilter = (name) => {
    if (activeFilter === name) setActiveFilter();
    else setActiveFilter(name);
  };

  const publishLesson = async () => {
    // Publish lesson to materi gratis
    await axios.put(`${LESSON_URL}/${previewProposal.id}/publish`, {});
    History.push('/admin/lesson-bank');
  };

  const handlePrevPage = async (published) => {
    setLoading(true);
    if (published) {
      const { data } = await axios.get(publishedPrevUrl);
      setPublishedLessons(data);
      setPublishedActivePage((prevState) => prevState - 1);
    } else {
      const { data } = await axios.get(allPrevUrl);
      setAllLessons(data);
      setAllActivePage((prevState) => prevState - 1);
    }
    setLoading(false);
  };

  const handleNextPage = async (published) => {
    setLoading(true);
    if (published) {
      const { data } = await axios.get(publishedNextUrl);
      setPublishedLessons(data);
      setPublishedActivePage((prevState) => prevState + 1);
    } else {
      const { data } = await axios.get(allNextUrl);
      setAllLessons(data);
      setAllActivePage((prevState) => prevState + 1);
    }
    setLoading(false);
  };

  const setActivePageNo = async (published, page, index) => {
    setLoading(true);
    if (published) {
      const { data } = await axios.get(page);
      setPublishedLessons(data);
      setPublishedActivePage(index + 1);
    } else {
      const { data } = await axios.get(page);
      setAllLessons(data);
      setAllActivePage(index + 1);
    }
    setLoading(false);
  };

  const handleSearchLesson = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      setLessonTitle(e.target.value);
    }
  };

  // Render Functions

  const renderHeading = () => {
    return (
      <div className='admin-lesson-requests-heading' >

        <h1 className='admin-lesson-requests-heading-text' >
          <img src={Folder} alt="" className='admin-lesson-requests-heading-img' />
          Lesson Bank
        </h1>

      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className='admin-lesson-requests-tabs' >
        <div
          className={`admin-lesson-requests-tabs-tab ${activeTab === 'toPublish' ? 'active' : ''}`}
          onClick={() => setActiveTab('toPublish')}
        >
          To Publish({allLessons && allLessons['hydra:totalItems']})
        </div>
        <div
          className={`admin-lesson-requests-tabs-tab ${activeTab === 'published' ? 'active' : ''}`}
          onClick={() => setActiveTab('published')}
        >
          Published({publishedLessons && publishedLessons['hydra:totalItems']})
        </div>
      </div>
    );
  };

  const ClassesFilter = () => {
    const configStatefulMultiselectClass = {
      selectSomeItems: 'Pilih Kelas',
      allItemsAreSelected: 'Semua Kelas',
      selectAll: 'Semua Kelas',
      search: 'Cari'
    };

    const gradeOptions = listOfEducationalLevel.retrieved
      ? listOfEducationalLevel.retrieved['hydra:member'].map((levels) => {
        return {
          label: levels.level,
          value: levels['@id'],
        };
      })
      : [];

    return (
      <div className='admin-lesson-requests-filter-container' >
        <MultiSelect
          options={gradeOptions}
          value={selectedFilterByGrade}
          onChange={(grade) => {
            setSelectedFilterByGrade(grade);
            const changeSelected = grade.map((g) => g.value);
            setFilterByGrade(changeSelected);
          }}
          labelledBy='Pilih Kelas'
          overrideStrings={configStatefulMultiselectClass}
          ItemRenderer={CheckBox}
        />
      </div>
    );
  };

  const SubjectsFilter = () => {
    const configStatefulMultiselectSubject = {
      selectSomeItems: 'Pilih Subjek',
      allItemsAreSelected: 'Semua Subjek',
      selectAll: 'Semua Subjek',
      search: 'Cari'
    };

    const subjectOptions = listOfTags.retrieved
      ? listOfTags.retrieved['hydra:member'].map((subject) => {
        return {
          label: subject.name,
          value: subject['@id'],
        };
      })
      : [];

    return (
      <div className='admin-lesson-requests-filter-container' >
        <MultiSelect
          options={subjectOptions}
          value={selectedFilterBySubject}
          onChange={(subject) => {
            setSelectedFilterBySubject(subject);
            const changeSelected = subject.map((g) => g.value);
            setFilterBySubject(changeSelected);
          }}
          labelledBy='Pilih Subjek'
          overrideStrings={configStatefulMultiselectSubject}
          ItemRenderer={CheckBox}
        />
      </div>
    );
  };

  const PeriodFilter = () => {
    return (
      <div className='admin-lesson-requests-period-filter' >
        <span className='admin-lesson-requests-period-filter-text' >Periode</span>

        <div
          onClick={() => toggleActiveFilter('period')}
          className={`admin-lesson-requests-filter period ${activeFilter === 'period' ? 'active' : ''}`}
        >
          {selectedPeriodFilter.length > 0
            ? `${moment(selectedPeriodFilter[0]).format('DD MMM YYYY')} 
            - ${moment(selectedPeriodFilter[1]).format('DD MMM YYYY')}`
            : 'No Period Selected'}

          <i className='fa fa-chevron-down admin-lesson-requests-filter-icon' />
        </div>

        {activeFilter === 'period' && <div className='admin-lesson-requests-filter-expand period' >

          <span className='admin-lesson-requests-period-filter-date-text' >DATE</span>

          <div className='admin-lesson-requests-period-filter-inputs' >

            <input
              type="text"
              className='admin-lesson-requests-period-filter-input'
              value={selectedPeriodFilter.length > 0 ? moment(selectedPeriodFilter[0]).format('DD MMM YYYY') : ''}
            />

            <span>TO</span>

            <input
              type="text"
              className='admin-lesson-requests-period-filter-input'
              value={selectedPeriodFilter.length > 0 ? moment(selectedPeriodFilter[1]).format('DD MMM YYYY') : ''}
            />

          </div>

          <Calendar
            className='admin-lesson-requests-calendar'
            next2Label={null}
            prev2Label={null}
            selectRange={true}
            returnValue='start'
            onChange={(value) => {
              setSelectedPeriodFilter(value);
              setActiveFilter();
            }}
          />

        </div>}

      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className='admin-lesson-requests-filters' >
        <ClassesFilter />
        <SubjectsFilter />

        <div className='admin-lesson-bank-filter-container' >
          <Form.Control
            placeholder='Search Lesson'
            onKeyUp={handleSearchLesson}
          />
        </div>

        <PeriodFilter />
      </div>
    );
  };

  const renderPagination = (data, published, pages, active) => {
    return (
      <div className='admin-lesson-requests-pagination' >

        {data && data['hydra:view']['hydra:previous'] && (
          <i
            className='fa fa-chevron-left admin-lesson-requests-pagination-arrow'
            onClick={() => handlePrevPage(published)}
          />
        )}

        {pages.map((p, i) => (
          <span
            className={`admin-lesson-requests-pagination-num ${active === i + 1 ? 'active' : ''}`}
            onClick={() => setActivePageNo(published, p, i)}
            key={i}
          >
            {i + 1}
          </span>
        ))}

        {data && data['hydra:view']['hydra:next'] && (
          <i
            className='fa fa-chevron-right admin-lesson-requests-pagination-arrow'
            onClick={() => handleNextPage(published)}
          />
        )}

        {/* <span>1 - 20 of 40</span> */}

      </div>
    );
  };

  const PublishedTable = () => {
    let filteredPublishedLesson = publishedLessons ? publishedLessons['hydra:member'] : [];

    if (selectedPeriodFilter[0]) {
      filteredPublishedLesson = filteredPublishedLesson.filter((lesson) => {
        return moment(lesson.publishedTime) >= selectedPeriodFilter[0]
        && moment(lesson.publishedTime) <= selectedPeriodFilter[1];
      });
    }

    return (
      <>
        <div className='admin-lesson-requests-table' >

          <div className='admin-lesson-requests-table-header' >

            <span className='admin-lesson-requests-table-header-text title' >TITLE</span>

            <span className='admin-lesson-requests-table-header-text subject' >SUBJECT</span>

            <span className='admin-lesson-bank-table-header-text grade' >
              GRADE
            <span className='admin-lesson-requests-table-header-text-arrow' >
                <i className='fa fa-caret-up' /> <i className='fa fa-caret-down' />
              </span>
            </span>

            <span className='admin-lesson-requests-table-header-text status' >PUBLISHED</span>

          </div>

          {publishedLessons && filteredPublishedLesson.map((lessonItem, i) => (
            <PublishedLesson
              lessonItem={lessonItem}
              projectList={listOfProject}
              organizationList={listOfOrganization}
              key={i}
            />
          ))}

        </div>
        {renderPagination(publishedLessons, true, publishedPages, publishedActivePage)}
      </>
    );
  };

  const ToPublishTable = () => {
    let filteredAllLesson = allLessons ? allLessons['hydra:member'] : [];

    if (selectedPeriodFilter[0]) {
      filteredAllLesson = filteredAllLesson.filter((lesson) => {
        return moment(lesson.publishedTime) >= selectedPeriodFilter[0]
        && moment(lesson.publishedTime) <= selectedPeriodFilter[1];
      });
    }

    return (
      <>
        <div className='admin-lesson-requests-table' >

          <div className='admin-lesson-requests-table-header' >

            <span className='admin-lesson-requests-table-header-text title' >TITLE</span>

            <span className='admin-lesson-requests-table-header-text subject' >SUBJECT</span>

            <span className='admin-lesson-bank-table-header-text grade' >
              GRADE
            <span className='admin-lesson-requests-table-header-text-arrow' >
                <i className='fa fa-caret-up' /> <i className='fa fa-caret-down' />
              </span>
            </span>

          </div>

          {allLessons && filteredAllLesson.map((lessonItem, i) => (
            <LessonAccordion
              lessonItem={lessonItem}
              setShowPreviewLessonModal={setShowPreviewLessonModal}
              setPreviewProposal={setPreviewProposal}
              key={i}
            />
          ))}
        </div>
        {renderPagination(allLessons, false, allPages, allActivePage)}
      </>
    );
  };

  return (
    <div>
      {loading ? <Loading /> : null}
      <AdminSidebar active='lessonBank' />
      <div className='admin-lesson-requests-content' >
        {renderHeading()}
        {renderTabs()}
        {renderFilters()}
        {activeTab === 'published' && <PublishedTable />}
        {activeTab === 'toPublish' && <ToPublishTable />}
        <ReviewLessonModal
          showModal={showReviewLessonModal}
          setShowModal={setShowReviewLessonModal}
        />
        <PreviewLessonModal
          showModal={showPreviewLessonModal}
          setShowModal={setShowPreviewLessonModal}
          previewProposal={previewProposal}
          setPreviewProposal={setPreviewProposal}
          publishLesson={publishLesson}
          listProject={projectList}
          setLoadingScreen={setLoading}
        />
      </div>
    </div>
  );
};

export default AdminLessonBank;
