import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list as userListAction, reset as userListActionReset } from '../../Generated/actions/user/list';
import { list as personActionList, reset as personActionReset } from '../../Generated/actions/person/list';
import { list as mediaActionList, reset as mediaActionReset } from '../../Generated/actions/mediaobject/list';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';

// get UserDataBasedOn rganization.searchList store
// if listOforganizationunconfirmedmembers.retrieved is present
// get data for them too but rganization.searchList must present
// return mappedMembers, mappedImages based on Taruns work on src/Pages/PapanPintar/PapanPintar.js
const GetUserDataBasedOnOrganizations = () => {
  const [mappedMembers, setMappedMembers] = useState({});
  const [mappedImages, setMappedImages] = useState({});
  const organizationsList = useSelector((state) => state.organization.searchList);
  const listOfPersons = useSelector((state) => state.person.list);
  const userList = useSelector((state) => state.user.list);
  const listOfImages = useSelector((state) => state.mediaobject.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const listOforganizationunconfirmedmembers = useSelector((state) => state.organizationunconfirmedmembers.list);

  const dispatch = useDispatch();
  useEffect(() => {
    if (organizationsList.retrieved && listOforganizationunconfirmedmembers.retrieved) {
      let membersArray = [];
      organizationsList.retrieved['hydra:member'].map((organizationFromList) => {
        membersArray = [...membersArray, ...organizationFromList.members, organizationFromList.founder];
        return null;
      });
      listOforganizationunconfirmedmembers.retrieved['hydra:member'].map((unconfirmedMemberFromList) => {
        membersArray = [...membersArray, unconfirmedMemberFromList.member];
        return null;
      });
      // remove duplicates and empty
      membersArray = [...new Set(membersArray)].filter(Boolean);
      dispatch(userListAction(`/users/?id[]=${membersArray.join('&id[]=')}`));
      const membersArrayString = membersArray.join('&user[]=');
      dispatch(personActionList(`/people?pagination=false&user[]=${membersArrayString}`));
    } else if (organizationsList.retrieved) {
      if (organizationsList.retrieved['hydra:member'].length > 0) {
        let membersArray = [];
        organizationsList.retrieved['hydra:member'].map((organizationFromList) => {
          membersArray = [...membersArray, ...organizationFromList.members, organizationFromList.founder];
          return null;
        });
        // remove duplicates and empty
        membersArray = [...new Set(membersArray)].filter(Boolean);
        dispatch(userListAction(`/users/?id[]=${membersArray.join('&id[]=')}`));
        const membersArrayString = membersArray.join('&user[]=');
        dispatch(personActionList(`/people?pagination=false&user[]=${membersArrayString}`));
      }
    }

    return () => {
      dispatch(personActionReset(listOfPersons.eventSource));
      dispatch(userListActionReset(userList.eventSource));
    };
  }, [organizationsList.retrieved, listOforganizationunconfirmedmembers.retrieved]);

  useEffect(() => {
    if (listOfPersons.retrieved) {
      let imagesArray = [];
      const mappedMembersObject = {};
      listOfPersons.retrieved['hydra:member'].map(
        (member) => {
          imagesArray = [...imagesArray, member.image];
          mappedMembersObject[member.user] = member;
          return null;
        }
      );
      setMappedMembers(mappedMembersObject);
      const mediaArrayString = imagesArray.filter(Boolean).join('&id[]=');
      dispatch(mediaActionList(`/media_objects?id[]=${mediaArrayString}`));
    }
    return () => {
      dispatch(mediaActionReset());
    };
  }, [listOfPersons.retrieved]);

  useEffect(() => {
    // we get list after retrieving the listOfPerson
    if (listOfImages.retrieved && listOfPersons.retrieved) {
      const object = {};
      listOfPersons.retrieved['hydra:member'].map(
        (person) => {
          object[person.user] = listOfImages.retrieved['hydra:member'].filter((image) => image['@id'] === person.image)
            .length > 0
                        && listOfImages.retrieved['hydra:member'].filter((image) => image['@id'] === person.image)
                          .shift().contentUrl;
          return setMappedImages(object);
        }
      );
    }
  }, [listOfImages.retrieved, listOfPersons.retrieved]);

  useEffect(() => {
    dispatch(tagsActionList('/tags?pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  return { mappedMembers, mappedImages };
};
export default GetUserDataBasedOnOrganizations;
