import React, { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import {
  Carousel, Col,
  Modal,
} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import './ReviewLessonModal.scss';

const ReviewLessonModal = ({ showModal, setShowModal }) => {
  // States

  const [slideIndex, setSlideIndex] = useState(0);

  const [isToggleActive, setIsToggleActive] = useState(false);

  const handleOnSelect = (indexValue) => {
    setSlideIndex(indexValue);
  };

  // Render Functions

  const renderLessonVideo = () => {
    return (
      <div>
        <div className='review-lesson-modal-sub-text' >
          Natural Science - Grade 4
        </div>
        <span className='review-lesson-modal-sub-text' >Pahami keanekaragaman budaya di Indonesia</span>
        <span className='review-lesson-modal-sub-text-small' >By Marion & Team - Ranked #1</span>
        <Col xs={12}>
          <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
        </Col>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      dialogClassName='free-material__modal'
      onHide={() => setShowModal()}
    >
      <div
        className='close'
        onClick={() => setShowModal()}
      >
        <X />
      </div>
      <Modal.Body>
        <div className='review-lesson-modal-title'>
          Review Lesson

          {/* Toggle Button */}
          <label className="review-lesson-modal-title-toggle-button">
            <input
              type="checkbox"
              checked={isToggleActive}
              onChange={() => setIsToggleActive((bool) => !bool)}
            />
            <span className="review-lesson-modal-title-toggle-button-slider round" />
          </label>
        </div>
        <Carousel
          interval={null}
          onSelect={handleOnSelect}
          indicators={false}
          defaultActiveIndex={slideIndex}
        >
          <Carousel.Item>
            {renderLessonVideo()}
          </Carousel.Item>
        </Carousel>
        <span className='review-lesson-modal-bottom-text' >
          Animation Mov
          <span className='review-lesson-modal-bottom-text-dot' />
          {slideIndex} of 18
        </span>
      </Modal.Body>
    </Modal>
  );
};

ReviewLessonModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default ReviewLessonModal;
