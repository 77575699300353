/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import MultiSelect from 'react-multi-select-component';
import AdminSidebar from '../../Components/AdminSidebar/AdminSidebar';
import Button from '../../Components/Button/Button';
import Notepad from '../../Assets/Images/Notepad.png';
import Label from '../../Components/Label/Label';
import PublishModal from '../../Components/PublishModal/PublishModal';
import StartProposalConfirmModal from '../../Components/StartProposalConfirmModal/StartProposalConfirmModal';
import './AdminLessonRequests.scss';
import { list as projectActionList, reset as projectActionReset } from '../../Generated/actions/project/list';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset
} from '../../Generated/actions/educationallevel/list';
import TagName from '../../Components/Tags/TagName';
import EducationalLevel from '../../Components/EducationalLevel/EducationalLevel';
import {
  list as awardActionList,
  reset as awardActionReset
} from '../../Generated/actions/award/list';
import {
  list as creativeWorkActionList,
  reset as creativeWorkActionReset
} from '../../Generated/actions/creativework/list';
import { PROJECT_URL } from '../../Constants/Constants';
import CheckBox from '../../Components/CheckBox/CheckBox';

const AdminLessonRequests = () => {
  const History = useHistory();
  const dispatch = useDispatch();
  const queryString = require('query-string');

  // States
  const [activeTab, setActiveTab] = useState('requested');

  const [activePageNo, setActivePageNo] = useState(1);

  const [activeFilter, setActiveFilter] = useState('');

  const [selectedFilterByGrade, setSelectedFilterByGrade] = useState([]);
  const [filterByGrade, setFilterByGrade] = useState([]);

  const [selectedFilterBySubject, setSelectedFilterBySubject] = useState([]);
  const [filterBySubject, setFilterBySubject] = useState([]);

  const [selectedFilterByStatus, setSelectedFilterByStatus] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState([]);

  const [showPublishModal, setShowPublishModal] = useState(false);

  const [showPublishedModal, setShowPublishedModal] = useState(false);

  const [publishedLesson, setPublishedLesson] = useState([]);

  const [draftLesson, setDraftLesson] = useState([]);

  const [publishModalData, setPublishModalData] = useState({});

  const [awardNotHasWinner, setAwardNotHasWinner] = useState([]);

  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState([]);

  const listOfProject = useSelector((state) => state.project.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const listOfEducationalLevel = useSelector((state) => state.educationallevel.list);
  const listOfAward = useSelector((state) => state.award.list);
  const listOfCreativeWorks = useSelector((state) => state.creativework.list);

  useEffect(() => {
    const url = `projects?${queryString.stringify(
      {
        tags: filterBySubject,
        educationalLevel: filterByGrade,
        itemsPerPage: 9
      },
      { arrayFormat: 'bracket' }
    )}`;
    dispatch(projectActionList(url));
    return () => {
      dispatch(projectActionReset(listOfProject.eventSource));
    };
  }, [
    dispatch,
    filterBySubject,
    filterByGrade,
    selectedPeriodFilter,
    listOfProject.eventSource]);

  useEffect(() => {
    dispatch(creativeWorkActionList('/creative_works?isSubmitted=true'));
    return () => {
      dispatch(creativeWorkActionReset(listOfCreativeWorks.eventSource));
    };
  }, [dispatch, listOfCreativeWorks.eventSource]);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);

  useEffect(() => {
    if (listOfAward.retrieved) {
      setAwardNotHasWinner(listOfAward.retrieved
        && listOfAward.retrieved['hydra:member']
          .filter((a) => !a.winner));
    }
  }, [listOfAward.retrieved]);

  useEffect(() => {
    if (listOfProject.retrieved) {
      const projectNotDraft = listOfProject.retrieved
      && listOfProject.retrieved['hydra:member']
        .filter((project) => project.isDraft === false);
      const projectNotHaveWinner = projectNotDraft
        .filter((project) => project.awards.find((p) => awardNotHasWinner.find((a) => a['@id'] === p)));
      setPublishedLesson(projectNotHaveWinner);
    }
  }, [listOfProject.retrieved, awardNotHasWinner]);

  useEffect(() => {
    if (listOfProject.retrieved) {
      setDraftLesson(listOfProject.retrieved
        && listOfProject.retrieved['hydra:member']
          .filter((project) => project.isDraft === true));
    }
  }, [listOfProject.retrieved]);

  useEffect(() => {
    dispatch(awardActionList());
    return () => {
      dispatch(awardActionReset(listOfAward.eventSource));
    };
  }, []);

  const statusFilterList = [
    {
      name: 'Need Winner',
      id: 'needwinner',
    },
    {
      name: 'Ongoing',
      id: 'ongoing',
    },
    {
      name: 'Completed',
      id: 'completed',
    }
  ];

  const publishedModalDetails = {
    show: showPublishedModal,
    setShow: setShowPublishedModal,
    heading: 'Yay! your request has been made. Hope it’s going well!',
    sub: 'You can check the progress on Ongoing Lesson Request or create another request!',
    isSingleButton: false,
    noText: 'Check Request',
    yesText: 'Create Another',
    yesClick: () => setShowPublishedModal(),
  };

  // Functions

  const toggleActiveFilter = (name) => {
    if (activeFilter === name) setActiveFilter();
    else setActiveFilter(name);
  };

  const handleDraftLessonClick = (canPublish, project) => {
    if (!canPublish) {
      History.push('/admin/create-lesson');
    } else {
      setPublishModalData(project);
      setShowPublishModal(true);
    }
  };

  // Render Functions

  const handleCreateLesson = async () => {
    try {
      const projectData = {
        id: uuidv4(),
        minimumNumberOfQuestions: 15,
        isDraft: true,
        lessonType: 1
      };
      const { data } = await axios.post(PROJECT_URL, projectData);
      if (data) {
        History.push(`/admin/lesson-request/${data.id}/edit`);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const renderHeading = () => {
    return (
      <div className='admin-lesson-requests-heading' >

        <h1 className='admin-lesson-requests-heading-text' >
          <img src={Notepad} alt="" className='admin-lesson-requests-heading-img' />
          Lesson Requests
        </h1>

        <div className='admin-lesson-requests-heading-btn' >
          <Button block onClick={handleCreateLesson}>Create Lesson</Button>
        </div>

      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className='admin-lesson-requests-tabs' >
        <div
          className={`admin-lesson-requests-tabs-tab ${activeTab === 'requested' ? 'active' : ''}`}
          onClick={() => setActiveTab('requested')}
        >
          Requested({publishedLesson && publishedLesson.length})
        </div>
        <div
          className={`admin-lesson-requests-tabs-tab ${activeTab === 'draft' ? 'active' : ''}`}
          onClick={() => setActiveTab('draft')}
        >
          Draft({draftLesson && draftLesson.length})
        </div>
      </div>
    );
  };

  const ClassesFilter = () => {
    const configStatefulMultiselectClass = {
      selectSomeItems: 'Pilih Kelas',
      allItemsAreSelected: 'Semua Kelas',
      selectAll: 'Semua Kelas',
      search: 'Cari'
    };

    const gradeOptions = listOfEducationalLevel.retrieved
      ? listOfEducationalLevel.retrieved['hydra:member'].map((levels) => {
        return {
          label: levels.level,
          value: levels['@id'],
        };
      })
      : [];

    return (
      <div className='admin-lesson-requests-filter-container' >
        <MultiSelect
          options={gradeOptions}
          value={selectedFilterByGrade}
          onChange={(grade) => {
            setSelectedFilterByGrade(grade);
            const changeSelected = grade.map((g) => g.value);
            setFilterByGrade(changeSelected);
          }}
          labelledBy='Pilih Kelas'
          overrideStrings={configStatefulMultiselectClass}
          ItemRenderer={CheckBox}
        />
      </div>
    );
  };

  const SubjectsFilter = () => {
    const configStatefulMultiselectSubject = {
      selectSomeItems: 'Pilih Subjek',
      allItemsAreSelected: 'Semua Subjek',
      selectAll: 'Semua Subjek',
      search: 'Cari'
    };

    const subjectOptions = listOfTags.retrieved
      ? listOfTags.retrieved['hydra:member'].map((subject) => {
        return {
          label: subject.name,
          value: subject['@id'],
        };
      })
      : [];

    return (
      <div className='admin-lesson-requests-filter-container' >
        <MultiSelect
          options={subjectOptions}
          value={selectedFilterBySubject}
          onChange={(subject) => {
            setSelectedFilterBySubject(subject);
            const changeSelected = subject.map((g) => g.value);
            setFilterBySubject(changeSelected);
          }}
          labelledBy='Pilih Subjek'
          overrideStrings={configStatefulMultiselectSubject}
          ItemRenderer={CheckBox}
        />
      </div>
    );
  };

  const StatusFilter = () => {
    const configStatefulMultiselectSubject = {
      selectSomeItems: 'Pilih Status',
      allItemsAreSelected: 'Semua Status',
      selectAll: 'Semua Status',
      search: 'Cari'
    };

    const statusOptions = statusFilterList
      ? statusFilterList.map((status) => {
        return {
          label: status.name,
          value: status.id,
        };
      })
      : [];

    return (
      <div className='admin-lesson-requests-filter-container' >
        <MultiSelect
          options={statusOptions}
          value={selectedFilterByStatus}
          onChange={(status) => {
            setSelectedFilterByStatus(status);
            const changeSelected = status.map((g) => g.value);
            setFilterByStatus(changeSelected);
          }}
          labelledBy='Pilih Status'
          overrideStrings={configStatefulMultiselectSubject}
          ItemRenderer={CheckBox}
        />
      </div>
    );
  };

  const PeriodFilter = () => {
    return (
      <div className='admin-lesson-requests-period-filter' >
        <span className='admin-lesson-requests-period-filter-text' >Period</span>

        <div
          onClick={() => toggleActiveFilter('period')}
          className={`admin-lesson-requests-filter period ${activeFilter === 'period' ? 'active' : ''}`}
        >
          {selectedPeriodFilter.length > 0
            ? `${moment(selectedPeriodFilter[0]).format('DD MMM YYYY')} 
            - ${moment(selectedPeriodFilter[1]).format('DD MMM YYYY')}`
            : 'No Period Selected'}

          <i className='fa fa-chevron-down admin-lesson-requests-filter-icon' />
        </div>

        {activeFilter === 'period' && <div className='admin-lesson-requests-filter-expand period' >

          <span className='admin-lesson-requests-period-filter-date-text' >DATE</span>

          <div className='admin-lesson-requests-period-filter-inputs' >

            <input
              type="text"
              className='admin-lesson-requests-period-filter-input'
              value={selectedPeriodFilter.length > 0 ? moment(selectedPeriodFilter[0]).format('DD MMM YYYY') : ''}
            />

            <span>TO</span>

            <input
              type="text"
              className='admin-lesson-requests-period-filter-input'
              value={selectedPeriodFilter.length > 0 ? moment(selectedPeriodFilter[1]).format('DD MMM YYYY') : ''}
            />

          </div>

          <Calendar
            className='admin-lesson-requests-calendar'
            next2Label={null}
            prev2Label={null}
            selectRange={true}
            returnValue='start'
            onChange={(value) => {
              setSelectedPeriodFilter(value);
              setActiveFilter();
            }}
          />

        </div>}

      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className='admin-lesson-requests-filters' >
        <ClassesFilter />
        <SubjectsFilter />
        <StatusFilter />
        <PeriodFilter />
      </div>
    );
  };

  const renderLabel = (project) => {
    const awards = listOfAward.retrieved && listOfAward.retrieved['hydra:member'];
    const filteredAwards = awards && awards.filter((award) => award.project === project['@id']).length > 0
      && awards.filter((award) => award.project === project['@id']);
    const checkTime = moment(project.endTime) > moment();

    if (checkTime) {
      return (
        <Label type='STATUS_IN_PROGRESS'>{'On Going'}</Label>
      );
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!checkTime && (filteredAwards !== null && !filteredAwards.find((w) => w.winner))) {
      return (
        <Label type='STATUS_NEED_PARTNER'>{'Select The Winner'}</Label>
      );
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!checkTime && (filteredAwards !== null && filteredAwards.find((w) => w.winner))) {
      return <Label type='STATUS_COMPLETED'>{'Completed'}</Label>;
    }
  };

  const getSubmittedCreativeWorks = (creativeWorks) => {
    const submittedCreativeWork = creativeWorks.filter((c) =>
      listOfCreativeWorks.retrieved['hydra:member'].find((cw) => cw['@id'] === c));
    const notSubmittedCreativeWork = creativeWorks.filter((c) =>
      !listOfCreativeWorks.retrieved['hydra:member'].find((cw) => cw['@id'] === c));
    return {
      finished: submittedCreativeWork.length,
      notFinished: notSubmittedCreativeWork.length
    };
  };

  const RequestedTable = () => {
    let filteredPublishedLesson = publishedLesson;

    if (selectedPeriodFilter[0]) {
      filteredPublishedLesson = filteredPublishedLesson.filter((lesson) => {
        return moment(lesson.endTime) >= selectedPeriodFilter[0] && moment(lesson.endTime) <= selectedPeriodFilter[1];
      });
    }

    if (filterByStatus.length) {
      filteredPublishedLesson = filteredPublishedLesson.filter((lesson) => {
        const awards = listOfAward.retrieved && listOfAward.retrieved['hydra:member'];
        const filteredAwards = awards && awards.filter((award) => award.project === lesson['@id']).length > 0
          && awards.filter((award) => award.project === lesson['@id']);
        const checkTime = moment(lesson.endTime) > moment();

        if (filterByStatus.includes('ongoing')) {
          if (checkTime) {
            return true;
          }
        }

        if (filterByStatus.includes('needwinner')) {
          if (!checkTime && (filteredAwards !== null && !filteredAwards.find((w) => w.winner))) {
            return true;
          }
        }

        if (filterByStatus.includes('completed')) {
          if (!checkTime && (filteredAwards !== null && filteredAwards.find((w) => w.winner))) {
            return true;
          }
        }

        return false;
      });
    }

    return (
      <div className='admin-lesson-requests-table' >

        <div className='admin-lesson-requests-table-header' >

          <span className='admin-lesson-requests-table-header-text title' >TITLE</span>

          <span className='admin-lesson-requests-table-header-text subject' >SUBJECT</span>

          <span className='admin-lesson-requests-table-header-text grade' >
            GRADE
            <span className='admin-lesson-requests-table-header-text-arrow' >
              <i className='fa fa-caret-up' /> <i className='fa fa-caret-down' />
            </span>
          </span>

          <span className='admin-lesson-requests-table-header-text proposal' >
            SUBMISSONS
            <span className='admin-lesson-requests-table-header-text-arrow' >
              <i className='fa fa-caret-up' /> <i className='fa fa-caret-down' />
            </span>
          </span>

          <span className='admin-lesson-requests-table-header-text status' >STATUS</span>

          <span className='admin-lesson-requests-table-header-text action' >Action</span>

        </div>

          {filteredPublishedLesson && filteredPublishedLesson.map((project, i) => (
          <div className='admin-lesson-requests-table-row' key={i}>

            <span
              className='admin-lesson-requests-table-row-text title'
              onClick={() => History.push(`/admin/lesson-requests/${project.id}`)}
            >
              {project.title}
            </span>

            <span className='admin-lesson-requests-table-row-text subject' >
              {project.tags.map((tag) => (
                <TagName tag={tag} key={tag} />
              ))}
            </span>

            <span className='admin-lesson-requests-table-row-text grade' >
              <EducationalLevel
                type='educational-level-text'
                educationalLevel={project.educationalLevel}
              />
            </span>

            <span className='admin-lesson-requests-table-row-text proposal'>
              Finished : {listOfCreativeWorks.retrieved && getSubmittedCreativeWorks(project.creativeWorks).finished}
              <br/>
              Working : {listOfCreativeWorks.retrieved
                && getSubmittedCreativeWorks(project.creativeWorks).notFinished} <br/>
              {/* Total : {project.creativeWorks.length} */}
            </span>

            <span className='admin-lesson-requests-table-row-text status'>
              {renderLabel(project)}
            </span>

            <span className='admin-lesson-requests-table-row-text action'>

              <Button
                type='secondary'
                onClick={() => { History.push(`/admin/lesson-request/${project.id}/edit`); }}
              >
                Edit Request
              </Button>
            </span>

          </div>
          ))}

      </div>
    );
  };

  const handleUpdateField = async (projectId, updatedData) => {
    try {
      await axios.put(`${PROJECT_URL}/${projectId}`, updatedData);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handlePublish = async (projectId) => {
    await handleUpdateField(projectId, { isDraft: false });
  };

  const DraftTable = () => {
    return (
      <div className='admin-lesson-requests-table' >

        <div className='admin-lesson-requests-table-header' >

          <span className='admin-lesson-requests-table-header-text title' >TITLE</span>

          <span className='admin-lesson-requests-table-header-text subject' >SUBJECT</span>

          <span className='admin-lesson-requests-table-header-text grade' >
            GRADE
          <span className='admin-lesson-requests-table-header-text-arrow' >
              <i className='fa fa-caret-up' /> <i className='fa fa-caret-down' />
            </span>
          </span>

        </div>

        {draftLesson && draftLesson.map((project, i) => (
          <div
            key={i}
            className='admin-lesson-requests-table-row'
            onClick={() => handleDraftLessonClick((project.winner !== '' || project.winner !== undefined), project)}
          >
            <span
              className='admin-lesson-requests-table-row-text title'
            >
              {project.title}
            </span>

            <span className='admin-lesson-requests-table-row-text subject' >
              {project.tags.map((tag) => (
                <TagName tag={tag} key={tag} />
              ))}
            </span>

            <span className='admin-lesson-requests-table-row-text grade' >
              <EducationalLevel
                type='educational-level-text'
                educationalLevel={project.educationalLevel}
              />
            </span>
            <div
              className={`admin-lesson-requests-table-row-btn 
               ${(project.winner !== '' || project.winner !== undefined) ? 'padding' : ''} 
              `}
            >
              {(project.isDraft === true && (project.title === '' || project.title === undefined))
                ? (
                  <Button
                    type='secondary'
                    onClick={() => { History.push(`/admin/lesson-request/${project.id}/edit`); }}
                  >
                    Edit Request
                  </Button>
                )
                : (
                  <Button onClick={() => handlePublish(project.id)}>Publish Lesson Request</Button>
                )}
            </div>
          </div>
        ))}

      </div>
    );
  };

  const renderPagination = () => {
    return (
      <div className='admin-lesson-requests-pagination' >

        <i className='fa fa-chevron-left admin-lesson-requests-pagination-arrow' />

        <span
          className={`admin-lesson-requests-pagination-num ${activePageNo === 1 ? 'active' : ''}`}
          onClick={() => setActivePageNo(1)}
        >
          1
        </span>

        <span
          className={`admin-lesson-requests-pagination-num ${activePageNo === 2 ? 'active' : ''}`}
          onClick={() => setActivePageNo(2)}
        >
          2
        </span>

        <i className='fa fa-chevron-right admin-lesson-requests-pagination-arrow' />

        <span>1 - 20 of 40</span>

      </div>
    );
  };

  return (
    <div>
      <PublishModal
        showModal={showPublishModal}
        setShowModal={setShowPublishModal}
        onPublishClick={() => {
          setShowPublishModal();
          setShowPublishedModal(true);
        }}
        publishModalData={publishModalData}
      />
      <AdminSidebar active='lessonRequests' />
      <div className='admin-lesson-requests-content' >
        {renderHeading()}
        {renderTabs()}
         {renderFilters()}
        {activeTab === 'requested' && <RequestedTable />}
        {activeTab === 'draft' && <DraftTable />}
         {renderPagination()}
        <StartProposalConfirmModal
          isShowModal={showPublishedModal}
          setIsShowModal={setShowPublishedModal}
          details={publishedModalDetails}
        />
      </div>
    </div>
  );
};

export default AdminLessonRequests;
