import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row, Col, Form,
  Button, Image
} from 'react-bootstrap';
import './SetPassword.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import albyIllustration from '../../Assets/Images/alby-illustration.png';
import Input from '../../Components/Input/Input';
import HandleEnterKey from '../../Components/Input/HandleEnterKey';
import { resetPasswordData } from '../../Actions/ForgotPassword';
import { resetPassword } from '../../Schemas/ForgotPasswordSchema';
import PasswordChangeSuccessModal from
  '../../Components/PasswordChangeSuccess/passwordChangeSuccessModal';

const SetPassword = () => {
  const history = useHistory();
  const isError = useSelector((state) => state.forgotPassword.hasError);
  const passwordChangeSuccess = useSelector((state) => state.forgotPassword.passwordChangeSuccess);
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordSuccessModel, setShowPasswordSuccessModel] = useState(passwordChangeSuccess);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (passwordChangeSuccess) {
      setIsLoading(false);
      setShowPasswordSuccessModel(true);
    }
  }, [passwordChangeSuccess]);

  const userData = (history !== undefined) ? queryString.parse(history.location.search) : [];
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmedPassword: ''
    },
    validationSchema: resetPassword,
  });

  const [passwordData, setPasswordData] = useState(
    {
      password: '',
      confirmedPassword: '',
      token: userData.token,
      id: userData.id
    }
  );
  const [activeField, setActiveField] = useState('');

  const {
    setFieldValue, errors,
    values, validateForm, setStatus
  } = formik;

  const handleResetPassword = async (event, credentials) => {
    event.preventDefault();
    const { password, confirmedPassword } = credentials;
    const passwordAndConfirmed = {
      password,
      confirmedPassword,
      token: passwordData.token,
      id: passwordData.id
    };
    const validate = await validateForm();
    if (isEmpty(validate)) {
      setIsLoading(true);
      return dispatch(resetPasswordData(passwordAndConfirmed, history));
    }
    setIsLoading(false);
    return setStatus('Silakan masukkan kata sandi Anda dan kata sandi terkonfirmasi!');
  };

  const handleOnChangePassword = (event) => {
    const { target: { value } } = event;
    const object = { ...passwordData, password: value };
    setPasswordData(object);
  };

  const handleOnChangeConfirmedPassword = (event) => {
    const { target: { value } } = event;
    const object = { ...passwordData, confirmedPassword: value };
    setPasswordData(object);
  };

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const renderSetPasswordForm = () => {
    return (
      <div className='set-password-form-input'>
        {isError && <div className='set-password-form-error'>{ isError }</div>}
        <Form>
          <Input
            controlId='passwordInput'
            label='Kata Sandi Baru'
            type={showPassword ? 'text' : 'password'}
            placeholder='Kata Sandi'
            isInvalid={activeField !== 'password' && !isEmpty(errors.password)}
            errorMessage={errors.password}
            onChange={handleOnChangePassword}
            onFocus={() => setActiveField('password')}
            onBlur={() => {
              setActiveField();
              setFieldValue('password', passwordData.password);
            }}
            addInputClass={passwordData.password !== '' ? 'filled' : ''}
          />
           <Input
            controlId='confirmPasswordInput'
            label='Konfirmasi Kata Sandi'
            type={showPassword ? 'text' : 'password'}
            placeholder='Kata Sandi'
            isInvalid={activeField !== 'confirmedPassword' && !isEmpty(errors.confirmedPassword)}
            errorMessage={errors.confirmedPassword}
            onChange={handleOnChangeConfirmedPassword}
            onFocus={() => setActiveField('confirmedPassword')}
            onBlur={() => {
              setActiveField();
              setFieldValue('confirmedPassword', passwordData.confirmedPassword);
            }}
            addInputClass={passwordData.confirmedPassword !== '' ? 'filled' : ''}
          />
          <Form.Text onClick={togglePassword} className="text-muted" style={{ cursor: 'pointer' }}>
            {showPassword ? 'Sembunyikan' : 'Tampilkan'} kata sandi
          </Form.Text>
          <Row className='set-password-form-input-after'>
            <Col xs={12}>
              <Button
                className='set-password-form-input-button'
                ref={buttonRef}
                type='submit'
                onClick={(event) => handleResetPassword(event, values)}
                disabled={isLoading}
              >
                {isLoading ? 'Sedang mengatur ulang...' : 'Atur Ulang'}
                </Button>
              <HandleEnterKey handleOnEnterKey={ () => {
                buttonRef.current.focus();
                buttonRef.current.click();
              } }/>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className='set-password'>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <div className='set-password-left-section'>
              <div className='set-password-round'>
                <div className='set-password-greetings'>
                <h1>
                    Kamu lupa! <br />
                    password? Tenang, <br />
                    Alby akan <br />
                    membantu!
                  </h1>
                </div>
              </div>
              <div className='set-password-illustration'>
                <Image src={albyIllustration} width='400' alt='' />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className='set-password-right-section'>
              <div className='set-password-form'>
                <Row>
                  <Col xs={12}>
                    <h1 className='set-password-form-title'>Atur Ulang Kata Sandi</h1>
                  </Col>
                  {/* <Col xs={12}>
                    {renderQuickLogin()}
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col xs={12}>
                    <div className='set-password-form-separator'>
                      Use another account ?
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12}>
                    {renderSetPasswordForm()}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <PasswordChangeSuccessModal
          isShowModal={showPasswordSuccessModel}
          setshowPasswordSuccessModel={setShowPasswordSuccessModel}/>
      </Container>
    </div>
  );
};

export default SetPassword;
