import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './ModalGuidelines.scss';

const ModalGuidelines = ({ showModal, setShowModal, guidelineDetail }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className='modal-guidelines'
      size='xl'
    >
      <Modal.Body>
        <h3>{guidelineDetail.heading}</h3>
        <span>{guidelineDetail.fileName}</span>
        <div>
          <ReactPlayer
            width='100%'
            height='100%'
            url={guidelineDetail.url}
            controls
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalGuidelines;
