import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import { decryptData } from '../Helpers/Common';
import { HASH_DECRYPT } from '../Constants/Constants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const History = useHistory();
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    (async () => {
      const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
      const exludePathname = ['/complete-profile', '/logout'];
      if (
        loggedUser
        && !(loggedUser.roles.includes('ROLE_TEACHER') || loggedUser.roles.includes('ROLE_DESIGNER'))
        && !loggedUser.roles.includes('ROLE_ADMIN')
        && !exludePathname.includes(History.location.pathname)
      ) {
        const { data } = await axios.get(localStorage.getItem('id'));
        if (data && isEmpty(data.tags)) {
          History.push('/complete-profile');
        }
      }
    })();
  }, []);

  return (
    <Route {...rest} component={(props) => (
      localStorage.getItem('token')
        ? (<Component {...props} />)
        : (<Redirect to="/papan-pintar" />)
    )} />
  );
};

export default PrivateRoute;
