import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { decryptData } from '../Helpers/Common';
import { HASH_DECRYPT } from '../Constants/Constants';

export const AdminRoute = ({ component: Component, ...rest }) => {
  const History = useHistory();
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    (async () => {
      const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
      const exludePathname = ['/complete-profile', '/logout'];
      if (
        loggedUser
        && !loggedUser.roles.includes('ROLE_ADMIN')
        && !exludePathname.includes(History.location.pathname)
      ) {
        History.push('/');
      }
    })();
  }, []);

  return (
    <Route {...rest} component={(props) => (
      localStorage.getItem('token')
        ? (<Component {...props} />)
        : (<Redirect to="/papan-pintar" />)
    )} />
  );
};

export default AdminRoute;
