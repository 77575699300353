import React, { useEffect, useState } from 'react';
import {
  Carousel, Col,
  Image,
  Modal, Row
} from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import VideoPlayer from 'react-simple-video-player';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from '../Button/Button';
import Label from '../Label/Label';
import './ViewProposalModal.scss';
import ProposalQuestionPreview from '../ProposalQuestionPreview/ProposalQuestionPreview';
import { DOCUMENT_OBJECTS, IMAGE_OBJECTS, VIDEO_OBJECTS } from '../../Constants/Constants';
import { multipleAnswerField } from '../../Helpers/StaticField';
import { submitQuestion } from '../../Actions/Question';
import {
  list as creativeWorkActionList,
  reset as creativeWorkActionListReset
} from '../../Generated/actions/creativework/list';

const ViewProposalModal = ({
  showModal, setShowModal, mappedImages,
  proposalData, organizationData, addToFinalRanking, cardIndex,
  setOrganizationData, setShowMembers, setMappedMembers, setLoading
}) => {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoData, setVideoData] = useState();
  const [docData, setDocData] = useState();
  const [scenesData, setScenesData] = useState([]);
  const [imageObjects, setImageObjects] = useState();
  const questions = useSelector((state) => state.questions);
  const creativeWorkList = useSelector((state) => state.creativework.list);
  const listOfPersons = useSelector((state) => state.person.list);

  useEffect(() => {
    const getImages = async () => {
      if (proposalData.hasParts.length === creativeWorkList.retrieved['hydra:member'].length) {
        setLoading(true);
        const questionImage = creativeWorkList.retrieved['hydra:member'].filter((q) => q.image)
          .map((q) => q.image);
        const imageList = [...questionImage, ...proposalData.images].join('&id[]=');
        if (imageList.length > 0) {
          const { data } = await axios.get(`${IMAGE_OBJECTS}?id[]=${imageList}`);
          setImageObjects(data);
          if (!isEmpty(proposalData.images)) {
            const lessonScenes = data['hydra:member']
              .filter((i) => proposalData.images.includes(i['@id']));
            setScenesData(lessonScenes);
          }
        } else {
          setImageObjects([]);
          setScenesData([]);
        }
        setLoading(false);
      }
    };
    if (!isEmpty(proposalData) && creativeWorkList.retrieved) {
      getImages();
    }
  }, [showModal, creativeWorkList.retrieved]);

  useEffect(() => {
    if (organizationData.creativeWork !== undefined) {
      const creativeWorkId = organizationData.creativeWork
        && organizationData.creativeWork.split('/')[2];
      dispatch(creativeWorkActionList(
        `/creative_works/${creativeWorkId}/has_parts?order%5Bordering%5D=asc`
      ));
    }
    return () => {
      dispatch(creativeWorkActionListReset(creativeWorkList.eventSource));
    };
  }, [organizationData]);

  useEffect(() => {
    (async () => {
      if (proposalData && proposalData.video !== undefined) {
        const videoId = proposalData.video && proposalData.video.split('/')[2];
        const { data } = await axios.get(`${VIDEO_OBJECTS}/${videoId}`);
        setVideoData(data);
      } else {
        setVideoData();
      }
      if (proposalData && proposalData.document !== undefined) {
        const documentId = proposalData.document && proposalData.document.split('/')[2];
        const { data } = await axios.get(`${DOCUMENT_OBJECTS}/${documentId}`);
        setDocData(data);
      } else {
        setDocData();
      }
    })();
  }, [proposalData]);

  useEffect(() => {
    if (creativeWorkList.retrieved) {
      const completeQuestionData = creativeWorkList.retrieved
        && creativeWorkList.retrieved['hydra:member']
          .map((filteredQuestion) => ({
            ...filteredQuestion,
            description: filteredQuestion.description || '',
            answerKey: filteredQuestion.answerKey || '',
            visualBrief: filteredQuestion.visualBrief || '',
            answers: [],
            answerPreview: multipleAnswerField,
            image: filteredQuestion.image || null
          }));
      dispatch(submitQuestion(completeQuestionData));
    }
  }, [creativeWorkList.retrieved]);

  const handleSelect = (selectedIndex) => {
    setCurrentSlide(selectedIndex);
  };

  const handleMembers = () => {
    const members = listOfPersons.retrieved['hydra:member']
      .filter((member) => organizationData.members.find((org) => org === member.user));
    setShowMembers(true);
    setMappedMembers(members);
  };

  const renderHeading = () => {
    return (
      <div className='view-proposal-modal-heading'>
        <span>{organizationData.name}</span>
        <div className='view-proposal-modal-heading-members' onClick={handleMembers}>
          {organizationData.members && organizationData.members.map((member, index) => (
            <img
              src={mappedImages[member]}
              alt=""
              className='lesson-request-detail-ranked-team-avatar'
              key={index}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderAnimation = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>
            {videoData ? videoData.name : 'MP4 File'}
          </div>
          <div className='proposal-summary_content file__vide'>
            <Row>
              <Col xs={12}>
                {videoData ? <VideoPlayer
                  url={videoData.contentUrl}
                  autosize
                /> : (
                  <h4 className='text-danger text-center'>Final Movie Not Uploaded</h4>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const renderVoiceover = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>
            Voice Over Text
          </div>
          <div className='proposal-summary__content file-vot__preview'>
            {proposalData && proposalData.transcript ? (
              <p>{proposalData.transcript}</p>
            ) : (
              <h4 className='text-danger text-center'>Voice Over Text Empty</h4>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLessonBrief = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>
            Lesson Brief
          </div>
          <div className='proposal-summary__content file__vide text-center'>
            {docData ? (
              <a className='text-center' href={docData.contentUrl} target='_blank'>{docData.name}</a>
            ) : (
              <h4 className='text-danger text-center'>Lesson Brief Empty</h4>
            )}
          </div>
        </div>
      </div>
    );
  };

  const LessonScenes = ({ image, index }) => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>
            Lesson Scenes {index + 1}
          </div>
          <div className='proposal-summary__content file__vide'>
            <a href={image.contentUrl} target="_blank" rel="noopener noreferrer">
              <Image src={image.contentUrl} width='100%'/>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Carousel
        interval={null}
        indicators={false}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          {renderAnimation()}
        </Carousel.Item>
        <Carousel.Item>
          {renderVoiceover()}
        </Carousel.Item>
        <Carousel.Item>
          {renderLessonBrief()}
        </Carousel.Item>
        {scenesData.length > 0
          ? scenesData.map((image, i) => ((
            <Carousel.Item key={image.id}>
              <LessonScenes image={image} index={i} />
            </Carousel.Item>
          )))
          : (
            <Carousel.Item>
              <h4 className='text-danger text-center'>Lesson Scenes Empty</h4>
            </Carousel.Item>
          )
        }

        {questions.map((question, index) => (
          <Carousel.Item key={index}>
            <ProposalQuestionPreview
              questionData={question}
              previewIndex={index}
              questionsCount={questions.length}
              imageObjects={imageObjects}
              isCreativeWork
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  return (
    <Modal
      show={showModal}
      dialogClassName='free-material__modal'
      onHide={() => {
        setShowModal(false);
        setCurrentSlide(0);
        setOrganizationData({});
      }}
    >
      <div
        className='close'
        onClick={() => {
          setShowModal(false);
          setOrganizationData({});
        }}
      >
        <X />
      </div>
      <Modal.Body>
        {renderHeading()}
        <Label type={organizationData.status} />
        {!isEmpty(proposalData) && renderContent()}
        <span className='view-proposal-modal-tally' >
          {currentSlide + 1} of {3 + questions.length + (scenesData.length === 0 ? 1 : scenesData.length)}
        </span>
        <div className='free-material__action'>
          <Row>
            <div className='view-proposal-modal-btn' >
              <Button type='secondary' block={true} onClick={() => {
                addToFinalRanking(cardIndex);
                setShowModal(false);
                setOrganizationData({});
              }}>
                <i className='view-proposal-modal-btn-heart fa fa-heart' />
              </Button>
            </div>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ViewProposalModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default ViewProposalModal;
