import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import './JoinTeamModal.scss';
import { X, PersonPlusFill } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import RequestJoinTeam from '../../Assets/Images/request-join-team.png';
import Button from '../Button/Button';
import {
  join as organizationUnconfirmedMembersActionJoin
} from '../../Generated/actions/organizationunconfirmedmembers/create';

const JoinTeamModal = (props) => {
  const dispatch = useDispatch();
  const { isShowModal, setIsShowModal, organization } = props;
  const [isRequest, setIsRequest] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [isCompleteProfile, setIsCompleteProfile] = useState(false);

  const handleSendRequest = () => {
    if (localStorage.getItem('id')) {
      dispatch(organizationUnconfirmedMembersActionJoin(
        {
          id: uuidv4(),
          organization: organization['@id'],
          member: localStorage.getItem('id')
        }
      ));
    }
    setIsRequest(false);
    setIsDone(true);
  };

  const handleDoneRequest = () => {
    setIsDone(false);
    setIsCompleteProfile(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className='join-team-modal'>
      <Modal
        show={isShowModal}
        dialogClassName='join-team-modal-box'
        backdrop='static'
        centered
      >
        <Modal.Body>
          <div className='join-team-modal-box-close'>
            <X onClick={handleCloseModal} />
          </div>
          <div className='join-team-modal-box-content'>
            <div className='join-team-illustration'>
              <Image src={RequestJoinTeam} width='100%' />
            </div>
            {isRequest && (
              <>
                <h4>Apakah kamu yakin ingin bergabung dengan tim?</h4>
                <p>Buat tim yang hebat dengan anggota tim dan menangkan kontes ini!</p>
                <Button block={true} onClick={handleSendRequest}>
                  <PersonPlusFill /> Kirim Permintaan
                </Button>
              </>
            )}
            {isDone && (
              <>
                <h4>Yass!</h4>
                <h4>Permintaan kamu telah dikirim!</h4>
                <p>
                  Setelah permintaan kamu disetujui, kamu akan diberi tahu.
                  Semoga berhasil dan selamat bersenang - senang!
                </p>
                <Button onClick={handleDoneRequest} block={true}>
                  Tutup
                </Button>
              </>
            )}
            {isCompleteProfile && (
              <>
                <h4>
                  Mohon lengkapi profil agar teman setim bisa mengenal lebih dekat
                </h4>
                <p>
                  Ooops! Maaf, kamu harus melengkapi profilmu sebelum bergabung dengan tim.
                </p>
                <Button onClick={handleCloseModal} block={true}>
                  Lengkapi Profil Saya
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default JoinTeamModal;
