const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SUBMIT_QUESTION':
      return action.payload;
    case 'SAVE_ANSWER_DATA':
      return action.payload;
    case 'SAVE_IMAGE_DATA':
      return action.payload;
    case 'ERROR_IMAGE_UPLOAD':
      return {
        ...state,
        errorUploadImage: action.payload
      };
    default:
      return state;
  }
};
