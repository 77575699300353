import React from 'react';
import { Badge } from 'react-bootstrap';
import TagName from './TagName';

function Tag(props) {
  return <Badge
    pill
    variant="light"
    className='project-list-items-description-custom-badge'
  >
    {TagName(props)}
  </Badge>;
}

export default Tag;
