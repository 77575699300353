import React, { useState } from 'react';
import {
  Container, Row, Col,
  Form, Button, Image
} from 'react-bootstrap';
import Select from 'react-select';
import { Redirect, Link } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import './CompleteProfile.scss';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import signUpIllustration from '../../Assets/Images/signup-illustration.png';
import designerPicker from '../../Assets/Images/designer-picker-illustration.png';
import teacherPicker from '../../Assets/Images/teacher-picker-illustration.png';
import { useFetch } from '../../Hooks/UseFetch/UseFetch';
import {
  ADDRESS_REGION, HASH_DECRYPT, OPTION_TAGS_URL
} from '../../Constants/Constants';
import MessageConstants from '../../Constants/MessageConstants';
import { completeProfileSchema } from '../../Schemas/CompleteProfileSchema';
import { completeProfile } from '../../Actions/CompleteProfile';
import PhoneInput from '../../Components/PhoneInput/PhoneInput';
import { decryptData } from '../../Helpers/Common';

const { ERROR_COMPLETE_PROFILE } = MessageConstants;

const CompleteProfile = (props) => {
  const { history } = props;
  const isErrorCompleteProfile = useSelector((state) => state.completeProfile.isErrorCompleteProfile);
  const [isPickerTeacher, setIsPickerTeacher] = useState(false);
  const [isPickerDesigner, setIsPickerDesigner] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const { fetchedData: { data: addressRegion } } = useFetch(`${ADDRESS_REGION}?order%5Bname%5D=asc`);
  const { fetchedData: { data: tagData }, callReFetch } = useFetch(OPTION_TAGS_URL, {
    role: isPickerTeacher ? 'ROLE_TEACHER' : 'ROLE_DESIGNER'
  });
  const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
  const { fetchedData: { data: userData } } = useFetch(localStorage.getItem('id'));
  const dispatch = useDispatch();
  const isSelectDisabled = (!(isPickerTeacher === true || isPickerDesigner === true));
  const animatedComponents = makeAnimated();
  const provinceOption = addressRegion
    && addressRegion['hydra:member'].map((value) => {
      return {
        value: value['@id'],
        label: value.name
      };
    });
  const tagOption = tagData
    && tagData['hydra:member'].map((value) => {
      return {
        value: value['@id'],
        label: value.tag
      };
    });

  const style = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused && '#F2767E',
      boxShadow: state.isFocused && '0 0 0 0.2rem #F2767E',
      '&:hover': {
        border: '1px solid #F2767E'
      }
    })
  };

  const formik = useFormik({
    initialValues: {
      role: '',
      tags: [],
      worksFor: '',
      addressRegion: '',
      phone: ''
    },
    validationSchema: completeProfileSchema,
  });

  const {
    errors, getFieldProps, setFieldValue,
    values, validateForm, touched
  } = formik;

  if (userData && !isEmpty(userData.tags)) {
    return <Redirect to='/papan-pintar' />;
  }

  const handleActivePicker = (pickerType) => {
    if (pickerType === 'teacher') {
      setIsPickerTeacher(true);
      setIsPickerDesigner(false);
      setFieldValue('role', 'ROLE_TEACHER');
    }
    if (pickerType === 'designer') {
      setIsPickerDesigner(true);
      setIsPickerTeacher(false);
      setFieldValue('role', 'ROLE_DESIGNER');
    }
    callReFetch();
  };

  const handleChangeTag = (value) => {
    let listOfTag = [];
    if (!isEmpty(value)) {
      listOfTag = value.map((tag) => tag.value);
    }
    setSelectedTags(value);
    setFieldValue('tags', listOfTag);
  };

  const handleChangeAddressRegion = (value) => {
    setFieldValue('addressRegion', value.value);
  };

  const handleSubmitPersonData = async (event, personData) => {
    event.preventDefault();
    const userId = localStorage.getItem('id');
    const validate = await validateForm();
    if (isEmpty(validate)) {
      const updateRoleAndTag = {
        role: personData.role,
        tags: personData.tags
      };
      let createPersonData;
      if (personData.addressRegion === '') {
        createPersonData = {
          phoneNumber: `+62${personData.phone}`,
          worksFor: personData.worksFor,
        };
      }
      if (personData.addressRegion !== '') {
        createPersonData = {
          phoneNumber: `+62${personData.phone}`,
          worksFor: personData.worksFor,
          addressRegion: personData.addressRegion
        };
      }
      dispatch(completeProfile(userId, updateRoleAndTag, createPersonData, history));
    }
  };

  const renderProfileCompletionForm = () => {
    return (
      <div className='complete-profile-form-input'>
        {isErrorCompleteProfile && <div className='error-complete-profile '>{ERROR_COMPLETE_PROFILE}</div>}
        <Form>
          <div className='complete-profile-form-picker'>
            <div
              className={`
                complete-profile-form-picker-teacher 
                ${isPickerTeacher && 'complete-profile-form-picker-active'}
              `}
              onClick={() => {
                handleActivePicker('teacher');
                setSelectedTags([]);
                setFieldValue('tags', []);
              }}
            >
              <Image src={teacherPicker} width='110' alt='' />
              <p className='complete-profile-form-picker-text'>Seorang Guru</p>
            </div>
            <div>ATAU</div>
            <div
              className={`
                complete-profile-form-picker-designer 
                ${isPickerDesigner && 'complete-profile-form-picker-active'}
              `}
              onClick={() => {
                handleActivePicker('designer');
                setSelectedTags([]);
                setFieldValue('tags', []);
              }}
            >
              <Image src={designerPicker} width='120' alt='' />
              <p className='complete-profile-form-picker-text'>Seorang Desainer</p>
            </div>
          </div>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Bidang</Form.Label>
            <Select
              isMulti
              components={animatedComponents}
              options={tagOption}
              placeholder='Pilih bidang anda'
              className='complete-profile-form-select'
              classNamePrefix='complete-profile-form-select'
              styles={style}
              isDisabled={isSelectDisabled}
              onChange={handleChangeTag}
              value={selectedTags}
            />
            <Form.Control.Feedback type="invalid">
              {errors.tags}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Tempat Kerja</Form.Label>
            <Form.Control
              required
              className={`complete-profile-form-input-control ${values.worksFor !== '' ? 'filled' : ''}`}
              type='text'
              placeholder='Masukkan nama lembaga tempat anda bekerja'
              isInvalid={!isEmpty(errors.worksFor) && touched.worksFor}
              errorMessage={errors.worksFor}
              {...getFieldProps('worksFor')}
            />
          </Form.Group>
          <PhoneInput
            label='Nomor Telpon'
            errorMessage={errors.phone}
            isInvalid={!isEmpty(errors.phone) && touched.phone}
            controlId="phoneInput"
            onChange={(e) => setFieldValue('phone', e.target.value)}
            inputClassname={values.phone !== '' ? 'filled' : ''}
            value={!isEmpty(values.phone) ? values.phone.replace('+62', '') : ''}
          />
          <Form.Group>
            <Form.Label className='complete-profile-form-input-label'>Kota</Form.Label>
            <Select
              components={animatedComponents}
              options={provinceOption}
              placeholder='Pilih lokasi'
              className='complete-profile-form-select'
              classNamePrefix='complete-profile-form-select'
              styles={style}
              onChange={handleChangeAddressRegion}
            />
            <Form.Control.Feedback type="invalid">
              {errors.addressRegion}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Row>
          <Col xs={12}>
            <Button
              className='complete-profile-form-input-button'
              type='submit'
              onClick={(event) => handleSubmitPersonData(event, values)}
            >
              Submit
            </Button>
          </Col>
          {loggedUser && loggedUser.roles.includes('ROLE_ADMIN') && (
            <Col xs={12}>
              <div className='complete-profile-form-input-skip'>
                <Link to='/papan-pintar'>Skip for now</Link>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  return (
    <div className='complete-profile'>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <div className='complete-profile-left-section'>
              <div className='complete-profile-round'>
                <div className='complete-profile-greetings'>
                  <h1>
                    Hore! <br />
                    Terima kasih <br />
                    sudah bergabung!
                  </h1>
                </div>
              </div>
              <div className='complete-profile-illustration'>
                <Image src={signUpIllustration} width='475' />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className='complete-profile-right-section'>
              <div className='complete-profile-form'>
                <Row>
                  <Col xs={12}>
                    <h1 className='complete-profile-form-title'>Aku adalah...</h1>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {renderProfileCompletionForm()}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompleteProfile;
