import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row, Col, Form,
  Button, Image, Alert
} from 'react-bootstrap';
// import { ArrowRight } from 'react-bootstrap-icons';
import './SignIn.scss';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import MessageConstants from '../../Constants/MessageConstants';
import albyIllustration from '../../Assets/Images/alby-illustration.png';
import { signIn } from '../../Actions/Auth';
import { signInSchema } from '../../Schemas/AuthenticationSchema';
import Input from '../../Components/Input/Input';
import { isErrorState, isSuccessRegisterState } from '../../Selectors/AuthSelector/AuthSelector';
import HandleEnterKey from '../../Components/Input/HandleEnterKey';
// import CheckBox from '../../Components/CheckBox/CheckBox';

const {
  ERROR_LOGIN,
  SUCCESS_REGISTER,
  SUCCESS_VERIFIED
} = MessageConstants;

const SignIn = () => {
  const history = useHistory();
  const isError = useSelector(isErrorState);
  const isSuccessRegister = useSelector(isSuccessRegisterState);
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
    }
  }, [isError]);

  const verifiedUserPath = (history !== undefined) ? history.location.pathname : '';
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: signInSchema,
  });

  const [signinData, setSigninData] = useState({ email: '', password: '' });
  const [activeField, setActiveField] = useState('');

  const {
    setFieldValue, errors,
    values, validateForm, setStatus
  } = formik;

  const handleSignIn = async (event, credentials) => {
    event.preventDefault();
    const { email, password } = credentials;
    const emailAndPassword = {
      email,
      password
    };
    const validate = await validateForm();
    if (isEmpty(validate)) {
      setIsLoading(true);
      return dispatch(signIn(emailAndPassword, history));
    }
    setIsLoading(false);
    return setStatus('Please input your email and password correctly!');
  };

  const handleOnChangeEmail = (event) => {
    const { target: { value } } = event;
    const object = { ...signinData, email: value };
    setSigninData(object);
    // setFieldValue('email', value);
  };

  const handleOnChangePassword = (event) => {
    const { target: { value } } = event;
    const object = { ...signinData, password: value };
    setSigninData(object);
    // setFieldValue('password', value);
  };

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  // const renderQuickLogin = () => {
  //   return (
  //     <div className='sign-in-form-quick-login'>
  //       <div className='sign-in-form-quick-login-avatar'>
  //         J
  //       </div>
  //       <div className='sign-in-form-quick-login-name'>John Doe</div>
  //       <div className='sign-in-form-quick-login-icon'>
  //         <ArrowRight />
  //       </div>
  //     </div>
  //   );
  // };

  const renderSignInForm = () => {
    return (
      <div className='sign-in-form-input'>
        {isError && <div className='sign-in-form-error'>{typeof isError === 'boolean' ? ERROR_LOGIN : isError}</div>}
        {(!isError && isSuccessRegister) && (
          <Alert variant='success'>
            {SUCCESS_REGISTER}
          </Alert>
        )}
        {(!isError && !isSuccessRegister && verifiedUserPath === '/login/verified-user') && (
            <Alert variant='success'>
              {SUCCESS_VERIFIED}
            </Alert>
        )}
        <Form>
          <Input
            controlId='emailInput'
            label='Alamat Email'
            type='email'
            placeholder='Alamat Email'
            isInvalid={activeField !== 'email' && !isEmpty(errors.email)}
            errorMessage={errors.email}
            onChange={handleOnChangeEmail}
            onFocus={() => setActiveField('email')}
            onBlur={() => {
              setActiveField();
              setFieldValue('email', signinData.email);
            }}
            addInputClass={signinData.email !== '' && activeField === 'email' ? 'filled' : ''}
          />
          <Input
            controlId='passwordInput'
            label='Kata Sandi'
            type={showPassword ? 'text' : 'password'}
            placeholder='Minimal 7 karakter'
            isInvalid={activeField !== 'password' && !isEmpty(errors.password)}
            errorMessage={errors.password}
            onChange={handleOnChangePassword}
            onFocus={() => setActiveField('password')}
            onBlur={() => {
              setActiveField();
              setFieldValue('password', signinData.password);
            }}
            addInputClass={signinData.password !== '' && activeField === 'password' ? 'filled' : ''}
          />
          <Form.Text onClick={togglePassword} className="text-muted" style={{ cursor: 'pointer' }}>
            {showPassword ? 'Sembunyikan' : 'Tampilkan'} kata sandi
          </Form.Text>
          <Row className='sign-in-form-input-after'>
            {/* <Col>
              <Form.Group controlId='rememberMe'>
                <CheckBox
                  label='Ingatkan saya'
                />
              </Form.Group>
            </Col> */}
            <Col className='mb-2'>
              <Link
                to='/forgot-password'
                className='sign-in-form-input-forgot-password'>
                Lupa kata sandi?
              </Link>
            </Col>
            <Col xs={12}>
              <Button
                className='sign-in-form-input-button'
                ref={buttonRef}
                type='submit'
                onClick={(event) => handleSignIn(event, values)}
                disabled={isLoading}
              >
                {isLoading ? 'Sedang memulai….' : 'Masuk'}
                </Button>
              <HandleEnterKey handleOnEnterKey={ () => {
                buttonRef.current.focus();
                buttonRef.current.click();
              } }/>
            </Col>
            <Col xs={12} align='center'>
              <div className='sign-in-form-input-link'>
                Belum jadi member? &nbsp;
                  <Link to='/register' className='sign-in-form-input-link-sign-up'>
                    Daftar
                  </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className='sign-in'>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <div className='sign-in-left-section'>
              <div className='sign-in-round'>
                <div className='sign-in-greetings'>
                  <h1>
                    Halo! <br />
                    Karya apa lagi <br />
                    yang ingin kamu <br />
                    buat hari ini?
                  </h1>
                  <p>Alby penasaran.</p>
                </div>
              </div>
              <div className='sign-in-illustration'>
                <Image src={albyIllustration} width='400' alt='' />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className='sign-in-right-section'>
              <div className='sign-in-form'>
                <Row>
                  <Col xs={12}>
                    <h1 className='sign-in-form-title'>Masuk</h1>
                  </Col>
                  {/* <Col xs={12}>
                    {renderQuickLogin()}
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col xs={12}>
                    <div className='sign-in-form-separator'>
                      Use another account ?
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12}>
                    {renderSignInForm()}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignIn;
