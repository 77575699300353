/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import './ProposalSummary.scss';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ProgressBar, Carousel } from 'react-bootstrap';
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css';
import VideoPlayer from 'react-simple-video-player';
import { useDispatch, useSelector } from 'react-redux';
import { X } from 'react-bootstrap-icons';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Label from '../Label/Label';
import Button from '../Button/Button';
import { CREATIVE_WORKS_URL, IMAGE_OBJECTS } from '../../Constants/Constants';
import ProposalQuestionPreview from '../ProposalQuestionPreview/ProposalQuestionPreview';
import {
  reset as creativeWorkActionRetrieveReset,
  retrieve as creativeWorkActionRetrieve,
} from '../../Generated/actions/creativework/show';

const ProposalSummary = (props) => {
  const { isShowSummary, setIsShowSummary, proposalFile, creativeWorkId, setShowWarningModal } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [slideIndex, setSlideIndex] = useState(0);
  const [creativeWorkItem, setCreativeWorkItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [imageObjects, setImageObjects] = useState();
  const questions = useSelector((state) => state.questions);
  const creativeWorkDetail = useSelector((state) => state.creativework.show);
  const projectDetail = useSelector((state) => state.project.show);

  useEffect(() => {
    const getImages = async () => {
      const { data } = await axios.get(IMAGE_OBJECTS);
      setImageObjects(data);
    };
    getImages();
  }, [isShowSummary]);

  useEffect(() => {
    dispatch(creativeWorkActionRetrieve(decodeURIComponent(`/creative_works/${creativeWorkId}`)));
    return () => {
      dispatch(creativeWorkActionRetrieveReset(creativeWorkDetail.eventSource));
    };
  }, [isShowSummary]);

  useEffect(() => {
    if (creativeWorkDetail.retrieved) {
      setCreativeWorkItem(creativeWorkDetail.retrieved);
    }
  }, [creativeWorkDetail.retrieved]);

  const handleCloseSummary = () => {
    setIsShowSummary(false);
    setSlideIndex(0);
  };

  const handleOnSelect = (indexValue) => {
    setSlideIndex(indexValue);
  };

  const handleQuestionErrors = () => {
    const errorCounter = [];
    if (projectDetail.retrieved) {
      const isInternal = projectDetail.retrieved.internal;
      for (let i = 0; i < questions.length; ++i) {
        if (
          questions[i].description === '' ||
          questions[i].connectedAnswers.length === 0 ||
          questions[i].answerKey === '' ||
          // || questions[i].visualBrief === ''
          questions[i].haveOneCorrectAnswer === false ||
          questions[i].haveEmptyDescription === true
        ) {
          errorCounter.push(i + 1);
        }
        if (questions[i].image === null) {
          if (!isInternal) {
            if (errorCounter.indexOf(i + 1) === -1) {
              errorCounter.push(i + 1);
            }
          }
        }
      }
    }
    return errorCounter;
  };

  const countDeadline = () => {
    const todayDate = moment(new Date());
    const endTime = projectDetail.retrieved ? projectDetail.retrieved.endTime : null;
    const projectEndTime = moment(endTime);
    const deadline = projectEndTime.diff(todayDate, 'days');
    return deadline;
  };

  const renderDeadline = () => {
    let deadline = '';
    if (countDeadline() === 0) {
      deadline = 'Hari ini';
    }
    if (countDeadline() < 0) {
      deadline = 'Sudah berlalu';
    }
    if (countDeadline() > 0) {
      deadline = `${countDeadline()} hari lagi`;
    }
    return deadline;
  };

  const calculateQuestionsPercent = () => {
    return questions.length * 3 - handleQuestionErrors().length * 3;
  };

  const calculatePercent = () => {
    let currentProgress = 0;
    if (projectDetail.retrieved) {
      const { lessonType, internal } = projectDetail.retrieved;

      if (internal) {
        currentProgress += 15;
        currentProgress += 20;
        currentProgress += 20;
      } else {
        if (lessonType === 1) {
          if (creativeWorkItem && creativeWorkItem.transcript && creativeWorkItem.transcript.length >= 300) {
            currentProgress += 15;
          }

          if (proposalFile.zipFile.contentUrl !== '') {
            currentProgress += 20;
          }
        } else {
          currentProgress += 15;
          currentProgress += 20;
        }

        if (lessonType === 2) {
          currentProgress += 20;
        } else {
          if (proposalFile.mp4File.contentUrl !== '') {
            currentProgress += 20;
          }
        }
      }
      if (!isEmpty(questions)) {
        currentProgress += calculateQuestionsPercent();
      }
    }
    return currentProgress;
  };

  const handleSubmitProposal = async () => {
    try {
      if (countDeadline() < 0) {
        setShowWarningModal('Deadline sudah berlalu! Tidak bisa kirim proposal!');
        setIsShowSummary(false);
        return;
      }
      if (handleQuestionErrors().length === 0 && calculatePercent() >= 100) {
        setIsLoading(true);
        await axios.put(`${CREATIVE_WORKS_URL}/${creativeWorkId}/submit`, {});
        history.push('/my-projects');
      } else {
        setShowWarningModal('Kamu harus menyelesaikan proposal!');
        setIsShowSummary(false);
      }
    } catch (error) {
      setIsShowSummary(false);
      setIsLoading(false);
      if (error.response.data['hydra:description'] && error.response.data['hydra:description'] === 'Access Denied.') {
        setShowWarningModal('Only Team Owner Can Submit Proposal!');
      } else {
        setShowWarningModal('Something Wrong!');
      }
    }
  };

  const renderProposalSummary = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content task'>
            <div className='proposal-summary__content proposal__progress'>
              <h1>Kemajuan Proposal</h1>
              <h1>{calculatePercent()}%</h1>
            </div>
            <div className='proposal-summary__content progress__bar'>
              <ProgressBar now={calculatePercent()} />
            </div>
            <div className='proposal-summary__content task__wrapper'>
              <div className='proposal-summary__content task__item'>
                <div className='proposal-summary_content task-description'>
                  <h1>Teks Suara</h1>
                  <p>{creativeWorkItem && creativeWorkItem.transcript ? 'Sudah Diupload' : 'Belum Diupload'}</p>
                </div>
                <div className='proposal-summary_content task-label'>
                  {creativeWorkItem && creativeWorkItem.transcript ? (
                    creativeWorkItem.transcript.length >= 300 ? (
                      <Label type='STATUS_COMPLETED' />
                    ) : (
                      <Label type='STATUS_NEED_PARTNER_TEXT'>Min 300 karakter</Label>
                    )
                  ) : (
                    <Label type='STATUS_NEED_PARTNER_TEXT'>Butuh Tekt</Label>
                  )}
                </div>
              </div>
              <div className='proposal-summary__content task__item'>
                <div className='proposal-summary_content task-description'>
                  <h1>Berkas After Effect</h1>
                  <p>{proposalFile.zipFile.contentUrl !== '' ? 'Sudah Diupload' : 'Belum Diupload'}</p>
                </div>
                <div className='proposal-summary_content task-label'>
                  {proposalFile.zipFile.contentUrl !== '' ? (
                    <Label type='STATUS_COMPLETED' />
                  ) : (
                    <Label type='STATUS_NEED_PARTNER_TEXT'>Butuh File</Label>
                  )}
                </div>
              </div>
              <div className='proposal-summary__content task__item'>
                <div className='proposal-summary_content task-description'>
                  <h1>Animasi</h1>
                  <p>{proposalFile.mp4File.contentUrl !== '' ? 'Sudah Diupload' : 'Belum Diupload'}</p>
                </div>
                <div className='proposal-summary_content task-label'>
                  {proposalFile.mp4File.contentUrl !== '' ? (
                    <Label type='STATUS_COMPLETED' />
                  ) : (
                    <Label type='STATUS_NEED_PARTNER_TEXT'>Butuh File</Label>
                  )}
                </div>
              </div>
              <div className='proposal-summary__content task__item'>
                <div className='proposal-summary_content task-description'>
                  <h1>Tanya & Jawab</h1>
                  <p>
                    {questions.length} dari 15 Selesai
                    {handleQuestionErrors().length > 0 && `• ${handleQuestionErrors().length} Pertanyaan Belum Selesai`}
                    {handleQuestionErrors().length > 0 && <br />}
                    {handleQuestionErrors().length > 0 &&
                      `Pertanyaan No. ${handleQuestionErrors().join(', ')} Belum Selesai`}
                  </p>
                </div>
                <div className='proposal-summary_content task-label'>
                  {handleQuestionErrors().length > 0 && <Label type='STATUS_OVERDUE'>Belum Selesai</Label>}
                  {handleQuestionErrors().length === 0 && questions.length === 15 && <Label type='STATUS_COMPLETED' />}
                  {handleQuestionErrors().length === 0 && questions.length < 15 && (
                    <Label type='STATUS_NEED_PARTNER_TEXT'>Tertunda</Label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVoiceOverText = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>Teks Suara</div>
          <div className='proposal-summary__content file-vot__preview'>
            {creativeWorkItem && creativeWorkItem.transcript ? (
              <p>{creativeWorkItem.transcript}</p>
            ) : (
              <h4 className='text-danger text-center'>Teks Suara Kosong</h4>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAnimation = () => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <div className='proposal-summary__content file__name'>
            {proposalFile.mp4File.name ? proposalFile.mp4File.name : 'Video Final'}
          </div>
          <div className='proposal-summary_content file__vide'>
            <Row>
              <Col xs={12}>
                {proposalFile.mp4File.name ? (
                  <VideoPlayer url={proposalFile.mp4File.contentUrl} autosize />
                ) : (
                  <h4 className='text-danger text-center'>Video Final Belum Diupload</h4>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='proposal-summary'>
      <Modal show={isShowSummary} dialogClassName='proposal-summary__modal' onHide={handleCloseSummary}>
        <div className='close' onClick={handleCloseSummary}>
          <X />
        </div>
        <Modal.Body>
          <div className='proposal-summary__title'>
            <h1>Ringkasan Proposal</h1>
            <span className='text-danger font-weight-bold'>Deadline : {renderDeadline()}</span>
          </div>
          <Carousel interval={null} onSelect={handleOnSelect} indicators={false} defaultActiveIndex={slideIndex}>
            <Carousel.Item>{renderProposalSummary()}</Carousel.Item>
            <Carousel.Item>{renderVoiceOverText()}</Carousel.Item>
            <Carousel.Item>{renderAnimation()}</Carousel.Item>
            {questions.map((question, index) => (
              <Carousel.Item key={index}>
                <ProposalQuestionPreview
                  questionData={question}
                  previewIndex={index}
                  questionsCount={questions.length}
                  imageObjects={imageObjects}
                  isCreativeWork
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <div className='proposal-summary__indicator'>
            <span>
              {slideIndex + 1} dari {3 + questions.length}
            </span>
          </div>
          <div className='proposal-summary__action'>
            <Row>
              <Col>
                <Button block={true} type='secondary' onClick={handleCloseSummary}>
                  Tutup
                </Button>
              </Col>
              <Col>
                <Button block={true} type='main' onClick={handleSubmitProposal} disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Kirim Proposal'}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ProposalSummary.propTypes = {
  isShowSummary: PropTypes.bool.isRequired,
  setIsShowSummary: PropTypes.func.isRequired,
};

export default ProposalSummary;
