import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  Col,
  Row,
} from 'react-bootstrap';

import './TabPlayer.scss';

const TabPlayer = ({
  activeVideo,
  availableVideo,
  setActiveVideo,
  unprocessedVideoUrl,
  titikpintarVideoUrl,
  sahabatpintarVideoUrl,
  youtubeVideoUrl
}) => {
  return (
    <Row className="tab-player">
      {availableVideo.includes('unprocessed')
        ? <Col onClick={() => setActiveVideo('unprocessed')}
            className={`${activeVideo.includes('unprocessed') ? 'active' : ''}`}>
              Unprocessed
          </Col>
        : '' }
      {availableVideo.includes('sahabatpintar')
        ? <Col onClick={() => setActiveVideo('sahabatpintar')}
            className={`${activeVideo.includes('sahabatpintar') ? 'active' : ''}`}>
              Sahabat Pintar
          </Col>
        : '' }
      {availableVideo.includes('titikpintar')
        ? <Col onClick={() => setActiveVideo('titikpintar')}
            className={`${activeVideo.includes('titikpintar') ? 'active' : ''}`}>
              Titik Pintar
          </Col>
        : '' }
      {availableVideo.includes('youtube')
        ? <Col onClick={() => setActiveVideo('youtube')}
            className={`${activeVideo.includes('youtube') ? 'active' : ''}`}>
              YouTube
          </Col>
        : '' }
      {activeVideo.includes('unprocessed')
        ? <div className="player">
          <ReactPlayer
            url={unprocessedVideoUrl}
            controls={true}
            width='100%'
          />
        </div>
        : '' }
      {activeVideo.includes('sahabatpintar')
        ? <div className="player">
          <ReactPlayer
            url={sahabatpintarVideoUrl}
            controls={true}
            width='100%'
          />
        </div>
        : '' }
      {activeVideo.includes('titikpintar')
        ? <div className="player">
          <ReactPlayer
            url={titikpintarVideoUrl}
            controls={true}
            width='100%'
          />
        </div>
        : '' }
      {activeVideo.includes('youtube')
        ? <div className="player">
          <ReactPlayer
            url={youtubeVideoUrl}
            controls={true}
            width='100%'
          />
        </div>
        : '' }
    </Row>
  );
};

TabPlayer.propTypes = {
  activeVideo: PropTypes.any,
  availableVideo: PropTypes.any,
  setActiveVideo: PropTypes.func,
  unprocessedVideoUrl: PropTypes.any,
  sahabatpintarVideoUrl: PropTypes.any,
  titikpintarVideoUrl: PropTypes.any,
  youtubeVideoUrl: PropTypes.any
};

export default TabPlayer;
