import React from 'react';
import {
  Col, Container, Image, Row
} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import MobilePng from '../../Assets/Images/mobile.png';
import { WALKTHROUGH_URL } from '../../Constants/Constants';
import './MobileView.scss';

const MobileView = () => {
  return (
    <Container>
      <Row className='mobile-device'>
        <Col xs={12}>
          <h1 className='text-center font-weight-bold'>Kalian belum bisa melihat kita di layar yang sekecil ini!</h1>
          <Image src={MobilePng} width='100%' className='my-3' />
          <p className='text-center'>
            Alby lagi mengerahkan seluruh tenaganya untuk memuat Sahabat Pintar ke
            dalam HP tapi untuk sekarang silahkan akses situs kita dari komputermu.
          </p>
          <div className='video-container'>
            <div className='player-wrapper'>
              <ReactPlayer
                url={WALKTHROUGH_URL}
                width='100%'
                height='100%'
                className='react-player'
                controls
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MobileView;
