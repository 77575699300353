import React from 'react';
import { Modal } from 'react-bootstrap';
import ProposalMemberItem from '../ProposalMemberItem/ProposalMemberItem';

const ModalProposalMember = ({ isShowModal, setIsShowModal, mappedMembers }) => {
  const handleHideModal = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={handleHideModal}
      dialogClassName='question-answer-overview-modal'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Anggota Kelompok
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mappedMembers && mappedMembers.map((members, i) => (
          <ProposalMemberItem members={members} key={i} />
        ))}
      </Modal.Body>
    </Modal>
  );
};

ModalProposalMember.propTypes = {

};

export default ModalProposalMember;
