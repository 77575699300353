/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import {
  Row, Col, ProgressBar
} from 'react-bootstrap';
import { Stopwatch } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'lodash';
import './OrganizationCard.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Label from '../Label/Label';
import Button from '../Button/Button';
import MathBg from '../../Assets/Images/math-card-bg.png';
import ScienceBg from '../../Assets/Images/science-card-bg.png';

import {
  lock as lockOrganization
} from '../../Generated/actions/organization/update';
import StartProposalModal from '../StartProposalModal/StartProposalModal';
import { ORGANIZATION_URL } from '../../Constants/Constants';
import { translateSubject } from '../../Helpers/Common';
import ReviewTeam from '../ReviewTeam/ReviewTeam';
import StartProposalConfirmModal from '../StartProposalConfirmModal/StartProposalConfirmModal';
import StartProposalAloneConfirmModal from '../StartProposalAloneConfirmModal/StartProposalAloneConfirmModal';

const OrganizationCard = ({ organization, setRefreshUsersOrg }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [project, setProject] = useState(null);
  const [educationalLevel, setEducationalLevel] = useState(null);
  const [person, setPerson] = useState(null);
  const [mediaObject, setMediaObject] = useState(null);
  const [mappedImages, setMappedImages] = useState([]);
  const [tags, setTags] = useState(null);
  const [showStartProposalModal, setShowStartProposalModal] = useState(false);
  const [startProposalBool, setStartProposalBool] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [startProposalConfirmModal, setStartProposalConfirmModal] = useState(false);
  const [startProposalAloneConfirmModal, setStartProposalAloneConfirmModal] = useState(false);
  // const organizationData = useSelector((state) => state.organization.list);
  const projectData = useSelector((state) => state.project.list);
  const personList = useSelector((state) => state.person.list);
  const mediaObjectList = useSelector((state) => state.mediaobject.list);
  const educationalLevelList = useSelector((state) => state.educationallevel.list);
  const tagList = useSelector((state) => state.tags.list);
  const storeCreativeWorkCreate = useSelector((state) => state.creativework.create);

  useEffect(() => {
    if (organization && projectData.retrieved) {
      setProject(projectData.retrieved
        && projectData.retrieved['hydra:member'].filter((filteredProject) =>
          (filteredProject['@id'] === organization.project)).length > 0
        && projectData.retrieved['hydra:member'].filter((filteredProject) =>
          (filteredProject['@id'] === organization.project)));
    }
  }, [organization, projectData.retrieved]);

  useEffect(() => {
    if (project && educationalLevelList.retrieved) {
      setEducationalLevel(educationalLevelList.retrieved
        && educationalLevelList.retrieved['hydra:member'].filter((filteredLevel) =>
          (filteredLevel['@id'] === project[0].educationalLevel)).length > 0
        && educationalLevelList.retrieved['hydra:member'].filter((filteredLevel) =>
          (filteredLevel['@id'] === project[0].educationalLevel)));
    }
  }, [project, educationalLevelList.retrieved]);

  useEffect(() => {
    if (organization && personList.retrieved) {
      setPerson(personList.retrieved
        && personList.retrieved['hydra:member'].filter((filteredPerson) =>
          (organization.members.includes(filteredPerson.user))).length > 0
        && personList.retrieved['hydra:member'].filter((filteredPerson) =>
          (organization.members.includes(filteredPerson.user))));
    }
  }, [organization, personList.retrieved]);

  useEffect(() => {
    if (person) {
      const imageArr = person.map((personData) => personData.image);
      setMappedImages(imageArr);
    }
  }, [person]);

  useEffect(() => {
    if (mappedImages && mediaObjectList.retrieved) {
      setMediaObject(mediaObjectList.retrieved
        && mediaObjectList.retrieved['hydra:member'].filter((filteredMedia) =>
          (mappedImages.includes(filteredMedia['@id']))).length > 0
        && mediaObjectList.retrieved['hydra:member'].filter((filteredMedia) =>
          (mappedImages.includes(filteredMedia['@id']))));
    }
  }, [mappedImages, mediaObjectList.retrieved]);

  useEffect(() => {
    if (project && tagList.retrieved) {
      setTags(tagList.retrieved
        && tagList.retrieved['hydra:member'].filter((tag) =>
          (project[0].tags.includes(tag['@id']))).length > 0
        && tagList.retrieved['hydra:member'].filter((tag) =>
          (project[0].tags.includes(tag['@id']))));
    }
  }, [project, tagList.retrieved]);

  useEffect(() => {
    if (storeCreativeWorkCreate.created) {
      setRefreshUsersOrg(true);
    }
  }, [storeCreativeWorkCreate.created]);

  useEffect(() => {
    (async () => {
      if (organization && startProposalBool) {
        const { data } = await axios.get(`${ORGANIZATION_URL}/${organization.id}`);
        if (data && (!isEmpty(data.creativeWork))) {
          const creativeWorkId = data.creativeWork.split('/')[2];
          history.push(`/proposal/${creativeWorkId}`);
        }
      }
    })();
  }, [organization, startProposalBool]);

  const handleDivOnClick = () => {
    if (organization && organization.status === 'STATUS_IN_PROGRESS') {
      const creativeWorkData = organization && organization.creativeWork && organization.creativeWork.split('/');
      history.push(`/proposal/${creativeWorkData[2]}`);
    }
    if (project && project[0].internal) {
      if (organization && organization.status === 'STATUS_COMPLETED' && !organization.winner) {
        const creativeWorkData = organization && organization.creativeWork && organization.creativeWork.split('/');
        history.push(`/proposal/${creativeWorkData[2]}`);
      }
    }
    if (organization && !organization.locked) {
      if (!showStartProposalModal) {
        setShowStartProposalModal(true);
      }
    }
  };

  const handleReview = () => {
    setShowReview(true);
  };

  const buttonFlexEnd = !isEqual(organization
    && organization.status, 'STATUS_IN_PROGRESS')
    && 'creative-work__button--flex-end';
  const memberFlexStart = !isEqual(organization
    && organization.status, 'STATUS_IN_PROGRESS')
    && 'creative-work__member--flex-end';
  const titleMargin = !isEqual(organization
    && organization.status, 'STATUS_IN_PROGRESS')
    && 'creative-work__title--margin-50';

  const labelType = {
    inProgressLabel: <Label type='STATUS_IN_PROGRESS'>Dikerjakan</Label>,
    notOpenToJoin: <Label type='STATUS_IN_PROGRESS'>Belum Dikerjakan</Label>,
    overdueLabel: <Label type='STATUS_OVERDUE'>Deadline Selesai</Label>,
    completedLabel: <Label type='STATUS_COMPLETED'>Selesai</Label>,
    needPartnerLabel: <Label type='STATUS_NEED_PARTNER'>Butuh Anggota</Label>
  };

  const buttonType = {
    completedButton: <Button onClick={handleReview} type='main'>Ulasan Tim</Button>,
    overdueButton: <Button type='secondary'>Lihat Proposal</Button>
  };

  const renderWinnerRibbon = () => {
    return (
      <div className='creative-work__ribbon'>
        <span>Menang</span>
      </div>
    );
  };

  const renderLabel = () => {
    return (
      <div className='creative-work__label'>
        {isEqual(organization
          && organization.status, 'STATUS_IN_PROGRESS') && labelType.inProgressLabel}
        {isEqual(organization
          && organization.status, 'STATUS_NOT_OPEN_FOR_JOIN') && labelType.notOpenToJoin}
        {isEqual(organization
          && organization.status, 'STATUS_OVERDUE') && labelType.overdueLabel}
        {isEqual(organization
          && organization.status, 'STATUS_COMPLETED') && labelType.completedLabel}
        {isEqual(organization
          && organization.status, 'STATUS_NEED_PARTNER') && labelType.needPartnerLabel}
      </div>
    );
  };

  const renderTitle = (modal) => {
    const creativeWorkData = organization && organization.creativeWork && organization.creativeWork.split('/');
    return (
      <div className={`creative-work__title ${titleMargin}`}>
        {modal && <span>
          {organization && organization.status === 'STATUS_IN_PROGRESS'
            ? (
              <a href={`/proposal/${creativeWorkData[2]}`}>
                {project && project[0].title}
              </a>
            )
            : (
              project && project[0].title
            )}
        </span>}
        <h1>
          {organization && organization.status === 'STATUS_IN_PROGRESS'
            ? (
              <a href={`/proposal/${creativeWorkData[2]}`}>
                {organization && organization.name.replace(' and ', ' dan ')}
              </a>
            )
            : (
              organization && organization.name.replace(' and ', ' dan ')
            )}
        </h1>
        {!modal && <span>
          {organization && organization.status === 'STATUS_IN_PROGRESS'
            ? (
              <a href={`/proposal/${creativeWorkData[2]}`}>
                {project && project[0].title}
              </a>
            )
            : (
              project && project[0].title
            )}
        </span>}
        <h1>
          {organization && organization.status === 'STATUS_IN_PROGRESS' ? (
            <a href={`/proposal/${creativeWorkData[2]}`}>
              {tags && 'Pelajaran '}{tags && translateSubject(tags[0].tag)}{tags && ' - '}
              {educationalLevel && educationalLevel[0].level}
            </a>
          ) : (
              <>
                {tags && 'Pelajaran '}{tags && translateSubject(tags[0].tag)}{tags && ' - '}
                {educationalLevel && educationalLevel[0].level}
              </>
          )}
        </h1>
      </div>
    );
  };

  const renderProgressBar = () => {
    let projectProgress = 0;
    if (project && project.length > 0) {
      const { startTime } = project[0];
      const { endTime } = project[0];
      const todayDate = moment(new Date());
      const totalDays = moment(endTime).diff(startTime, 'days');
      const goneDays = moment(todayDate).diff(startTime, 'days');
      projectProgress = isNaN(goneDays / totalDays) ? 0 : ((goneDays / totalDays) * 100);
    }
    return (
      <div className='creative-work__progress-bar'>
        <Row>
          <Col xs={11}>
            <ProgressBar now={projectProgress} />
          </Col>
          <Col xs={1}>
            <span className='creative-work__progress-bar percent'>{Math.round(projectProgress)}%</span>
          </Col>
        </Row>
      </div>
    );
  };

  const renderDeadline = () => {
    const todayDate = moment(new Date());
    const endTime = project ? project[0].endTime : null;
    const projectEndTime = moment(endTime);
    const countDeadline = projectEndTime.diff(todayDate, 'days');
    let deadline = '';
    if (countDeadline === 0) {
      deadline = 'Hari ini';
    }
    if (countDeadline < 0) {
      deadline = 'Deadline sudah berlalu';
    }
    if (countDeadline > 0) {
      deadline = `${countDeadline} hari lagi`;
    }
    return (
      <div className='creative-work__deadline'>
        <Stopwatch /> {deadline}
      </div>
    );
  };

  const renderCreativeWorkMember = () => {
    return (
      <Col xs={6}>
        <div
          className={`creative-work__member ${memberFlexStart} ${(organization && !organization.locked) ? '' : ''}`}>
          {mediaObject && mediaObject
            .map((mediaObjectData, i) => (
              <div key={i} className='creative-work__member avatar'>
                <img src={mediaObjectData.contentUrl} alt='' className='creative-work__member-img' />
              </div>
            ))}
        </div>
      </Col>
    );
  };

  const renderButton = () => {
    return (
      <Col xs={6}>
        <div className={`creative-work__button ${buttonFlexEnd}`}>
          {isEqual(organization && organization.status, 'STATUS_OVERDUE')
            && buttonType.overdueButton}
          {isEqual(organization && organization.status, 'STATUS_COMPLETED')
            && buttonType.completedButton}
        </div>
      </Col>
    );
  };

  const submitStartProposal = () => {
    let dataProject = null;
    if (project[0].lessonType === 3) {
      // eslint-disable-next-line prefer-destructuring
      dataProject = project[0];
    }
    dispatch(lockOrganization(organization, { lock: true }, dataProject));
    setStartProposalBool(true);
  };

  const confirmStartProposal = (userLength) => {
    setShowStartProposalModal(false);
    if (userLength === 1) {
      setStartProposalAloneConfirmModal(true);
    } else {
      setStartProposalConfirmModal(true);
    }
  };

  const modalDetail = {
    heading: 'Mulailah mengerjakan proposal dengan rekan satu tim Anda!',
    sub: 'Anda tidak dapat mengubah tim anda segera setelah memulai proposal. Apakah anda yakin?',
    isSingleButton: false,
    noText: 'Tidak, saya masih berpikir',
    yesText: 'Ya, saya yakin!',
    yesClick: submitStartProposal,
    noClick: () => setStartProposalConfirmModal(false)
  };
  const modalAloneDetail = {
    heading: 'Apakah kamu yakin ingin memulai proposal sendirian?',
    sub: 'Anda tidak dapat lagi menambahkan anggota segera setelah Anda memulai proposal. Apakah kamu yakin?',
    isSingleButton: false,
    noText: 'Tidak, saya masih berpikir',
    yesText: 'Ya, saya yakin!',
    yesClick: submitStartProposal,
    noClick: () => setStartProposalAloneConfirmModal(false)
  };

  const renderButtonsBeforeLockingTeam = () => {
    const isOwner = isEqual(organization.founder, localStorage.getItem('id'));
    return (organization && !organization.locked
      && <div className='organization-card-before-lock-team-btn' >
        {isOwner ? <Button onClick={
          () => {
            setShowStartProposalModal(true);
          }
        }
          type='main'>Mulai Proposal</Button>
          : <Button onClick={
            () => {
              setShowStartProposalModal(true);
            }
          }
            block={true} type='secondary'>Keluar Proyek</Button>
        }
        <StartProposalModal
          isShowModal={showStartProposalModal}
          setIsShowModal={setShowStartProposalModal}
          startProposal={confirmStartProposal}
          organization={organization}
          renderLabel={renderLabel}
          renderTitle={renderTitle}
          renderDeadlineClock={renderDeadline}
          setRefreshUsersOrg={setRefreshUsersOrg}
          isOwner={isOwner}
        />
        {startProposalConfirmModal && (
          <StartProposalConfirmModal
            isShowModal={startProposalConfirmModal}
            setIsShowModal={setStartProposalConfirmModal}
            details={modalDetail}
          />
        )}
        {startProposalAloneConfirmModal && (
          <StartProposalAloneConfirmModal
            isShowModal={startProposalAloneConfirmModal}
            setIsShowModal={setStartProposalAloneConfirmModal}
            details={modalAloneDetail}
          />
        )}
      </div>
    );
  };

  return (
    <div className='creative-work' onClick={handleDivOnClick}>
      <img src={tags ? tags[0].tag === 'Science' ? ScienceBg : MathBg : ''} className='creative-work-img-bg' alt="" />
      <div className='creative-work__wrapper'>
        {organization.winner && renderWinnerRibbon()}
        {renderLabel()}
        {renderTitle()}
        {isEqual(organization
          && organization.status, 'STATUS_IN_PROGRESS') && renderProgressBar()}
        <Row className='creative-work-bottom-row' >
          {(isEqual(organization && organization.status, 'STATUS_IN_PROGRESS')
            || isEqual(organization && organization.status, 'STATUS_NEED_PARTNER')
            || isEqual(organization && organization.status, 'STATUS_NOT_OPEN_FOR_JOIN'))
            && renderDeadline()}
          {renderCreativeWorkMember()}
          {!isEqual(organization
            && organization.status, 'STATUS_IN_PROGRESS') && renderButton()}
          {renderButtonsBeforeLockingTeam()}
        </Row>
        <ReviewTeam
          show={showReview}
          setShow={setShowReview}
          teamMembers={person && person}
          organization={organization}
        />
      </div>
    </div>
  );
};

OrganizationCard.propTypes = {
  organization: PropTypes.object.isRequired
};

export default OrganizationCard;
