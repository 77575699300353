import PropTypes from 'prop-types';
import {
  StarFill, Trophy,
} from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagName from '../Tags/TagName';
import avatarImage from '../../Assets/Images/avatar-image.png';
import './UserListWithProfile.scss';
import Label from '../Label/Label';

import Button from '../Button/Button';
import { acceptJoinRequest } from '../../Generated/actions/organizationunconfirmedmembers/update';

const UserListWithProfile = ({
  item,
  handleClick,
  organization,
  acceptInvitationButton = false,
  withdrawButton,
  removeTeamButton = false
}) => {
  const listOfPeople = useSelector((state) => state.person.list);
  const listOfImages = useSelector((state) => state.mediaobject.list);
  const [person, setPerson] = useState(null);
  const [personImage, setPersonImage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (listOfPeople.retrieved) {
      setPerson(listOfPeople.retrieved
        // todo probably this check doesnt' needed ?
        && listOfPeople.retrieved['hydra:member'].filter((personFromList) =>
          (personFromList.user === item['@id'])).length > 0
        && listOfPeople.retrieved['hydra:member'].filter((personFromList) =>
          (personFromList.user === item['@id']))
          .shift());
    }
  }, [item, listOfPeople.retrieved]);

  useEffect(() => {
    if (person && listOfImages.retrieved) {
      const filteredImage = listOfImages.retrieved
        // todo probably this check doesnt' needed ?
        && listOfImages.retrieved['hydra:member'].filter((image) => (image['@id'] === person.image)).length > 0
        && listOfImages.retrieved['hydra:member'].filter((image) => (image['@id'] === person.image))
          .shift().contentUrl;
      setPersonImage(filteredImage);
    }
  }, [person, listOfImages.retrieved]);

  const checkFounder = () => {
    if (organization && organization.founder === item['@id']) {
      return true;
    }
    return false;
  };

  const handleSendRequest = (e) => {
    e.stopPropagation();
    dispatch(acceptJoinRequest(item.unconfirmedMember, { accept: true }));
  };
  return <>
    <div
      key={item.id}
      className='add-friend-modal-member-profile'
      onClick={() => handleClick(item.username, item['@id'])}
    >
      <div className='add-friend-modal-member-profile-avatar'>
        {personImage ? <img
          className='team-detail-card-member-profile-avatar-img'
          src={personImage} alt='person Image'/>
          : <img
            className='team-detail-card-member-profile-avatar-img'
            src={avatarImage}
            alt='avatar Image'/>}
      </div>
      <div className='add-friend-modal-member-profile-info'>
        <div className='start-proposal-modal-member-profile-info-flex'>
          <h2>{item.username}</h2>
          {checkFounder() && <Label type='with-text'>Pemimpin Tim</Label>}
        </div>
        <div >
          {person && person.worksFor }
          <div className='middle-dot'/>
          {item.tags && item.tags.map((tag, number) => {
            if (number === 0) {
              return <TagName tag={tag} key={item.id}/>;
            }
            return <>
              <div className='middle-dot'/>
              <TagName tag={tag} key={item.id}/>
              </>;
          })}
        </div>
        <div className='add-friend-modal-member-profile-info-other'>
          {person && person.aggregateRating && <StarFill/>}
          {' '}
          {person && person.aggregateRating }
          {' '}
          {person && person.aggregateWins && <Trophy/> }
          {' '}
          {person && person.aggregateWins}
        </div>
      </div>
      <div className='add-friend-modal-member-profile-button'>
        {acceptInvitationButton && (<Button onClick={(e) => handleSendRequest(e)}>
          Accept
        </Button>) }
        {withdrawButton && (<Button type='secondary' onClick={() => handleClick(item.username, item['@id'])}>
          Withdraw
        </Button>) }
        {removeTeamButton && (<Button onClick={() => handleClick(item.username, item['@id'])}>
          Hapus
        </Button>) }
      </div>
    </div>
  </>;
};

UserListWithProfile.propTypes = {
  handleClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default React.memo(UserListWithProfile);
