import React from 'react';
import { Modal } from 'react-bootstrap';
import openToAnyone from '../../Assets/Images/open-to-anyone.png';
import alreadyOpen from '../../Assets/Images/already-open.png';
import './OpenToAnyoneModal.scss';

const OpenToAnyoneModal = (props) => {
  const {
    show,
    handleClose,
    handleNextScreen,
    isNextScreen
  } = props;

  return (
    <div className='open-to-anyone'>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='open-to-anyone-modal'
        backdrop='static'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='open-to-anyone-modal-content'>
            {!isNextScreen ? (
              <>
                <div className='open-to-anyone-modal-content-image'>
                  <img src={openToAnyone} alt='' />
                </div>
                <div className='open-to-anyone-modal-content-text'>
                  <h4>
                    Apakah kamu yakin ingin
                    <br />
                    bergabung dengan siapa saja?
                  </h4>
                  <p>
                    Anda harus menunggu untuk membentuk tim dengan menerima jika
                    ada yang ingin bergabung
                  </p>
                </div>
                <div className='open-to-anyone-modal-content-submit'>
                  <button
                    onClick={handleNextScreen}
                    className='open-to-anyone-modal-content-submit-button'
                  >
                    Ya, saya yakin
                  </button>
                  <button
                    onClick={handleClose}
                    className='open-to-anyone-modal-content-submit-button-primary'
                  >
                    Coba tim lain
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className='open-to-anyone-modal-content-image'>
                  <img src={alreadyOpen} alt='' />
                </div>
                <div className='open-to-anyone-modal-content-text'>
                  <h4>
                    Waktunya menunggu tim dan
                    <br />
                    berkarya bersama di Papan Pintar!
                  </h4>
                  <p>
                    Anda bisa memulai proposal setelah tim anda terbentuk.
                    Semoga beruntung dengan tim baru anda!
                  </p>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OpenToAnyoneModal;
