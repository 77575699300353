import React from 'react';
import Select from 'react-select';
import './SelectComponent.scss';
import PropTypes from 'prop-types';

const SelectComponent = (props) => {
  const {
    options,
    value,
    onChange,
    defaultValue
  } = props;

  return (
    <div className='select-component'>
      <Select
        className='select-component-library'
        classNamePrefix='select-component'
        options={options}
        value={value}
        onChange={onChange}
        isSearchable={false}
        defaultValue={defaultValue}
        placeholder='Pilih'
      />
    </div>
  );
};

SelectComponent.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectComponent;
