import React, {
  useEffect, useRef, useState
} from 'react';
import {
  Col, Form,
  Image, Row
} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import CharacterCounter from 'react-character-counter';
import { CardImage, TrashFill, X } from 'react-bootstrap-icons';
import './LessonQuestionForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Button from '../Button/Button';
import trueOrFalseIcon from '../../Assets/Images/true-or-false.png';
import multipleChoiceIcon from '../../Assets/Images/multiple-choice.png';
import fillTheBlankIcon from '../../Assets/Images/fill-the-blank.png';
import { submitQuestion, uploadImageToS3 } from '../../Actions/Question';
import { generateRandomString } from '../../Helpers/Common';
import {
  multipleAnswerField,
  multipleChoiceField,
  trueOrFalseField
} from '../../Helpers/StaticField';
import {
  LESSON_ANSWER_URL, LESSON_QUESTION_URL, QUESTION_DIFFICULTY, QUESTION_IMAGE_HEIGHT, QUESTION_IMAGE_WIDTH
} from '../../Constants/Constants';
import ConfirmationModal from '../Modal/ConfirmationModal';
import CheckBox from '../CheckBox/CheckBox';

const LessonQuestionForm = (props) => {
  const dispatch = useDispatch();
  const questionList = useSelector((state) => state.questions);
  // const errorUploadImage = useSelector((state) => state.questions.errorUploadImage);
  const [errorUploadImage, setErrorUploadImage] = useState(false);
  const [trueOrFalseAnswer, setTrueOrFalseAnswer] = useState(cloneDeep(trueOrFalseField));
  const [multipleChoiceAnswer, setMultipleChoiceAnswer] = useState(cloneDeep(multipleChoiceField));
  const [fillTheBlankAnswer, setFillTheBlankAnswer] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [sameImage, setSameImage] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [langSwitch, setLangSwitch] = useState('ID');
  const [initialRender, setInitialRender] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [onClickEvent, setOnClickEvent] = useState();
  const inputFile = useRef(null);
  const {
    questionData, questionIndex, setPreviewCorrectAnswer,
    previewContent, setPreviewContent, setLoading
  } = props;

  useEffect(() => {
    const getQuestionLang = async () => {
      setLoading(true);
      const newQuestionList = [...questionList];
      if (langSwitch === 'ID') {
        const { data: lessonId } = await axios.get(questionData['@id']);
        newQuestionList[questionIndex] = {
          ...lessonId,
          description: lessonId.description || '',
          answerKey: lessonId.answerKey || '',
          visualBrief: lessonId.visualBrief || '',
          answers: lessonId.connectedAnswers,
          answerPreview: multipleAnswerField,
          image: lessonId.image || null
        };
      } else {
        const { data: lessonEn } = await axios.get(questionData['@id'], {
          headers: {
            'X-LOCALE': 'en'
          }
        });
        newQuestionList[questionIndex] = {
          ...lessonEn,
          description: lessonEn.description || '',
          answerKey: lessonEn.answerKey || '',
          visualBrief: lessonEn.visualBrief || '',
          answers: lessonEn.connectedAnswers,
          answerPreview: multipleAnswerField,
          image: lessonEn.image || null
        };
      }
      dispatch(submitQuestion(newQuestionList));
      setLoading(false);
    };
    if (initialRender) {
      setInitialRender(false);
    } else {
      getQuestionLang();
    }
  }, [langSwitch]);

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    if (questionData.type === 'TYPE_TRUE_OR_FALSE') {
      const trueOrFalse = [...trueOrFalseAnswer];
      questionData.connectedAnswers
        .sort(compare)
        .map((answer, index) => {
          trueOrFalseAnswer[index]['@id'] = answer['@id'];
          trueOrFalseAnswer[index].id = answer.id;
          trueOrFalseAnswer[index].name = answer.description;
          trueOrFalseAnswer[index].selected = answer.correct;
          if (answer.correct === true) {
            questionList[questionIndex].haveOneCorrectAnswer = true;
          }
          return trueOrFalse;
        });
      setTrueOrFalseAnswer(trueOrFalse);
    }
  }, [questionData]);

  useEffect(() => {
    if (questionData.type === 'TYPE_MULTIPLE_CHOICE') {
      const multipleChoice = [...multipleChoiceAnswer];
      questionData.connectedAnswers
        .sort(compare)
        .map((answer, index) => {
          multipleChoice[index]['@id'] = answer['@id'];
          multipleChoice[index].id = answer.id;
          multipleChoice[index].name = answer.description || '';
          if (answer.correct === true) {
            multipleChoice[index].selected = true;
            questionList[questionIndex].haveOneCorrectAnswer = true;
          }
          return multipleChoice;
        });
      setMultipleChoiceAnswer(multipleChoice);
    }
  }, [questionData]);

  useEffect(() => {
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answerPreview = multipleChoiceAnswer;
    dispatch(submitQuestion(listOfQuestion));
  }, [multipleChoiceAnswer]);

  useEffect(() => {
    if (questionData.type === 'TYPE_FILL_THE_BLANK') {
      const fillTheBlank = [];
      questionData.connectedAnswers
        .sort(compare)
        .map((answer) => {
          const generateAnswer = {
            ...answer,
            name: answer.description || ''
          };
          fillTheBlank.push(generateAnswer);
          return fillTheBlank;
        });
      setFillTheBlankAnswer(fillTheBlank);
    }
  }, [questionData]);

  const updateQuestion = async (updateData) => {
    const listOfQuestion = [...questionList];
    const key = Object.keys(updateData);
    const newUpdateData = updateData;
    if (key[0] !== 'description') {
      if (isEmpty(listOfQuestion[questionIndex].description)) {
        if (langSwitch !== 'ID') {
          newUpdateData.description = '';
        }
      }
    }
    try {
      if (langSwitch === 'ID') {
        await axios.put(`${LESSON_QUESTION_URL}/${listOfQuestion[questionIndex].id}`, newUpdateData);
      } else {
        await axios.put(`${LESSON_QUESTION_URL}/${listOfQuestion[questionIndex].id}`, newUpdateData, {
          headers: {
            'X-LOCALE': 'en'
          }
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const updateAnswer = async (answerId, updateData) => {
    // const listOfQuestion = [...questionList];
    try {
      // if (listOfQuestion[questionIndex].connectedAnswers[answerIndex] === undefined) {
      //   const createAnswer = {
      //     id: updateData.id,
      //     creator: questionData.creator,
      //     parentItem: `/questions/${questionData.id}`,
      //     description: updateData.description,
      //     ordering: updateData.ordering
      //   };
      //   console.log('createAnswer', createAnswer)
      //   return await axios.post(`${LESSON_ANSWER_URL}`, createAnswer);
      // }
      const getAnswerId = answerId.split('/');
      if (langSwitch === 'ID') {
        return await axios.put(`${LESSON_ANSWER_URL}/${getAnswerId[2]}`, { description: updateData.description });
      }
      return await axios.put(`${LESSON_ANSWER_URL}/${getAnswerId[2]}`, { description: updateData.description }, {
        headers: {
          'X-LOCALE': 'en'
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const handleUpdateCorrectAnswer = async (answerId) => {
    const listOfQuestion = [...questionList];
    try {
      if (listOfQuestion[questionIndex].connectedAnswers) {
        const getAnswerId = answerId.split('/');
        await axios.put(`${LESSON_ANSWER_URL}/${getAnswerId[2]}`, { correct: true });
        const updateToFalse = listOfQuestion[questionIndex].connectedAnswers
          .filter((answer) => answer['@id'] !== answerId);
        updateToFalse.map(async (answer) => {
          const getUpdateToFalseId = answer['@id'].split('/');
          await axios.put(`${LESSON_ANSWER_URL}/${getUpdateToFalseId[2]}`, { correct: false });
        });
        questionList[questionIndex].haveOneCorrectAnswer = true;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleInputQuestion = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].description = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const handleAnswerKey = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answerKey = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const handleDeleteQuestion = async () => {
    onClickEvent.preventDefault();
    onClickEvent.stopPropagation();
    try {
      const questionId = questionData.id;
      const listOfQuestion = [...questionList];
      const resultQuestion = listOfQuestion.filter((question) => questionId !== question.id);
      if (previewContent > 0) {
        setPreviewContent(resultQuestion.length - 1);
      }
      if (previewContent === 0) {
        setPreviewContent(0);
      }
      const deletedQuestion = listOfQuestion.filter((question) => question.id === questionId);
      if (deletedQuestion[0].connectedAnswers.length !== 0) {
        deletedQuestion[0].connectedAnswers.map(async (answer) => {
          const getAnswerId = answer['@id'].split('/');
          await axios.delete(`${LESSON_ANSWER_URL}/${getAnswerId[2]}`);
        });
      }
      await axios.delete(`${LESSON_QUESTION_URL}/${questionId}`);
      dispatch(submitQuestion(resultQuestion));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const renderQuestionIcon = (questionType) => {
    if (questionType === 'TYPE_TRUE_OR_FALSE') {
      return (
        <Image src={trueOrFalseIcon}/>
      );
    }
    if (questionType === 'TYPE_MULTIPLE_CHOICE') {
      return (
        <Image src={multipleChoiceIcon}/>
      );
    }
    if (questionType === 'TYPE_FILL_THE_BLANK') {
      return (
        <Image src={fillTheBlankIcon} width='18'/>
      );
    }
    return null;
  };

  const handleOnBlurQuestionInput = async () => {
    await updateQuestion({ description: questionList[questionIndex].description });
  };

  const renderQuestionInput = (questionType) => {
    return (
      <div className='question-form-input'>
        <div className='question-form-input-icon'>
          <div className='question-form-input-icon-round'>
            {renderQuestionIcon(questionType)}
          </div>
          <h5>
            {questionIndex + 1}
          </h5>
        </div>
         <CharacterCounter
           value={questionList[questionIndex].description || ''}
           maxLength={100}
         >
          <Form.Control
            className=''
            as='textarea'
            maxLength={100}
            value={questionList[questionIndex].description}
            placeholder='Tulis pertanyaanmu disini'
            onChange={handleInputQuestion}
            onFocus={() => setPreviewContent(questionIndex)}
            onBlur={() => handleOnBlurQuestionInput()}
          />
         </CharacterCounter>
      </div>
    );
  };

  const handleAnswerTrueOrFalseSwitch = async (event, index, answerId) => {
    const listOfTrueOrFalseQuestion = [...trueOrFalseAnswer];
    setPreviewContent(questionIndex);
    if (event.target.value === 'on') {
      if (index === 0) {
        listOfTrueOrFalseQuestion[0].selected = true;
        listOfTrueOrFalseQuestion[1].selected = false;
      }
      if (index === 1) {
        listOfTrueOrFalseQuestion[0].selected = false;
        listOfTrueOrFalseQuestion[1].selected = true;
      }
      await handleUpdateCorrectAnswer(answerId);
      return setTrueOrFalseAnswer(listOfTrueOrFalseQuestion);
    }
    return null;
  };

  const renderAnswerTrueOrFalse = () => {
    return trueOrFalseAnswer.map((answer, index) => (
      <Col xs={6} key={index}>
        <div
          className={`
            question-form-answer
            ${answer.selected && 'question-form-answer-selected'}
          `}
        >
          <div className='question-form-answer-items'>
            <div className='question-form-answer-option'>
              {answer.option}
            </div>
            <div className='question-form-answer-text'>{answer.name}</div>
          </div>
          <div className='question-form-answer-switch'>
             <Form.Check
              onChange={(event) => handleAnswerTrueOrFalseSwitch(event, index, answer['@id'])}
              checked={answer.selected}
              type='switch'
              id={`${generateRandomString(5)}-switch`}
              label=''
             />
          </div>
        </div>
      </Col>
    ));
  };

  const handleOnChangeMultiple = (event, index) => {
    const { target: { value } } = event;
    const listOfMultipleChoice = [...multipleChoiceAnswer];
    const lisOfQuestion = [...questionList];
    setPreviewContent(questionIndex);
    listOfMultipleChoice[index].name = value;
    lisOfQuestion[questionIndex].answerPreview[index].name = value;
    setMultipleChoiceAnswer(listOfMultipleChoice);
    dispatch(submitQuestion(lisOfQuestion));
  };

  const handleAnswerMultipleSwitch = async (event, answer, answerId) => {
    const listOfMultipleChoice = [...multipleChoiceAnswer];
    setPreviewContent(questionIndex);
    if (event.target.value === 'on') {
      if (answer === 'A') {
        listOfMultipleChoice[0].selected = true;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'C') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = true;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'B') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = true;
        listOfMultipleChoice[3].selected = false;
      }
      if (answer === 'D') {
        listOfMultipleChoice[0].selected = false;
        listOfMultipleChoice[1].selected = false;
        listOfMultipleChoice[2].selected = false;
        listOfMultipleChoice[3].selected = true;
      }
      await handleUpdateCorrectAnswer(answerId);
      return setMultipleChoiceAnswer(listOfMultipleChoice);
    }
    return null;
  };

  const handleUpdateAnswerMultiple = async (id, description) => {
    await updateAnswer(id, { description });
  };

  const handleMultipleChoiceTab = (e, option, index) => {
    if (e.key === 'Tab' && option !== 'D') {
      e.preventDefault();
      switch (option) {
        case 'A':
          document.getElementById(`question-${index}-option-B`).focus();
          break;
        case 'B':
          document.getElementById(`question-${index}-option-C`).focus();
          break;
        case 'C':
          document.getElementById(`question-${index}-option-D`).focus();
          break;
        default:
          break;
      }
    }
  };

  const renderAnswerMultiple = () => {
    return (
      <>
        {multipleChoiceAnswer && multipleChoiceAnswer.map((answer, index) => (
          <Col key={index} xs={6}>
            <div
              className={`question-form-answer
                  ${answer.selected && 'question-form-answer-selected'}`}
            >
              <div className='question-form-answer-items'>
                <div className='question-form-answer-option'>
                  {answer.option}
                </div>
                <div className='question-form-answer-textarea'>
                   <CharacterCounter
                    value={answer.name || ''}
                    maxLength={46}
                   >
                    <Form.Control
                      id={`question-${questionIndex}-option-${answer.option}`}
                      maxLength={46}
                      as='textarea'
                      className='question-form-answer-text-input'
                      value={answer.name}
                      onChange={(event) => handleOnChangeMultiple(event, index)}
                      onFocus={() => setPreviewContent(questionIndex)}
                      onBlur={() => handleUpdateAnswerMultiple(answer['@id'], answer.name)}
                      onKeyDown={(e) => handleMultipleChoiceTab(e, answer.option, questionIndex)}
                      style={{ fontSize: 14, resize: 'none' }}
                    />
                   </CharacterCounter>
                </div>
              </div>
              <div className='question-form-answer-switch'>
                 <Form.Check
                  checked={answer.selected}
                  onChange={(event) => handleAnswerMultipleSwitch(event, answer.option, answer['@id'])}
                  type='switch'
                  id={`${generateRandomString(5)}-switch`}
                  label=''
                 />
              </div>
            </div>
          </Col>
        ))}
      </>
    );
  };

  const handleOnChangeOption = (event, index) => {
    const { target: { value } } = event;
    const listOfFillTheBlank = [...fillTheBlankAnswer];
    setPreviewContent(questionIndex);
    listOfFillTheBlank[index].name = value;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answers = listOfFillTheBlank;
    dispatch(submitQuestion(listOfQuestion));
    setFillTheBlankAnswer(listOfFillTheBlank);
  };

  const handleGenerateInputAnswer = async () => {
    try {
      const listOfFillTheBlank = [...fillTheBlankAnswer];
      setPreviewContent(questionIndex);
      const createAnswer = {
        id: uuidv4(),
        creator: questionData.creator,
        parentItem: `/lesson_questions/${questionData.id}`,
        ordering: listOfFillTheBlank.length,
        correct: true,
        description: ''
      };
      await axios.post(`${LESSON_ANSWER_URL}`, createAnswer);
      listOfFillTheBlank.push(createAnswer);
      const listOfQuestion = [...questionList];
      listOfQuestion[questionIndex].answers = listOfFillTheBlank;
      dispatch(submitQuestion(listOfQuestion));
      setFillTheBlankAnswer(listOfFillTheBlank);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleCancelAnswer = async (index, answerId) => {
    try {
      setPreviewContent(questionIndex);
      const fillTheBlankAnswerOption = [...fillTheBlankAnswer];
      fillTheBlankAnswerOption.splice(index, 1);
      const listOfQuestion = [...questionList];
      listOfQuestion[questionIndex].answers = fillTheBlankAnswerOption;
      dispatch(submitQuestion(listOfQuestion));
      setFillTheBlankAnswer(fillTheBlankAnswerOption);
      await axios.delete(`${LESSON_ANSWER_URL}/${answerId}`);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleUpdateAnswerFillBlank = async (index) => {
    try {
      const listOfFillTheBlank = [...fillTheBlankAnswer];
      const updateData = {
        description: listOfFillTheBlank[index].name
      };
      if (questionList[questionIndex].answers[index]) {
        const getAnswerId = questionList[questionIndex].answers[index].id;
        if (langSwitch === 'ID') {
          await axios.put(`${LESSON_ANSWER_URL}/${getAnswerId}`, updateData);
        } else {
          await axios.put(`${LESSON_ANSWER_URL}/${getAnswerId}`, updateData, {
            headers: {
              'X-LOCALE': 'en'
            }
          });
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const renderAnswerFillTheBlank = () => {
    return (
      <Col xs={12}>
        <Row>
          <div className='question-form-answer-fill' id='question-form-answer-fill'>
            {fillTheBlankAnswer.map((answer, index) => (
              <Col xs={12} key={index}>
                <div
                  className='question-form-answer'
                >
                  <div className='question-form-answer-items'>
                    <div className='question-form-answer-option'>
                      {index + 1}
                    </div>
                    <div className='question-form-answer-text'>
                       <CharacterCounter
                        value={answer.name || ''}
                        maxLength={25}
                       >
                        <Form.Control
                          type='text'
                          className='question-form-answer-text-input'
                          onChange={(event) => handleOnChangeOption(event, index)}
                          value={answer.name}
                          maxLength={25}
                          onFocus={() => setPreviewContent(questionIndex)}
                          onBlur={() => handleUpdateAnswerFillBlank(index, answer.name)}
                        />
                       </CharacterCounter>
                    </div>
                  </div>
                  <div
                    className='question-form-answer-cancel'
                    onClick={() => handleCancelAnswer(index, answer.id)}
                  >
                    <X/>
                  </div>
                </div>
              </Col>
            ))}
          </div>
          <Col xs={12}>
            <p
              className='question-form-tooltip'
              onClick={() => handleGenerateInputAnswer()}>
              Klik di sini untuk tambah satu atau lebih jawaban yang benar
            </p>
          </Col>
        </Row>
      </Col>
    );
  };

  const answerKeyOnFocus = () => {
    setPreviewContent(questionIndex);
    setPreviewCorrectAnswer(questionIndex);
  };

  const handleOnBlurAnswerKey = async () => {
    await updateQuestion({ answerKey: questionList[questionIndex].answerKey });
    setPreviewCorrectAnswer(undefined);
  };

  const renderAnswerKeyInput = () => {
    return (
      <div className='question-form-answer-key'>
        <Form.Group>
          <Form.Label>Answer Key</Form.Label>
           <CharacterCounter
            value={questionList[questionIndex].answerKey || ''}
            maxLength={300}>
            <Form.Control
              as="textarea"
              className='question-form-answer-key-textarea'
              maxLength={300}
              placeholder='Jelaskan tentang alasan jawaban yang benar'
              value={questionList[questionIndex].answerKey}
              onChange={handleAnswerKey}
              onFocus={answerKeyOnFocus}
              onBlur={() => handleOnBlurAnswerKey()}
            />
           </CharacterCounter>
        </Form.Group>
      </div>
    );
  };

  const handleVisualBrief = (event) => {
    const { target: { value } } = event;
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].visualBrief = value;
    dispatch(submitQuestion(listOfQuestion));
  };

  const visualBriefOnFocus = () => {
    setPreviewContent(questionIndex);
  };

  const handleOnBlurVisualBrief = async () => {
    await updateQuestion({ visualBrief: questionList[questionIndex].visualBrief });
  };

  const renderVisualBriefInput = () => {
    return (
      <div className='question-form-answer-key'>
        <Form.Group>
          <Form.Label>
            Arahan visual untuk desainer (opsional)
            <div className='question-form-visual-brief-label-mark' >?
              <div className='question-form-visual-brief-label-mark-tooltip' >
                Bantu desainer untuk memvisualisasikan pelajaran
              </div>
            </div>
          </Form.Label>
          <CharacterCounter
            value={questionList[questionIndex].visualBrief || ''}
            maxLength={150}>
            <Form.Control
              as="textarea"
              className='question-form-answer-key-textarea'
              maxLength={150}
              placeholder='Jelaskan konsep gambar latar belakang'
              value={questionList[questionIndex].visualBrief}
              onChange={handleVisualBrief}
              onFocus={() => visualBriefOnFocus()}
              onBlur={() => handleOnBlurVisualBrief()}
            />
          </CharacterCounter>
        </Form.Group>
      </div>
    );
  };

  const handleUploadFile = () => {
    setPreviewContent(questionIndex);
    inputFile.current.click();
  };

  const handleOnChangeFile = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      event.stopPropagation();
      setLoadingFile(true);
      setErrorUploadImage(false);
      const { target: { files } } = event;
      if (files[0] && files[0] !== undefined) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const img = document.createElement('img');
          img.onload = async () => {
            if (img.width !== QUESTION_IMAGE_WIDTH || img.height !== QUESTION_IMAGE_HEIGHT) {
              setErrorUploadImage(true);
              toast.error(
                'Ukuran gambar yang anda unggah salah. Pastikan ukuran gambar kamu 1440x2960.'
              );
            } else {
              const getTypeImage = files[0].type.split('/');
              const fileType = {
                fileExtension: getTypeImage[1]
              };
              let custom = null;
              let header = null;
              if (questionList[questionIndex].image) {
                custom = {
                  method: 'PATCH',
                  id: questionList[questionIndex].image['@id']
                };
                header = {
                  'X-LOCALE': langSwitch === 'EN' ? 'en' : 'id',
                  'Content-Type': 'application/merge-patch+json'
                };
              }
              const uploadedData = await dispatch(uploadImageToS3(fileType, files[0], header, custom));
              if (uploadedData) {
                const newListOfQuestion = [...questionList];
                newListOfQuestion[questionIndex].image = uploadedData;
                dispatch(submitQuestion(newListOfQuestion));
                const updateData = {
                  image: uploadedData['@id']
                };
                await updateQuestion(updateData);
              }
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    } catch (error) {
      setErrorUploadImage(true);
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
  };

  const handleSwitchImage = async () => {
    const isSameImage = !sameImage;
    setSameImage(isSameImage);
    if (isSameImage) {
      // Get Bahasa image
      const imageBahasaId = questionList[questionIndex].image['@id'];
      const { data } = await axios.get(imageBahasaId);
      // Patch Bahasa Image to English Image
      await axios({
        method: 'PATCH',
        url: imageBahasaId,
        data: {
          name: data.name,
          url: data.contentUrl
        },
        headers: {
          'X-LOCALE': 'en',
          'Content-Type': 'application/merge-patch+json'
        }
      });
      const newListOfQuestion = [...questionList];
      newListOfQuestion[questionIndex].image = data;
      dispatch(submitQuestion(newListOfQuestion));
    } else {
      // Remove image from state if not using the same image
      const newListOfQuestion = [...questionList];
      const emptyImageEn = {
        ...newListOfQuestion[questionIndex].image,
        contentUrl: null
      };
      newListOfQuestion[questionIndex].image = emptyImageEn;
      dispatch(submitQuestion(newListOfQuestion));
    }
  };

  const renderUploadAndDeleteButton = () => {
    return (
      <div className='question-form-buttons'>
        <input
          type='file'
          id='upload-file-question'
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleOnChangeFile}
          onFocus={() => setPreviewContent(questionIndex)}
        />
        {langSwitch === 'EN' && (
          <div>
            <span style={{ fontSize: '16px' }}>Use the same image</span>
            <div className="onoffswitch">
              <input
                type="checkbox"
                className="onoffswitch-checkbox"
                checked={sameImage}
                onChange={handleSwitchImage}
                id={`imageSwitch-${questionIndex}`}
              />
              <label className="onoffswitch-label" htmlFor={`imageSwitch-${questionIndex}`}>
                <span className="onoffswitch-inner"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
        )}
        {!sameImage || langSwitch === 'ID' ? (
          <div className='question-form-upload'>
            <Button disabled={loadingFile} onClick={handleUploadFile}>
              <CardImage/> Pilih File (.JPG)
            </Button>
            {errorUploadImage && 'Upload Failed!'}
          </div>
        ) : null}
        <div className='d-flex flex-row'>
          {/* <div className='pt-2'>
            <div className="lang-onoffswitch">
              <input
                type="checkbox"
                className="lang-onoffswitch-checkbox"
                checked={langSwitch === 'ID'}
                onChange={() => setLangSwitch((prevState) => {
                  if (prevState === 'ID') {
                    return 'EN';
                  }
                  return 'ID';
                })}
                id={`lang-switch-${questionIndex}`}
              />
              <label className="lang-onoffswitch-label" htmlFor={`lang-switch-${questionIndex}`}>
                <span className="lang-onoffswitch-inner"></span>
                <span className="lang-onoffswitch-switch"></span>
              </label>
            </div>
          </div> */}
          <div
            className='question-form-buttons-remove'
            onClick={(event) => {
              setShowConfirmDelete(true);
              setOnClickEvent(event);
            }}
          >
            <TrashFill/>
          </div>
        </div>
      </div>
    );
  };

  const style = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused && '#F2767E',
      boxShadow: state.isFocused && '0 0 0 0.2rem #F2767E',
      '&:hover': {
        border: '1px solid #F2767E'
      }
    })
  };

  const handleQuestionDifficulty = async (value) => {
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].difficulty = value.value;
    dispatch(submitQuestion(listOfQuestion));
    await updateQuestion({ difficulty: listOfQuestion[questionIndex].difficulty });
  };

  const handleOnFocusDiffculty = () => {
    setPreviewContent(questionIndex);
  };

  const renderDifficulty = () => {
    return (
      <div className='question-form-position'>
        <Form.Group>
          <Form.Label>
            Tingkat Kesulitan
          </Form.Label>
          <Select
            options={QUESTION_DIFFICULTY}
            placeholder='Pilih Tingkat Kesulitan'
            className='question-form-position-select'
            classNamePrefix='question-form-position-select'
            styles={style}
            onChange={handleQuestionDifficulty}
            value={QUESTION_DIFFICULTY.find((d) => d.value === questionList[questionIndex].difficulty)}
            menuPlacement='top'
            maxMenuHeight={300}
            onFocus={handleOnFocusDiffculty}
          />
        </Form.Group>
      </div>
    );
  };

  const handleAnswerableWithoutImage = async () => {
    const listOfQuestion = [...questionList];
    listOfQuestion[questionIndex].answerableWithoutImage = !listOfQuestion[questionIndex].answerableWithoutImage;
    dispatch(submitQuestion(listOfQuestion));
    await updateQuestion({ answerableWithoutImage: listOfQuestion[questionIndex].answerableWithoutImage });
  };

  const renderAnswerableWithoutImage = () => {
    return (
      <div className='question-form-position'>
        <Form.Group>
          <CheckBox
            label='Mark this as question answerable without question image'
            onChange={handleAnswerableWithoutImage}
            className='sign-up-form-input-label-checkbox'
            checked={questionList[questionIndex].answerableWithoutImage}
          />
        </Form.Group>
      </div>
    );
  };

  const orderOptions = () => {
    const options = questionList.map((q, i) => {
      return {
        label: i + 1,
        value: i
      };
    });
    return options;
  };

  const arrayMoveMutate = (array, from, to) => {
    array.splice(
      to < 0 ? array.length + to : to,
      0,
      array.splice(from, 1)[0]
    );
  };

  const arrayMove = (array, from, to) => {
    const newArray = array.slice();
    arrayMoveMutate(newArray, from, to);
    return newArray;
  };

  const handleOrderQuestion = async (value) => {
    setLoading(true);
    try {
      const newArray = arrayMove(questionList, questionIndex, value.value);
      for (let index = 0; index < newArray.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await axios.put(newArray[index]['@id'], {
          ordering: index + 1
        });
      }
      dispatch(submitQuestion(newArray));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoading(false);
  };

  const renderOrderQuestion = () => {
    return (
      <div className='question-form-position'>
        <Form.Group>
          <Form.Label>
            Posisi Pertanyaan
          </Form.Label>
          <Select
            options={orderOptions()}
            placeholder='Pilih Posisi Pertanyaan'
            className='question-form-position-select'
            classNamePrefix='question-form-position-select'
            styles={style}
            onChange={handleOrderQuestion}
            value={{
              value: questionIndex,
              label: questionIndex + 1,
            }}
            menuPlacement='top'
            maxMenuHeight={300}
          />
        </Form.Group>
      </div>
    );
  };

  const optionContents = {
    trueOrFalse: renderAnswerTrueOrFalse(),
    multipleChoice: renderAnswerMultiple(),
    fillTheBlank: renderAnswerFillTheBlank()
  };

  return (
    <div
      className='question-form'
      id={`question-form-id-${questionIndex + 1}`}
      onClick={() => setPreviewContent(questionIndex)}
    >
      <ConfirmationModal
        handleSubmit={handleDeleteQuestion}
        setIsShowModal={setShowConfirmDelete}
        isShowModal={showConfirmDelete}
        doneMsg={'Berhasil Menghapus Pertanyaan!'}
        headerMsg={'Menghapus Pertanyaan'}
      />
      <Row>
        <Col xs={12}>
          {renderQuestionInput(questionData.type)}
        </Col>
      </Row>
      <Row>
        {questionData.type === 'TYPE_TRUE_OR_FALSE' && optionContents.trueOrFalse}
        {questionData.type === 'TYPE_MULTIPLE_CHOICE' && optionContents.multipleChoice}
        {questionData.type === 'TYPE_FILL_THE_BLANK' && optionContents.fillTheBlank}
      </Row>
      <Row>
        <Col xs={12}>
          {renderAnswerKeyInput()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderVisualBriefInput()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderOrderQuestion()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderDifficulty()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderAnswerableWithoutImage()}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {renderUploadAndDeleteButton()}
        </Col>
      </Row>
    </div>
  );
};

export default LessonQuestionForm;
