import PropTypes from 'prop-types';
import React from 'react';
import Label from '../Label/Label';

const RenderRecipients = ({ recipients, handleCancelRecipient }) =>
  recipients.map((member) => {
    return (
      <Label
        key={member['@id']}
        type='STATUS_WITH-CANCEL'
        onClick={() => handleCancelRecipient(member['@id'], member.type)}
      >
        {member.recipient}
      </Label>
    );
  });

export default RenderRecipients;

RenderRecipients.propTypes = {
  handleCancelRecipient: PropTypes.func.isRequired,
  recipients: PropTypes.object.isRequired
};
