import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Logo from '../../Assets/Images/logo.png';
import Folder from '../../Assets/Images/Folder.png';
import Logout from '../../Assets/Images/Logout.png';
import Notepad from '../../Assets/Images/Notepad.png';
import './AdminSidebar.scss';

const SidebarOption = ({
  icon,
  text,
  isActive,
  onClick
}) => {
  return (
    <div
      className={`admin-sidebar-option ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <img src={icon} alt="" className='admin-sidebar-option-img' /> {text}
    </div>
  );
};

const AdminSidebar = ({ active }) => {
  const History = useHistory();

  const handleLogout = () => {
    History.push('/logout');
  };

  return (
    <div className='admin-sidebar' >

      <img src={Logo} alt="logo" className='admin-sidebar-logo' onClick={() => History.push('/')} />

      <div className='admin-sidebar-options' >
        <SidebarOption
          icon={Notepad}
          text='Lesson Requests'
          isActive={active === 'lessonRequests'}
          onClick={() => History.push('/admin/lesson-requests')}
        />
        <SidebarOption
          icon={Folder}
          text='Lesson Bank'
          isActive={active === 'lessonBank'}
          onClick={() => History.push('/admin/lesson-bank')}
        />
      </div>

      <div className='admin-sidebar-logout' >
        <SidebarOption icon={Logout} text='Log Out' onClick={handleLogout} />
      </div>

    </div>
  );
};

AdminSidebar.propTypes = {
  active: PropTypes.string,
};

export default AdminSidebar;
