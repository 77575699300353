/* eslint-disable no-nested-ternary */
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import InvitationCard from '../InvitationCard/InvitationCard';
import NoProjectsTab from '../NoProjectsTab/NoProjectsTab';
import { list as unconfirmedListAction } from '../../Generated/actions/organizationunconfirmedmembers/list';
import { list as organizationSearchListAction } from '../../Generated/actions/organization/searchList';
import GetUserDataBasedOnOrganizations from './GetUserDataBasedOnOrganizations';

const ProjectInvitationsTab = () => {
  const dispatch = useDispatch();
  const unconfirmedList = useSelector(
    (state) => state.organizationunconfirmedmembers.list
  );

  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    if (refresh) {
      dispatch(
        unconfirmedListAction(
          `/organization_unconfirmed_members?member=${localStorage.getItem(
            'id'
          )}&order%5BcreatedAt%5D=desc`
        )
      );
      setRefresh(false);
    }
    // return () => {
    //   dispatch(unconfirmedListReset(unconfirmedList.eventSource));
    // };
  }, [refresh]);

  useEffect(() => {
    if (
      unconfirmedList.retrieved
      && unconfirmedList.retrieved['hydra:totalItems']
    ) {
      const mappedUnconfirmedOrg = unconfirmedList.retrieved[
        'hydra:member'
      ].map((item) => {
        return item.organization;
      });
      const filterQueryString = queryString.stringify(
        {
          id: mappedUnconfirmedOrg,
        },
        { arrayFormat: 'bracket' }
      );
      dispatch(
        organizationSearchListAction(`/organizations?${filterQueryString}`)
      );
    }
  }, [unconfirmedList.retrieved]);

  // eslint-disable-next-line no-unused-vars
  const data = GetUserDataBasedOnOrganizations();

  return (
    unconfirmedList.retrieved
      && unconfirmedList.retrieved['hydra:totalItems'] ? (
        <>
          <br />
          <Row className='my-projects-container-row'>
            {unconfirmedList.retrieved['hydra:member'].map(
              (unconfirmedOrganization) => (
                <Col xs={6} as='div' className='my-projects-container-row-col'>
                  <InvitationCard
                    unconfirmedMember={unconfirmedOrganization}
                    setRefresh={setRefresh}
                    key={unconfirmedOrganization.id}
                  />
                </Col>
              )
            )}
          </Row>
        </>
      ) : (
        <NoProjectsTab />
      )
  );
};

export default ProjectInvitationsTab;
