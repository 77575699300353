import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Image } from 'react-bootstrap';
import React from 'react';
import Input from '../Components/Input/Input';
import submitPreview from '../Assets/Images/submit-preview.png';

export const trueOrFalseField = [
  {
    id: '',
    option: 'A',
    name: 'True',
    value: 'true',
    selected: false
  },
  {
    id: '',
    option: 'B',
    name: 'False',
    value: 'false',
    selected: false
  }
];

export const multipleChoiceField = [
  {
    option: 'A',
    name: '',
    value: '',
    selected: false
  },
  {
    option: 'C',
    name: '',
    value: '',
    selected: false
  },
  {
    option: 'B',
    name: '',
    value: '',
    selected: false
  },
  {
    option: 'D',
    name: '',
    value: '',
    selected: false
  },
];

export const multipleAnswerField = [
  {
    name: ''
  },
  {
    name: ''
  },
  {
    name: ''
  },
  {
    name: ''
  }
];

export const fileUploadField = {
  pdfFile: {
    name: '',
    contentUrl: ''
  },
  mp4File: {
    name: '',
    contentUrl: ''
  },
  zipFile: {
    name: '',
    contentUrl: ''
  },
  audioFile: {
    name: '',
    contentUrl: ''
  },
};

export const generateQuestion = (creator, isPartOf, type) => {
  let connectedAnswers;
  if (type === 'TYPE_TRUE_OR_FALSE') {
    connectedAnswers = cloneDeep(trueOrFalseField);
  }
  if (type === 'TYPE_MULTIPLE_CHOICE') {
    connectedAnswers = cloneDeep(multipleChoiceField);
  }
  if (type === 'TYPE_FILL_THE_BLANK') {
    connectedAnswers = [];
  }
  return {
    id: uuidv4(),
    creator,
    isPartOf,
    type,
    description: '',
    connectedAnswers,
    image: null,
    haveOneCorrectAnswer: false,
    haveEmptyDescription: true,
    difficulty: 0,
    answerPreview: [
      {
        name: ''
      },
      {
        name: ''
      },
      {
        name: ''
      },
      {
        name: ''
      }
    ]
  };
};

export const generateLessonQuestion = (isPartOf, type) => {
  let connectedAnswers;
  if (type === 'TYPE_TRUE_OR_FALSE') {
    connectedAnswers = cloneDeep(trueOrFalseField);
  }
  if (type === 'TYPE_MULTIPLE_CHOICE') {
    connectedAnswers = cloneDeep(multipleChoiceField);
  }
  if (type === 'TYPE_FILL_THE_BLANK') {
    connectedAnswers = [];
  }
  return {
    id: uuidv4(),
    isPartOf,
    type,
    description: '',
    connectedAnswers,
    image: null,
    haveOneCorrectAnswer: false,
    haveEmptyDescription: true,
    answerPreview: [
      {
        name: ''
      },
      {
        name: ''
      },
      {
        name: ''
      },
      {
        name: ''
      }
    ]
  };
};

export const dropdownCreateQuestion = (handleGenerateQuestion) => [
  {
    title: 'Benar / Salah',
    props: {
      onClick: () => handleGenerateQuestion('TYPE_TRUE_OR_FALSE')
    }
  },
  {
    title: 'Pilihan Ganda',
    props: {
      onClick: () => handleGenerateQuestion('TYPE_MULTIPLE_CHOICE')
    }
  },
  {
    title: 'Isi Yang Kosong',
    props: {
      onClick: () => handleGenerateQuestion('TYPE_FILL_THE_BLANK')
    }
  }
];

export const generateAnswerTrueOrFalse = (creator, questionId) => [
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    description: 'Benar',
    ordering: 1
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    description: 'Salah',
    ordering: 2
  }
];

export const generateLessonAnswerTrueOrFalse = (creator, questionId) => [
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    description: 'Benar',
    ordering: 1
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    description: 'Salah',
    ordering: 2
  }
];

export const generateAnswerMultipleChoice = (creator, questionId) => [
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    ordering: 1
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    ordering: 2
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    ordering: 3
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/questions/${questionId}`,
    ordering: 4
  }
];

export const generateLessonAnswerMultipleChoice = (creator, questionId) => [
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    ordering: 1
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    ordering: 2
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    ordering: 3
  },
  {
    id: uuidv4(),
    creator,
    parentItem: `/lesson_questions/${questionId}`,
    ordering: 4
  }
];

export const generateQuestionData = (createdQuestion, type, ordering) => ({
  id: createdQuestion.id,
  creator: createdQuestion.creator,
  isPartOf: createdQuestion.isPartOf,
  type,
  ordering,
  difficulty: 0
});

const fontSizeByLength = (length) => {
  if (length > 30) {
    return 11;
  }
  return 13;
};

export const answerField = (questions, previewContent) => ({
  renderTrueOrFalse: (
    <div className='question-answer-content-preview-footer-answer margin-top-50'>
      <div className='question-answer-content-preview-footer-answer-button'>
        <h1>True</h1>
      </div>
      <div className='question-answer-content-preview-footer-answer-button'>
        <h1>False</h1>
      </div>
    </div>
  ),
  renderMultipleChoice: (
    <>
      <div className='question-answer-content-preview-footer-answer'>
        <div className='question-answer-content-preview-footer-answer-button'>
          <h1
            style={{ fontSize: fontSizeByLength(questions[previewContent].answerPreview[0].name.length) }}
          >
            {questions[previewContent].answerPreview[0].name}
          </h1>
        </div>
        <div className='question-answer-content-preview-footer-answer-button'>
          <h1
            style={{ fontSize: fontSizeByLength(questions[previewContent].answerPreview[1].name.length) }}
          >
            {questions[previewContent].answerPreview[1].name}
          </h1>
        </div>
      </div>
      <div className='question-answer-content-preview-footer-answer'>
        <div className='question-answer-content-preview-footer-answer-button'>
          <h1
            style={{ fontSize: fontSizeByLength(questions[previewContent].answerPreview[2].name.length) }}
          >
            {questions[previewContent].answerPreview[2].name}
          </h1>
        </div>
        <div className='question-answer-content-preview-footer-answer-button'>
          <h1
            style={{ fontSize: fontSizeByLength(questions[previewContent].answerPreview[3].name.length) }}
          >
            {questions[previewContent].answerPreview[3].name}
          </h1>
        </div>
      </div>
    </>
  ),
  renderFillTheBlank: (
    <div className='question-answer-content-preview-footer-answer margin-top-50'>
      <Input placeholder='TYPE ANSWER HERE' />
      <Image src={submitPreview} />
    </div>
  )
});
