import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './Textarea.scss';

const Textarea = ({
  maxLength,
  value,
  onChange,
  rows,
  onBlur
}) => {
  return (
    <div className='textarea-container' >
      <Form.Control
        as="textarea"
        className='textarea'
        maxLength={maxLength}
        rows={rows || 5}
        placeholder='Explain how this background image should look, what elements it has'
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      />
      <span className='textarea-length' >{value.length}/{maxLength}</span>
    </div>
  );
};

Textarea.propTypes = {
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  row: PropTypes.number,
  onBlur: PropTypes.func,
};

export default Textarea;
