import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Modal, Row
} from 'react-bootstrap';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Button from '../Button/Button';
import './PublishModal.scss';
import TagName from '../Tags/TagName';
import StartProposalConfirmModal from '../StartProposalConfirmModal/StartProposalConfirmModal';
import { PROJECT_URL } from '../../Constants/Constants';
import GetThemeAndSubThemeNameFormObject from '../DataHelpers/GetThemeAndSubThemeNameFormObject';

const PublishModal = ({
  showModal, publishModalData,
  setShowModal, onPublishClick,
  awardExists
}) => {
  // delete logic we can move it form here
  const History = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteLesson = async () => {
    try {
      await axios.delete(`${PROJECT_URL}/${publishModalData.id}`);
      History.push('/admin/lesson-requests');
    } catch (error) {
      throw new Error(error);
    }
  };
  const deleteModalDetails = {
    show: showDeleteModal,
    setShow: setShowDeleteModal,
    heading: 'Are you sure you want to delete this request?',
    sub: 'You can’t undo your action after you delete it. You can always save it on draft and edit it later',
    isSingleButton: false,
    noText: "No, I'd like to edit",
    yesText: 'Yes, delete please',
    yesClick: async () => {
      await handleDeleteLesson();
      setShowDeleteModal();
    },
  };
  // end delete logic

  const renderHeader = () => {
    return (
      <div className='publish-modal-header' >
        <span className='publish-modal-header-heading'>
          {`${publishModalData && !publishModalData.isDraft ? 'Update' : 'Publish'} Lesson Request`}
        </span>
        <span className='publish-modal-header-sub'>{publishModalData && publishModalData.title}</span>
        <span className='publish-modal-header-deadline'>
          DEADLINE: {publishModalData && moment(publishModalData.endTime).format('DD-MM-YYYY')}
        </span>
      </div>
    );
  };

  const renderTag = () => {
    if (!isEmpty(publishModalData) && publishModalData.tags) {
      return publishModalData.tags.map((tag) => (
        <TagName tag={tag} key={tag}/>
      ));
    }
    return '';
  };

  const renderContent = () => {
    return (
      <div className='publish-modal-content'>
        <div className='question-answer-overview-content-wrapper'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text'>Subject</span>
            </Col>
            <Col xs={10}>
              <p>
                {renderTag()}
              </p>
            </Col>
          </Row>
        </div>
        <div className='question-answer-overview-content-wrapper'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text' >material</span>
            </Col>
            <Col xs={10}>
              <p>
                {publishModalData && publishModalData.material}
              </p>
            </Col>
          </Row>
        </div>
        {publishModalData && publishModalData.subtheme && (
          <div className='question-answer-overview-content-wrapper'>
            <Row>
              <Col xs={2}>
                <span className='publish-modal-content-left-text' >Tema</span>
              </Col>
              <Col xs={10}>
                <p>
                  <GetThemeAndSubThemeNameFormObject
                    projectDetail={publishModalData}
                  />
                </p>
              </Col>
            </Row>
          </div>
        )}
        <div className='question-answer-overview-content-wrapper'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text'>Objektif</span>
            </Col>
            <Col xs={10}>
              <p>
                {publishModalData && publishModalData.objective}
              </p>
            </Col>
          </Row>
        </div>
        <div className='question-answer-overview-content-wrapper'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text'>Aktivitas</span>
            </Col>
            <Col xs={10}>
              <p>{publishModalData && publishModalData.activity}</p>
            </Col>
          </Row>
        </div>
        <div className='question-answer-overview-content-wrapper'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text' >Penilaian</span>
            </Col>
            <Col xs={10}>
              <p>
                {publishModalData && publishModalData.assessment}
              </p>
            </Col>
          </Row>
        </div>
        <div className='question-answer-overview-content-wrapper no-border-bottom'>
          <Row>
            <Col xs={2}>
              <span className='publish-modal-content-left-text'>Award</span>
            </Col>
            <Col xs={10}>
              <ol>
                {awardExists && awardExists.filter((a) => a !== '').map((award, i) => (
                  <li key={i}>{award}</li>
                ))}
              </ol>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      dialogClassName='free-material__modal'
      onHide={() => setShowModal()}
    >
      <Modal.Body>
        {renderHeader()}
        {renderContent()}

        <div className='publish-modal-btn-container'>
          <div className='publish-modal-btn-left' >
            <buttton className='publish-modal-btn-circle'
                     onClick={() => { History.push(`/admin/lesson-request/${publishModalData.id}/edit`); }} >
              <i className='fa fa-pencil' />
            </buttton>
            <button className='publish-modal-btn-circle' onClick={() => setShowDeleteModal(true)} >
              <i className='fa fa-trash' />
            </button>
          </div>
          <div className='publish-modal-btn-main' >
            <Button block onClick={() => onPublishClick()} >
              {`Yes, ${publishModalData && !publishModalData.isDraft ? 'Update' : 'Publish'} Now`}
            </Button>
          </div>
        </div>
        <StartProposalConfirmModal
            isShowModal={showDeleteModal}
            setIsShowModal={setShowDeleteModal}
            details={deleteModalDetails}
        />
      </Modal.Body>
    </Modal>
  );
};

PublishModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onPublishClick: PropTypes.func,
};

export default PublishModal;
