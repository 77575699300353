import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';

const ConfirmWinnerModal = ({
  showModal,
  setShowModal,
  winnerData,
  publishWinner,
  awards,
  publishLoading
}) => {
  const listOfOrganization = useSelector((state) => state.organization.list);

  const RenderWinner = ({ data }) => {
    const filteredOrganization = listOfOrganization.retrieved
      && listOfOrganization.retrieved['hydra:member'].filter(
        (organization) => organization['@id'] === data.winner['@id']
      );

    return <li className='font-weight-bold'>{filteredOrganization[0].name}</li>;
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      dialogClassName='free-material__modal'
      centered
    >
      <div className='close' onClick={() => setShowModal(false)}>
        <X />
      </div>
      <Modal.Body>
        <h5 className='text-center mb-3'>
          Are you sure you want to choose these group as the winners?
        </h5>
        <ol className='my-4'>
          {winnerData
            .filter((d) => d.winner)
            .map((w, i) => (
              <RenderWinner key={i} data={w} />
            ))}
        </ol>
        <Row className='justify-content-around'>
          <Button type='secondary' onClick={() => setShowModal(false)}>
            Check Again
          </Button>
          {winnerData.length === awards.length ? (
            <Button disabled={publishLoading} onClick={publishWinner}>Yes</Button>
          ) : (
            <p className='text-danger'>You need to select {awards.length} teams as winners</p>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ConfirmWinnerModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  winnerData: PropTypes.array,
  awards: PropTypes.array,
};

export default ConfirmWinnerModal;
