import React from 'react';
import { Button as BoostrapButton, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { noop } from '../../Constants/Constants';
import './Button.scss';

const Button = (props) => {
  const {
    children,
    type,
    onClick,
    block,
    dropdownItem,
    icon,
    bsPrefix,
    halfWidth,
    disabled = false
  } = props;

  if (type === 'dropdown') {
    return (
      <div className='custom-button'>
        <Dropdown>
          <Dropdown.Toggle variant="primary">
            {icon} {children}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {dropdownItem.map((item, i) => (
              <Dropdown.Item key={i} {...item.props}>{item.title}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  if (type === 'text-button') {
    return (
      <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
        <BoostrapButton
          variant='light'
          onClick={onClick}
          className={`${block ? 'custom-button-full-width' : ''}`}
          disabled={disabled}
        >
          {children}
        </BoostrapButton>
      </div>
    );
  }

  if (type === 'secondary') {
    return (
      <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
        <BoostrapButton
          variant='secondary'
          onClick={onClick}
          className={`${block ? 'custom-button-full-width' : ''}${halfWidth ? 'custom-button-full-width' : ''}`}
          bsPrefix={bsPrefix || ''}
          disabled={disabled}
        >
          {children}
        </BoostrapButton>
      </div>
    );
  }

  if (type === 'disabled') {
    return (
      <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
        <BoostrapButton
          disabled
          variant='primary'
          onClick={onClick}
          className={`${block || halfWidth ? 'custom-button-full-width' : ''} disabled`}
          disabled={disabled}
        >
          {children}
        </BoostrapButton>
      </div>
    );
  }

  if (type === 'danger') {
    return (
      <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
          <BoostrapButton
              variant='danger'
              onClick={onClick}
              className={`${block || halfWidth ? 'custom-button-full-width' : ''} disabled`}
              bsPrefix={bsPrefix || ''}
              disabled={disabled}
          >
              {children}
          </BoostrapButton>
      </div>
    );
  }

  if (type === 'blue') {
    return (
      <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
          <BoostrapButton
              onClick={onClick}
              className={`${block || halfWidth ? 'custom-button-full-width' : ''}`}
              bsPrefix={bsPrefix || ''}
              // style={{ backgroundColor: '#A2DAF1' }}
              ref={(el) => {
                if (el) {
                  el.style.setProperty('background-color', '#A2DAF1', 'important');
                }
              }}
          >
              {children}
          </BoostrapButton>
      </div>
    );
  }

  return (
    <div className={`custom-button ${block ? 'width-100' : ''} ${halfWidth ? 'width-50' : ''}`}>
      <BoostrapButton
        variant='primary'
        onClick={onClick}
        className={`${block || halfWidth ? 'custom-button-full-width' : ''}`}
        bsPrefix={bsPrefix || ''}
        disabled={disabled}
      >
        {children}
      </BoostrapButton>
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  dropdownItem: PropTypes.array,
  icon: PropTypes.any,
};

Button.defaultProps = {
  onClick: noop,
  block: false,
  halfWidth: false,
  dropdownItem: [{}],
  icon: null,
};

export default Button;
