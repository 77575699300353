import axios from 'axios';
import {
  API_BASE_URL,
  PERSON_URL,
  headerConfig,
  IMAGE_OBJECTS,
  IMAGE_OBJECTS_PUT_LINK,
  CONTACT_URL
} from '../Constants/Constants';

export const isErrorEditProfile = (value) => ({
  type: 'IS_ERROR_EDIT_PROFILE',
  payload: value
});

export const editProfile = (userId, personData) => {
  return async (dispatch) => {
    const personObject = personData;
    Object.keys(personObject).forEach(
      (key) =>
        (personObject[key] === null || personObject[key] === '')
         && delete personObject[key]
    );
    try {
      await axios.put(`${PERSON_URL}/${userId}`, personObject,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/ld+json'
          }
        });
      if (Object.prototype.hasOwnProperty.call(personObject, 'tags') && personObject.tags.length) {
        const authUserid = localStorage.getItem('id');
        const tags = {
          tags: personObject.tags
        };
        await axios.put(`${API_BASE_URL}${authUserid}`, tags,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/ld+json'
            }
          });
      }
    } catch (error) {
      dispatch(isErrorEditProfile(true));
    }
  };
};

export const updateProfileImage = (personId, imagIRI) => {
  return async (dispatch) => {
    try {
      await axios.put(`${PERSON_URL}/${personId}`, imagIRI,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/ld+json'
          }
        });
    } catch (error) {
      dispatch(isErrorEditProfile(true));
    }
  };
};

export const getImageData = (imageIRI) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${imageIRI}`);
      return data;
    } catch (error) {
      return dispatch(isErrorEditProfile(true));
    }
  };
};

export const updateSocialProfile = (personId, contactObject) => {
  return async (dispatch) => {
    try {
      Object.keys(contactObject).forEach(
        (key) => {
          if (Object.prototype.hasOwnProperty.call(contactObject[key], 'isExist')) {
            // Update Contacts
            const object = {
              name: contactObject[key].name,
              value: contactObject[key].value,
              person: `/people/${personId}`,
            };
            axios.put(`${CONTACT_URL}/${contactObject[key].id}`, object,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/ld+json'
                }
              });
          } else {
            // Create Contacts
            const object = {
              id: contactObject[key].id,
              name: contactObject[key].name,
              value: contactObject[key].value,
              person: `/people/${personId}`,
            };
            axios.post(`${CONTACT_URL}`, object,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/ld+json'
                }
              });
          }
        }
      );
    } catch (error) {
      dispatch(isErrorEditProfile(true));
    }
  };
};

export const uploadImageToS3 = (fileObject, file) => {
  return async () => {
    const buffer = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const configHeader = {
      method: 'PUT',
      headers: {
        'Content-Type': fileObject.type,
        'Content-Encoding': 'base64',
      },
      body: buffer
    };
    const extension = fileObject.fileExtension;
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      const fileType = {
        fileExtension: fileObject.fileExtension
      };
      const { data: { url } } = await axios.post(API_BASE_URL + IMAGE_OBJECTS_PUT_LINK, fileType, headerConfig);
      await fetch(url, configHeader);
      const urlToBucket = {
        name: fileObject.name,
        url
      };
      const { data } = await axios.post(IMAGE_OBJECTS, urlToBucket, headerConfig);
      return data;
    }
    return null;
  };
};
