import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './DropdownFormComponent.scss';

const DropdownFormComponent = ({
  label,
  errorMessage,
  placeholder,
  options,
  isMulti,
  onChange,
  value,
  disabled = false
}) => {
  return (
    <div className='form-input'>
    <Form.Group>
      <Form.Label className='form-input-label'>{label}</Form.Label>
        <Select
          isDisabled={disabled}
          classNamePrefix='dropdown-form-component'
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          onChange={onChange}
          value={value}
        />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  </div>

  );
};

DropdownFormComponent.propTypes = {
  label: PropTypes.string,
  errorMessage: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func
};

export default DropdownFormComponent;
