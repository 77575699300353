import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';

import File from '../../Assets/Images/file.png';
import NewLesson from '../../Assets/Images/icon_newly_id.png';
import EducationalLevel from '../EducationalLevel/EducationalLevel';
import Label from '../Label/Label';
import TagName from '../Tags/TagName';

const FreeMaterialCard = ({
  lessonItem,
  setShowMaterialModal,
  setPreviewLesson,
  setNewLessonHours = 168,
}) => {
  const [content, setContent] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [gif, setGif] = useState(null);
  const listOfImages = useSelector((state) => state.imageobject.list);

  const checkNewLesson = (publishedTIme) => {
    const published = new Date(publishedTIme);
    const now = new Date();
    const hours = Math.abs(now - published) / 36e5;
    if (hours <= setNewLessonHours) {
      return true;
    }
    return false;
  };

  const getGif = async () => {
    if (lessonItem.video.animation) {
      if (gif) {
        setContent(gif);
      } else {
        const { data } = await axios.get(lessonItem.video.animation);
        setGif(data.contentUrl);
        setContent(data.contentUrl);
      }
    }
  };

  const removegif = () => {
    setContent(thumbnail);
  };

  useEffect(() => {
    if (listOfImages.retrieved && lessonItem) {
      if (lessonItem.video.image) {
        const imgThumb = listOfImages.retrieved['hydra:member']
          .find((img) => img['@id'] === lessonItem.video.image);
        if (imgThumb !== undefined) {
          setThumbnail(imgThumb.contentUrl);
          setContent(imgThumb.contentUrl);
        }
      }
    }
  }, [listOfImages.retrieved, lessonItem]);

  const getDuration = (duration) => {
    let mins = '';
    let secs = '';
    if (duration) {
      mins = `${Math.floor((duration / 60))} menit`;
      if (duration % 60 !== 0) secs = ` dan ${Math.floor(duration % 60)} detik pelajaran`;
    }
    return mins + secs;
  };

  return (
    <div className='materi-gratis-free-material'>
      <div className='materi-gratis-free-material-container' onClick={() => {
        setPreviewLesson(lessonItem);
        setShowMaterialModal(true);
      }} >
        <div className='materi-gratis-free-material-header' onMouseOver={getGif} onMouseLeave={removegif}>
          {content && <Image src={content} width='100%' className='materi-gratis-free-material-header-image' />}
          {checkNewLesson(lessonItem.publishedTime) ? (
            <img src={NewLesson} className="materi-gratis-free-material-header-banner" />
          ) : ''}
        </div>
        <div className='materi-gratis-free-material-labels' >
            <div className='materi-gratis-free-material-labels-label' >
              <Label type='with-text'>
                {lessonItem && (
                  <EducationalLevel
                    type='educational-level-text'
                    educationalLevel={lessonItem.createdFor.educationalLevel['@id']}
                  />
                )}
              </Label>
            </div>
            <div className='materi-gratis-free-material-labels-label' >
              <Label type='with-text'>
                {lessonItem && lessonItem.createdFor.tags.map((tag) => (
                  <TagName tag={tag['@id']} key={tag['@id']} />
                ))}
              </Label>
            </div>
        </div>
        <span
          className='materi-gratis-free-material-title'
        >
          {lessonItem && lessonItem.createdFor.title}
        </span>
        <span className='materi-gratis-free-material-duration' >
          <span className='materi-gratis-free-material-play-btn' >
            <span className='materi-gratis-free-material-play-btn-play' />
          </span>
          {lessonItem && getDuration(lessonItem.video.duration)}
        </span>
        <span className='materi-gratis-free-material-total-questions' >
          <img src={File} alt="" className='materi-gratis-free-material-total-questions-img' />
          15 Q&A
        </span>
      </div>
    </div>
  );
};

export default FreeMaterialCard;
