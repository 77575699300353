import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import './EducationalLevel.scss';

function EducationalLevel(props) {
  const listOfeducationallevel = useSelector((state) => state.educationallevel.list);

  if (props.type === 'educational-level-text') {
    return (
      <>
        {listOfeducationallevel.retrieved
        // todo probably this check doesnt' needed ?
        && listOfeducationallevel.retrieved['hydra:member']
          .filter((educationallevel) => (educationallevel['@id'] === props.educationalLevel)).length > 0
        && listOfeducationallevel.retrieved['hydra:member']
          .filter((educationallevel) => (educationallevel['@id'] === props.educationalLevel)).shift().level}
      </>
    );
  }

  return (
    <Badge
        pill
        variant="light"
        className='educational-level'
    >
      {listOfeducationallevel.retrieved
      // todo probably this check doesnt' needed ?
      && listOfeducationallevel.retrieved['hydra:member']
        .filter((educationallevel) => (educationallevel['@id'] === props.educationalLevel)).length > 0
      && listOfeducationallevel.retrieved['hydra:member']
        .filter((educationallevel) => (educationallevel['@id'] === props.educationalLevel)).shift().level}
    </Badge>
  );
}

EducationalLevel.defaultProps = {
  type: ''
};

export default EducationalLevel;
