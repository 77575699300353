import { HASH_DECRYPT } from '../Constants/Constants';
import { decryptData } from '../Helpers/Common';
import { refreshToken } from './Auth';

export function Jwt({ dispatch, getState }) {
  return (next) => (action) => {
    // only worry about expiring token for async actions
    if (typeof action === 'function') {
      if (decryptData(localStorage.getItem('user'), HASH_DECRYPT)) {
        // decode jwt so that we know if and when it expires
        const tokenExpiration = decryptData(localStorage.getItem('user'), HASH_DECRYPT)
          && decryptData(localStorage.getItem('user'), HASH_DECRYPT).exp;
        if (tokenExpiration && Date.now() >= tokenExpiration * 1000) {
          // make sure we are not already refreshing the token
          if (!getState().auth.freshTokenPromise) {
            return refreshToken(dispatch).then(() => next(action));
          }
          return getState().auth.freshTokenPromise.then(() => next(action));
        }
      }
    }
    return next(action);
  };
}
