/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft, CardImage, PlusCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Col, Image, Row, ProgressBar } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { Dot } from 'react-animated-dots';
import { toast } from 'react-toastify';
import Button from '../../Components/Button/Button';
import Delete from '../../Assets/Images/delete.png';
// import DeleteWhite from '../../Assets/Images/icon_delete.png';
import iconFile from '../../Assets/Images/jpeg_icon.png';
import iconMP4 from '../../Assets/Images/ico-mp4.png';
import iconTxt from '../../Assets/Images/ico-txt.png';
import iconWav from '../../Assets/Images/ico-wav.png';
import iconZip from '../../Assets/Images/ico-zip.png';
import iconPdf from '../../Assets/Images/ico-pdf.png';
import cancelPreview from '../../Assets/Images/cancel-preview.png';
import questionPreview from '../../Assets/Images/question-preview.png';
import correctIcon from '../../Assets/Images/correct-icon.png';
import Textarea from '../../Components/Textarea/Textarea';
// import Image from '../../Assets/Images/image.png';
import Download from '../../Assets/Images/download.png';
import './AdminEditProposal.scss';
import { list as projectActionList, reset as projectActionResetList } from '../../Generated/actions/project/list';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset,
} from '../../Generated/actions/educationallevel/list';
import Loading from '../../Components/Loading/Loading';
import { submitQuestion, uploadToS3, uploadImageToS3 } from '../../Actions/Question';
import {
  AWS_WATERMARK_API_KEY,
  AWS_WATERMARK_URL,
  LESSON_ANSWER_URL,
  LESSON_QUESTION_URL,
  LESSON_URL,
} from '../../Constants/Constants';
import PreviewLessonModal from '../../Components/PreviewLessonModal/PreviewLessonModal';
import WarningModal from '../../Components/Modal/WarningModal';
import {
  answerField,
  multipleAnswerField,
  dropdownCreateQuestion,
  generateLessonQuestion,
  generateQuestionData,
  generateLessonAnswerTrueOrFalse,
  generateLessonAnswerMultipleChoice,
} from '../../Helpers/StaticField';
import LessonQuestionForm from '../../Components/LessonQuestionForm/LessonQuestionForm';
import { getFileExt } from '../../Helpers/Common';

const AdminEditProposal = (props) => {
  // States
  const dispatch = useDispatch();
  const history = useHistory();
  const projectList = useSelector((state) => state.project.list);
  const listOfEducationalLevel = useSelector((state) => state.educationallevel.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const questions = useSelector((state) => state.questions);
  const movieBahasaFile = useRef();
  const iconFileRef = useRef();
  const zipBahasaFile = useRef();
  const audioBahasaFile = useRef();
  const titleBahasaFile = useRef();
  const imageSummaryBahasaFile = useRef();
  const movieEnglishFile = useRef();
  const zipEnglishFile = useRef();
  const audioEnglishFile = useRef();
  const titleEnglishFile = useRef();
  const [fileProgress, setFileProgress] = useState(0);
  const [lessonFile, setLessonFile] = useState({
    mp4File: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
    zipFile: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
    audioFile: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
    titleFile: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
    iconFile: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
    imageSummaryFile: {
      english: {
        name: '',
        contentUrl: '',
      },
      bahasa: {
        name: '',
        contentUrl: '',
      },
    },
  });
  const [loadingFile, setLoadingFile] = useState(false);
  const [errorFile, setErrorFile] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [previewContent, setPreviewContent] = useState(0);
  const [previewCorrectAnswer, setPreviewCorrectAnswer] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [lessonDataEn, setLessonDataEn] = useState();
  const [lessonData, setLessonData] = useState();
  const [questionsData, setQuestionsData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingLesson, setLoadingLesson] = useState(false);
  const [loadingType, setLoadingType] = useState();

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    const getLessonData = async () => {
      setLoading(true);
      const { data } = await axios.get(`${LESSON_URL}/${props.match.params.page}`);
      setLessonData(data);
      setQuestionsData(data.hasParts);
      const { data: dataEn } = await axios.get(`${LESSON_URL}/${props.match.params.page}`, {
        headers: {
          'X-LOCALE': 'en',
        },
      });

      // Update question image english version
      const questionsBahasa = data.hasParts.sort(compare);
      const questionsEn = dataEn.hasParts.sort(compare);
      for (let i = 0; i < questionsBahasa.length; i++) {
        // Check if question image english version is null
        if (questionsEn[i].image) {
          if (!questionsEn[i].image.contentUrl) {
            // eslint-disable-next-line no-await-in-loop
            await axios({
              method: 'PATCH',
              url: questionsEn[i].image['@id'],
              data: {
                name: questionsBahasa[i].image.name,
                url: questionsBahasa[i].image.contentUrl,
              },
              headers: {
                'X-LOCALE': 'en',
                'Content-Type': 'application/merge-patch+json',
              },
            });
          }
        }
      }

      // Update True False Question Translations
      const trueFalseQuestions = dataEn.hasParts.filter((q) => q.type === 'TYPE_TRUE_OR_FALSE');
      for (let index = 0; index < trueFalseQuestions.length; index++) {
        const answers = trueFalseQuestions[index].connectedAnswers;
        for (let i = 0; i < answers.length; i++) {
          const answer = answers[i];
          if (!answer.description) {
            // eslint-disable-next-line no-await-in-loop
            await axios.put(
              answer['@id'],
              {
                description: answer.ordering === 1 ? 'True' : 'False',
              },
              {
                headers: {
                  'X-LOCALE': 'en',
                },
              }
            );
          }
        }
      }

      setLessonDataEn(dataEn);
      setLoading(false);
    };
    getLessonData();
  }, []);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  useEffect(() => {
    dispatch(educationalLevelActionList('/educational_levels?order%5Bordering%5D=asc&pagination=false'));
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);

  useEffect(() => {
    // Get List Project
    dispatch(projectActionList());
    return () => {
      dispatch(projectActionResetList(projectList.eventSource));
    };
  }, []);

  useEffect(() => {
    const getFiles = async () => {
      // Get Video, Archive, Audio
      if (lessonData && lessonDataEn) {
        setLessonFile((prevState) => ({
          ...prevState,
          mp4File: {
            bahasa: {
              contentUrl: lessonData.video ? lessonData.video.contentUrl : '',
              name: lessonData.video ? lessonData.video.name : '',
            },
            english: {
              contentUrl: lessonDataEn.video ? lessonDataEn.video.contentUrl : '',
              name: lessonDataEn.video ? lessonDataEn.video.name : '',
            },
          },
          zipFile: {
            bahasa: {
              contentUrl: lessonData.archive ? lessonData.archive.contentUrl : '',
              name: lessonData.archive ? lessonData.archive.name : '',
            },
            english: {
              contentUrl: lessonDataEn.archive ? lessonDataEn.archive.contentUrl : '',
              name: lessonDataEn.archive ? lessonDataEn.archive.name : '',
            },
          },
          audioFile: {
            bahasa: {
              contentUrl: lessonData.audio.length > 0 ? lessonData.audio[0].contentUrl : '',
              name: lessonData.audio.length > 0 ? lessonData.audio[0].name : '',
            },
            english: {
              contentUrl: lessonDataEn.audio.length > 0 ? lessonDataEn.audio[0].contentUrl : '',
              name: lessonDataEn.audio.length > 0 ? lessonDataEn.audio[0].name : '',
            },
          },
          titleFile: {
            bahasa: {
              contentUrl: lessonData.voTitle ? lessonData.voTitle.contentUrl : '',
              name: lessonData.voTitle ? lessonData.voTitle.name : '',
            },
            english: {
              contentUrl: lessonDataEn.voTitle ? lessonDataEn.voTitle.contentUrl : '',
              name: lessonDataEn.voTitle ? lessonDataEn.voTitle.name : '',
            },
          },
          iconFile: {
            bahasa: {
              contentUrl: lessonData.icon ? lessonData.icon.contentUrl : '',
              name: lessonData.icon ? lessonData.icon.name : '',
            },
            english: {
              contentUrl: lessonData.icon ? lessonData.icon.contentUrl : '',
              name: lessonData.icon ? lessonData.icon.name : '',
            },
          },
          imageSummaryFile: {
            bahasa: {
              contentUrl: lessonData.imageSummary ? lessonData.imageSummary.contentUrl : '',
              name: lessonData.imageSummary ? lessonData.imageSummary.name : '',
            },
            english: {
              contentUrl: lessonData.imageSummary ? lessonData.imageSummary.contentUrl : '',
              name: lessonData.imageSummary ? lessonData.imageSummary.name : '',
            },
          },
        }));
      }
    };
    getFiles();
  }, [lessonData, lessonDataEn]);

  useEffect(() => {
    if (questionsData) {
      const completeQuestionData =
        questionsData &&
        questionsData.sort(compare).map((filteredQuestion) => ({
          ...filteredQuestion,
          description: filteredQuestion.description || '',
          answerKey: filteredQuestion.answerKey || '',
          visualBrief: filteredQuestion.visualBrief || '',
          answers: filteredQuestion.connectedAnswers,
          answerPreview: multipleAnswerField,
          image: filteredQuestion.image || null,
        }));
      dispatch(submitQuestion(completeQuestionData));
    }
  }, [questionsData]);

  useEffect(() => {
    if (questions[previewContent] && questions[previewContent].image) {
      setPreviewImage(questions[previewContent].image.contentUrl);
    } else {
      setPreviewImage(null);
    }
  }, [previewContent, questions[previewContent], questions]);

  useEffect(() => {
    const previewSection = document.getElementById('fixed-wrapper');
    const stickyPreview = previewSection.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset >= stickyPreview + 1000) {
        previewSection.classList.add('fixed');
      }
      if (window.pageYOffset <= stickyPreview + 1000) {
        previewSection.classList.remove('fixed');
      }
    });

    return () => {
      window.removeEventListener('scroll', () => scrollCallBack);
    };
  }, []);

  const [voiceOverText, setVoiceOverText] = useState({
    bahasa: '',
    english: '',
  });
  const [lessonSummaryText, setLessonSummaryText] = useState({
    bahasa: '',
    english: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (lessonData && lessonDataEn) {
      setVoiceOverText((prevState) => ({
        ...prevState,
        bahasa: lessonData.transcript || '',
        english: lessonDataEn.transcript || '',
      }));
      setLessonSummaryText((prevState) => ({
        ...prevState,
        bahasa: lessonData.summary || '',
        english: lessonDataEn.summary || '',
      }));
    }
  }, [lessonData, lessonDataEn]);

  // Functions

  const handleGenerateTrueOrFalseAnswer = async (creator, questionId) => {
    const generatedAnswer = generateLessonAnswerTrueOrFalse(creator, questionId);
    try {
      const { data: firstAnswer } = await axios.post(LESSON_ANSWER_URL, generatedAnswer[0]);
      const { data: secondAnswer } = await axios.post(LESSON_ANSWER_URL, generatedAnswer[1]);
      const firstAnswerData = firstAnswer;
      const secondAnswerData = secondAnswer;
      return {
        firstAnswerData,
        secondAnswerData,
      };
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleGenerateMultipleChoiceAnswer = async (creator, questionId) => {
    const generatedAnswer = generateLessonAnswerMultipleChoice(creator, questionId);
    try {
      const { data: firstAnswer } = await axios.post(`${LESSON_ANSWER_URL}`, generatedAnswer[0]);
      const { data: secondAnswer } = await axios.post(`${LESSON_ANSWER_URL}`, generatedAnswer[1]);
      const { data: thirdAnswer } = await axios.post(`${LESSON_ANSWER_URL}`, generatedAnswer[2]);
      const { data: fourthAnswer } = await axios.post(`${LESSON_ANSWER_URL}`, generatedAnswer[3]);
      const firstAnswerData = firstAnswer;
      const secondAnswerData = secondAnswer;
      const thirdAnswerData = thirdAnswer;
      const fourthAnswerData = fourthAnswer;
      return {
        firstAnswerData,
        secondAnswerData,
        thirdAnswerData,
        fourthAnswerData,
      };
    } catch (error) {
      throw new Error(error);
    }
  };

  const generateTrueOrFalse = async (createdQuestion, creator, questionId) => {
    const { firstAnswerData, secondAnswerData } = await handleGenerateTrueOrFalseAnswer(creator, questionId);
    return {
      ...createdQuestion,
      connectedAnswers: [firstAnswerData, secondAnswerData],
    };
  };

  const generateMultipleAnswer = async (createdQuestion, creator, questionId) => {
    const { firstAnswerData, secondAnswerData, thirdAnswerData, fourthAnswerData } =
      await handleGenerateMultipleChoiceAnswer(creator, questionId);
    return {
      ...createdQuestion,
      connectedAnswers: [firstAnswerData, secondAnswerData, thirdAnswerData, fourthAnswerData],
    };
  };

  const generateAnswer = async (type, createdQuestion, generatedQuestionData) => {
    if (type === 'TYPE_TRUE_OR_FALSE') {
      return await generateTrueOrFalse(createdQuestion, generatedQuestionData.creator, generatedQuestionData.id);
    }
    if (type === 'TYPE_MULTIPLE_CHOICE') {
      return await generateMultipleAnswer(createdQuestion, generatedQuestionData.creator, generatedQuestionData.id);
    }
    if (type === 'TYPE_FILL_THE_BLANK') {
      return {
        ...createdQuestion,
      };
    }
    return null;
  };

  const handleGenerateQuestion = async (type) => {
    let listOfQuestion = [...questions];
    const isPartOfId = `/lessons/${props.match.params.page}`;
    const createdQuestion = generateLessonQuestion(isPartOfId, type);
    const generatedQuestionData = generateQuestionData(createdQuestion, type, listOfQuestion.length + 1);
    try {
      await axios.post(LESSON_QUESTION_URL, generatedQuestionData);
      const questionData = await generateAnswer(type, createdQuestion, generatedQuestionData);
      listOfQuestion = [...listOfQuestion, questionData];
      dispatch(submitQuestion(listOfQuestion));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const onContentChange = (lang, value, type) => {
    if (type === 'transcript') {
      setVoiceOverText((prevState) => ({
        ...prevState,
        [lang]: value,
      }));
    } else if (type === 'summary') {
      setLessonSummaryText((prevState) => ({
        ...prevState,
        [lang]: value,
      }));
    }
  };

  const generateBlobURL = (data, fileName) => {
    const downloadFile = window.URL.createObjectURL(data);
    const fileURL = document.createElement('a');
    fileURL.href = downloadFile;
    fileURL.setAttribute('download', fileName);
    document.body.appendChild(fileURL);
    return fileURL.click();
  };

  const handleGetFile = async (fileURL, fileName) => {
    try {
      const data = await fetch(fileURL, {
        method: 'get',
      });
      const blob = await data.blob();
      return generateBlobURL(blob, fileName);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const handleUploadFile = async (type, lang) => {
    let inputRef = null;
    if (type === 'video') {
      inputRef = lang === 'bahasa' ? movieBahasaFile : movieEnglishFile;
    }
    if (type === 'archive') {
      inputRef = lang === 'bahasa' ? zipBahasaFile : zipEnglishFile;
    }
    if (type === 'audio') {
      inputRef = lang === 'bahasa' ? audioBahasaFile : audioEnglishFile;
    }
    if (type === 'title') {
      inputRef = lang === 'bahasa' ? titleBahasaFile : titleEnglishFile;
    }
    if (type === 'image') {
      inputRef = iconFileRef;
    }
    if (type === 'imageSummary') {
      inputRef = imageSummaryBahasaFile;
    }
    return inputRef.current.click();
  };

  const handleDownloadFile = async (typeUpload, lang) => {
    if (typeUpload === 'video') {
      const { contentUrl, name } = lessonFile.mp4File[lang];
      await handleGetFile(contentUrl, name);
    }
    if (typeUpload === 'archive') {
      const { contentUrl, name } = lessonFile.zipFile[lang];
      await handleGetFile(contentUrl, name);
    }
    if (typeUpload === 'audio') {
      const { contentUrl, name } = lessonFile.audioFile[lang];
      await handleGetFile(contentUrl, name);
    }
    if (typeUpload === 'title') {
      const { contentUrl, name } = lessonFile.titleFile[lang];
      await handleGetFile(contentUrl, name);
    }
    if (typeUpload === 'image') {
      const { contentUrl, name } = lessonFile.iconFile[lang];
      await handleGetFile(contentUrl, name);
    }
    if (typeUpload === 'imageSummary') {
      const { contentUrl, name } = lessonFile.imageSummaryFile[lang];
      await handleGetFile(contentUrl, name);
    }
  };

  const handleUpdateUpload = async (typeUpload, uploadedData, files, lang) => {
    const updatedState = {
      name: files[0].name,
      contentUrl: uploadedData.contentUrl || '',
    };
    const header = lang === 'english' && {
      headers: {
        'X-LOCALE': 'en',
      },
    };
    try {
      if (typeUpload === 'video') {
        setLessonFile((prevState) => ({
          ...prevState,
          mp4File: {
            ...prevState.mp4File,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          video: uploadedData['@id'],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
      }
      if (typeUpload === 'image') {
        setLessonFile((prevState) => ({
          ...prevState,
          iconFile: {
            ...prevState.zipFile,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          icon: uploadedData['@id'],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
      }
      if (typeUpload === 'archive') {
        setLessonFile((prevState) => ({
          ...prevState,
          zipFile: {
            ...prevState.zipFile,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          archive: uploadedData['@id'],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
      }
      if (typeUpload === 'audio') {
        setLessonFile((prevState) => ({
          ...prevState,
          audioFile: {
            ...prevState.audioFile,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          audio: [uploadedData['@id']],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
        if (lessonData.audio.length === 0) {
          const updateLessonData = {
            ...lessonData,
            audio: [uploadedData],
          };
          setLessonData(updateLessonData);
        }
      }
      if (typeUpload === 'title') {
        setLessonFile((prevState) => ({
          ...prevState,
          titleFile: {
            ...prevState.titleFile,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          voTitle: uploadedData['@id'],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
        const updateLessonData = {
          ...lessonData,
          voTitle: uploadedData,
        };
        if (!lessonData.voTitle) {
          setLessonData(updateLessonData);
        }
      }
      if (typeUpload === 'imageSummary') {
        setLessonFile((prevState) => ({
          ...prevState,
          imageSummaryFile: {
            ...prevState.imageSummaryFile,
            [lang]: updatedState,
          },
        }));
        const updatedData = {
          imageSummary: uploadedData['@id'],
        };
        await axios.put(`${LESSON_URL}/${props.match.params.page}`, updatedData, header);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const validateExtensions = (ext, type) => {
    if (type === 'audio') {
      return ext === 'wav' || ext === 'mp3';
    }
    if (type === 'archive') {
      return ext === 'zip' || ext === 'rar';
    }
    if (type === 'video') {
      return ext === 'mp4';
    }
    if (type === 'title') {
      return ext === 'wav' || ext === 'mp3';
    }
    if (type === 'image' || type === 'imageSummary') {
      return ext === 'png' || ext === 'jpg' || ext === 'jpeg';
    }
    return false;
  };

  const fileExistsData = (type) => {
    let custom = null;
    let fileId = null;
    if (type === 'video') {
      fileId = lessonData.video ? lessonData.video['@id'] : null;
    }
    if (type === 'audio') {
      fileId = lessonData.audio.length > 0 ? lessonData.audio[0]['@id'] : null;
    }
    if (type === 'archive') {
      fileId = lessonData.archive ? lessonData.archive['@id'] : null;
    }
    if (type === 'title') {
      fileId = lessonData.voTitle ? lessonData.voTitle['@id'] : null;
    }
    if (fileId) {
      custom = {
        method: 'PATCH',
        id: fileId,
      };
    } else {
      custom = {
        method: 'POST',
      };
    }
    return custom;
  };

  const triggerWatermark = async (uploadedData) => {
    const request = {
      topic: {
        id: lessonData.createdFor.id,
        name: lessonData.createdFor.title,
      },
      video: {
        id: uploadedData.id,
        file_path: uploadedData.contentUrl,
      },
      lang: 'id',
      video_version: 'sahabat_pintar',
    };

    const uninterceptedAxiosInstance = axios.create();
    await uninterceptedAxiosInstance.post(AWS_WATERMARK_URL, request, {
      headers: {
        'X-Api-Key': AWS_WATERMARK_API_KEY,
        'Content-Type': 'application/json',
      },
    });
  };

  const handleOnChangeFile = async (event, typeUpload, lang) => {
    try {
      setLoadingType();
      setLoadingFile(true);
      setErrorFile();
      event.preventDefault();
      event.persist();
      event.stopPropagation();
      const {
        target: { files },
      } = event;
      let fileType;
      if (!isEmpty(files)) {
        const fileExt = getFileExt(files[0].name);
        fileType = {
          fileExtension: fileExt,
        };
      }
      if (validateExtensions(fileType.fileExtension, typeUpload)) {
        // Custom data/config base on file exists
        const custom = fileExistsData(typeUpload);

        const header = {
          'X-LOCALE': lang === 'english' ? 'en' : 'id',
        };
        if (custom.method === 'PATCH') {
          header['Content-Type'] = 'application/merge-patch+json';
        }
        setLoadingType(`${typeUpload}${lang}`);
        let uploadedData = await dispatch(uploadToS3(typeUpload, fileType, files[0], setFileProgress, header, custom));
        if (typeUpload === 'image' || typeUpload === 'imageSummary') {
          uploadedData = await dispatch(uploadImageToS3(fileType, files[0], header, custom));
        }
        if (uploadedData !== null) {
          await handleUpdateUpload(typeUpload, uploadedData, files, lang);

          if (typeUpload === 'video') {
            await triggerWatermark(uploadedData);
          }
        }
      } else {
        setErrorFile({
          type: typeUpload,
          message: 'Wrong File Type!',
        });
      }
    } catch (error) {
      setErrorFile({
        type: typeUpload,
        message: 'Something Wrong!',
      });
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
    setLoadingType();
  };

  const handleSubmitText = async (lang, type) => {
    try {
      if (lang === 'bahasa') {
        if (type === 'transcript') {
          const transcriptData = {
            transcript: voiceOverText.bahasa,
          };
          await axios.put(`${LESSON_URL}/${props.match.params.page}`, transcriptData);
        } else if (type === 'summary') {
          const summaryData = {
            summary: lessonSummaryText.bahasa,
          };
          await axios.put(`${LESSON_URL}/${props.match.params.page}`, summaryData);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (type === 'transcript') {
          const transcriptData = {
            transcript: voiceOverText.english,
          };
          await axios.put(`${LESSON_URL}/${props.match.params.page}`, transcriptData, {
            headers: {
              'X-LOCALE': 'en',
            },
          });
        } else if (type === 'summary') {
          const summaryData = {
            summary: lessonSummaryText.english,
          };
          await axios.put(`${LESSON_URL}/${props.match.params.page}`, summaryData, {
            headers: {
              'X-LOCALE': 'en',
            },
          });
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const publishLesson = async () => {
    let valid = true;
    if (
      lessonFile.audioFile.name === '' ||
      lessonFile.mp4File.name === '' ||
      lessonFile.zipFile.name === '' ||
      lessonFile.titleFile.name === ''
    ) {
      valid = false;
    }
    if (valid) {
      await axios.put(`${LESSON_URL}/${props.match.params.page}/publish`, {});
      history.push('/admin/lesson-bank');
    } else {
      setShowWarning(true);
      setShowPreviewModal(false);
    }
  };

  // Render Functions

  const renderHeader = () => {
    return (
      <div className='admin-edit-proposal-header'>
        <ArrowLeft className='admin-edit-proposal-header-arrow' onClick={() => history.goBack()} />
        <span className='admin-edit-proposal-header-title'>
          <span>{lessonData && lessonData.createdFor.title}</span>
          <span>by {lessonData && lessonData.creator.name}</span>
        </span>
        <div className='admin-edit-proposal-header-btn'>
          <Button onClick={() => setShowPreviewModal(true)}>Preview Lesson</Button>
        </div>
        <img src={Delete} alt='' className='admin-edit-proposal-header-delete' />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className='admin-edit-proposal-title'>
        <img src={iconFile} alt='' />
        <span>
          Proposal <br /> Pelajaran
        </span>
      </div>
    );
  };

  const renderButton = (type, file, lang) => {
    return (
      <>
        <div className='admin-edit-proposal-audio-field-container'>
          <div style={{ width: '95%' }}>
            {fileProgress !== 0 && fileProgress !== 100 && loadingType === `${type}${lang}` && (
              <ProgressBar animated now={fileProgress} variant='danger' />
            )}
          </div>
          <Button disabled={loadingFile} block onClick={() => handleUploadFile(type, lang)}>
            {!isEmpty(file.name) ? (
              file.name
            ) : loadingFile ? (
              <>
                Loading <Dot>.</Dot>
                <Dot>.</Dot>
                <Dot>.</Dot>
              </>
            ) : (
              <>
                <CardImage /> Pilih file
              </>
            )}
          </Button>
          {errorFile && (
            <span className='admin-edit-proposal-audio-field-container-error'>
              {errorFile.type === type && errorFile.message}
            </span>
          )}
        </div>
        <button
          disabled={isEmpty(file.name)}
          className='admin-edit-proposal-input-btn'
          onClick={() => handleDownloadFile(type, lang)}
        >
          <img src={Download} alt='' />
        </button>
      </>
    );
  };

  const Title = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconWav} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Title</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>Wav or MP3</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.titleFile.bahasa.name) && 'filled-button'}
            `}
          >
            <input
              type='file'
              ref={titleBahasaFile}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'title', 'bahasa')}
            />
            {renderButton('title', lessonFile.titleFile.bahasa, 'bahasa')}
          </div>

          <div
            className={`admin-edit-proposal-audio-field ${
              !isEmpty(lessonFile.titleFile.english.name) && 'filled-button'
            }
            `}
          ></div>
        </div>
      </div>
    );
  };

  const Audio = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconWav} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Audio</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>Wav or MP3</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.audioFile.bahasa.name) && 'filled-button'}
            `}
          >
            <input
              type='file'
              ref={audioBahasaFile}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'audio', 'bahasa')}
            />
            {renderButton('audio', lessonFile.audioFile.bahasa, 'bahasa')}
          </div>

          <div
            className={`admin-edit-proposal-audio-field ${
              !isEmpty(lessonFile.audioFile.english.name) && 'filled-button'
            }
            `}
          ></div>
        </div>
      </div>
    );
  };

  const AeFolder = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconZip} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>A E Folder</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>Zip File</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.zipFile.bahasa.name) && 'filled-button'}`}
          >
            <input
              type='file'
              ref={zipBahasaFile}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'archive', 'bahasa')}
            />
            {renderButton('archive', lessonFile.zipFile.bahasa, 'bahasa')}
          </div>

          <div
            className={`admin-edit-proposal-audio-field ${
              !isEmpty(lessonFile.zipFile.english.name) && 'filled-button'
            }`}
          ></div>
        </div>
      </div>
    );
  };

  const Movie = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconMP4} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Movie</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>MP4 or Mov</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.mp4File.bahasa.name) && 'filled-button'}
          `}
          >
            <input
              type='file'
              ref={movieBahasaFile}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'video', 'bahasa')}
            />
            {renderButton('video', lessonFile.mp4File.bahasa, 'bahasa')}
          </div>

          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.mp4File.english.name) && 'filled-button'}
          `}
          ></div>
        </div>
      </div>
    );
  };

  const TeksPanduan = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconPdf} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Text</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>Docx or PDF</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div className='admin-edit-proposal-audio-field'>
            <div className='text-download'>
              <Button block onClick={() => handleGetFile(lessonData.document.contentUrl, lessonData.document.name)}>
                <img src={Download} alt='' />
                Download Teks Panduan
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const IconField = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconFile} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Icon</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>PNG or JPEG/JPG</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${!isEmpty(lessonFile.iconFile.bahasa.name) && 'filled-button'}
          `}
          >
            <input
              type='file'
              ref={iconFileRef}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'image', 'bahasa')}
            />
            {renderButton('image', lessonFile.iconFile.bahasa, 'bahasa')}
          </div>
        </div>
      </div>
    );
  };

  const ImageSummaryField = () => {
    return (
      <div className='admin-edit-proposal-audio'>
        <img src={iconFile} alt='' className='admin-edit-proposal-voice-over-img' />
        <div className='admin-edit-proposal-voice-over-labels'>
          <span className='admin-edit-proposal-voice-over-labels-main'>Image Summary</span>
          <span className='admin-edit-proposal-voice-over-labels-sub'>PNG or JPEG/JPG</span>
        </div>

        <div className='admin-edit-proposal-voice-over-fields'>
          <div
            className={`admin-edit-proposal-audio-field ${
              !isEmpty(lessonFile.imageSummaryFile.bahasa.name) && 'filled-button'
            }
          `}
          >
            <input
              type='file'
              ref={imageSummaryBahasaFile}
              style={{ display: 'none' }}
              onChange={(event) => handleOnChangeFile(event, 'imageSummary', 'bahasa')}
            />
            {renderButton('imageSummary', lessonFile.imageSummaryFile.bahasa, 'bahasa')}
          </div>
        </div>
      </div>
    );
  };

  const renderLessonContent = () => {
    return (
      <div className='admin-edit-proposal-lesson-content'>
        <div className='question-answer-lesson-content-desc'>
          <h2>#1. Lesson data</h2> <div className='question-answer-lesson-content-desc-line' />
        </div>

        <div className='admin-edit-proposal-voice-over'>
          <img src={iconTxt} alt='' className='admin-edit-proposal-voice-over-img' />
          <div className='admin-edit-proposal-voice-over-labels'>
            <span className='admin-edit-proposal-voice-over-labels-main'>V-over</span>
            <span className='admin-edit-proposal-voice-over-labels-sub'>Text form</span>
          </div>

          <div className='admin-edit-proposal-voice-over-fields'>
            <div className='admin-edit-proposal-voice-over-field'>
              <span className='admin-edit-proposal-voice-over-field-lang bahasa'>BAHASA INDONESIA</span>
              <Textarea
                rows={10}
                maxLength={10000}
                value={voiceOverText.bahasa}
                onChange={(e) => onContentChange('bahasa', e.target.value, 'transcript')}
                onBlur={() => handleSubmitText('bahasa', 'transcript')}
              />
            </div>

            <div className='admin-edit-proposal-voice-over-field'></div>
          </div>
        </div>

        <div className='admin-edit-proposal-voice-over'>
          <img src={iconTxt} alt='' className='admin-edit-proposal-voice-over-img' />
          <div className='admin-edit-proposal-voice-over-labels'>
            <span className='admin-edit-proposal-voice-over-labels-main'>Lesson Summary</span>
            <span className='admin-edit-proposal-voice-over-labels-sub'>Text form</span>
          </div>

          <div className='admin-edit-proposal-voice-over-fields'>
            <div className='admin-edit-proposal-voice-over-field'>
              <Textarea
                rows={10}
                maxLength={10000}
                value={lessonSummaryText.bahasa}
                onChange={(e) => onContentChange('bahasa', e.target.value, 'summary')}
                onBlur={() => handleSubmitText('bahasa', 'summary')}
              />
            </div>

            <div className='admin-edit-proposal-voice-over-field'></div>
          </div>
        </div>

        <Title />
        <Audio />
        <AeFolder />
        <Movie />
        <TeksPanduan />
        <IconField />
        <ImageSummaryField />
      </div>
    );
  };

  const renderCorrectAnswerPreview = () => {
    return (
      questions.length !== 0 &&
      previewCorrectAnswer !== undefined && (
        <div className='question-answer-content-preview-mask' id='question-answer-content-preview-mask'>
          <div className='question-answer-content-preview-mask-text'>
            <h1>Yay</h1>
          </div>
          <div className='question-answer-content-preview-mask-icon'>
            <Image src={correctIcon} />
          </div>
          <div className='question-answer-content-preview-mask-description'>
            <h1>Correct</h1>
            <div className='question-answer-content-preview-mask-key'>
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={70} autoHide={false}>
                <p className='display-line-break'>{questions[previewContent].answerKey}</p>
              </Scrollbars>
            </div>
            <div
              className='question-answer-content-preview-mask-button'
              onClick={() => setPreviewCorrectAnswer(undefined)}
            >
              <div className='question-answer-content-preview-mask-button-image'>Close</div>
            </div>
            <span>Report to Us</span>
          </div>
        </div>
      )
    );
  };

  const renderQuestionPreview = () => {
    return (
      <div id='fixed-wrapper'>
        <div
          className='question-answer-content-preview'
          id='question-answer-content-preview'
          style={{ backgroundImage: `url(${previewImage && previewImage})` }}
        >
          <div className='question-answer-content-wrapper'>
            {questions.length !== 0 && (
              <div className='question-answer-content-preview-header'>
                <div className='question-answer-content-preview-header-ask'>
                  <Image src={questionPreview} />
                </div>
                <div className='question-answer-content-preview-header-progress'>
                  <span style={{ color: '#FF9908', marginBottom: '8px' }}>Questions Answered</span>
                  <span style={{ color: '#000', background: '#BDBDBD' }}>
                    {previewContent + 1}/{questions.length}
                  </span>
                </div>
                <div className='question-answer-content-preview-header-cancel'>
                  <Image src={cancelPreview} />
                </div>
              </div>
            )}
            {questions.length !== 0 && (
              <div className='question-answer-content-preview-content'>
                <h1 className='display-line-break'>{questions[previewContent].description}</h1>
              </div>
            )}
            <div className='question-answer-content-preview-footer'>
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_TRUE_OR_FALSE' &&
                answerField(questions, previewContent).renderTrueOrFalse}
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_MULTIPLE_CHOICE' &&
                answerField(questions, previewContent).renderMultipleChoice}
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_FILL_THE_BLANK' &&
                answerField(questions, previewContent).renderFillTheBlank}
            </div>
          </div>
        </div>
        {renderCorrectAnswerPreview()}
      </div>
    );
  };

  const renderQuestionContent = () => {
    return (
      <div className='admin-edit-proposal-lesson-content'>
        <div className='question-answer-lesson-content-desc'>
          <h2>#2. Questions Answers</h2> <div className='question-answer-lesson-content-desc-line' />
        </div>

        <Row>
          <Col xs={8}>
            <div className='admin-edit-proposal-questions'>
              {!isEmpty(questions) &&
                questions.map((question, index) => {
                  return (
                    <LessonQuestionForm
                      key={question.id}
                      questionData={question}
                      questionIndex={index}
                      previewContent={previewContent}
                      setPreviewContent={setPreviewContent}
                      setPreviewCorrectAnswer={setPreviewCorrectAnswer}
                      setLoading={setLoadingLesson}
                    />
                  );
                })}
            </div>
            <div className='question-answer-content-create'>
              <Button
                type='dropdown'
                icon={<PlusCircleFill />}
                dropdownItem={dropdownCreateQuestion(handleGenerateQuestion)}
              >
                Buat Soal Baru
              </Button>
            </div>
          </Col>
          <Col xs={4}>{renderQuestionPreview()}</Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      {loading || loadingModal || loadingLesson ? <Loading /> : null}
      {renderHeader()}
      {renderTitle()}
      {renderLessonContent()}
      {renderQuestionContent()}
      <PreviewLessonModal
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        previewProposal={lessonData && lessonData}
        listProject={projectList.retrieved && projectList.retrieved['hydra:member']}
        publishLesson={publishLesson}
        setLoadingScreen={setLoadingModal}
      />
      <WarningModal setShowModal={setShowWarning} showModal={showWarning}>
        <h4 className='text-danger'>All field must be filled!</h4>
      </WarningModal>
    </div>
  );
};

export default AdminEditProposal;
