import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-bootstrap-icons';
import {
  Carousel, Col, Image,
  Modal, Row
} from 'react-bootstrap';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Button from '../Button/Button';
import './FreeMaterialModal.scss';
import downloadIcon from '../../Assets/Images/download.png';
import iconBook from '../../Assets/Images/ico-book.png';
import prevIcon from '../../Assets/Images/icon_arrow_left.png';
import nextIcon from '../../Assets/Images/icon_arrow_right.png';
import Label from '../Label/Label';
import { multipleAnswerField } from '../../Helpers/StaticField';
import SmallQuestionPreviewMateriGratis from '../SmallQuestionPreviewMateriGratis/SmallQuestionPreviewMateriGratis';
import { submitQuestion } from '../../Actions/Question';
import GetThemeAndSubThemeNameFormObject from '../DataHelpers/GetThemeAndSubThemeNameFormObject';
import EducationalLevel from '../EducationalLevel/EducationalLevel';
import TagName from '../Tags/TagName';

const FreeMaterialModal = (props) => {
  const {
    isShowMaterial,
    setIsShowMaterial,
    onDownloadClick,
    previewLesson,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [videoUrl, setVideoUrl] = useState(null);
  const [project, setProject] = useState();
  const [slideIndex, setSlideIndex] = useState(0);
  const [lessonData, setLessonData] = useState();

  const [activePanel, setActivePanel] = useState('movie');

  const questions = useSelector((state) => state.questions);

  useEffect(() => {
    setActivePanel('movie');
    const getLessonData = async () => {
      if (isShowMaterial && previewLesson) {
        const { data } = await axios.get(previewLesson['@id']);
        setLessonData(data);
      }
    };
    getLessonData();
  }, [isShowMaterial, previewLesson]);

  useEffect(() => {
    if (previewLesson) {
      const subtheme = previewLesson.createdFor.subtheme ? previewLesson.createdFor.subtheme['@id'] : null;
      const projectItem = {
        ...previewLesson.createdFor,
        subtheme
      };
      setProject(projectItem);
    }
    if (previewLesson && previewLesson.derivativeForSahabatPintar) {
      setVideoUrl(previewLesson.derivativeForSahabatPintar.contentUrl);
    } else if (previewLesson && previewLesson.video !== undefined) {
      setVideoUrl(previewLesson.video.contentUrl);
    }
  }, [previewLesson]);

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    if (lessonData) {
      const completeQuestionData = lessonData
        && lessonData.hasParts
          .sort(compare)
          .map((filteredQuestion) => ({
            ...filteredQuestion,
            description: filteredQuestion.description || '',
            answerKey: filteredQuestion.answerKey || '',
            visualBrief: filteredQuestion.visualBrief || '',
            answers: filteredQuestion.connectedAnswers,
            answerPreview: multipleAnswerField,
            image: filteredQuestion.image || null
          }));
      dispatch(submitQuestion(completeQuestionData));
    }
  }, [lessonData]);

  const handleCloseMaterial = () => {
    setIsShowMaterial(false);
    setSlideIndex(0);
  };

  const handleOnSelect = (indexValue) => {
    setSlideIndex(indexValue);
  };

  const renderLessonVideo = () => {
    return (
      <Row style={{ margin: '20px' }}>
        <Col xs={12}>
          <ReactPlayer url={videoUrl} controls={true} autosize width="100%" />
          <Link to={{ pathname: videoUrl }} target="_blank" className="btn btn-download" download={true}>
            <img src={downloadIcon} />
            Download
          </Link>
        </Col>
      </Row>
    );
  };

  const renderLessonDescription = () => {
    return (
      <Row>
        <Col xs={12}>
          <div className='free-material__lesson-title'>
            <Row>
              <Col xs={2}>
                <Image src={iconBook} width='64' />
              </Col>
              <Col xs={10}>
                <h1>Rangkuman Pelajaran</h1>
                <Label type='with-text'>
                  {lessonData && (
                    <EducationalLevel
                      type='educational-level-text'
                      educationalLevel={lessonData.createdFor.educationalLevel['@id']}
                    />
                  )}
                </Label>
                <Label type='with-text'>
                  {lessonData && lessonData.createdFor.tags.map((tag) => (
                    <TagName tag={tag['@id']} key={tag['@id']} />
                  ))}
                </Label>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={12}>
          <div className='free-material__description'>
            {project && project.subtheme && (
              <div className='wrapper'>
                <Row>
                  <Col xs={2}>
                    <h5>Tema</h5>
                  </Col>
                  <Col xs={10}>
                    {project && <GetThemeAndSubThemeNameFormObject projectDetail={project}/>}
                  </Col>
                </Row>
              </div>
            )}
            <div className='wrapper'>
              <Row>
                <Col xs={2}>
                  <h5>Objektif</h5>
                </Col>
                <Col xs={10}>
                  <p>{previewLesson && previewLesson.createdFor.objective}</p>
                </Col>
              </Row>
            </div>
            <div className='wrapper no-border-bottom'>
              <Row>
                <Col xs={2}>
                  <h5>Aktivitas</h5>
                </Col>
                <Col xs={10}>
                  <p>{previewLesson && previewLesson.createdFor.activity}</p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderTab = () => {
    return (
    <div className="free-material__tab-panel">
      <Row>
        <Col
          className={`${activePanel.includes('movie') ? 'active' : ''}`}
          onClick={() => setActivePanel('movie')}>
          <svg className="icon" width="25" height="25" viewBox="0 0 25 25">
          <path d="M1.21484 14.6892V22.3975C1.21484 23.8148 2.35768 24.9669 3.76126
            24.9669H22.0955C23.5002 24.9669 24.642 23.8148 24.642 22.3975V14.6892H1.21484Z"/>
          <path d="M12.2725 2.65775L8.46094 3.57041L11.6542 8.31152L15.7824 7.36394L12.2725 2.65775Z"/>
          <path d="M7.41262 3.82837L3.30469 4.81089L6.4643 9.50783L10.5987 8.55918L7.41262 3.82837Z"/>
          <path d="M24.6038 4.79314L23.737 1.4755C23.5374 0.668688 22.7184 0.161001
            21.9107 0.347012L18.4883 1.16717L21.7355 5.99771L24.2239 5.42628C24.3593
            5.39545 24.4754 5.31013 24.5468 5.19094C24.6181 5.07175 24.6384 4.92881 24.6038 4.79314Z"/>
          <path d="M17.4371 1.42404L13.3516 2.40246L16.8676 7.11891L20.6781 6.24427L17.4371 1.42404Z"/>
          <path d="M6.35236 9.55344L4.72266 13.6635H8.71853L10.3482 9.55344H6.35236Z"/>
          <path d="M11.4344 9.5495L9.80469 13.6596H13.8005L15.4303 9.5495H11.4344Z"/>
          <path d="M24.1247 9.55542H21.618L19.9883 13.6665H24.634V10.0693C24.6339
            9.7846 24.4068 9.55542 24.1247 9.55542Z"/>
          <path d="M16.5243 9.5495L14.8945 13.6596H18.8894L20.5201 9.5495H16.5243Z"/>
          <path d="M2.24322 5.06406L1.41307 5.2624C1.00871 5.3549 0.666472 5.60258
            0.449516 5.95822C0.23256 6.31487 0.16839 6.7342 0.269229 7.14014L1.21344
            10.9059V13.6623H3.61929L5.14815 9.8072L5.39667 9.75069L2.24322 5.06406Z"/>
        </svg>

          Video Pembelajaran
        </Col>
        <Col
          className={`${activePanel.includes('question') ? 'active' : ''}`}
          onClick={() => setActivePanel('question')}>
          <svg className="icon" width="25" height="25" viewBox="0 0 25 25">
            <path d="M20.3057 6.96884H19.7701V11.1703C19.7701 14.2832 17.2603
              16.8157 14.1753 16.8157H6.17969V17.3563C6.17969 19.673 8.04766
              21.5577 10.3436 21.5577H18.8972L23.2962 25.2566C23.4272 25.3668
              23.5901 25.424 23.7544 25.424C24.1477 25.424 24.4696 25.1019 24.4696
              24.7021V11.1703C24.4696 8.85011 22.6093 6.96884 20.3057 6.96884Z"/>
            <path d="M18.3368 11.1707V4.98492C18.3368 2.66055 16.4726 0.783417
              14.1729 0.783417H4.21078C1.90721 0.783417 0.046875 2.6645 0.046875
              4.98492V18.5166C0.046875 18.9163 0.368823 19.2385 0.76213 19.2385C0.926457
              19.2385 1.08929 19.1814 1.22027 19.071L5.6193 15.3722H14.1729C16.4689
              15.3722 18.3368 13.4876 18.3368 11.1707ZM9.19185 12.2793C8.79687
              12.2793 8.47641 11.956 8.47641 11.5574C8.47641 11.1589 8.79687 10.8355
              9.19185 10.8355C9.58665 10.8355 9.90729 11.1589 9.90729 11.5574C9.90729
              11.956 9.58665 12.2793 9.19185 12.2793ZM9.90729 9.0866V9.23775C9.90729
              9.6363 9.58683 9.95965 9.19185 9.95965C8.79668 9.95965 8.47641 9.6363
              8.47641 9.23775V8.46453C8.47641 8.06579 8.79668 7.74263 9.19185 7.74263C9.85363
              7.74263 10.3921 7.19913 10.3921 6.53137C10.3921 5.86343 9.85363 5.32012
              9.19185 5.32012C8.87065 5.32012 8.56938 5.44608 8.34282 5.67468C8.11627
              5.90328 7.99144 6.20727 7.99144 6.53043C7.99144 6.92917 7.67116 7.2529
              7.276 7.2529C6.88083 7.2529 6.56055 6.92992 6.56055 6.53137V6.53043C6.56055
              5.82169 6.83425 5.15506 7.33096 4.65386C7.82785 4.15248 8.48833 3.87632
              9.19092 3.87632C10.6427 3.87632 11.823 5.06745 11.823 6.53137C11.823 7.74526
              11.0116 8.77152 9.90729 9.0866Z"/>
          </svg>

          Pertanyaan
        </Col>
      </Row>
        {activePanel.includes('movie')
          ? renderLessonVideo()
          : (
          <Carousel
            interval={null}
            onSelect={handleOnSelect}
            indicators={false}
            defaultActiveIndex={slideIndex}
            prevIcon={<Image src={prevIcon} />}
            nextIcon={<Image src={nextIcon} />}
          >
            {questions && questions.map((question, index) => (
              <Carousel.Item key={index} >
                <SmallQuestionPreviewMateriGratis
                  questionData={question}
                  questionIndex={index}
                  questionLength={questions.length}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          )
        }
    </div>
    );
  };

  return (
    <div className='free-material'>
      <Modal
        show={isShowMaterial}
        dialogClassName='free-material__modal-materi-gratis'
        onHide={handleCloseMaterial}
      >
        <div
          className='close'
          onClick={handleCloseMaterial}
        >
          <X />
        </div>
        <Modal.Body>
          <div className='free-material__title'>
            <h1>{previewLesson && previewLesson.createdFor.title}</h1>
          </div>
          <Row>
            <Col xs={7} style={{ textAlign: 'center', marginTop: '20px' }}>
              <b className="title">
                {activePanel.includes('movie')
                  ? 'Video Pembelajaran'
                  : `Pertanyaan ${slideIndex + 1} dari ${questions.length}`
                }
              </b>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              {renderTab()}
            </Col>
            <Col xs={5} className="free-material__description-col">
              {renderLessonDescription()}

              <div className='free-material__action'>
                <Button block={true} type='secondary' onClick={() => history.push('/papan-pintar')}>
                  Dibayar untuk Membuat Konten
                </Button>
                <Button block={true} type='main' onClick={() => onDownloadClick()} >
                  Unduh Aplikasi Titik Pintar
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

FreeMaterialModal.propTypes = {
  onDownloadClick: PropTypes.func,
  isShowMaterial: PropTypes.bool,
  setIsShowMaterial: PropTypes.func,
};

export default FreeMaterialModal;
