import * as Yup from 'yup';

export const signInSchema = Yup.object().shape({
  email: Yup.string().email('Alamat email tidak valid!').required('Harap isi alamat email!'),
  password: Yup.string().min(5, 'Kata sandi terlalu pendek!').required('Harap isi kata sandi!')
});

export const signUpSchema = Yup.object().shape({
  username: Yup.string().required('Harap isi nama lengkap!'),
  email: Yup.string().email('Alamat email tidak valid!').required('Harap isi alamat email!'),
  password: Yup
    .string()
    .min(7, 'Kata sandi terlalu pendek!')
    .matches(
      /^(?=.*[0-9])/,
      'Harus terdiri dari 7 karakter dan 1 angka'
    )
    .required('Harap isi kata sandi!'),
  acceptTerms: Yup.bool().oneOf([true], 'Klik terima syarat dan ketentuan')
});
