import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import axios from 'axios';
import { decryptData } from '../Helpers/Common';
import { HASH_DECRYPT } from '../Constants/Constants';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    (async () => {
      const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
      if (
        loggedUser
        && !(loggedUser.roles.includes('ROLE_TEACHER') || loggedUser.roles.includes('ROLE_DESIGNER'))
        && !loggedUser.roles.includes('ROLE_ADMIN')
      ) {
        const { data } = await axios.get(localStorage.getItem('id'));
        if (data && isEmpty(data.tags)) {
          history.push('/complete-profile');
        }
      }
    })();
  }, []);

  return (
    <Route {...rest} component={(props) => (
      <Component {...props} />
    )} />
  );
};

export default PublicRoute;
