import React from 'react';
import { StarFill } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import './Recommendation.scss';

const Recommendation = (props) => {
  const {
    ratingValue, name, job, company, date, skills, testimonial, avatar
  } = props;

  const activeRating = () => {
    const arrayOfStars = [];
    for (let i = 0; i < ratingValue; i++) {
      arrayOfStars.push(
        <StarFill className='recommendation-contents-rating-active' key={uuidv4()} />
      );
    }
    return arrayOfStars;
  };

  const nonActiveRating = () => {
    const arrayOfStars = [];
    for (let i = 0; i < 5 - ratingValue; i++) {
      arrayOfStars.push(
        <StarFill className='recommendation-contents-rating-non-active' key={uuidv4()}/>
      );
    }
    return arrayOfStars;
  };

  return (
    <div className='recommendation'>
      <div className='recommendation-avatar'>
        <img src={avatar} alt='' />
      </div>
      <div className='recommendation-details'>
        <h3>{name}</h3>
        <p>
          {job} <br /> di {company}
        </p>
      </div>
      <div className='recommendation-contents'>
        <div className='recommendation-contents-rating'>
          {activeRating()}
          {nonActiveRating()}
          <p>{date}</p>
        </div>
        <div className='recommendation-contents-button'>
          {skills.map((item, i) => (
            <button key={i}>
              {ratingValue <= 3 ? `${item} yang Perlu Ditingkatkan` : `${item} yang Luar Biasa`}
            </button>
          ))}
        </div>
        <p className='recommendation-contents-testimonial'>{testimonial}</p>
      </div>
    </div>
  );
};

export default Recommendation;
