import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import './InputDropZone.scss';

const InputDropZone = ({
  uploadFiles, type, text, loading
}) => {
  const [error, setError] = useState();
  const [color, setColor] = useState('black');
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setError();
      setColor('black');
      if (acceptedFiles.length > 0) {
        uploadFiles(acceptedFiles, type);
      } else {
        setError('Maksimal 1 file');
      }
    },
    noDragEventsBubbling: true,
    noClick: true,
    multiple: false,
    onDragEnter: () => setColor('#f2767e'),
    onDragLeave: () => setColor('black')
  });

  return (
    <FormGroup className='w-100'>
      <section className="pb-1">
        <div {...getRootProps({ className: 'dropzone' })} style={{ border: `2px dashed ${color}` }}>
          <input {...getInputProps()} />
          <p>
            <em>({loading ? 'Uploading...' : text})</em>
          </p>
        </div>
      </section>
      {error && <span className='text-center text-danger'>{error}</span>}
    </FormGroup>
  );
};

export default InputDropZone;
