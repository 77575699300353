import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`person_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`person_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={this.renderField}
          name="worksFor"
          type="text"
          placeholder="Organizations outside of system that the person works for."
        />
        <Field
          component={this.renderField}
          name="image"
          type="text"
          placeholder="An image of the item. This can be a \[\[URL\]\] or a fully described \[\[ImageObject\]\].
other organizations can use that image"
        />
        <Field
          component={this.renderField}
          name="addressRegion"
          type="text"
          placeholder="The region in which the locality is, and which is in the country. For example, California or another appropriate first-level Administrative division see https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country"
        />

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'person',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
