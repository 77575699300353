/* eslint-disable consistent-return */
import React from 'react';
import {
  Button, Col, Image,
  Form, Modal, Row
} from 'react-bootstrap';
import './SignInModal.scss';
// import { ArrowRight, X } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import { X } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import signInAlby from '../../Assets/Images/signin-modal-alby.png';
import { signInSchema } from '../../Schemas/AuthenticationSchema';
import { signIn } from '../../Actions/Auth';
import MessageConstants from '../../Constants/MessageConstants';
import Input from '../Input/Input';

const { ERROR_LOGIN } = MessageConstants;

const SignInModal = ({
  isShowModal,
  setIsShowModal,
  title = 'Masuk',
  desc = 'Hari ini kamu mau menciptakan apa? Alby Penasaran!',
  titleActive = false,
}) => {
  const isError = useSelector((state) => state.auth.isError);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignIn = async (values) => {
    const { email, password } = values;
    const emailAndPassword = {
      email,
      password
    };
    dispatch(signIn(emailAndPassword, history));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: signInSchema,
    onSubmit: handleSignIn
  });

  const {
    handleSubmit, setFieldValue,
    errors
  } = formik;

  const handleOnChangeEmail = (event) => {
    const { target: { value } } = event;
    setFieldValue('email', value);
  };

  const handleOnChangePassword = (event) => {
    const { target: { value } } = event;
    setFieldValue('password', value);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  // const renderQuickLogin = () => {
  //   return (
  //     <div className='sign-in-form-quick-login'>
  //       <div className='sign-in-form-quick-login-avatar'>
  //         J
  //       </div>
  //       <div className='sign-in-form-quick-login-name'>John Doe</div>
  //       <div className='sign-in-form-quick-login-icon'>
  //         <ArrowRight />
  //       </div>
  //     </div>
  //   );
  // };

  const renderSignInForm = () => {
    return (
      <div className='sign-in-form-input'>
        {isError && <div className='sign-in-form-error'>{ERROR_LOGIN}</div>}
        <Form onSubmit={handleSubmit}>
          <Input
            controlId='emailInput'
            label='Alamat Email'
            type='email'
            placeholder='Alamat Email'
            isInvalid={!isEmpty(errors.email)}
            errorMessage={errors.email}
            onChange={handleOnChangeEmail}
          />
          <Input
            controlId='passwordInput'
            label='Kata Sandi'
            type='password'
            placeholder='Minimal 7 karakter'
            isInvalid={!isEmpty(errors.password)}
            errorMessage={errors.password}
            onChange={handleOnChangePassword}
          />
          <Row className='sign-in-form-input-after'>
            <Col>
              <Link
                to='/forgot-password'
                className='sign-in-form-input-forgot-password'>
                Lupa kata sandi?
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                className='sign-in-form-input-button'
                type='submit'
              >
                Masuk
              </Button>
            </Col>
            <Col xs={12} align='center'>
              <div className='sign-in-form-input-link'>
                Belum jadi member? &nbsp;
                <Link to='/register' className='sign-in-form-input-link-sign-up'>
                  Daftar
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className='sign-in-modal'>
      <Modal
        show={isShowModal}
        dialogClassName='sign-in-modal-box'
        onHide={() => setIsShowModal(false)}
      >
        <Modal.Body>
          <div className='sign-in-modal-box-close'>
            <X onClick={handleCloseModal} />
          </div>
          <div className='sign-in-form-illustration'>
            <Image src={signInAlby} />
          </div>
          <div className='sign-in-form'>
            <Row>
              <Col xs={12}>
                <h1 className={`sign-in-form-title ${titleActive ? 'active' : ''}`}>{title}</h1>
                <p className='sign-in-form-desc'>{desc}</p>
              </Col>
              {/* <Col xs={12}>
                    {renderQuickLogin()}
                  </Col> */}
            </Row>
            {/* <Row>
                  <Col xs={12}>
                    <div className='sign-in-form-separator'>
                      Use another account ?
                    </div>
                  </Col>
                </Row> */}
            <Row>
              <Col xs={12}>
                {renderSignInForm()}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

SignInModal.propTypes = {
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  titleActive: PropTypes.bool,
};

export default SignInModal;
