/* eslint-disable no-useless-return */
import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { Carousel, Col, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './PreviewLessonModal.scss';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LESSON_URL, TTP_API_BASE_URL, API_KEY } from '../../Constants/Constants';
import Button from '../Button/Button';
import TagName from '../Tags/TagName';
import EducationalLevel from '../EducationalLevel/EducationalLevel';
import { multipleAnswerField } from '../../Helpers/StaticField';
import { submitQuestion } from '../../Actions/Question';
import ProposalQuestionPreview from '../ProposalQuestionPreview/ProposalQuestionPreview';
import TabPlayer from '../TabPlayer/TabPlayer';
import CheckBox from '../CheckBox/CheckBox';

const TOPIC_TYPE = [
  {
    value: 'self-paced',
    label: 'Self Paced',
  },
  {
    value: 'live-class',
    label: 'Live Class',
  },
];

const PreviewLessonModal = ({
  showModal,
  setShowModal,
  previewProposal,
  publishLesson,
  listProject,
  setLoadingScreen,
}) => {
  const dispatch = useDispatch();
  const History = useHistory();

  const [lessonQuestion, setLessonQuestion] = useState([]);
  const [project, setProject] = useState();
  const [unprocessedVideoUrl, setUnprocessedVideoUrl] = useState(null);
  const [sahabatpintarVideoUrl, setSahabatpintarVideoUrl] = useState(null);
  const [titikpintarVideoUrl, setTitikpintarVideoUrl] = useState(null);
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState(null);
  const [activeVideo, setActiveVideo] = useState('unprocessed');
  const [availableVideo, setAvailableVideo] = useState([]);
  const [isBahasa, setIsBahasa] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [imageObjects, setImageObjects] = useState();
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmMerge, setShowConfirmMerge] = useState(false);
  const [showAnotherLesson, setShowAnotherLesson] = useState(false);
  const [anotherLessons, setAnotherLessons] = useState();
  const [categories, setCategories] = useState();
  const [selectedAnotherLesson, setSelectedAnotherLesson] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedTopicType, setSelectedTopicType] = useState();
  const [haveMinigame, setHaveMinigame] = useState(true);
  const [haveSingleplayer, setHaveSingleplayer] = useState(true);
  const [haveMultiplayer, setHaveMultiplayer] = useState(true);

  useEffect(() => {
    const getLessonQuestion = async () => {
      setLoadingScreen(true);
      setLoading(true);
      let available = [];
      if (isBahasa) {
        const { data } = await axios.get(`${LESSON_URL}/${previewProposal.id}`);
        setLessonQuestion(data.hasParts);
        if (data.derivativeForSahabatPintar) {
          available = available.concat('sahabatpintar');
          setSahabatpintarVideoUrl(data.derivativeForSahabatPintar.contentUrl);
        }
        if (data.derivativeForTitikPintar) {
          available = available.concat('titikpintar');
          setTitikpintarVideoUrl(data.derivativeForTitikPintar.contentUrl);
        }
        if (data.derivativeForYoutube) {
          available = available.concat('youtube');
          setYoutubeVideoUrl(data.derivativeForYoutube.contentUrl);
        }
        if (data.video) {
          available = available.concat('unprocessed');
          setUnprocessedVideoUrl(data.video.contentUrl);
        }
        setAvailableVideo(available);
        const questionImages = {};
        questionImages['hydra:member'] = data.hasParts.map((q) => q.image);
        setImageObjects(questionImages);
      } else {
        const { data } = await axios.get(`${LESSON_URL}/${previewProposal.id}`, {
          headers: {
            'X-LOCALE': 'en',
          },
        });
        setLessonQuestion(data.hasParts);
        if (data.derivativeForSahabatPintar) {
          available = available.concat('sahabatpintar');
          setSahabatpintarVideoUrl(data.derivativeForSahabatPintar.contentUrl);
        }
        if (data.derivativeForTitikPintar) {
          available = available.concat('titikpintar');
          setTitikpintarVideoUrl(data.derivativeForTitikPintar.contentUrl);
        }
        if (data.derivativeForYoutube) {
          available = available.concat('youtube');
          setYoutubeVideoUrl(data.derivativeForYoutube.contentUrl);
        }
        if (data.video) {
          available = available.concat('unprocessed');
          setUnprocessedVideoUrl(data.video.contentUrl);
        }
        const questionImages = {};
        questionImages['hydra:member'] = data.hasParts.map((q) => q.image);
        setImageObjects(questionImages);
      }
      setLoadingScreen(false);
      setLoading(false);
    };
    if (previewProposal && previewProposal.id) {
      getLessonQuestion();
    }
  }, [previewProposal, isBahasa]);

  useEffect(() => {
    if (listProject && !isEmpty(previewProposal)) {
      let projectId = previewProposal.createdFor;
      if (typeof projectId !== 'string') {
        projectId = projectId['@id'];
      }
      const projectItem = listProject && listProject.find((p) => p['@id'] === projectId);
      setProject(projectItem);
    }
  }, [previewProposal, listProject]);

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    if (lessonQuestion) {
      const completeQuestionData =
        lessonQuestion &&
        lessonQuestion.sort(compare).map((filteredQuestion) => ({
          ...filteredQuestion,
          description: filteredQuestion.description || '',
          answerKey: filteredQuestion.answerKey || '',
          visualBrief: filteredQuestion.visualBrief || '',
          answers: [],
          answerPreview: multipleAnswerField,
          image: filteredQuestion.image || null,
        }));
      dispatch(submitQuestion(completeQuestionData));
    }
  }, [lessonQuestion]);

  const handleOnSelect = (indexValue) => {
    setSlideIndex(indexValue);
  };

  const handleDropdownExport = async () => {
    try {
      setLoading(true);

      // Get Categories
      const uninterceptedAxiosInstance = axios.create();
      const { data: dataCategories } = await uninterceptedAxiosInstance.get(`${TTP_API_BASE_URL}/ccs/categories`, {
        headers: {
          'SAHABAT-PINTAR-API-KEY': API_KEY,
        },
      });
      setCategories(
        dataCategories.data.map((cat) => {
          return {
            value: cat.id,
            label: `${cat.translations[0].name} - ${cat.grade.name}`,
          };
        })
      );
      setShowDropdown(true);
    } catch (error) {
      toast.error('Something Wrong!');
    }
    setLoading(false);
  };

  const handleExport = async () => {
    try {
      // Check quiz content
      if (!haveSingleplayer && !haveMultiplayer) {
        toast.error('Please choose between singleplayer, multiplayer or both!');
        return;
      }

      setLoading(true);
      const { data } = await axios.get(`${LESSON_URL}/${previewProposal.id}`);

      if (selectedTopicType.value === 'self-paced') {
        if (isEmpty(data.icon)) {
          setLoading(false);
          toast.error('Self Paced Topic need a Topic Icon!');
          return;
        }

        if (isEmpty(data.imageSummary)) {
          setLoading(false);
          toast.error('Image Summary cannont be empty!');
          return;
        }

        if (isEmpty(data.summary)) {
          setLoading(false);
          toast.error('Text Summary cannont be empty!');
          return;
        }
      }
      if (isEmpty(data.video)) {
        // setLoading(false);
        // toast.error('Video cannot be empty!');
        // return;
      } else {
        if (!data.video.duration) {
          setLoading(false);
          toast.error('Video Duration cannot be 0 (zero)!');
          return;
        }
        if (data.video.duration === 0) {
          setLoading(false);
          toast.error('Video Duration cannot be 0 (zero)!');
          return;
        }
      }

      let valid = true;
      let desc = '';
      data.hasParts.forEach((q) => {
        if (isEmpty(q.description)) {
          valid = false;
          desc = `Description Question No. ${q.ordering} is Empty!`;
          return;
        }
        if (isEmpty(q.answerKey)) {
          valid = false;
          desc = `Answer Key Question No. ${q.ordering} is Empty!`;
          return;
        }
        if (q.difficulty === 0) {
          valid = false;
          desc = `Difficulty Question No. ${q.ordering} is Empty!`;
          return;
        }
        let haveOneCorrectAnswer = false;
        q.connectedAnswers.forEach((a) => {
          if (isEmpty(a.description)) {
            valid = false;
            desc = `Answer Description for Question No. ${q.ordering} is Empty!`;
            return;
          }
          if (a.correct) {
            haveOneCorrectAnswer = true;
          }
        });
        if (!haveOneCorrectAnswer) {
          valid = false;
          desc = `No Correct Answer for Question No. ${q.ordering}!`;
          return;
        }
      });
      if (!valid) {
        setLoading(false);
        toast.error(desc);
        return;
      }

      await axios.put(previewProposal['@id'], {
        exported: true,
      });
      // Export to TTP
      await axios.post(
        `${TTP_API_BASE_URL}/ccs/export`,
        {
          lesson_id: previewProposal.id,
          type: selectedTopicType.value,
          category_id: selectedCategory.value,
          have_minigame: haveMinigame,
          have_singleplayer: haveSingleplayer,
          have_multiplayer: haveMultiplayer,
        },
        {
          headers: {
            'SAHABAT-PINTAR-API-KEY': API_KEY,
          },
        }
      );
      toast.success('Lesson Exported Successfully');
      History.push('/admin/lesson-bank');
    } catch (error) {
      toast.error('Something Wrong!');
    }
    setLoading(false);
  };

  const handleMergeLesson = async () => {
    setLoading(true);
    try {
      await axios.post(`${previewProposal['@id']}/merge`, {
        to: selectedAnotherLesson.value,
      });
      toast.success('Lesson Merge Successfully');
      History.push('/admin/lesson-bank');
    } catch (error) {
      toast.error('Something Wrong!');
    }
    setLoading(false);
  };

  const handlePublish = async () => {
    setLoading(true);
    await publishLesson();
    setLoading(false);
  };

  const handleMerge = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${LESSON_URL}?itemsPerPage=500`);
      setAnotherLessons(
        data['hydra:member']
          .filter((l) => l.id !== previewProposal.id)
          .map((l) => {
            return {
              value: l.id,
              label: l.createdFor.title,
            };
          })
      );
      setShowAnotherLesson(true);
    } catch (error) {
      toast.error('Something Wrong!');
    }
    setLoading(false);
  };

  const handleHaveContent = (type) => {
    if (type === 'minigame') {
      setHaveMinigame((prevState) => !prevState);
    }
    if (type === 'singleplayer') {
      setHaveSingleplayer((prevState) => !prevState);
    }
    if (type === 'multiplayer') {
      setHaveMultiplayer((prevState) => !prevState);
    }
  };

  const renderLessonVideo = () => {
    return (
      <div>
        <TabPlayer
          activeVideo={activeVideo}
          availableVideo={availableVideo}
          setActiveVideo={setActiveVideo}
          unprocessedVideoUrl={unprocessedVideoUrl}
          titikpintarVideoUrl={titikpintarVideoUrl}
          sahabatpintarVideoUrl={sahabatpintarVideoUrl}
          youtubeVideoUrl={youtubeVideoUrl}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Carousel interval={null} indicators={false} onSelect={handleOnSelect}>
        <Carousel.Item>{renderLessonVideo()}</Carousel.Item>

        {lessonQuestion.map((question, index) => (
          <Carousel.Item key={index}>
            <ProposalQuestionPreview
              questionData={question}
              previewIndex={index}
              questionsCount={lessonQuestion.length}
              imageObjects={imageObjects}
              lang={isBahasa ? 'id' : 'en'}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const renderHeading = () => {
    return (
      <>
        <div className='review-lesson-modal-sub-text'>
          {project && project.tags.map((tag) => <TagName tag={tag} key={tag} />)}
          {' - '}
          <EducationalLevel type='educational-level-text' educationalLevel={project && project.educationalLevel} />
        </div>
        <span className='review-lesson-modal-sub-text'>{project && project.title}</span>
        <div className='preview-lesson-modal-reprocess'>
          <Button>Reprocess</Button>
        </div>
      </>
    );
  };

  const renderExport = () => {
    if (showConfirm) {
      return (
        <div>
          <h5 className='text-center mt-3'>Are you sure want to export this lesson?</h5>
          <ul>
            <li>Lesson Name : {project.title}</li>
            <li>Category : {selectedCategory.label}</li>
            <li>Topic/Lesson Type : {selectedTopicType.label}</li>
            <li>{haveMinigame ? 'This Topic Have Minigame' : "This Topic Doesn't Have Minigame"}</li>
            <li>{haveSingleplayer ? 'This Topic Have Singleplayer' : "This Topic Doesn't Have Singleplayer"}</li>
            <li>{haveMultiplayer ? 'This Topic Have Multiplayer' : "This Topic Doesn't Have Multiplayer"}</li>
          </ul>
          <Row>
            <Col>
              <Button type='secondary' block onClick={() => setShowConfirm(false)}>
                {loading ? 'Loading...' : 'No'}
              </Button>
            </Col>
            <Col>
              <Button block onClick={handleExport}>
                {loading ? 'Loading...' : 'Yes'}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div className='preview-lesson-modal-export-ttp'>
        <h5 className='text-center mt-3'>Please Select Category and Topic to Export to TTP</h5>
        <Select
          classNamePrefix='dropdown-form-component'
          placeholder='Select Category'
          options={categories}
          onChange={(cat) => setSelectedCategory(cat)}
          value={selectedCategory}
          className='my-3'
        />
        <Select
          classNamePrefix='dropdown-form-component'
          placeholder='Select Topic/Lesson Type'
          options={TOPIC_TYPE}
          onChange={(topic) => setSelectedTopicType(topic)}
          value={selectedTopicType}
          className='my-3'
        />
        <CheckBox
          label={<span>Is This Topic Have Minigame?</span>}
          onChange={() => handleHaveContent('minigame')}
          className='sign-up-form-input-label-checkbox'
          checked={haveMinigame}
        />
        <CheckBox
          label={<span>Is This Topic Have Singleplayer?</span>}
          onChange={() => handleHaveContent('singleplayer')}
          className='sign-up-form-input-label-checkbox'
          checked={haveSingleplayer}
        />
        <CheckBox
          label={<span>Is This Topic Have Multiplayer?</span>}
          onChange={() => handleHaveContent('multiplayer')}
          className='sign-up-form-input-label-checkbox'
          checked={haveMultiplayer}
        />
        <Button block onClick={() => setShowConfirm(true)} disabled={loading || !selectedTopicType}>
          {loading ? 'Loading...' : 'Export To TTP'}
        </Button>
      </div>
    );
  };

  const renderAnotherLessons = () => {
    if (showConfirmMerge) {
      return (
        <div>
          <h5 className='text-center mt-3'>Are you sure want to merge to this lesson?</h5>
          <ul>
            <li>From Lesson : {project.title}</li>
            <li>To Lesson : {selectedAnotherLesson.label}</li>
          </ul>
          <Row>
            <Col>
              <Button type='secondary' block onClick={() => setShowConfirmMerge(false)}>
                {loading ? 'Loading...' : 'No'}
              </Button>
            </Col>
            <Col>
              <Button block onClick={handleMergeLesson}>
                {loading ? 'Loading...' : 'Yes'}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div className='preview-lesson-modal-export-ttp'>
        <h5 className='text-center mt-3'>Please Select Another Lesson</h5>
        <Select
          classNamePrefix='dropdown-form-component'
          placeholder='Select Another Lesson'
          options={anotherLessons}
          onChange={(l) => setSelectedAnotherLesson(l)}
          value={selectedAnotherLesson}
          className='my-3'
        />
        <Button block onClick={() => setShowConfirmMerge(true)} disabled={loading || !selectedAnotherLesson}>
          {loading ? 'Loading...' : 'Merge to this lesson'}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      dialogClassName='free-material__modal'
      onHide={() => {
        setShowModal();
        setSlideIndex(0);
      }}
    >
      <div className='close' onClick={() => setShowModal()}>
        <X />
      </div>
      <Modal.Body>
        <div className='review-lesson-modal-title'>
          Preview Lesson
          <div className='preview-lesson-modal-switch'>
            <div className='onoffswitch'>
              <input
                type='checkbox'
                className='onoffswitch-checkbox'
                checked={isBahasa}
                onChange={() => setIsBahasa((bool) => !bool)}
                id='modalLangSwitch'
                disabled={loading}
              />
              <label className='onoffswitch-label' htmlFor='modalLangSwitch'>
                <span className='onoffswitch-inner'></span>
                <span className='onoffswitch-switch'></span>
              </label>
            </div>
          </div>
        </div>
        {renderHeading()}
        {renderContent()}
        <span className='review-lesson-modal-bottom-text'>
          <span>{slideIndex < 1 ? 'Animation Mov' : `Tanya & Jawab no. ${slideIndex}`}</span>
          <span>
            {slideIndex + 1} of {1 + lessonQuestion.length}
          </span>
        </span>
        {showDropdown && renderExport()}
        {showAnotherLesson && renderAnotherLessons()}
      </Modal.Body>

      <div className='publish-modal-btn-container'>
        <div className='publish-modal-btn-left'>
          <button
            className='publish-modal-btn-circle'
            onClick={() => {
              const getLessonId = !isEmpty(previewProposal) && previewProposal['@id'].split('/')[2];
              setShowModal();
              History.push(`/admin/edit-proposal/${getLessonId}`);
            }}
          >
            <i className='fa fa-pencil' />
          </button>
          <button className='publish-modal-btn-circle'>
            <i className='fa fa-trash' />
          </button>
        </div>
        <div className='publish-modal-btn-main'>
          <Button block onClick={handleMerge} disabled={loading}>
            {loading ? 'Loading...' : 'Merge to another lesson'}
          </Button>
        </div>
        {previewProposal && !previewProposal.published && (
          <div className='publish-modal-btn-main'>
            <Button block onClick={handlePublish} disabled={loading}>
              {loading ? 'Loading...' : 'Publish to Materi Gratis'}
            </Button>
          </div>
        )}
        {!showDropdown && (
          <div className='publish-modal-btn-main'>
            <Button block onClick={handleDropdownExport} disabled={loading}>
              {loading
                ? 'Loading...'
                : `${previewProposal && !previewProposal.exported ? 'Export' : 'Re-Export'} to Titik Pintar`}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

PreviewLessonModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  publishLesson: PropTypes.func,
  listProject: PropTypes.any,
};

export default PreviewLessonModal;
