/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import AdminSidebar from '../../Components/AdminSidebar/AdminSidebar';
import Notepad from '../../Assets/Images/Notepad.png';
import Label from '../../Components/Label/Label';
import Button from '../../Components/Button/Button';
import ViewProposalModal from '../../Components/ViewProposalModal/ViewProposalModal';
import ConfirmWinnerModal from '../../Components/ConfirmWinnerModal/ConfirmWinnerModal';
import GrabIcon from '../../Assets/Images/grab-handle-icon.png';
import './LessonRequestDetail.scss';
import {
  retrieve as projectActionDetail,
  reset as projectActionReset
} from '../../Generated/actions/project/show';
import EducationalLevel from '../../Components/EducationalLevel/EducationalLevel';
import {
  list as tagsActionList
} from '../../Generated/actions/tags/list';
import {
  list as educationalLevelActionList,
} from '../../Generated/actions/educationallevel/list';
import Tag from '../../Components/Tags/Tag';
import LessonRequestCard from '../../Components/LessonRequestCard/LessonRequestCard';
import {
  list as organizationActionList,
  reset as organizationActionReset
} from '../../Generated/actions/organization/list';
import { list as personActionList, reset as personActionReset } from '../../Generated/actions/person/list';
import { list as mediaActionList, reset as mediaActionReset } from '../../Generated/actions/mediaobject/list';
import { AWARDS_URL, CREATIVE_WORKS_URL } from '../../Constants/Constants';
import {
  list as creativeWorkActionList,
  reset as creativeWorkActionListReset
} from '../../Generated/actions/creativework/list';
import ModalProposalMember from '../../Components/ModalProposalMember/ModalProposalMember';
import Loading from '../../Components/Loading/Loading';

const LessonRequestDetail = (props) => {
  // States
  const History = useHistory();
  const dispatch = useDispatch();

  const [teamRankings, setTeamRankings] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [awards, setAwards] = useState([]);
  const [showViewProposalModal, setShowViewProposalModal] = useState(false);
  const [showConfirmWinnerModal, setShowConfirmWinnerModal] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [completedOrganization, setCompletedOrganization] = useState([]);
  const [proposalData, setProposalData] = useState([]);
  const [tempAwards, setTempAwards] = useState([]);
  const [loading, setLoading] = useState(false);

  const projectDetail = useSelector((state) => state.project.show);
  const listOfOrganization = useSelector((state) => state.organization.list);
  const listOfPersons = useSelector((state) => state.person.list);
  const listOfImages = useSelector((state) => state.mediaobject.list);
  const creativeWorkList = useSelector((state) => state.creativework.list);
  const [mappedImages, setMappedImages] = useState({});
  const [mappedMembers, setMappedMembers] = useState();
  const [organizationData, setOrganizationData] = useState({});
  const [cardIndex, setCardIndex] = useState(0);
  // const checkWinner = ((!isEmpty(awards) && !awards[0].hasOwnProperty('winner'))
  //   || (!isEmpty(awards) && awards[0].hasOwnProperty('winnerTemp')));
  const checkWinner = !isEmpty(tempAwards);

  useEffect(() => {
    dispatch(projectActionDetail(`/projects/${props.match.params.page}`));
    return () => {
      dispatch(projectActionReset(projectDetail.eventSource));
    };
  }, [dispatch, projectDetail.eventSource]);

  useEffect(() => {
    if (!showViewProposalModal) {
      dispatch(creativeWorkActionList(`/creative_works?createdFor=${props.match.params.page}`));
    }
    return () => {
      dispatch(creativeWorkActionListReset(creativeWorkList.eventSource));
    };
  }, [showViewProposalModal]);

  useEffect(() => {
    dispatch(
      organizationActionList(
        `organizations?project=${decodeURIComponent(props.match.params.page)}`
      )
    );
    return () => {
      dispatch(organizationActionReset(listOfOrganization.eventSource));
    };
  }, []);

  useEffect(() => {
    if (listOfOrganization.retrieved) {
      let membersArray = [];
      listOfOrganization.retrieved['hydra:member'].map((organizationFromList) => {
        membersArray = [...membersArray, ...organizationFromList.members, organizationFromList.founder];
        return null;
      });
      const membersArrayString = membersArray.join('&user[]=');
      dispatch(personActionList(`/people?pagination=false&user[]=${membersArrayString}`));
    }
    return () => {
      dispatch(personActionReset(listOfPersons.eventSource));
    };
  }, [listOfOrganization.retrieved]);

  useEffect(() => {
    if (listOfPersons.retrieved) {
      let imagesArray = [];
      listOfPersons.retrieved['hydra:member'].map(
        (member) => {
          imagesArray = [...imagesArray, member.image];
          return null;
        }
      );
      const mediaArrayString = imagesArray.filter(Boolean).join('&id[]=');
      dispatch(mediaActionList(`/media_objects?id[]=${mediaArrayString}`));
    }
    return () => {
      dispatch(mediaActionReset());
    };
  }, [listOfPersons.retrieved]);

  useEffect(() => {
    if (listOfImages.retrieved && listOfPersons.retrieved) {
      const object = {};
      listOfPersons.retrieved['hydra:member'].map(
        (person) => {
          object[person.user] = listOfImages.retrieved['hydra:member']
            .filter((image) => image['@id'] === person.image)
            .length > 0
            && listOfImages.retrieved['hydra:member']
              .filter((image) => image['@id'] === person.image)
              .shift().contentUrl;
          return setMappedImages(object);
        }
      );
    }
  }, [listOfImages.retrieved, listOfPersons.retrieved]);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
  }, []);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
  }, []);

  useEffect(() => {
    (async () => {
      if (listOfOrganization.retrieved && projectDetail.retrieved) {
        const { data: awardData } = await axios.get(
          `${AWARDS_URL}?project=${projectDetail.retrieved.id}&order[rank]=asc`
        );
        setAwards(awardData && awardData['hydra:member']);

        if (!isEmpty(awardData && awardData['hydra:member'].winner)) {
          const rankingData = listOfOrganization.retrieved['hydra:member']
            .filter((organization) => awardData && awardData['hydra:member']
              .filter((award) => (award.winner['@id'] === organization['@id'])).length > 0
              && awardData && awardData['hydra:member']
              .filter((award) => (award.winner['@id'] === organization['@id'])));
          setTeamRankings(rankingData);
        }

        const orgWithCreativeWork = listOfOrganization.retrieved['hydra:member']
          .filter((organization) => organization.creativeWork);
        const completedOrgData = orgWithCreativeWork
          .filter((organization) => !teamRankings.includes(organization));
        setCompletedOrganization(completedOrgData);
      }
    })();
  }, [listOfOrganization.retrieved, projectDetail.retrieved]);

  // case 1 ke 2

  const arrayMoveMutate = (array, from, to) => {
    array.splice(
      to < 0 ? array.length + to : to,
      0,
      array.splice(from, 1)[0]
    );
  };

  const arrayMove = (array, from, to) => {
    const newArray = array.slice();
    arrayMoveMutate(newArray, from, to);
    return newArray;
  };

  const handleUpdateAwards = async () => {
    if (!isEmpty(awards)) {
      for (let i = 0; i < awards.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await axios.put(`${AWARDS_URL}/${awards[i].id}`, {
          rank: i + 1,
          winner: tempAwards[i].winner['@id']
        });

        // Send Email to Winner
        // eslint-disable-next-line no-await-in-loop
        await axios.put(`${AWARDS_URL}/${awards[i].id}/winner`, {});
      }
    }
  };

  const handlePublishWinner = async () => {
    for (let i = 0; i < awards.length; i++) {
      const orgId = tempAwards[i].winner;
      const creativeWorkId = creativeWorkList.retrieved
        && creativeWorkList.retrieved['hydra:member']
          .filter((creativeWork) => creativeWork.creator === orgId['@id']);
      // eslint-disable-next-line no-await-in-loop
      await axios.put(`${CREATIVE_WORKS_URL}/${creativeWorkId[0].id}/import-to-lesson`, {});
    }
  };

  const publishWinner = async () => {
    try {
      setPublishLoading(true);
      await handlePublishWinner();
      await handleUpdateAwards();
      History.push('/admin/lesson-requests');
    } catch (error) {
      toast.error('Published Failed!', {
        autoClose: 10000
      });
    }
    setPublishLoading(false);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(tempAwards, oldIndex, newIndex);
    setTempAwards(newArray);
  };

  const addToFinalRanking = async (position) => {
    try {
      const otherLessonArray = [...completedOrganization];
      const selectedOtherLesson = otherLessonArray[position];
      otherLessonArray.splice(position, 1);
      setCompletedOrganization(otherLessonArray);

      const awardArray = [...tempAwards];
      awardArray.push({
        winner: {
          '@id': selectedOtherLesson['@id']
        },
        winnerTemp: {
          '@id': selectedOtherLesson['@id']
        }
      });
      setTempAwards(awardArray);
    } catch (error) {
      throw new Error(error);
    }
  };

  const removeFromFinalRanking = async (position) => {
    try {
      if (checkWinner) {
        const awardsArray = [...tempAwards];
        const selectedTeam = awardsArray[position];

        const filteredOrganization = listOfOrganization.retrieved
          && listOfOrganization.retrieved['hydra:member']
            .filter((organization) => organization['@id'] === selectedTeam.winner['@id']);
        setCompletedOrganization((array) => [...array, filteredOrganization[0]]);

        awardsArray.splice(position, 1);
        setTempAwards(awardsArray);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // Render Functions
  const renderHeading = () => {
    return (
      <div className='admin-lesson-requests-heading' >
        <h1 className='admin-lesson-requests-heading-text' >
          <img src={Notepad} alt="" className='admin-lesson-requests-heading-img' />
          Lesson Requests
        </h1>
        <h2 className='lesson-request-detail-heading-sub' >
          <ArrowLeft onClick={() => History.goBack()} style={{ cursor: 'pointer' }} />
            Requested</h2>
      </div>
    );
  };

  const renderLessonTitle = () => {
    return (
      <div className='lesson-request-detail-title' >
        <span className='lesson-request-detail-title-main'>
          {projectDetail.retrieved && projectDetail.retrieved.title}
        </span>
        <div className='lesson-request-detail-title-labels' >
          <div className='lesson-request-detail-title-label' >
            {projectDetail.retrieved && projectDetail.retrieved.tags.map((tag) => (
              <Tag tag={tag} key={tag} />
            ))}
          </div>
          <div className='lesson-request-detail-title-label' >
            <EducationalLevel
              educationalLevel={projectDetail.retrieved && projectDetail.retrieved.educationalLevel}
            />
          </div>
        </div>
        <div className='lesson-request-detail-title-btn' >
          <Button
            type='secondary'
            onClick={() => { History.push(`/lesson-request/${props.match.params.page}`); }}
          >
            Read Full Brief
          </Button>
        </div>
      </div>
    );
  };

  const DragIcon = SortableHandle(() => (
    <div className='lesson-request-detail-ranked-team-grab' >
      <img src={GrabIcon} alt="" />
    </div>
  ));

  // eslint-disable-next-line no-shadow
  const RankedTeam = SortableElement(({
    rank, team,
    mappedImagesData
  }) => {
    if (!isEmpty(team.winner)) {
      const filteredOrganization = listOfOrganization.retrieved
        && listOfOrganization.retrieved['hydra:member']
          .filter((organization) => organization['@id'] === team.winner['@id']);

      const proposal = creativeWorkList.retrieved
        && creativeWorkList.retrieved['hydra:member']
          .filter((creativeWork) => creativeWork.creator === filteredOrganization[0]['@id']);

      return (
        <div className='lesson-request-detail-ranked-team'>
          <span className='lesson-request-detail-ranked-team-rank'>{rank}</span>
          <i
            className='fa fa-heart lesson-request-detail-ranked-team-heart'
            onClick={() => removeFromFinalRanking(rank - 1)}
          />
          <span className='lesson-request-detail-ranked-team-name'>{filteredOrganization[0].name}</span>
          <div className='lesson-request-detail-ranked-team-label'>
            <Label type={filteredOrganization[0].status}/>
          </div>
          <div className='lesson-request-detail-ranked-team-members'>
            {filteredOrganization[0].members && filteredOrganization[0].members.map((member, index) => (
              <img
                src={mappedImagesData[member]}
                alt=""
                className='lesson-request-detail-ranked-team-avatar'
                key={index}
              />
            ))}
            {/* <span className='lesson-request-detail-ranked-team-avatar span' >+3</span> */}
          </div>

          <div className='lesson-request-detail-ranked-team-btn'>
            <Button onClick={() => {
              setOrganizationData(filteredOrganization[0]);
              setShowViewProposalModal(true);
              setProposalData(proposal[0]);
              setCardIndex(cardIndex);
            }}>View Proposal</Button>
          </div>

          {checkWinner && (
            <DragIcon/>
          )}
        </div>
      );
    }
    return (
      <div />
    );
  });

  const FinalRankingList = SortableContainer(() => {
    return (
      <div>
        {!isEmpty(tempAwards) && tempAwards.map((value, index) => (
          <RankedTeam
            key={index}
            index={index}
            rank={index + 1}
            team={value}
            mappedImagesData={mappedImages && mappedImages}
          />
        ))}
      </div>
    );
  });

  const renderFinalRanking = () => {
    return (
      <div className='lesson-request-detail-final-ranking' >
        <div className='lesson-request-detail-final-ranking-heading' >
          <span className='lesson-request-detail-final-ranking-heading-text' >Final Ranking</span>
          {!isEmpty(tempAwards) && moment(projectDetail.retrieved.endTime) < moment()
          && <Button onClick={() => setShowConfirmWinnerModal(true)}>Publish Winner</Button>}
        </div>
        <FinalRankingList
          onSortEnd={onSortEnd}
          useDragHandle={true}
        />
      </div>
    );
  };

  const renderOtherLessons = () => {
    return (
      <div>
        <span className='lesson-request-detail-other-lesson-heading'>Other Lessons Created</span>
        <div className='lesson-request-detail-other-lesson-cards' >
          {completedOrganization && completedOrganization
            .map((organization, index) => (
            <LessonRequestCard
              key={index}
              cardIndex={index}
              organization={organization}
              addToFinalRanking={addToFinalRanking}
              mappedImages={mappedImages && mappedImages}
              setShowViewProposalModal={setShowViewProposalModal}
              setProposalData={setProposalData}
              setOrganizationData={setOrganizationData}
              setCardIndex={setCardIndex}
            />
            ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {creativeWorkList.loading || loading ? <Loading /> : null}
      <AdminSidebar active='lessonRequests' />
      <div className='admin-lesson-requests-content' >
        {renderHeading()}
        {renderLessonTitle()}
        {renderFinalRanking()}
        {renderOtherLessons()}
        <ViewProposalModal
          showModal={showViewProposalModal}
          setShowModal={setShowViewProposalModal}
          proposalData={proposalData}
          organizationData={organizationData}
          setOrganizationData={setOrganizationData}
          mappedImages={mappedImages && mappedImages}
          addToFinalRanking={addToFinalRanking}
          cardIndex={cardIndex}
          setShowMembers={setShowMembers}
          setMappedMembers={setMappedMembers}
          setLoading={setLoading}
        />
        <ConfirmWinnerModal
          showModal={showConfirmWinnerModal}
          setShowModal={setShowConfirmWinnerModal}
          winnerData={tempAwards}
          awards={awards}
          publishWinner={publishWinner}
          publishLoading={publishLoading}
        />
        <ModalProposalMember
          mappedMembers={mappedMembers && mappedMembers}
          isShowModal={showMembers}
          setIsShowModal={setShowMembers}
        />
      </div>
    </div>
  );
};

export default LessonRequestDetail;
