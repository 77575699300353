import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import { Sliders } from 'react-bootstrap-icons';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import './PapanPintar.scss';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import ProjectList from '../../Components/ProjectList/ProjectList';
import ScrollableLesson from '../../Components/ScrollableLesson/ScrollableLesson';
import FilterBar from '../../Components/FilterBar/FilterBar';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import {
  list as projectActionList,
  reset as projectActionReset,
} from '../../Generated/actions/project/list';
import {
  list as tagsActionList,
  reset as tagsActionReset,
} from '../../Generated/actions/tags/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset,
} from '../../Generated/actions/educationallevel/list';
import {
  list as awardActionList,
  reset as awardActionReset
} from '../../Generated/actions/award/list';
import CheckBox from '../../Components/CheckBox/CheckBox';
import Loading from '../../Components/Loading/Loading';
import { WALKTHROUGH_URL } from '../../Constants/Constants';

const queryString = require('query-string');

const MORE_THAN_A_MONTH = 'MORE_THAN_A_MONTH';
const MORE_THEN_TWO_WEEKS = 'MORE_THEN_TWO_WEEKS';

const NEED_PARTNER = 'NEED_PARTNER';
const NO_CONTESTANT = 'NO_CONTESTANT';

const PapanPintar = () => {
  const [filterByLesson, setFilterByLesson] = useState([]);
  const [selectedFilterByGrade, setSelectedFilterByGrade] = useState([]);
  const [filterByGrade, setFilterByGrade] = useState([]);
  const [filterByTime, setFilterByTime] = useState([]);
  const [filterByType, setFilterByType] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [filterTab, setFilterTab] = useState(false);
  const listOfProject = useSelector((state) => state.project.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const [mappedTags, setMappedTags] = useState({});
  const listOfEducationalLevel = useSelector(
    (state) => state.educationallevel.list
  );
  const listOfAward = useSelector((state) => state.award.list);
  const dispatch = useDispatch();

  useEffect(() => {
    const toggleSection = document.getElementById('papan-pintar-toggle');
    const itemsSection = document.getElementById('project-list');
    const stickyHeader = toggleSection.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset >= stickyHeader - 100) {
        toggleSection.classList.add('sticky');
        itemsSection.classList.add('project-list-top-margin');
      }
      if (window.pageYOffset <= stickyHeader - 100) {
        toggleSection.classList.remove('sticky');
        itemsSection.classList.remove('project-list-top-margin');
      }
    });

    return () => {
      window.removeEventListener('scroll', () => scrollCallBack);
    };
  }, []);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);

  useEffect(() => {
    if (listOfProject.retrieved) {
      const projectAwards = listOfProject.retrieved['hydra:member']
        .filter((p) => !p.isDraft)
        .map((p) => `&id[]=${p.awards[0].split('/')[2]}`);
      dispatch(awardActionList(
        `/awards?rank=1${projectAwards.join('')}`
      ));
    }
    return () => {
      dispatch(awardActionReset(listOfAward.eventSource));
    };
  }, [listOfProject.retrieved]);

  useEffect(() => {
    if (listOfProject.retrieved) {
      setProjectList(listOfProject.retrieved['hydra:member'].filter((p) => !p.isDraft));
    }
  }, [listOfProject.retrieved]);

  useEffect(() => {
    const twoWeeksFromNow = new Date(Date.now() + 12096e5)
      .toLocaleDateString()
      .toString();
    const now = new Date();
    const monthFromNow = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      now.getDate()
    )
      .toLocaleDateString()
      .toString();

    let afterFilter;
    if (filterByTime.includes(MORE_THAN_A_MONTH)) {
      afterFilter = { 'endTime[after]': monthFromNow };
    }
    if (filterByTime.includes(MORE_THEN_TWO_WEEKS)) {
      afterFilter = { 'endTime[after]': twoWeeksFromNow };
    }

    let needPartnerFilter;
    if (filterByType.includes(NEED_PARTNER)) {
      needPartnerFilter = {
        'organizations.isOpenForJoin': true,
        'organizations.isLocked': false,
      };
    }

    let noContestantFilter;
    if (filterByType.includes(NO_CONTESTANT)) {
      noContestantFilter = { 'exists[organizations]': false };
    }

    dispatch(
      projectActionList(
        `/projects?${queryString.stringify(
          {
            isDraft: false,
            'tags.value': filterByLesson,
            educationalLevel: filterByGrade,
            'endTime[after]': moment().format('YYYY-MM-DD'),
            ...afterFilter,
            ...needPartnerFilter,
            ...noContestantFilter,
          },
          { arrayFormat: 'bracket' }
        )}`
      )
    );
    return () => {
      dispatch(projectActionReset(listOfProject.eventSource));
    };
  }, [
    dispatch,
    listOfProject.eventSource,
    filterByLesson,
    filterByGrade,
    filterByTime,
    filterByType]);

  useEffect(() => {
    if (listOfTags.retrieved) {
      const object = {};
      listOfTags.retrieved['hydra:member'].map((tag) => {
        object[`/tags/${tag.id}`] = tag;
        return null;
      });
      setMappedTags(object);
    }
  }, [listOfTags.retrieved]);

  const configStatefulMultiselect = {
    selectSomeItems: 'Pilih Kelas',
    allItemsAreSelected: 'Semua Kelas',
    selectAll: 'Semua Kelas',
    search: 'Cari'
  };

  const filterByTimeOptions = [
    {
      name: '2 Minggu+',
      value: MORE_THEN_TWO_WEEKS,
    },
    {
      name: '1 Bulan+',
      value: MORE_THAN_A_MONTH,
    },
  ];

  const filterByTypeOptions = [
    {
      name: 'Tim butuh partner',
      value: NEED_PARTNER,
    },
    {
      name: 'Belum ada kontestan',
      value: NO_CONTESTANT,
    },
  ];

  const renderJumbotron = () => {
    return (
      <div className="papan-pintar-jumbotron">
        <Container>
          <Row>
            <Col xs={6}>
              <h1>Papan Pintar</h1>
              <p>
                Tim terbaik yang berhasil membuat karya terseru akan mendapatkan
                <strong> hadiah!</strong>
                <br />
                Tunggu apa lagi? Buat dan kirim karya timmu sekarang!
              </p>
              <p>
                Pelajari Lebih Lanjut <Link to="/how-it-works">Cara Kerjanya</Link>
              </p>
            </Col>
            <Col xs={6}>
              <div className='video-container'>
                <div className='player-wrapper'>
                  <ReactPlayer
                    url={WALKTHROUGH_URL}
                    width='100%'
                    height='100%'
                    className='react-player'
                    controls
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const renderFilterSection = () => {
    const gradeOptions = listOfEducationalLevel.retrieved
      ? listOfEducationalLevel.retrieved['hydra:member'].map((levels) => {
        return {
          label: levels.level,
          value: levels['@id'],
        };
      })
      : [];

    return (
      <div id="papan-pintar-toggle" className="papan-pintar-toggle">
        <Container fluid>
          <Row noGutters={true}>
            <Col xs={12} lg={2}>
              <div className="papan-pintar-toggle-dropdown">
              <MultiSelect
                options={gradeOptions}
                value={selectedFilterByGrade}
                onChange={(grade) => {
                  setSelectedFilterByGrade(grade);
                  const changeSelected = grade.map((g) => g.value);
                  setFilterByGrade(changeSelected);
                }}
                labelledBy='Pilih Kelas'
                overrideStrings={configStatefulMultiselect}
                ItemRenderer={CheckBox}
              />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <ScrollableLesson
                filterByLessonOptions={
                  listOfTags.retrieved
                    ? listOfTags.retrieved['hydra:member']
                    : []
                }
                filterByLesson={filterByLesson}
                setFilterByLesson={setFilterByLesson}
              />
            </Col>
            <Col xs={12} lg={2}>
              <div className="papan-pintar-toggle-filter">
                <Button
                  className={`${filterTab && 'active-btn-filter'}`}
                  type="primary"
                  onClick={() => setFilterTab(!filterTab)}
                >
                  Filter <Sliders />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <FilterBar
                filterTab={filterTab}
                filterByTime={filterByTime}
                setFilterByTime={setFilterByTime}
                filterByTimeOptions={filterByTimeOptions}
                filterByType={filterByType}
                setFilterByType={setFilterByType}
                filterByTypeOptions={filterByTypeOptions}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const renderListOfProject = () => {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <ProjectList
              projectList={projectList}
              mappedTags={mappedTags}
              awardList={listOfAward.retrieved}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div className="papan-pintar">
      {listOfProject.loading && <Loading />}
      <NavigationBar active='papanPintar' />
      {renderJumbotron()}
      {renderFilterSection()}
      {renderListOfProject()}
    </div>
  );
};

export default PapanPintar;
