import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import { X, ExclamationTriangleFill } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';

const WarningModal = ({ showModal, setShowModal, children }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal()}
      dialogClassName='free-material__modal'
      centered
    >
      <div className='close' onClick={() => setShowModal()}>
        <X />
      </div>
      <Modal.Body>
        <Row>
          <ExclamationTriangleFill color='#FFD300' size={60} />
          <div className='align-self-center ml-3'>
            {children}
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

WarningModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default WarningModal;
