import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { list as subthemeListAction, reset as resetSubthemeListAction } from '../../Generated/actions/subtheme/list';
import { list as themeListAction, reset as resetThemeListAction } from '../../Generated/actions/theme/list';

export default function GetThemeAndSubThemeNameFormObject({ projectDetail }) {
  const dispatch = useDispatch();
  // set team logic
  const subthemeEvent = useSelector((state) => state.subtheme.list.eventSource);
  const themeEvent = useSelector((state) => state.theme.list.eventSource);

  useEffect(() => {
    dispatch(subthemeListAction());
    dispatch(themeListAction());
    return () => {
      dispatch(resetSubthemeListAction(subthemeEvent));
      dispatch(resetThemeListAction(themeEvent));
    };
  }, []);
  const subthemeList = useSelector((state) => state.subtheme.list.retrieved);
  const themeList = useSelector((state) => state.theme.list.retrieved);
  const [theme, setTheme] = useState('');
  useEffect(() => {
    if (subthemeList && themeList && !_.isEmpty(projectDetail)) {
      let string = '';
      const foundSubtheme = subthemeList['hydra:member']
        .find((subtheme) => subtheme['@id'] === projectDetail.subtheme);

      if (foundSubtheme) {
        string += foundSubtheme.name;
        const foundTheme = themeList['hydra:member']
          .find((i) => i['@id'] === foundSubtheme.theme);
        if (foundTheme) {
          string += ` | ${foundTheme.name}`;
        }
      }

      setTheme(string);
    }
  }, [subthemeList, themeList, projectDetail]);

  return <>{theme}</>;
}

GetThemeAndSubThemeNameFormObject.propTypes = {
  projectDetail: PropTypes.object
};
