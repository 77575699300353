import React from 'react';
import { Dot } from 'react-animated-dots';
import './Loading.scss';

const Loading = () => {
  return (
    <>
      <div className='loading-component'>
        <div className='loading-component-image'>
          <div className='loading-component-image-text'>
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
