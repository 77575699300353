import React, { useEffect, useState } from 'react';
import { StarFill, Trophy } from 'react-bootstrap-icons';
import { Image } from 'react-bootstrap';
import axios from 'axios';
import Button from '../Button/Button';
import whatsappIcon from '../../Assets/Images/whatsapp-icon.png';
import emailIcon from '../../Assets/Images/icon_email.png';
import { FETCH_USERS, IMAGE_OBJECTS, OPTION_TAGS_URL } from '../../Constants/Constants';

const ProposalMemberItem = ({ members }) => {
  const [memberImage, setMemberImage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [mappedTags, setMappedTags] = useState(null);

  useEffect(() => {
    (async () => {
      if (members) {
        const getImageUrl = members.image.split('/')[2];
        const { data: memberImages } = await axios.get(`${IMAGE_OBJECTS}/${getImageUrl}`);
        setMemberImage(memberImages);
      }
    })();
  }, [members]);

  useEffect(() => {
    (async () => {
      if (members) {
        const getUserId = members.user.split('/')[2];
        const { data: userDataValue } = await axios.get(`${FETCH_USERS}/${getUserId}`);
        setUserData(userDataValue);
      }
    })();
  }, [members]);

  useEffect(() => {
    (async () => {
      if (userData) {
        let tagArray = [];
        userData.tags.map((tag) => {
          tagArray = [...tagArray, tag];
          return null;
        });
        const tagArrayString = tagArray.join('&id[]=');
        const { data: tagData } = await axios.get(`${OPTION_TAGS_URL}?id[]=${tagArrayString}`);
        setMappedTags(tagData);
      }
    })();
  }, [userData]);

  return (
    <div className='question-answer-overview-modal-profile'>
      <div className='question-answer-overview-modal-profile-wrapper'>
        <div className='question-answer-overview-modal-profile-avatar'>
          <img src={memberImage && memberImage.contentUrl} alt='' />
        </div>
        <div className='question-answer-overview-modal-profile-info'>
          <h2>{userData && userData.username}</h2>
          <p>
            {members.worksFor} <div className='middle-dot' />{' '}
            {mappedTags && mappedTags['hydra:member'].map((tag, index) => {
              if (index === 0) {
                return tag.name;
              }
              return <>
                {' '} <div className='middle-dot'/> {' '}
                {tag.name}
              </>;
            })}
          </p>
          <div className='question-answer-overview-modal-profile-info-other'>
            {members && members.aggregateRating && <StarFill/>}
            {' '}
            {members && members.aggregateRating }
            {' '}
            {members && members.aggregateWins && <Trophy/> }
            {' '}
            {members && members.aggregateWins}
          </div>
        </div>
      </div>
      <div className='question-answer-overview-modal-profile-whatsapp'>
        <Button onClick={() => window.open(`mailto:${userData.email}`, '_blank')}>
          <Image src={emailIcon} width={22} /> Email
        </Button>
        <Button onClick={() => window.open(`https://wa.me/${members.phoneNumber}`, '_blank')}>
          <Image src={whatsappIcon} /> Whatsapp
        </Button>
      </div>
    </div>
  );
};

ProposalMemberItem.propTypes = {

};

export default ProposalMemberItem;
