/* eslint-disable max-len */
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TTP_API_BASE_URL = process.env.REACT_APP_TTP_API_BASE_URL;
export const HASH_DECRYPT = process.env.REACT_APP_HASH_DECRYPT;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const AWS_WATERMARK_API_KEY = process.env.REACT_APP_AWS_WATERMARK_API_KEY;
export const AWS_WATERMARK_URL = process.env.REACT_APP_AWS_WATERMARK_URL;
export const LOGIN_URL = '/authentication_token';
export const REFRESH_TOKEN = '/token/refresh';
export const FETCH_USERS = '/users';
export const CREATIVE_WORKS_URL = '/creative_works';
export const OPTION_TAGS_URL = '/tags';
export const ADDRESS_REGION = '/address_regions';
export const MEDIA_OBJECTS = '/media_objects';
export const IMAGE_OBJECTS = '/image_objects';
export const ARCHIVE_OBJECTS = '/archive_objects';
export const VIDEO_OBJECTS = '/video_objects';
export const DOCUMENT_OBJECTS = '/document_objects';
export const AUDIO_OBJECTS = '/audio_objects';
export const ANIMATION_OBJECTS = '/animation_objects';
export const MEDIA_OBJECTS_PUT_LINK = '/media_objects/get-put-link';
export const IMAGE_OBJECTS_PUT_LINK = '/image_objects/give-me-put-link';
export const ARCHIVE_OBJECTS_PUT_LINK = '/archive_objects/give-me-put-link';
export const VIDEO_OBJECTS_PUT_LINK = '/video_objects/give-me-put-link';
export const DOCUMENT_OBJECTS_PUT_LINK = '/document_objects/give-me-put-link';
export const AUDIO_OBJECTS_PUT_LINK = '/audio_objects/give-me-put-link';
export const PERSON_URL = '/people';
export const ORGANIZATION_UNCONFIRMED = '/organization_unconfirmed_members';
export const QUESTIONS_URL = '/questions';
export const ANSWERS_URL = '/answers';
export const ORGANIZATION_URL = '/organizations';
export const PROJECT_URL = '/projects';
export const AWARDS_URL = '/awards';
export const LESSON_URL = '/lessons';
export const SEND_TOKEN_TO_EMAIL_URL = '/user/send-token-to-email';
export const CONTACT_URL = '/contacts';
export const LESSON_QUESTION_URL = '/lesson_questions';
export const LESSON_ANSWER_URL = '/lesson_answers';
export const BANKS_URL = '/banks';
export const BANK_ACCOUNTS_URL = '/bank_accounts';

export const TEACHER_GUIDELINE_URL =
  'https://prod-s3-direct-upload-bucket-sahabatpintar.s3-ap-southeast-1.amazonaws.com/Panduan+Untuk+Guru+(Bahasa).mp4';
export const DESIGN_GUIDELINE_URL =
  'https://drive.google.com/file/d/13pkPFEUwohJieAv6ks9WEkAO5eHOuqqe/view?usp=sharing';
export const ANIMATION_GUIDELINE_URL =
  'https://prod-s3-direct-upload-bucket-sahabatpintar.s3-ap-southeast-1.amazonaws.com/Pedoman+Animasi.mp4';
export const ASSET_GUIDELINE_URL =
  'https://drive.google.com/drive/folders/1BdTbHXUVmpAzGx_Afz-QkVFqZsmAViUQ?usp=sharing';
// export const LESSON_GUIDELINE_URL =
//  'https://drive.google.com/file/d/1jNqBTtRn8i_T0XicAFWsedxceljA3FfG/view?usp=sharing';
export const LESSON_GUIDELINE_URL =
  'https://drive.google.com/file/d/1joLXMUpgA104nsoHfSZ-IhW-oPVCrcnq/view?usp=sharing';
// export const LESSON_GUIDELINE_URL = 'https://drive.google.com/file/d/1Olgmi9CfUMPfaR_j6Gj3MIsbWbjUdRUM/view?usp=sharing';
export const WALKTHROUGH_URL =
  'https://prod-s3-direct-upload-bucket-sahabatpintar.s3-ap-southeast-1.amazonaws.com/static/Sahabat_Pintar_Walkthrough_Bahasa_x264.mp4';
export const HOW_ITS_WORK_URL = 'https://storage.googleapis.com/titikpintar/20210126-cara-kerja.mp4';

export const QUESTION_IMAGE_WIDTH = 1440;
export const QUESTION_IMAGE_HEIGHT = 2960;

export const QUESTION_DIFFICULTY = [
  {
    value: 25,
    label: 'Mudah',
  },
  {
    value: 50,
    label: 'Sedang',
  },
  {
    value: 75,
    label: 'Sulit',
  },
];

export const noop = () => {};

export const headerConfig = {
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
};

export const headerAuthorizedLDJsonConfig = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    'Content-Type': 'application/ld+json',
  },
};

export const headerLDJsonConfig = {
  headers: {
    'Content-Type': 'application/ld+json',
  },
};

export const headerImageConfig = (files) => ({
  headers: {
    Authorization: '',
    'Content-Type': files.type,
  },
});

export const headerBlob = {
  responseType: 'blob',
};
