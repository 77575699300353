const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'IS_ERROR':
      return {
        ...state,
        isError: action.payload
      };
    case 'IS_SUCCESS_REGISTER':
      return {
        ...state,
        isSuccessRegister: action.payload
      };
    case 'SIGN_OUT':
      return {

      };
    case 'REFRESHING_TOKEN':
      return {
        ...state,
        freshTokenPromise: action.freshTokenPromise
      };
    case 'AUTH_REFRESH_SUCCESS':
      return {
        ...state,
        freshTokenPromise: null
      };
    case 'AUTH_REFRESH_ERROR':
      return {
        ...state,
        freshTokenPromise: null,
        error: 'something is wrong, reload the page'
      };
    default:
      return state;
  }
};
