import { Col, Image, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import CheckList from '../../Assets/Images/checklist.png';
import Animation from '../../Assets/Images/animation.png';
import Briefing from '../../Assets/Images/briefing.png';
import Button from '../Button/Button';
import Bubble from '../../Assets/Images/bubble.png';
import ModalGuidelines from '../ModalGuidelines/ModalGuidelines';
import {
  ANIMATION_GUIDELINE_URL,
  ASSET_GUIDELINE_URL,
  DESIGN_GUIDELINE_URL,
  TEACHER_GUIDELINE_URL,
  LESSON_GUIDELINE_URL
} from '../../Constants/Constants';

const ProposalGuidelines = () => {
  const [selectedGuideline, setSelectedGuideline] = useState('animation');
  const [showPreview, setShowPreview] = useState(false);
  const [guidelineDetail, setGuidelineDetail] = useState({});

  const handleDownload = (role) => {
    if (role === 'guru') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Guru',
        fileName: 'Panduan Untuk Guru (Bahasa).mp4',
        url: TEACHER_GUIDELINE_URL
      });
    }
    if (role === 'animasi') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Animasi',
        fileName: 'Pedoman Animasi.mp4',
        url: ANIMATION_GUIDELINE_URL
      });
    }
    if (role === 'desain') {
      window.open(DESIGN_GUIDELINE_URL);
    }
    if (role === 'aset') {
      window.open(ASSET_GUIDELINE_URL);
    }
    if (role === 'lesson') {
      window.open(LESSON_GUIDELINE_URL);
    }
  };

  return (
    <Row as='div' className='contestant-proposal-guidelines'>
      <Col xs={2}>
        <div className='question-answer-overview-title'>
          <Row>
            <Col xs={12}>
              <Image src={CheckList} fluid/>
            </Col>
            <Col className="mt-2" xs={10}>
              <h1>Pedoman <br/> Proposal</h1>
            </Col>
            <span className='contestant-proposal-gold-text contestant-proposal-guidelines-left-sub'>
                Pelajari Cara Kerja
            </span>
          </Row>
        </div>
      </Col>

      <Col xs={10} as='div' className='contestant-proposal-guidelines-right'>
        <div className='question-answer-overview-content'>
          <div className=''>
            Setiap soal yang dikirimkan harus terdiri dari:
          </div>
          <div className='contestant-proposal-guidelines-cards'>
            <div
              className={`contestant-proposal-guidelines-cards-card 
              ${selectedGuideline === 'briefing' ? 'active' : ''}`}
              onClick={() => setSelectedGuideline('briefing')}
            >
              <span className='contestant-proposal-guidelines-cards-card-heading small'>Pengarahan</span>
              <span className='contestant-proposal-guidelines-cards-card-heading'>Teks & Adegan</span>
              <img src={Briefing} alt="" className='contestant-proposal-guidelines-cards-card-img'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-small'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-big'/>
              <div className='contestant-proposal-guidelines-cards-card-btn'>
                <Button onClick={() => handleDownload('guru')} type='secondary' block>Lihat Pedoman Guru</Button>
                <Button onClick={() => handleDownload('lesson')} type='secondary' block>
                  Unduh Template Pelajaran
                </Button>
              </div>
            </div>

            <div
              className={`contestant-proposal-guidelines-cards-card 
              ${selectedGuideline === 'animation' ? 'active' : ''}`}
              onClick={() => setSelectedGuideline('animation')}
            >
              <span className='contestant-proposal-guidelines-cards-card-heading small'>1 - 2 menit</span>
              <span className='contestant-proposal-guidelines-cards-card-heading'>Animasi</span>
              <img src={Animation} alt="" className='contestant-proposal-guidelines-cards-card-img'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-small'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-big'/>
              <div className='contestant-proposal-guidelines-cards-card-btn'>
                <Button onClick={() => handleDownload('animasi')} type='secondary' block>
                  Lihat Pedoman Animasi
                </Button>
              </div>
            </div>

            <div
              className={`contestant-proposal-guidelines-cards-card 
              ${selectedGuideline === 'questionAnswers' ? 'active' : ''}`}
              onClick={() => setSelectedGuideline('questionAnswers')}
            >
              <span className='contestant-proposal-guidelines-cards-card-heading small'>15 (Lima Belas)</span>
              <span className='contestant-proposal-guidelines-cards-card-heading'>Tanya & Jawab</span>
              <img src={Bubble} alt="" className='contestant-proposal-guidelines-cards-card-img'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-small'/>
              <div className='contestant-proposal-guidelines-cards-card-circle-big'/>
              <div className='contestant-proposal-guidelines-cards-card-btn'>
                <Button type='secondary' block onClick={() => handleDownload('desain')}>
                  Lihat Pedoman Desain
                </Button>
                <Button type='secondary' block onClick={() => handleDownload('aset')}>
                  Unduh Paket Aset
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <ModalGuidelines showModal={showPreview} setShowModal={setShowPreview} guidelineDetail={guidelineDetail} />
    </Row>
  );
};

export default ProposalGuidelines;
