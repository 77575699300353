import * as Yup from 'yup';

export const emailCheckSchema = Yup.object().shape({
  email: Yup.string().email('Alamat email tidak valid!').required('Harap isi alamat email!'),
});

export const resetPassword = Yup.object().shape({
  password: Yup
    .string()
    .min(7, 'Kata sandi terlalu pendek!')
    .matches(
      /^(?=.*[0-9])/,
      'Harus terdiri dari 7 karakter dan 1 angka'
    )
    .required('Harap isi kata sandi!'),
  confirmedPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Kata sandi tidak cocok!'
    )
  })
});
