import React, { useState } from 'react';
import { Modal, FormControl, Tab, Tabs } from 'react-bootstrap';
import { ArrowLeft, PersonPlusFill } from 'react-bootstrap-icons';
import './AddFriend.scss';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import Button from '../Button/Button';
import reviewSuccess from '../../Assets/Images/review-success.png';
import RenderMembers from './RenderMembers';
import RenderSearchMember from './RenderSearchMember';
import RenderRecipients from './RenderRecipients';

const AddFriend = (props) => {
  const {
    show,
    handleClose,
    handleChange,
    search,
    recipients,
    handleClickAddTeacherRecipient,
    handleClickAddDesignerRecipient,
    handleCancelRecipient,
    counter,
    handleSubmitRecipients,
    isSubmit,
    dataFilteredTeacher,
    dataFilteredDesigner,
    handleGoBack,
  } = props;
  const [searchValue, setSearchValue] = useState('');

  const renderSearch = () => {
    function handleLocalChange(event) {
      const { value } = event.target;
      setSearchValue(value);
      handleChange(value);
    }

    return (
      <div className='add-friend-modal-search'>
        <FormControl value={searchValue} onChange={handleLocalChange} placeholder='Cari nama peserta' />
      </div>
    );
  };

  const list = RenderRecipients({
    handleCancelRecipient,
    recipients,
  });

  return (
    <div className='add-friend'>
      <Modal show={show} onHide={handleClose} dialogClassName='add-friend-modal' backdrop='static' centered>
        {!isSubmit ? (
          <>
            <Modal.Header closeButton>
              <ArrowLeft className='add-friend-modal-back' onClick={handleGoBack} />
              <Modal.Title>
                Undang Peserta Terbaik <br />
                untuk Tim Barumu!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs defaultActiveKey='designer'>
                <Tab eventKey='designer' title='Desainer'>
                  <div className='add-friend-modal-scrollable'>
                    {renderSearch()}
                    <p>
                      <span>Catatan:</span> Kamu tidak perlu mengundang animator di timmu karena animasi Sahabat Pintar
                      akan membantu kamu
                    </p>
                    {search === '' ? (
                      <RenderMembers
                        dataMembers={dataFilteredDesigner}
                        handleClickAddRecipient={handleClickAddDesignerRecipient}
                      />
                    ) : (
                      <RenderSearchMember
                        dataMembers={dataFilteredDesigner}
                        handleClickAddRecipient={handleClickAddDesignerRecipient}
                      />
                    )}
                  </div>
                </Tab>
                <Tab eventKey='teacher' title='Guru'>
                  <div className='add-friend-modal-scrollable'>
                    {renderSearch()}
                    {search === '' ? (
                      <RenderMembers
                        dataMembers={dataFilteredTeacher}
                        handleClickAddRecipient={handleClickAddTeacherRecipient}
                      />
                    ) : (
                      <RenderSearchMember
                        dataMembers={dataFilteredTeacher}
                        handleClickAddRecipient={handleClickAddTeacherRecipient}
                      />
                    )}
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              {recipients.length > 0 ? (
                <div className='add-friend-modal-list'>
                  <span>Penerima</span>
                  <ScrollMenu
                    data={list}
                    wheel={false}
                    dragging={true}
                    alignCenter={false}
                    hideArrows={true}
                    hideSingleArrow={true}
                  />
                </div>
              ) : null}
              <div className='add-friend-modal-submit'>
                <Button type={counter === 0 ? 'disabled' : null} block={true} onClick={handleSubmitRecipients}>
                  <PersonPlusFill /> Undang Bergabung {counter === 0 ? null : `(${counter})`}
                </Button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className='add-friend-modal-done'>
                <div className='add-friend-modal-done-image'>
                  <img src={reviewSuccess} alt='' />
                </div>
                <div className='add-friend-modal-done-caption'>
                  <h4>Yay! Undangan anda telah dikirim!</h4>
                  <p>Anda bisa memulai proposal setelah tim anda terbentuk. Semoga beruntung dengan tim baru anda!</p>
                </div>
                <div className='add-friend-modal-done-invitation'>
                  Cek <span className='add-friend-modal-done-invitation-link'>undangan anda</span> atau coba{' '}
                  <span className='add-friend-modal-done-invitation-link'>undang anggota</span>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddFriend;
