import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
  Form, Button, Image, Alert
} from 'react-bootstrap';
import './SignUp.scss';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import signUpIllustration from '../../Assets/Images/signup-illustration.png';
import Input from '../../Components/Input/Input';
import { signUpSchema } from '../../Schemas/AuthenticationSchema';
import { signUp } from '../../Actions/Auth';
import { capitalizeFirst } from '../../Helpers/Common';
import CheckBox from '../../Components/CheckBox/CheckBox';

const SignUp = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeField, setActiveField] = useState('');
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      acceptTerms: false
    },
    validationSchema: signUpSchema,
  });

  const {
    setFieldValue, errors, values,
    validateForm, setStatus, status
  } = formik;

  const { history } = props;

  useEffect(() => {
    if (status) {
      setIsLoading(false);
    }
  }, [status]);

  const handleSignUp = async (userData) => {
    setStatus();
    const validate = await validateForm();
    if (isEmpty(validate)) {
      setIsLoading(true);
      const registerUser = {
        id: uuidv4(),
        username: userData.username,
        email: userData.email,
        password: userData.password
      };
      dispatch(signUp(registerUser, history, setStatus));
    }
  };

  const handleErrorSignUp = () => {
    return status && (
      <div className='sign-up-form-input-error'>
        <Alert variant='danger'>
          {status.map((statusMessage) => (
            <ul key={statusMessage}>
              <li>
                {`${capitalizeFirst(statusMessage.propertyPath)}: ${statusMessage.message}`}
              </li>
            </ul>
          ))}
        </Alert>
      </div>
    );
  };

  const handleOnChangeName = (event) => {
    const { target: { value } } = event;
    setFieldValue('username', value);
  };

  const handleOnChangeEmail = (event) => {
    const { target: { value } } = event;
    setFieldValue('email', value);
  };

  const handleOnChangePassword = (event) => {
    const { target: { value } } = event;
    setFieldValue('password', value);
  };

  const handleOnChangeTerms = () => {
    setFieldValue('acceptTerms', !values.acceptTerms);
  };

  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const renderSignUpForm = () => {
    return (
      <div className='sign-up-form-input'>
        {handleErrorSignUp()}
        <Form>
          <Input
            controlId='nameInput'
            label='Nama Lengkap'
            type='text'
            placeholder='Masukkan nama lengkap'
            isInvalid={!isEmpty(errors.username)}
            errorMessage={errors.username}
            onChange={handleOnChangeName}
            onFocus={() => setActiveField('username')}
            onBlur={() => setActiveField()}
            addInputClass={values.username !== '' && activeField === 'username' ? 'filled' : ''}
          />
          <Input
            controlId='emailInput'
            label='Alamat Email'
            type='email'
            placeholder='Alamat Email'
            isInvalid={!isEmpty(errors.email)}
            errorMessage={errors.email}
            onChange={handleOnChangeEmail}
            onFocus={() => setActiveField('email')}
            onBlur={() => setActiveField()}
            addInputClass={values.email !== '' && activeField === 'email' ? 'filled' : ''}
          />
          <Input
            controlId='passwordInput'
            label='Kata Sandi'
            type={showPassword ? 'text' : 'password'}
            placeholder='Minimal 7 karakter'
            isInvalid={!isEmpty(errors.password)}
            errorMessage={errors.password}
            onChange={handleOnChangePassword}
            onFocus={() => setActiveField('password')}
            onBlur={() => setActiveField()}
            addInputClass={values.password !== '' && activeField === 'password' ? 'filled' : ''}
          />
          <Form.Text onClick={togglePassword} className="text-muted" style={{ cursor: 'pointer' }}>
            {showPassword ? 'Sembunyikan' : 'Tampilkan'} kata sandi
          </Form.Text>
          <Form.Group controlId='terms-and-policy'>
            <CheckBox
              label={(
                <span>
                  Saya sudah membaca dan menerima&nbsp;
                  <Link to='/' className='sign-up-form-input-terms-policy'>
                    Syarat dan Ketentuan
                  </Link>
                </span>
              )}
              onChange={handleOnChangeTerms}
              className='sign-up-form-input-label-checkbox'
            />
            {errors.acceptTerms && (
              <small className="form-text text-danger">
                {errors.acceptTerms}
              </small>
            )}
            {/* <Form.Check
              className='sign-up-form-input-label-checkbox'
              type='checkbox'
              onChange={handleOnChangeTerms}
              isInvalid={!isEmpty(errors.acceptTerms)}
              label={(
                <span>
                  Saya sudah membaca dan menerima&nbsp;
                  <Link to='/' className='sign-up-form-input-terms-policy'>
                    Syarat dan Ketentuan
                  </Link>
                </span>
              )}
            /> */}
          </Form.Group>
        </Form>
        <Row>
          <Col xs={12}>
            <Button
              className='sign-up-form-input-button'
              type='submit'
              onClick={() => handleSignUp(values)}
              disabled={isLoading}
            >
              {isLoading ? 'Sedang memulai….' : 'Daftar'}
            </Button>
          </Col>
          <Col xs={12} align='center'>
            <div className='sign-up-form-input-link'>
              Sudah jadi member? &nbsp;
              <Link to='/login' className='sign-up-form-input-link-sign-in'>
                Masuk
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className='sign-up'>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <div className='sign-up-left-section'>
              <div className='sign-up-round'>
                <div className='sign-up-greetings'>
                  <h1>
                    Ayo tunjukkan <br />
                    karyamu terbaikmu <br />
                    di Sahabat Pintar
                  </h1>
                </div>
              </div>
              <div className='sign-up-illustration'>
                <Image src={signUpIllustration} width='475' />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className='sign-up-right-section'>
              <div className={`sign-up-form ${status && 'set-min-height'}`}>
                <Row>
                  <Col xs={12}>
                    <h1 className='sign-up-form-title'>Daftar</h1>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {renderSignUpForm()}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
