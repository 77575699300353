import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './PhoneInput.scss';

const PhoneInput = (
  {
    controlId,
    label,
    errorMessage,
    onChange,
    value,
    isInvalid,
    inputClassname
  }
) => {
  return (
    <div className='form-input'>
      <Form.Group controlId={controlId}>
        <Form.Label className='form-input-label'>{label}</Form.Label>
        <div className={`phone-input ${isInvalid && 'phone-input-invalid'}`} >
          <div className={`phone-input-country-code ${isInvalid && 'country-code-invalid'}`} >
            +62
          </div>
          <input type="text"
            onChange={onChange}
            value={value}
            className={`phone-input-number ${inputClassname}`}
          />
        </div>
        <span className='phone-input-help-text text-danger'>{isInvalid && errorMessage}</span>
      </Form.Group>
    </div>
  );
};

PhoneInput.propTypes = {
  controlId: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.any,
};

export default PhoneInput;
