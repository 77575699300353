import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  ChevronDown,
  ChevronUp
} from 'react-bootstrap-icons';
import './ProfileDropdown.scss';
import avatarImage from '../../Assets/Images/avatar-image.png';
import { decryptData } from '../../Helpers/Common';
import { HASH_DECRYPT } from '../../Constants/Constants';

const ProfileDropdown = (props) => {
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const {
    name
  } = props;
  const wrapperRef = useRef(null);
  const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
  const userAvatar = localStorage.getItem('avatar');

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current
      && !wrapperRef.current.contains(event.target)
    ) {
      setIsActiveDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleActiveDropdown = () => {
    setIsActiveDropdown(!isActiveDropdown);
  };

  const resultName = name.split(' ');

  return (
    <div className='profile-dropdown' ref={wrapperRef}>
      <div
        className={`profile-dropdown-button 
        ${isActiveDropdown ? 'profile-dropdown-button-active' : ''}`}
        onClick={handleActiveDropdown}
      >
        <div className={`profile-dropdown-button-avatar 
        ${isActiveDropdown ? 'profile-dropdown-button-avatar-active' : ''}`}>
          <img src={userAvatar || avatarImage} alt='' />
        </div>
        <p>
          {resultName.length >= 2
            ? `${resultName[0]} ${resultName[1].charAt(0).toUpperCase()}.`
            : name}
        </p>
        <div className='profile-dropdown-button-arrow'>
          {isActiveDropdown ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {isActiveDropdown
        && <div
          className='profile-dropdown-content'>
          <ul>
            {loggedUser.roles.includes('ROLE_ADMIN') && (
              <li>
                <NavLink to='/admin/lesson-requests'>
                  Menu Admin
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to='/profile'>
                Lihat Profil
              </NavLink>
            </li>
            {!loggedUser.roles.includes('ROLE_ADMIN') && (
              <li>
                <NavLink to='/my-projects'>
                  Proyek Saya
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to='/logout'>
                Keluar
              </NavLink>
            </li>
          </ul>
        </div>}
    </div>
  );
};

ProfileDropdown.propTypes = {
  name: PropTypes.string,
  dropdownContents: PropTypes.array
};

ProfileDropdown.defaultProps = {
  name: '',
  dropdownContents: []
};

export default ProfileDropdown;
