import React from 'react';
import UserListWithProfile from '../User/UserListWithProfile';

const RenderSearchMember = ({ dataMembers, handleClickAddRecipient }) => {
  return (
    <div className='add-friend-modal-content'>
      <span>Search</span>
      <div className='add-friend-modal-member'>
        {dataMembers.length > 0 ? (
          dataMembers.map((item) => (
            <UserListWithProfile item={item} handleClick={handleClickAddRecipient} key={item.id}/>
          ))
        ) : (
          <p className='add-friend-modal-member-not-found'>User Not Found</p>
        )}
      </div>
    </div>
  );
};

export default RenderSearchMember;
