import React, { useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import Button from '../Button/Button';
import ProposalMemberItem from '../ProposalMemberItem/ProposalMemberItem';
import './ModalTutorial.scss';
import CongratsImage from '../../Assets/Images/request-join-team.png';
import RememberContact from '../../Assets/Images/open-to-anyone.png';

const ModalTutorial = ({ isShowModal, setIsShowModal, mappedMembers }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleHideModal = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <Modal
      show={isShowModal}
      onHide={() => {}}
      dialogClassName='tutorial-modal'
      centered
    >
      <Modal.Body>
        <Carousel
          interval={null}
          controls={false}
          defaultActiveIndex={0}
          activeIndex={slideIndex}
        >
          <Carousel.Item>
            <img src={CongratsImage} alt="" width='100%' />
            <h4 className='text-center mt-3 mb-3'>
              Selamat! Tim kamu sudah lengkap
              dan siap mengikuti kompetisi!
            </h4>
            <p className='text-center mb-3'>
              Hubungi anggota timmu untuk mulai membuat proyek.
            </p>
            <Button block onClick={() => setSlideIndex(1)}>Lanjut</Button>
          </Carousel.Item>
          <Carousel.Item className='p-2'>
            <h5 className='text-center'>Anggota Kelompok</h5>
            {mappedMembers && mappedMembers.map((members, i) => (
              <ProposalMemberItem members={members} key={i} />
            ))}
            <Button block onClick={() => setSlideIndex(2)}>Lanjut</Button>
          </Carousel.Item>
          <Carousel.Item>
          <img src={RememberContact} alt="" width='100%' />
            <h5 className='text-center mt-3 mb-3'>
              Kamu bisa temukan detail kontak timmu dengan mengklik ikon avatar di bawah Anggota Kelompok.
            </h5>
            <Button block onClick={handleHideModal}>Oke, mengerti!</Button>
          </Carousel.Item>
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

ModalTutorial.propTypes = {

};

export default ModalTutorial;
