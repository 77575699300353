const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'HAS_ERROR':
      return {
        ...state,
        hasError: action.payload
      };
    case 'IS_SUCCESS_EMAIL_SENT':
      return {
        ...state,
        emailSent: action.payload
      };
    case 'PASSWORD_CHANGE_SUCCESS':
      return {
        ...state,
        passwordChangeSuccess: action.payload
      };
    default:
      return state;
  }
};
