import { AlertList } from 'react-bs-notifier';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset
  as organizationActionErrorReset
} from '../../Generated/actions/organization/create';
import {
  reset
  as organizationUnconfirmedMembersActionReset
} from '../../Generated/actions/organizationunconfirmedmembers/create';

const ContestantProposalAlert = () => {
  const [alerts, setAlerts] = useState([]);

  const OrganizationStore = useSelector((state) => state.organization);
  const OrganizationunconfirmedmembersStore = useSelector((state) => state.organizationunconfirmedmembers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (OrganizationStore.create.error) {
      const type = 'danger';
      const headline = 'Terjadi kesalahan';
      setAlerts((oldAlerts) => ([...oldAlerts, {
        id: uuidv4(),
        type,
        headline: `Whoa, ${headline} ! `,
        message: OrganizationStore.create.error
      }]));
      dispatch(organizationActionErrorReset());
    }
  }, [OrganizationStore.create.error]);
  useEffect(() => {
    if (OrganizationunconfirmedmembersStore.create.error) {
      const type = 'danger';
      const headline = 'Terjadi kesalahan';
      setAlerts((oldAlerts) => ([...oldAlerts, {
        id: uuidv4(),
        type,
        headline: `Whoa, ${headline} ! `,
        message: OrganizationunconfirmedmembersStore.create.error
      }]));
      dispatch(organizationUnconfirmedMembersActionReset());
    }
  }, [OrganizationunconfirmedmembersStore.create.error]);

  useEffect(() => {
    if (OrganizationStore.create.created) {
      const type = 'success';
      const headline = 'Tim telah berhasil dibuat';
      setAlerts((oldAlerts) => ([...oldAlerts, {
        id: uuidv4(),
        type,
        headline: `Whoa, ${headline} ! `,
        message: `${OrganizationStore.create.created.name.replace(' and ', ' dan ')} telah berhasil di buat`
      }]));
    }
  }, [OrganizationStore.create.created]);

  useEffect(() => {
    if (OrganizationunconfirmedmembersStore.create.created) {
      const type = 'success';
      const headline = 'Undangan kamu telah berhasil dibuat ';
      setAlerts((oldAlerts) => ([...oldAlerts, {
        id: uuidv4(),
        type,
        headline: `Whoa, ${headline} ! `,
      }]));
    }
  }, [OrganizationunconfirmedmembersStore.create.created]);

  const onDismissed = React.useCallback((alert) => {
    setAlerts((oldAlerts) => {
      const idx = oldAlerts.indexOf(alert);
      if (idx < 0) return oldAlerts;
      return [...oldAlerts.slice(0, idx), ...oldAlerts.slice(idx + 1)];
    });
  }, []);

  return (<AlertList dismissTitle="Begone!" position="top-right" timeout={2000}
                      alerts={alerts} onDismiss={onDismissed} />);
};

export default ContestantProposalAlert;
