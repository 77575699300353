import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const ImitateUser = (props) => {
  useEffect(() => {
    localStorage.setItem('imitating_email', props.match.params.email);
  }, []);

  return (
        <div>
            <Redirect to={'/'}></Redirect>
        </div>
  );
};

export default ImitateUser;
