import React from 'react';
import UserListWithProfile from '../User/UserListWithProfile';

const RenderMembers = ({ dataMembers, handleClickAddRecipient }) => {
  return (
    <>
      {/* <div className='add-friend-modal-content'> */}
      {/*  <span>Suggestions</span> */}
      {/*  <div className='add-friend-modal-member'> */}
      {/*    {dataMembers && dataMembers.length > 0 ? ( */}
      {/*      dataMembers.map((item) => ( */}
      {/*        <UserListWithProfile item={item} handleClick={handleClickAddRecipient} /> */}
      {/*      )) */}
      {/*    ) : ( */}
      {/*      <p className='add-friend-modal-member-not-found'> */}
      {/*        User Not Found */}
      {/*      </p> */}
      {/*    )} */}
      {/*  </div> */}
      {/* </div> */}
      <div className='add-friend-modal-content'>
        <span>Semua Anggota</span>
        <div className='add-friend-modal-member'>
          {dataMembers && dataMembers.length > 0 ? (
            dataMembers.map((item) =>
              <UserListWithProfile item={item} handleClick={handleClickAddRecipient} />)
          ) : (
            <p className='add-friend-modal-member-not-found'>
              User Not Found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default RenderMembers;
