import PropTypes from 'prop-types';
import { StarFill, Trophy } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagName from '../Tags/TagName';
import Button from '../Button/Button';
import HandleEscapeKey from '../Input/HandleEscapeKey';
import avatarImage from '../../Assets/Images/avatar-image.png';
import { exit, removeMember } from '../../Generated/actions/organization/update';
import { reject } from '../../Generated/actions/organizationunconfirmedmembers/update';
import NoProjectImage from '../../Assets/Images/open-to-anyone.png';
import './RenderTeamRemovalModal.scss';

const RenderTeamRemovalModal = ({
  showTeamRemovalModal,
  setShowTeamRemovalModal,
  organization,
  isOwner,
  setIsShowModal,
  removeUnconfirmedMember = false
}) => {
  const dispatch = useDispatch();

  const listOfPeople = useSelector((state) => state.person.list);
  const listOfImages = useSelector((state) => state.mediaobject.list);
  const [person, setPerson] = useState(null);
  const [personImage, setPersonImage] = useState(null);

  useEffect(() => {
    if (listOfPeople.retrieved && showTeamRemovalModal) {
      setPerson(listOfPeople.retrieved
                // todo probably this check doesnt' needed ?
                && listOfPeople.retrieved['hydra:member'].filter((personFromList) =>
                  (personFromList.user === showTeamRemovalModal['@id'])).length > 0
                && listOfPeople.retrieved['hydra:member'].filter((personFromList) =>
                  (personFromList.user === showTeamRemovalModal['@id']))
                  .shift());
    }
  }, [showTeamRemovalModal, listOfPeople.retrieved]);

  useEffect(() => {
    if (person && listOfImages.retrieved) {
      const filteredImage = listOfImages.retrieved
                // todo probably this check doesnt' needed ?
                && listOfImages.retrieved['hydra:member'].filter((image) => (image['@id'] === person.image)).length > 0
                && listOfImages.retrieved['hydra:member'].filter((image) => (image['@id'] === person.image))
                  .shift().contentUrl;
      setPersonImage(filteredImage);
    }
  }, [person, listOfImages.retrieved]);

  const handleDeleteButton = () => {
    if (removeUnconfirmedMember) {
      dispatch(reject(showTeamRemovalModal.unconfirmedMember, { reject: true }));
    } else if (showTeamRemovalModal['@id'] === localStorage.getItem('id') && !removeUnconfirmedMember) {
      dispatch(exit(organization, { exit: true }));
      setIsShowModal(false);
    } else if (isOwner && !removeUnconfirmedMember) {
      dispatch(removeMember(organization, { memberForRemoval: showTeamRemovalModal['@id'] }));
    }
    // setRefreshUsersOrg(true);
    setShowTeamRemovalModal(false);
  };
  return (showTeamRemovalModal && (isOwner || showTeamRemovalModal['@id'] === localStorage.getItem('id'))
        && <>
            <div className='start-proposal-modal-team-remove-overlay'/>
            <div className='start-proposal-modal-team-remove-modal'>
                <i
                    className='fa fa-times start-proposal-modal-team-remove-modal-close'
                    onClick={() => setShowTeamRemovalModal(false)}
                />
                <div className='start-proposal-modal-team-remove-modal-heading'>
                    Apakah kamu yakin ingin menghapus
                </div>
                <div className='start-proposal-modal-team-remove-modal-heading'>
            <span>
              {showTeamRemovalModal['@id'] === localStorage.getItem('id')
                ? 'dirimu sendiri'
                : showTeamRemovalModal.username} {' '}
            </span>
                    dari tim {isOwner && 'kamu'}?
                </div>

                <p
                    className='start-proposal-modal-team-remove-modal-sub'
                >
                    {isOwner && 'Kamu tidak bisa kembali. Pilih dengan bijak untuk membangun tim yang hebat!'}
                </p>
                {showTeamRemovalModal['@id'] !== localStorage.getItem('id')
                  ? <>
                <div className='start-proposal-modal-team-remove-modal-avatar'>
                    {personImage ? <img
                            className='start-proposal-modal-member-profile-avatar-img'
                            src={personImage} alt='person Image'/>
                      : <img
                            className='start-proposal-modal-member-profile-avatar-img'
                            src={avatarImage}
                            alt='avatar Image'/>}
                    {/* <img src={Avatar} className='start-proposal-modal-member-profile-avatar-img' alt=''/> */}
                </div>
                <p className='start-proposal-modal-team-remove-modal-tags'>
                    {showTeamRemovalModal.tags && showTeamRemovalModal.tags.map((tag, number) => {
                      if (number === 0) {
                        return <TagName tag={tag} key={showTeamRemovalModal.id}/>;
                      }
                      return <>
                            <div className='middle-dot'/>
                            <TagName tag={tag} key={showTeamRemovalModal.id}/>
                        </>;
                    })}
                </p>
                <div className='start-proposal-modal-team-remove-modal-achievements'>
                    {person && person.aggregateRating && <StarFill/>}
                    {' '}
                    {person && person.aggregateRating }
                    {' '}
                    {person && person.aggregateWins && <Trophy/> }
                    {' '}
                    {person && person.aggregateWins}
                </div> </> : <img src={NoProjectImage} alt="" />}
                <p
                    className='start-proposal-modal-team-remove-modal-sub'
                >
                    {!isOwner
                    && 'Kamu tidak bisa bergabung kembali jika kamu keluar. '
                    + 'Tolong berpikir dua kali sebelum kamu memutuskan untuk keluar.'}
                </p>

                <div className='start-proposal-modal-team-remove-modal-btn'>
                    <Button type='secondary' onClick={() => setShowTeamRemovalModal(false)}>
                      Tidak, saya masih berpikir
                    </Button>
                    <Button onClick={handleDeleteButton }>Iya, saya yakin!</Button>
                </div>
            </div>
            <HandleEscapeKey handleOnEscapeKey={() => { setShowTeamRemovalModal(false); }}/>
        </>
  );
};
export default RenderTeamRemovalModal;

RenderTeamRemovalModal.propTypes = {
  setShowTeamRemovalModal: PropTypes.func.isRequired,
  showTeamRemovalModal: PropTypes.any.isRequired
};
