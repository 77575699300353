/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { CardImage, TrashFill, ArrowClockwise } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Dot } from 'react-animated-dots';
import { toast } from 'react-toastify';

import { uploadImageToS3 } from '../../Actions/Question';
import iconJpg from '../../Assets/Images/jpeg_icon.png';
import Button from '../Button/Button';
import { CREATIVE_WORKS_URL, API_BASE_URL } from '../../Constants/Constants';

const LessonScenes = ({
  image, idImg, creativeWorkId, images,
  setLessonScenes, lessonScenes
}) => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [errorUploadImage, setErrorUploadImage] = useState(false);

  const handleUploadFile = () => {
    inputFile.current.click();
  };

  const handleOnChangeFile = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      event.stopPropagation();
      setLoadingFile(true);
      setErrorUploadImage(false);
      const { target: { files } } = event;
      if (files[0] && files[0] !== undefined) {
        const getTypeImage = files[0].type.split('/');
        const fileType = {
          fileExtension: getTypeImage[1]
        };
        const uploadedData = await dispatch(uploadImageToS3(fileType, files[0]));
        if (uploadedData) {
          const prevImages = lessonScenes.map((img) => img['@id']).filter(Boolean);
          const updatedData = {
            images: [
              ...prevImages,
              uploadedData['@id']
            ]
          };
          await axios.put(`${CREATIVE_WORKS_URL}/${creativeWorkId}`, updatedData);
          const removeEmpty = lessonScenes.filter((l) => l.name !== '');
          setLessonScenes([...removeEmpty, uploadedData]);
        }
      }
    } catch (error) {
      setErrorUploadImage(true);
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
  };

  const handleDeleteUpload = async () => {
    setLoadingFile(true);
    try {
      const filteredImage = {
        images: images.images.filter((i) => i !== image['@id'])
      };
      await axios.delete(image['@id']);
      await fetch(`${API_BASE_URL}${CREATIVE_WORKS_URL}/${creativeWorkId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/ld+json'
        },
        body: JSON.stringify(filteredImage)
      });
      setLessonScenes((prevImage) => {
        const filtered = prevImage.filter((p) => p['@id'] !== image['@id']);
        if (filtered.length === 0) {
          return [{ name: '', contentUrl: '' }];
        }
        return filtered;
      });
      setLoadingFile(false);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
  };

  return (
    <>
      <div className='question-answer-animation-content-wrapper'>
        <Row>
          <input
            type='file'
            id={`upload-file-img-${idImg}`}
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={(event) => handleOnChangeFile(event, 'video')}
          />
          <Col xs={1}>
            <Image src={iconJpg} width='40' />
          </Col>
          <Col xs={6}>
            <h3>Adegan Pelajaran</h3>
            <p>JPEG atau JPG (Rasio 16:9)</p>
          </Col>
          <Col xs={5}>
            <div
              className={`question-answer-animation-content-wrapper-button 
              ${image.name !== '' && 'filled-button '}`}
            >
              <Button block={true} disabled={loadingFile} onClick={handleUploadFile}>
                {image.name !== ''
                  ? image.name
                  : loadingFile ? (
                    <>
                    Sedang memulai{' '}
                      <Dot>.</Dot>
                      <Dot>.</Dot>
                      <Dot>.</Dot>
                    </>
                  )
                    : <>
                      <CardImage /> Pilih File
                    </>
                  }
              </Button>
              {image.name !== '' && (
                <div
                  className='question-answer-lesson-content-wrapper-button-trash'
                  onClick={handleDeleteUpload}
                >
                  {loadingFile ? <ArrowClockwise /> : <TrashFill />}
                </div>
              )}
            </div>
            {errorUploadImage && <p className='text-center'>Gagal Upload!</p>}
          </Col>
        </Row>
      </div>
      <div
        className={image.name === '' ? 'question-answer-animation-content-preview' : ''}
        // style={{ backgroundImage: `url(${image ? image.contentUrl : ''})` }}
      >
        {image.name === '' ? <p>Tampilan akan ada disini</p> : (
          <img src={image.contentUrl} alt="" width='100%'/>
        )}
      </div>
      <div className='question-answer-animation-content-wrapper'></div>
    </>
  );
};

LessonScenes.defaultProps = {
  idImg: 1
};

export default LessonScenes;
