import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TagName from '../Tags/TagName';
import EducationalLevel from '../EducationalLevel/EducationalLevel';

const PublishedLesson = ({
  lessonItem,
  projectList,
  organizationList
}) => {
  const [project, setProject] = useState();
  const [org, setOrg] = useState();

  useEffect(() => {
    if (projectList.retrieved) {
      const findProject = projectList.retrieved && projectList.retrieved['hydra:member']
        .find((projectVal) => projectVal['@id'] === lessonItem.createdFor['@id']);
      setProject(findProject);
    }
  }, [projectList.retrieved]);

  useEffect(() => {
    if (organizationList.retrieved) {
      const findProject = organizationList.retrieved && organizationList.retrieved['hydra:member']
        .find((orgVal) => orgVal['@id'] === lessonItem.creator['@id']);
      setOrg(findProject);
    }
  }, [organizationList.retrieved]);

  return (
    <div className='admin-lesson-requests-table-row' onClick={() => {
      window.location.href = '/materi-gratis';
    }} >
      <span
        className='admin-lesson-requests-table-row-text title'
      >
        {project && project.title} - {org && org.name}
      </span>

      <span className='admin-lesson-bank-table-row-text subject' >
        {project && project.tags.map((tag) => (
          <TagName tag={tag} key={tag} />
        ))}
      </span>

      <span className='admin-lesson-bank-table-row-text grade' >
        {project && (
          <EducationalLevel
            type='educational-level-text'
            educationalLevel={project.educationalLevel}
          />
        )}
      </span>

      <span className='admin-lesson-requests-table-row-text status' >{moment(lessonItem.publishedTime).fromNow()}</span>

    </div>
  );
};

export default PublishedLesson;
