import { combineReducers } from 'redux';
import list from './list';
import listDesigners from './listDesigners';
import listTeachers from './listTeachers';
import create from './create';
import update from './update';
import del from './delete';
import show from './show';

export default combineReducers({ list,listTeachers,listDesigners, create, update, del, show });
