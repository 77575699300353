import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Button from '../Button/Button';
import Delete from '../../Assets/Images/delete.png';
import { CREATIVE_WORKS_URL } from '../../Constants/Constants';

const ListOfCreativeWork = ({
  lessonVal, setShowPreviewLessonModal,
  setPreviewProposal, isLesson
}) => {
  const [organization, setOrganization] = useState({});
  const [winner, setWinner] = useState(0);
  const listOfOrganization = useSelector((state) => state.organization.list);
  const listOfAward = useSelector((state) => state.award.list);

  useEffect(() => {
    if (listOfOrganization.retrieved) {
      const orgId = isLesson ? lessonVal.creator['@id'] : lessonVal.creator;
      const org = listOfOrganization.retrieved['hydra:member']
        .find((o) => o['@id'] === orgId);
      setOrganization(org);
    }
  }, [listOfOrganization.retrieved]);

  useEffect(() => {
    if (listOfAward.retrieved) {
      const orgId = isLesson ? lessonVal.creator['@id'] : lessonVal.creator;
      const award = listOfAward.retrieved['hydra:member']
        .find((a) => a.winner === orgId);
      if (award) {
        setWinner(award.rank);
      } else {
        setWinner(0);
      }
    }
  }, [listOfAward.retrieved]);

  const importToLesson = async () => {
    // Re-import creative work to lesson
    await axios.put(`${CREATIVE_WORKS_URL}/${lessonVal.id}/import-to-lesson`, {});
    window.location.reload();
  };

  const handleClick = async () => {
    if (isLesson) {
      setShowPreviewLessonModal(true);
      setPreviewProposal(lessonVal);
    } else {
      await importToLesson();
    }
  };

  return (
    <div key={organization.name} className='admin-lesson-bank-to-publish-teams-container' >
      <div className='admin-lesson-bank-to-publish-teams-container-main' >
        <input
          type='checkbox'
          className='admin-lesson-bank-to-publish-check-input'
          defaultChecked={lessonVal.published}
        />
        <span className='admin-lesson-bank-to-publish-check-checkmark' />

        <span className='admin-lesson-bank-to-publish-teams-container-name' >
          {organization.name && organization.name.replace(' and ', ' dan ')}
        </span>
        <span className='admin-lesson-bank-to-publish-teams-container-dot' />
        <span className='admin-lesson-bank-to-publish-teams-container-rank' >Ranked # {winner}</span>
      </div>
      <div className='admin-lesson-bank-to-publish-teams-container-btn' >
        <Button onClick={handleClick} >{isLesson ? 'Preview' : 'Import'}</Button>
      </div>
      <img src={Delete} alt="" className='admin-lesson-bank-to-publish-teams-container-delete' />
    </div>
  );
};

ListOfCreativeWork.propTypes = {

};

export default ListOfCreativeWork;
