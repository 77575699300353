import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './Input.scss';

const Input = (props) => {
  const {
    controlId,
    label,
    type,
    placeholder,
    isInvalid,
    errorMessage,
    onChange,
    disabled,
    addInputClass,
    style,
    onBlur,
    onFocus,
    value,
  } = props;

  return (
    <div style={style} className='form-input'>
      <Form.Group controlId={controlId}>
        <Form.Label className='form-input-label'>{label}</Form.Label>
        <Form.Control
          className={`form-input-control ${addInputClass}`}
          type={type}
          placeholder={placeholder}
          isInvalid={isInvalid}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

Input.propTypes = {
  controlId: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  addClass: PropTypes.string,
  style: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

Input.defaultProps = {
  placeholder: '',
  required: false,
  isInvalid: false,
  errorMessage: '',
  disabled: false,
};

export default Input;
