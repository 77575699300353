import React, { useEffect, useState } from 'react';
import {
  Alert,
  Row,
  Col,
  Container
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import MultiSelect from 'react-multi-select-component';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Button from '../../Components/Button/Button';
import InfiniteLoader from '../../Components/InfiniteLoader/InfiniteLoader';
import FreeMaterialModal from '../../Components/FreeMaterialModal/FreeMaterialModal';
import HomePageIllustration from '../../Assets/Images/homepage-illustration.png';
import StartProposalConfirmModal from '../../Components/StartProposalConfirmModal/StartProposalConfirmModal';
import PhoneInput from '../../Components/PhoneInput/PhoneInput';
import Input from '../../Components/Input/Input';
import './MateriGratis.scss';
import {
  list as lessonActionList,
  reset as lessonActionReset,
  fetchMore as lessonActionFetchMore
} from '../../Generated/actions/lesson/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset
} from '../../Generated/actions/educationallevel/list';
import { list as tagsActionList, reset as tagsActionReset } from '../../Generated/actions/tags/list';
import { list as imageActionList, reset as imageActionReset } from '../../Generated/actions/imageobject/list';
import FreeMaterialCard from '../../Components/FreeMaterialCard/FreeMaterialCard';
import { HASH_DECRYPT, IMAGE_OBJECTS, LESSON_URL } from '../../Constants/Constants';
import CheckBox from '../../Components/CheckBox/CheckBox';
import ScrollableLessonMateriGratis from '../../Components/ScrollableLessonMateriGratis/ScrollableLessonMateriGratis';
import SignInModal from '../../Components/SignInModal/SignInModal';
import { decryptData } from '../../Helpers/Common';

const MateriGratis = () => {
  // States

  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [filterByLesson, setFilterByLesson] = useState([]);
  const [selectedFilterByGrade, setSelectedFilterByGrade] = useState([]);
  const [filterByGrade, setFilterByGrade] = useState([]);
  const [order, setOrder] = useState('desc');
  const [activeOrderClasses, setActiveOrderClasses] = useState([]);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [showTextLinkModal, setShowTextLinkModal] = useState(false);
  const [showEmailLinkModal, setShowEmailLinkModal] = useState(false);
  const [previewLesson, setPreviewLesson] = useState();
  const [recipient, setRecipient] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [load, setLoad] = useState(1);
  const [signUp, setSignUp] = useState(false);

  const [nexturl, setNexturl] = useState('');
  const [hasMore, setHasMore] = useState(true);

  const listOfLessons = useSelector((state) => state.lesson.list);
  const listOfEducationalLevel = useSelector((state) => state.educationallevel.list);
  const listOfTags = useSelector((state) => state.tags.list);
  const listOfImages = useSelector((state) => state.imageobject.list);

  useEffect(() => {
    const toggleSection = document.getElementById('materi-gratis-toggle');
    const itemsSection = document.getElementById('materi-gratis');
    const stickyHeader = toggleSection.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset >= stickyHeader - 100) {
        toggleSection.classList.add('sticky');
        itemsSection.classList.add('margin-sticky');
      }
      if (window.pageYOffset <= stickyHeader - 100) {
        toggleSection.classList.remove('sticky');
        itemsSection.classList.remove('margin-sticky');
      }
    });

    return () => {
      window.removeEventListener('scroll', () => scrollCallBack);
    };
  }, []);

  useEffect(() => {
    const url = `${LESSON_URL}?${queryString.stringify(
      {
        published: true,
        'createdFor.tags': filterByLesson,
        'createdFor.educationalLevel': filterByGrade,
        itemsPerPage: 9
      },
      { arrayFormat: 'bracket' }
    )}&order%5BpublishedTime%5D=${order}`;
    dispatch(
      lessonActionList(url)
    );
    return () => {
      dispatch(lessonActionReset(listOfLessons.eventSource));
    };
  }, [
    dispatch,
    order,
    listOfLessons.eventSource,
    filterByLesson,
    filterByGrade]);

  useEffect(() => {
    if (listOfLessons.retrieved) {
      const imageList = listOfLessons.retrieved['hydra:member']
        .map((l) => l.video.image);
      if (imageList.length > 0) {
        dispatch(imageActionList(`${IMAGE_OBJECTS}?id[]=${imageList.join('&id[]=')}`));
      }
      if (listOfLessons.retrieved['hydra:view']['hydra:next']) {
        setNexturl(listOfLessons.retrieved['hydra:view']['hydra:next']);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
    return () => {
      dispatch(imageActionReset(listOfImages.eventSource));
    };
  }, [dispatch, listOfImages.eventSource, listOfLessons.retrieved]);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, [dispatch, listOfEducationalLevel.eventSource]);

  useEffect(() => {
    dispatch(tagsActionList('/tags?role=ROLE_TEACHER&pagination=false'));
    return () => {
      dispatch(tagsActionReset(listOfTags.eventSource));
    };
  }, [dispatch, listOfTags.eventSource]);

  // Functions

  const configStatefulMultiselect = {
    selectSomeItems: 'Pilih Kelas',
    allItemsAreSelected: 'Semua Kelas',
    selectAll: 'Semua Kelas',
    search: 'Cari'
  };

  const changeOrder = async (name) => {
    setActiveOrderClasses('');
    await setOrder(name);
  };

  const onDownloadClick = () => {
    setShowMaterialModal(false);
    setShowEmailLinkModal(true);
  };

  const onEmailTextClick = () => {
    setShowTextLinkModal(false);
    setShowEmailLinkModal(true);
  };

  // const onTextLinkClick = () => {
  //   setShowEmailLinkModal(false);
  //   setShowTextLinkModal(true);
  // };

  const sendLink = async (via) => {
    setIsSuccess(false);
    if (recipient) {
      setIsLoading(true);
      if (via === 'email') {
        await axios.post('/user/send-mobile-application-link-to-email', {
          email: recipient
        });
        setIsSuccess(true);
        setIsLoading(false);
      }
    }
  };

  const handleRegister = () => {
    if (decryptData(localStorage.getItem('user'), HASH_DECRYPT)) {
      history.push('/papan-pintar');
    } else {
      history.push('/register');
    }
  };

  const handleFetchData = () => {
    if (!localStorage.getItem('token') && load > 0) {
      setHasMore(false);
      setSignUp(true);
      setShowSignInModal(true);
    } else {
      dispatch(lessonActionFetchMore(nexturl));
    }

    setLoad(load + 1);
  };

  const toggleActiveOrderClass = (name) => {
    const index = activeOrderClasses.indexOf(name);
    if (index !== -1) {
      const array = [...activeOrderClasses];
      array.splice(index, 1);
      setActiveOrderClasses(array);
    } else setActiveOrderClasses((array) => [...array, name]);
  };

  // Render Functions

  const renderHeader = () => {
    return (
      <div className='materi-gratis-header' >
        <span className='materi-gratis-header-heading' >Berkarya & Berbagi di Sahabat Pintar!</span>
        <p className='materi-gratis-header-para' >
          Tunjukkan kreativitasmu dalam membuat soal dan mendesain! Titik Pintar butuh
          kamu untuk merealisasikan sarana edukasi yang menyenangkan dan bermanfaat
          untuk para siswa Sekolah Dasar. Daftarkan dirimu sekarang!
        </p>
        <img src={HomePageIllustration} className='materi-gratis-header-img' alt="illustration" />
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className='materi-gratis-btns' >
        <div className='materi-gratis-btns-btn' onClick={handleRegister} >
          <Button block >Daftar & Menangkan Kompetisinya</Button>
        </div>
        <div className='materi-gratis-btns-btn' >
          <Button type='blue' block onClick={() => setShowEmailLinkModal(true)} >Unduh Titik Pintar</Button>
        </div>
      </div>
    );
  };

  const renderFilterSection = () => {
    const gradeOptions = listOfEducationalLevel.retrieved
      ? listOfEducationalLevel.retrieved['hydra:member'].map((levels) => {
        return {
          label: levels.level,
          value: levels['@id'],
        };
      })
      : [];

    return (
      <div id="materi-gratis-toggle" className="materi-gratis-toggle">
        <div className="container-fluid">
          <Row noGutters={true}>
            <Col xs={12} lg={2}>
              <div className="materi-gratis-toggle-dropdown">
                <MultiSelect
                  options={gradeOptions}
                  value={selectedFilterByGrade}
                  onChange={(grade) => {
                    setSelectedFilterByGrade(grade);
                    const changeSelected = grade.map((g) => g.value);
                    setFilterByGrade(changeSelected);
                  }}
                  labelledBy='Pilih Kelas'
                  overrideStrings={configStatefulMultiselect}
                  ItemRenderer={CheckBox}
                />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <ScrollableLessonMateriGratis
                filterByLessonOptions={
                  listOfTags.retrieved
                    ? listOfTags.retrieved['hydra:member']
                    : []
                }
                filterByLesson={filterByLesson}
                setFilterByLesson={setFilterByLesson}
              />
            </Col>
            <Col xs={12} lg={2}>
              <div>
                <div
                  onClick={() => toggleActiveOrderClass('classes')}
                  className={`materi-gratis-toggle-dropdown-order 
                  ${activeOrderClasses.includes('classes') ? 'active' : ''}`}
                >
                  <span>{order === 'desc' ? 'Terbaru' : 'Terlama'}</span>

                  <svg width="24" height="24" fill="none"
                    stroke="currentColor" strokeWidth="2" className="dropdown-heading-dropdown-arrow gray">
                    {activeOrderClasses.includes('classes') ? <path d="M18 15 12 9 6 15"></path>
                      : <path d="M6 9L12 15 18 9"></path>}
                  </svg>
                </div>

                {activeOrderClasses.includes('classes') && <div className='materi-gratis-toggle-dropdown-order-expand' >
                  <div className={`materi-gratis-toggle-dropdown-order-expand-list ${order === 'desc' ? 'active' : ''}`}
                    onClick={() => changeOrder('desc')}
                  >
                    Terbaru
                  </div>
                  <div className={`materi-gratis-toggle-dropdown-order-expand-list ${order === 'asc' ? 'active' : ''}`}
                    onClick={() => changeOrder('asc')}
                  >
                    Terlama
                  </div>
                </div>
                }

              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderFreeMaterials = () => {
    if (listOfLessons.retrieved) {
      const FilteredLesson = listOfLessons.retrieved['hydra:member'];

      return (
        <div>
          <InfiniteScroll
            dataLength={listOfLessons.retrieved['hydra:member'].length}
            next={handleFetchData}
            hasMore={hasMore}
          >
            <div className='materi-gratis-free-materials'>
              {listOfLessons.retrieved && FilteredLesson
                .map((freeMaterial) => <FreeMaterialCard
                  key={freeMaterial.id}
                  lessonItem={freeMaterial}
                  organizationList
                  setShowMaterialModal={setShowMaterialModal}
                  setPreviewLesson={setPreviewLesson}
                  setNewLessonHours={168}
                />)}
            </div>
          </InfiniteScroll>
          {hasMore ? <InfiniteLoader><br /><br /></InfiniteLoader> : ''}
        </div>
      );
    }

    return (<InfiniteLoader />);
  };

  const renderSignUp = () => {
    return (
      <div className='materi-gratis-btn-signup' >
        <Button block onClick={() => setShowSignInModal(true)}>Daftar Sekarang</Button>
      </div>
    );
  };

  const renderTextLinkModal = () => {
    const details = {
      show: showTextLinkModal,
      setShow: setShowTextLinkModal,
      heading: 'Mengajar lebih mudah bersama siswa yang bahagia!',
      sub: <>
        <p>
          Kamu bisa temukan materi pelajaran terbaru <br />
          di aplikasi <span className='bold-text' >Titik Pintar</span> untuk
          memotivasi para siswa agar belajar dengan semangat!
        </p>
        <PhoneInput />
        <div className='small-gold-text' onClick={() => onEmailTextClick()} >ATAU KIRIM LEWAT EMAIL</div>
      </>,
      isSingleButton: false,
      yesText: 'Kirimkan link-nya',
      yesClick: () => setShowTextLinkModal(),
      noClick: () => setShowTextLinkModal(false),
      noText: 'Nanti saja',
      loading: isLoading
    };
    return (
      <StartProposalConfirmModal
        isShowModal={showTextLinkModal}
        setIsShowModal={setShowTextLinkModal}
        details={details}
      />);
  };

  const renderEmailLinkModal = () => {
    const details = {
      show: showEmailLinkModal,
      setShow: setShowEmailLinkModal,
      heading: 'Mengajar lebih mudah bersama siswa yang bahagia!',
      sub: <>
        <p>
          Kamu bisa temukan materi pelajaran terbaru <br />
          di aplikasi <span className='bold-text' >Titik Pintar</span> untuk
          memotivasi para siswa agar belajar dengan semangat!
        </p>
        {isSuccess && (
          <Alert variant='success'>
            Link telah berhasil dikirim ke email kamu!
          </Alert>
        )}
        <Input placeholder='Masukkan alamat email kamu' onChange={(e) => setRecipient(e.target.value)} />
        {/* <div className='small-gold-text' onClick={() => onTextLinkClick()} >ATAU KIRIM LEWAT SMS</div> */}
      </>,
      isSingleButton: false,
      yesText: 'Kirimkan link-nya',
      yesClick: () => sendLink('email'),
      noClick: () => setShowEmailLinkModal(false),
      noText: 'Nanti saja',
      loading: isLoading
    };
    return (
      <StartProposalConfirmModal
        isShowModal={showEmailLinkModal}
        setIsShowModal={setShowEmailLinkModal}
        details={details}
      />);
  };

  return (
    <div>
      <NavigationBar active='materiGratis' />
      <Container>
        {renderHeader()}
        {renderButtons()}
      </Container>
      {renderFilterSection()}
      <Container id="materi-gratis">
        {renderFreeMaterials()}
        {signUp
          ? renderSignUp()
          : ''
        }
        <FreeMaterialModal
          isShowMaterial={showMaterialModal}
          setIsShowMaterial={setShowMaterialModal}
          onDownloadClick={onDownloadClick}
          previewLesson={previewLesson}
        />
        {renderTextLinkModal()}
        {renderEmailLinkModal()}
        <SignInModal
          isShowModal={showSignInModal}
          setIsShowModal={setShowSignInModal}
          title="Guru yang mencintai pengajaran, Mengajari anak untuk suka belajar."
          desc="Gabung sekarang untuk melihat materi pelajaran tanpa batas."
          titleActive={true}
        />
      </Container>
    </div>
  );
};

export default MateriGratis;
