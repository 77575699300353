import React from 'react';
import {
  Col, Image,
  Modal, Row
} from 'react-bootstrap';
import './passwordChangeSuccessModal.scss';
import { Link } from 'react-router-dom';
import { X } from 'react-bootstrap-icons';
import signInAlby from '../../Assets/Images/signin-modal-alby.png';

const PasswordChangeSuccessModal = (props) => {
  const { isShowModal, setshowPasswordSuccessModel } = props;
  const handleCloseModal = () => {
    setshowPasswordSuccessModel(false);
  };
  return (
    <div className='password-change-success-modal'>
      <Modal
        show={isShowModal}
        dialogClassName='password-change-success-modal-box'
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setshowPasswordSuccessModel(false)}
        centered
      >
        <Modal.Body>
          <div className='password-change-success-modal-box-close'>
            <X onClick={handleCloseModal} />
          </div>
          <div className='password-change-success-form-illustration'>
            <Image src={signInAlby} />
          </div>
          <div className='password-change-success-form'>
            <Row>
              <Col xs={12}>
                <h5 className='password-change-success-form-title'>Anda berhasil mengubah <br/> kata sandi Anda!</h5>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className='password-change-success-form'>
            <Row>
              <Col xs={12}>
                <p>Anda bisa mencobanya sekarang. &nbsp;
                <Link to='/login' className='password-change-success-sign-in'>
                  Masuk
                </Link></p>
              </Col>
            </Row>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PasswordChangeSuccessModal;
