import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup, Col, Row, ToggleButton
} from 'react-bootstrap';
import './FilterBar.scss';

const FilterBar = (props) => {
  const {
    filterTab,
    filterByTime,
    setFilterByTime,
    filterByTimeOptions,
    filterByType,
    setFilterByType,
    filterByTypeOptions,
  } = props;

  const handleFilterByTime = (event) => {
    const { currentTarget: { value } } = event;
    let filteredData = [];
    let resultFilteredData = [];
    if (filterByTime.includes(value)) {
      filteredData = filterByTime.filter((time) => time !== value);
      return setFilterByTime(filteredData);
    }
    filteredData.push(value);
    resultFilteredData = [...filterByTime, ...filteredData];
    return setFilterByTime(resultFilteredData);
  };

  const renderFilterByTime = () => (
    filterByTimeOptions.map((time) => {
      return (
        <ButtonGroup toggle key={time.value}>
          <ToggleButton
            type='checkbox'
            variant='secondary'
            checked={filterByTime.includes(time.value)}
            value={time.value}
            onChange={(event) => handleFilterByTime(event)}
          >
            {time.name}
          </ToggleButton>
        </ButtonGroup>
      );
    })
  );

  const handleFilterByType = (event) => {
    const { currentTarget: { value } } = event;
    let filteredData = [];
    let resultFilteredData = [];
    if (filterByType.includes(value)) {
      filteredData = filterByType.filter((type) => type !== value);
      return setFilterByType(filteredData);
    }
    filteredData.push(value);
    resultFilteredData = [...filterByType, ...filteredData];
    return setFilterByType(resultFilteredData);
  };

  const renderFilterByType = () => (
    filterByTypeOptions.map((type) => {
      return (
        <ButtonGroup toggle key={type.value}>
          <ToggleButton
            type='checkbox'
            variant='secondary'
            checked={filterByType.includes(type.value)}
            value={type.value}
            onChange={(event) => handleFilterByType(event)}
          >
            {type.name}
          </ToggleButton>
        </ButtonGroup>
      );
    })
  );

  return (
    <>
      {(filterTab
        && <div className='filter-bar'>
          <Row>
            <Col xs={6}>
              <h1>Waktu yang tersisa</h1>
              {renderFilterByTime()}
            </Col>
            <Col xs={6}>
              <h1>Jenis Kontestan</h1>
              {renderFilterByType()}
            </Col>
          </Row>
        </div>)}
    </>
  );
};

FilterBar.propTypes = {
  filterTab: PropTypes.bool,
  filterByTime: PropTypes.array,
  setFilterByTime: PropTypes.func,
  filterByTimeOptions: PropTypes.array,
  filterByType: PropTypes.array,
  setFilterByType: PropTypes.func,
  filterByTypeOptions: PropTypes.array,
};

export default FilterBar;
