import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { CheckAll } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import ListOfCreativeWork from './ListOfCreativeWork';
import TagName from '../Tags/TagName';
import EducationalLevel from '../EducationalLevel/EducationalLevel';

const LessonAccordion = ({
  lessonItem,
  setShowPreviewLessonModal,
  setPreviewProposal,
}) => {
  const [project, setProject] = useState();
  const listOfProject = useSelector((state) => state.project.list);

  useEffect(() => {
    if (listOfProject.retrieved) {
      // Get Project
      const lessonProject = listOfProject.retrieved['hydra:member']
        .find((p) => p['@id'] === lessonItem.createdFor['@id']);
      setProject(lessonProject);
    }
  }, [listOfProject.retrieved]);

  return (
    <Accordion key={lessonItem.id} >
      <Accordion.Toggle as="div" eventKey="0" className='admin-lesson-requests-table-row' >
        <span className='admin-lesson-requests-table-row-text title'>
          {lessonItem.published && <CheckAll size={26} />} {project && project.title}
        </span>

        <span className='admin-lesson-bank-table-row-text subject' >
          {project && project.tags.map((tag) => (
            <TagName tag={tag} key={tag} />
          ))}
        </span>

        <span className='admin-lesson-bank-table-row-text grade' >
          {project && (
            <EducationalLevel
              type='educational-level-text'
              educationalLevel={project.educationalLevel}
            />
          )}
        </span>

        <span className='admin-lesson-bank-table-row-text gold-text'>
          View More
          <i className='fa fa-chevron-down admin-lesson-bank-to-publish-arrow' />
        </span>

      </Accordion.Toggle>
      <Accordion.Collapse as='div' eventKey='0'>
        <ListOfCreativeWork
          lessonVal={lessonItem}
          setShowPreviewLessonModal={setShowPreviewLessonModal}
          setPreviewProposal={setPreviewProposal}
          isLesson={true}
        />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default LessonAccordion;
