import PropTypes from 'prop-types';
import CharacterCounter from 'react-character-counter';
import { Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { list, reset } from '../../Generated/actions/award/list';
import { update } from '../../Generated/actions/award/update';
import { create } from '../../Generated/actions/award/create';
import { del } from '../../Generated/actions/award/delete';

const RenderAwardsForm = ({ projectDetailData, setAwardExists }) => {
  const dispatch = useDispatch();

  const [award1Value, setAward1Value] = useState('');
  const [award2Value, setAward2Value] = useState('');
  const [award3Value, setAward3Value] = useState('');

  const [award1retrieved, setAward1retrieved] = useState(null);
  const [award2retrieved, setAward2retrieved] = useState(null);
  const [award3retrieved, setAward3retrieved] = useState(null);

  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  } = useSelector((state) => state.award.list);

  useEffect(() => {
    if (award1Value !== '') {
      setAwardExists([
        award1Value, award2Value, award3Value
      ]);
    } else {
      setAwardExists(false);
    }
  }, [award1Value, projectDetailData]);

  // getting project id and retrieve already created awards
  useEffect(() => {
    if (projectDetailData) {
      const projectID = projectDetailData['@id'];
      dispatch(list(`/awards?project=${projectID}`));
    }
    return () => {
      dispatch(reset(eventSource));
    };
  }, [projectDetailData]);

  // on update get list again
  const created = useSelector((state) => state.award.create.created);
  const updated = useSelector((state) => state.award.update.updated);
  const deleted = useSelector((state) => state.award.del.deleted);
  useEffect(() => {
    if (projectDetailData && (created || updated || deleted)) {
      const projectID = projectDetailData['@id'];
      dispatch(list(`/awards?project=${projectID}`));
    }
  }, [created, updated, deleted]);

  useEffect(() => {
    // it's getting data from previous screen
    setAward1Value('');
    setAward2Value('');
    setAward3Value('');
    setAward1retrieved(null);
    setAward2retrieved(null);
    setAward3retrieved(null);
    if (retrieved && retrieved['hydra:totalItems']) {
      retrieved['hydra:member'].forEach(
        (award) => {
          switch (award.rank) {
            case 1:
              setAward1retrieved(award);
              setAward1Value(award.name);
              break;
            case 2:
              setAward2retrieved(award);
              setAward2Value(award.name);
              break;
            case 3:
              setAward3retrieved(award);
              setAward3Value(award.name);
              break;
            default:
            // console.log(`Sorry, we are out of ${award.rank}.`);
          }
        }
      );
    }
  }, [retrieved]);

  const sendRequestToServer = (awardRank) => {
    const project = projectDetailData['@id'];
    switch (awardRank) {
      case 1:
        if (award1Value.length && award1retrieved) {
          dispatch(update(award1retrieved, { name: award1Value }));
        } else if (!award1Value.length && award1retrieved) {
          dispatch(del(award1retrieved));
          setAward1retrieved(null);
        } else if (award1Value.length) {
          dispatch(create({
            id: uuidv4(),
            name: award1Value,
            rank: awardRank,
            project
          }));
        }
        break;
      case 2:
        if (award2Value.length && award2retrieved) {
          dispatch(update(award2retrieved, { name: award2Value }));
        } else if (!award2Value.length && award2retrieved) {
          dispatch(del(award2retrieved));
          setAward2retrieved(null);
        } else if (award2Value.length) {
          dispatch(create({
            id: uuidv4(),
            name: award2Value,
            rank: awardRank,
            project
          }));
        }
        break;
      case 3:
        if (award3Value.length && award3retrieved) {
          dispatch(update(award3retrieved, { name: award3Value }));
        } else if (!award3Value.length && award3retrieved) {
          dispatch(del(award3retrieved));
          setAward3retrieved(null);
        } else if (award3Value.length) {
          dispatch(create({
            id: uuidv4(),
            name: award3Value,
            rank: awardRank,
            project
          }));
        }
        break;
      default:
      // console.log(`Sorry, we are out of ${awardRank}.`);
    }
  };

  return (
    <div className='admin-create-lesson-detail-form' >
      <span className='admin-create-lesson-form-heading' >Hadiah</span>

      {loading && (
        <div className="alert alert-info">Loading...</div>
      )}
      {deletedItem && (
        <div className="alert alert-success">
          {deletedItem['@id']} deleted.
        </div>
      )}
      {error && (
        <div className="alert alert-danger">{error}</div>
      )}

      <div className='admin-create-lesson-form-field' >
        <div>
          <span className='admin-create-lesson-form-field-label' >Award 1</span>
          <span className='admin-create-lesson-form-field-label-sub' >
            What is the award for the first place?
          </span>
        </div>
        <div className='admin-create-lesson-form-field-textarea-container' >
          <CharacterCounter maxLength={20} value={award1Value}>
            <Form.Control
              disabled={loading}
              className='admin-create-lesson-form-field-textarea'
              maxLength={20}
              value={award1Value}
              placeholder='No Award for 1st place set, please add one'
              onChange={(e) => setAward1Value(e.target.value)}
              onBlur={() => sendRequestToServer(1)}
            />
          </CharacterCounter>
        </div>
      </div>

      <div className='admin-create-lesson-form-field' >
        <div>
          <span className='admin-create-lesson-form-field-label' >Award 2</span>
          <span className='admin-create-lesson-form-field-label-sub' >
            What is the award for the second place?
          </span>
        </div>
        <div className='admin-create-lesson-form-field-textarea-container' >
          <CharacterCounter maxLength={20} value={award2Value}>
            <Form.Control
              disabled={loading}
              className='admin-create-lesson-form-field-textarea'
              maxLength={20}
              value={award2Value}
              placeholder='optional'
              onChange={(e) => setAward2Value(e.target.value)}
              onBlur={() => sendRequestToServer(2)}
            />
          </CharacterCounter>
        </div>
      </div>

      <div className='admin-create-lesson-form-field' >
        <div>
          <span className='admin-create-lesson-form-field-label' >Award 3</span>
          <span className='admin-create-lesson-form-field-label-sub' >
            What is the award for the third place?
          </span>
        </div>
        <div className='admin-create-lesson-form-field-textarea-container' >
          <CharacterCounter maxLength={20} value={award3Value}>
            <Form.Control
              disabled={loading}
              className='admin-create-lesson-form-field-textarea'
              maxLength={20}
              value={award3Value}
              placeholder='optional'
              onChange={(e) => setAward3Value(e.target.value)}
              onBlur={() => sendRequestToServer(3)}
            />
          </CharacterCounter>
        </div>
      </div>
    </div>
  );
};

export default RenderAwardsForm;

RenderAwardsForm.propTypes = {
  projectDetailData: PropTypes.object.isRequired,
  setAwardExists: PropTypes.bool
};
