import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { list as educationalLevelActionList } from '../../Generated/actions/educationallevel/list';
import { list as organizationListAction } from '../../Generated/actions/organization/searchList';
import Button from '../Button/Button';
// import SelectComponent from '../SelectComponent/SelectComponent';

const filterByStatusOptions = [
  {
    name: 'Semua Proyek',
    value: 'ALL_PROPOSALS',
  },
  {
    name: 'Sedang Berjalan',
    value: 'STATUS_IN_PROGRESS',
  },
  {
    name: 'Selesai',
    value: 'STATUS_COMPLETED',
  },
];

const ALL_PROPOSALS = 'ALL_PROPOSALS';
const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS';
const STATUS_COMPLETED = 'STATUS_COMPLETED';

// const LAST_JOINED = 'LAST_JOINED';
// const LAST_CHANGED = 'LAST_CHANGED';

// const selectOptions = [
//   {
//     value: 'NEWEST_REQUEST',
//     label: 'Permintaan Terbaru'
//   },
//   {
//     value: 'NEAREST_DEADLINE',
//     label: 'Deadline Terbaru'
//   },
// ];

const RenderFilterButtons = (props) => {
  const [filterByStatus, setFilterByStatus] = useState(ALL_PROPOSALS);
  // const [isFilterBtnActive, setIsFilterBtnActive] = useState(false);
  // const [sortSelected, setSortSelected] = useState(null);

  const updateOforganizationunconfirmedmembers = useSelector((state) => state.organizationunconfirmedmembers.update);
  const updateOforganization = useSelector((state) => state.organization.update);
  const dispatch = useDispatch();

  const handleFilterByStatus = (event) => {
    const {
      currentTarget: { value },
    } = event;
    setFilterByStatus(value);
  };

  useEffect(() => {
    dispatch(educationalLevelActionList(
      '/educational_levels?order%5Bordering%5D=asc&pagination=false'
    ));
  }, []);

  // orgs where user is member
  useEffect(() => {
    let filter;
    if (filterByStatus === ALL_PROPOSALS) {
      filter = {};
    }
    if (filterByStatus === STATUS_IN_PROGRESS) {
      filter = {
        isLocked: true,
        'creativeWork.isSubmitted': false
      };
    }
    if (filterByStatus === STATUS_COMPLETED) {
      filter = {
        isLocked: true,
        'creativeWork.isSubmitted': true
      };
    }
    const order = {
      'order[createdAt]': 'desc'
    };
    // if (sortSelected === LAST_JOINED) {
    //   order = {
    //     order: {
    //       createdAt: 'desc'
    //     }
    //   };
    // }
    // if (sortSelected === LAST_CHANGED) {
    //   order = {
    //     order: {
    //       updatedAt: 'desc'
    //     }
    //   };
    // }

    const filterByLodgedUser = {
      members: localStorage.getItem('id')
    };

    const filterQueryString = queryString.stringify(
      {
        ...filter,
        ...order,
        ...filterByLodgedUser
      },
      { arrayFormat: 'bracket' }
    );

    dispatch(
      organizationListAction(`organizations?${filterQueryString}`)
    );
  }, [filterByStatus]);

  useEffect(() => {
    if (updateOforganizationunconfirmedmembers.updated || updateOforganization.updated) {
      const filterQueryString = queryString.stringify({ members: localStorage.getItem('id') },
        { arrayFormat: 'bracket' });
      dispatch(
        organizationListAction(`organizations?${filterQueryString}`)
      );
    }
  }, [updateOforganizationunconfirmedmembers.updated, updateOforganization.updated]);

  const [projectName, setProjectName] = useState('');

  const handleSearchProject = () => {
    props.searchProjectByName(projectName);
  };

  const handleEnterPressOnSearchProject = (event) => {
    if (event.key === 'Enter') {
      handleSearchProject();
    }
  };

  return (
        <div className='my-projects-filter'>
            <div className='contestant-proposal-filter-left'>
                {filterByStatusOptions.map((status, index) => (
                    <ButtonGroup key={index} toggle>
                        <ToggleButton
                            type='checkbox'
                            variant='secondary'
                            checked={filterByStatus === status.value}
                            value={status.value}
                            onChange={(event) => handleFilterByStatus(event)}
                        >
                            {status.name}
                        </ToggleButton>
                    </ButtonGroup>
                ))}
            </div>
            <div className='my-projects-filter-right'>
              <Form.Control
                className='form-input-control'
                type='text'
                placeholder='Cari nama proyek'
                onChange={(e) => setProjectName(e.target.value)}
                onKeyPress={handleEnterPressOnSearchProject}
                style={{ height: '41px', borderRadius: '100px', marginRight: '10px' }}
              />
              <Button onClick={handleSearchProject}>
                <i className='fa fa-search' />
              </Button>
            </div>
            {/* <div className='my-projects-filter-right'>
                <SelectComponent
                    onChange={setSortSelected}
                    options={selectOptions}
                />
                <ToggleButton
                    type='checkbox'
                    variant='secondary'
                    bsPrefix='my-projects-filter-right-btn'
                    checked={isFilterBtnActive}
                    onChange={(event) => setIsFilterBtnActive(event.currentTarget.checked)}
                >
                    Filter <i className='fa fa-sliders my-projects-filter-right-icon-filter' />
                </ToggleButton>
            </div> */}
        </div>
  );
};
export default RenderFilterButtons;
