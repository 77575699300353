import CryptoJS from 'crypto-js';

export const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 15)
    + Math.random().toString(36).substring(2, 15);
};

export const generateRandomString = (length) => {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
};

export const capitalizeFirst = (value) => {
  const splitStr = value.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const translateSubject = (value) => {
  if (value === 'Science') {
    return 'Sains';
  }
  if (value === 'Math') {
    return 'Matematika';
  }
  return 'Sains';
};

export const getFileExt = (fileName) => {
  const lastDot = fileName.lastIndexOf('.');

  const ext = fileName.substring(lastDot + 1);

  return ext;
};

export const encryptData = (data, salt) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
};

export const decryptData = (ciphertext, salt) => {
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      return null;
    }
  }
  return null;
};
