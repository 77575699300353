import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEmpty } from 'lodash';
import axios from 'axios';
import questionPreview from '../../Assets/Images/question-preview.png';
import cancelPreview from '../../Assets/Images/cancel-preview.png';
import correctIcon from '../../Assets/Images/correct-icon.png';
import Input from '../Input/Input';
import submitPreview from '../../Assets/Images/submit-preview.png';
import { LESSON_QUESTION_URL, QUESTIONS_URL } from '../../Constants/Constants';

const ProposalQuestionPreview = ({
  questionData, previewIndex,
  questionsCount, imageObjects,
  isCreativeWork, lang = 'id'
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [multipeChoiceAnswer, setMultipleChoiceAnswer] = useState();

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    if (questionData && questionData.type === 'TYPE_MULTIPLE_CHOICE') {
      const getAnswers = async () => {
        let url = LESSON_QUESTION_URL;
        if (isCreativeWork) {
          url = QUESTIONS_URL;
        }
        let headers = {};
        if (lang === 'en') {
          headers = {
            'X-LOCALE': 'en'
          };
        }
        const { data } = await axios.get(`${url}/${questionData.id}/connected_answers`, {
          headers
        });
        const orderAnswer = [...data['hydra:member']];
        setMultipleChoiceAnswer(orderAnswer.sort(compare));
      };
      getAnswers();
    }
  }, [questionData]);

  useEffect(() => {
    if (!isEmpty(imageObjects) && questionData.image !== null) {
      if (isCreativeWork) {
        let imageId = questionData.image;
        if (typeof imageId !== 'string') {
          imageId = imageId['@id'];
        }
        const imageObjectArr = imageObjects && imageObjects['hydra:member'].filter((image) => {
          return image['@id'] === imageId;
        });
        if (imageObjectArr.length > 0) {
          setPreviewImage(imageObjectArr[0].contentUrl);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (questionData.image) {
          setPreviewImage(questionData.image.contentUrl);
        }
      }
    }
  }, [imageObjects, questionData]);

  const renderAnswerField = (question) => ({
    trueOrFalse: (
      <div className='footer__answer margin-top--80'>
        <div className='footer__answer-button'>
          <h1>{lang === 'en' ? 'True' : 'Benar'}</h1>
        </div>
        <div className='footer__answer-button'>
          <h1>{lang === 'en' ? 'False' : 'Salah'}</h1>
        </div>
      </div>
    ),
    multipleChoice: (
      <>
        <div className='footer__answer'>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE'
              && multipeChoiceAnswer && multipeChoiceAnswer[0].description}</h1>
          </div>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE'
              && multipeChoiceAnswer && multipeChoiceAnswer[1].description}</h1>
          </div>
        </div>
        <div className='footer__answer'>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE'
              && multipeChoiceAnswer && multipeChoiceAnswer[2].description}</h1>
          </div>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE'
              && multipeChoiceAnswer && multipeChoiceAnswer[3].description}</h1>
          </div>
        </div>
      </>
    ),
    fillTheBlank: (
      <div className='footer__answer margin-top--80'>
        <Input placeholder='TYPE ANSWER HERE' />
        <Image src={submitPreview} />
      </div>
    )
  });

  const renderCorrectAnswerPreview = (answerKey) => {
    return (
      <div className='mask'>
        <div className='mask__text'>
          <h1>Yay</h1>
        </div>
        <div className='mask__icon'>
          <Image src={correctIcon} />
        </div>
        <div className='mask__description'>
          <h1>Correct</h1>
          <div className='mask__key'>
            <Scrollbars
              autoHeight
              autoHeightMin={0}
              autoHeightMax={70}
              autoHide={false}
            >
              <p className="display-line-break">{answerKey}</p>
            </Scrollbars>
          </div>
          <div className='mask__button'>
            <div className='mask__button image'>
              Close
            </div>
          </div>
          <span>Report to Us</span>
        </div>
      </div>
    );
  };

  const renderQuestionPreviewWithAnswer = (question, index) => {
    return (
      <div className='proposal-summary__preview-wrapper'>
        <div
          className='proposal-summary__preview'
          style={{ backgroundImage: `url(${previewImage})` }}
        >
          <div className='wrapper'>
            <div className='header'>
              <div className='header__ask'>
                <Image src={questionPreview} />
              </div>
              <div className='header__progress'>
                <span className='question-answered--style'>Questions Answered</span>
                <span className='question-answered--count'>{index + 1}/{questionsCount}</span>
              </div>
              <div className='header__cancel'>
                <Image src={cancelPreview} />
              </div>
            </div>
            <div className='content'>
              <h1 className='display-line-break'>
                {question.description}
              </h1>
            </div>
            <div className='footer'>
              {question.type === 'TYPE_TRUE_OR_FALSE' && renderAnswerField(question).trueOrFalse}
              {question.type === 'TYPE_MULTIPLE_CHOICE' && renderAnswerField(question).multipleChoice}
              {question.type === 'TYPE_FILL_THE_BLANK' && renderAnswerField(question).fillTheBlank}
            </div>
          </div>
        </div>
        {renderCorrectAnswerPreview(question.answerKey)}
      </div>
    );
  };

  const renderQuestionPreviewWithoutAnswer = (question, index) => {
    return (
      <>
        <div
          className='proposal-summary__preview'
          style={{ backgroundImage: `url(${previewImage})` }}
        >
          <div className='wrapper'>
            <div className='header'>
              <div className='header__ask'>
                <Image src={questionPreview} />
              </div>
              <div className='header__progress'>
                <span className='question-answered--style'>Questions Answered</span>
                <span className='question-answered--count'>{index + 1}/{questionsCount}</span>
              </div>
              <div className='header__cancel'>
                <Image src={cancelPreview} />
              </div>
            </div>
            <div className='content'>
              <h1 className='display-line-break'>
                {question.description}
              </h1>
            </div>
            <div className='footer'>
              {question.type === 'TYPE_TRUE_OR_FALSE' && renderAnswerField(question).trueOrFalse}
              {question.type === 'TYPE_MULTIPLE_CHOICE' && renderAnswerField(question).multipleChoice}
              {question.type === 'TYPE_FILL_THE_BLANK' && renderAnswerField(question).fillTheBlank}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderQuestions = (question, index) => {
    return (
      <div className='proposal-summary__wrapper'>
        <div className='proposal-summary__content'>
          <Row>
            <Col xs={6}>
              {renderQuestionPreviewWithoutAnswer(question, index)}
            </Col>
            <Col xs={6}>
              {renderQuestionPreviewWithAnswer(question, index)}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderQuestions(questionData, previewIndex)}
    </div>
  );
};

export default ProposalQuestionPreview;
