import React, { useState } from 'react';
import {
  Container, Image,
  Nav, Navbar
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './NavigationBar.scss';
import papanPintarLogo from '../../Assets/Images/logo.png';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { decryptData } from '../../Helpers/Common';
import { HASH_DECRYPT } from '../../Constants/Constants';

const NavigationBar = ({ active }) => {
  const loggedUser = decryptData(localStorage.getItem('user'), HASH_DECRYPT);
  const [imitate, setImitate] = useState(localStorage.getItem('imitating_email'));

  const rightMenu = {
    profile: (
      <ProfileDropdown
        name={loggedUser && loggedUser.username}
      />
    ),
    menu: (
      <>
        <Nav.Link href='/login' className='navigation-bar-sign-in'>Masuk</Nav.Link>
        <Nav.Link href='/register' className='navigation-bar-sign-up'>Daftar</Nav.Link>
      </>
    )
  };

  function handleCancelImitate() {
    localStorage.removeItem('imitating_email');
    setImitate(false);
  }

  return (
    <div className='navigation-bar'>
      <Navbar bg='light' expand='lg'>
        <Container fluid>
          <Navbar.Brand href='/'>
            <Image src={papanPintarLogo} className='navigation-bar-logo' />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className='mr-auto'>
              <Nav.Link
                href='/materi-gratis'
                className={`navigation-bar-navbar-option ${active === 'materiGratis' ? 'active' : ''}`}
              >Materi Gratis
              </Nav.Link>
              <Nav.Link
                href='/papan-pintar'
                className={`navigation-bar-navbar-option ${active === 'papanPintar' ? 'active' : ''}`}
              >
                Papan Pintar
              </Nav.Link>
              <Nav.Link
                href='/how-it-works'
                className={`navigation-bar-navbar-option ${active === 'caraKerja' ? 'active' : ''}`}
              >
                Cara Kerja
              </Nav.Link>
            </Nav>
            <Nav className='ml-auto'>
              {localStorage.getItem('token')
                ? rightMenu.profile
                : rightMenu.menu}
            </Nav>
            {imitate && (
              <Nav.Link onClick={handleCancelImitate}>
                {localStorage.getItem('imitating_email')} X
              </Nav.Link>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

NavigationBar.propTypes = {
  active: PropTypes.string,
};

export default NavigationBar;
