import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row, Col, Form,
  Button, Image
} from 'react-bootstrap';
import './ForgotPassword.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import MessageConstants from '../../Constants/MessageConstants';
import albyIllustration from '../../Assets/Images/alby-illustration.png';
import { forgotPassword, hasError } from '../../Actions/ForgotPassword';
import { emailCheckSchema } from '../../Schemas/ForgotPasswordSchema';
import Input from '../../Components/Input/Input';
import HandleEnterKey from '../../Components/Input/HandleEnterKey';

const {
  EMAIL_SUCCESSFULLY_SENT
} = MessageConstants;

const ForgotPassword = () => {
  const isError = useSelector((state) => state.forgotPassword.hasError);
  const isMailSent = useSelector((state) => state.forgotPassword.emailSent);
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (isMailSent) {
      setIsLoading(false);
    }
  }, [isMailSent]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailCheckSchema
  });

  const [emailAddress, setEmail] = useState('');
  const [activeField, setActiveField] = useState('');

  const {
    setFieldValue, errors,
    values, validateForm, setStatus
  } = formik;

  const handleEmailCheck = async (event, credentials) => {
    event.preventDefault();
    const { email } = credentials;
    const emailData = {
      email
    };
    const validate = await validateForm();
    if (isEmpty(validate)) {
      setIsLoading(true);
      return dispatch(forgotPassword(emailData));
    }
    setIsLoading(false);
    return setStatus('Silakan masukkan email terdaftar Anda');
  };

  const handleOnChangeEmail = (event) => {
    dispatch(hasError(''));
    const { target: { value } } = event;
    setEmail(value);
  };

  const renderForgotPasswordForm = () => {
    return (
      <div className='forgot-password-form-input'>
        <Form>
          <Input
            controlId='emailInput'
            label='Alamat Email'
            type='email'
            placeholder='Alamat Email'
            isInvalid={activeField !== 'email' && !isEmpty(errors.email)}
            errorMessage={errors.email}
            onChange={handleOnChangeEmail}
            onFocus={() => setActiveField('email')}
            onBlur={() => {
              setActiveField();
              setFieldValue('email', emailAddress);
            }}
            addInputClass={emailAddress !== '' ? 'filled' : ''}
          />
          {isError && <div className='forgot-password-form-error'>{ isError }</div>}
          {(!isError && isMailSent) && <div className='forgot-password-form-success'>{ EMAIL_SUCCESSFULLY_SENT }</div>}
          <Row className='forgot-password-form-input-after'>
            <Col xs={12}>
              <Button
                className='forgot-password-form-input-button'
                ref={buttonRef}
                type='submit'
                onClick={(event) => handleEmailCheck(event, values)}
                disabled={isLoading}
              >
                {isLoading ? 'Sedang mengirim...' : 'Kirim'}
                </Button>
              <HandleEnterKey handleOnEnterKey={ () => {
                buttonRef.current.focus();
                buttonRef.current.click();
              } }/>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className='forgot-password'>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <div className='forgot-password-left-section'>
              <div className='forgot-password-round'>
                <div className='forgot-password-greetings'>
                  <h1>
                    Kamu lupa! <br />
                    password? Tenang, <br />
                    Alby akan <br />
                    membantu!
                  </h1>
                </div>
              </div>
              <div className='forgot-password-illustration'>
                <Image src={albyIllustration} width='400' alt='' />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className='forgot-password-right-section'>
              <div className='forgot-password-form'>
                <Row>
                  <Col xs={12}>
                    <h1 className='forgot-password-form-title'>Lupa Password</h1>
                  </Col>
                  <Col xs={12}>
                    <p>Kami akan mengirimkan tautan ke email Anda untuk mengatur ulang kata sandi Anda.</p>
                  </Col>
                  {/* <Col xs={12}>
                    {renderQuickLogin()}
                  </Col> */}
                </Row>
                {/* <Row>
                  <Col xs={12}>
                    <div className='forgot-password-form-separator'>
                      Use another account ?
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12}>
                    {renderForgotPasswordForm()}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
