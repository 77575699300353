import React from 'react';
import NoProjectImage from '../../Assets/Images/open-to-anyone.png';
import Button from '../Button/Button';

const NoProjectsTab = () => {
  return (
        <div className='my-projects-no-projects'>
            <img className='my-projects-no-projects-img' src={NoProjectImage} alt="" />
            <span className='my-projects-no-projects-heading'>Kamu belum memiliki proyek nih!</span>
            <span className='my-projects-no-projects-heading-sub'>
            Temukan proyek yang ingin kamu buat dan ajak orang lain untuk bergabung dengan timmu!
        </span>
            <div className='my-projects-no-projects-btn'>
                <Button block>Cari Proyek</Button>
            </div>
        </div>
  );
};
export default NoProjectsTab;
