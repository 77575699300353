import PropTypes from 'prop-types';
import React from 'react';

const AwardForProjectBriefTab = ({ award }) => {
  return (
            <p>
                Place #{award.rank}: {award.name}
            </p>
  );
};
export default AwardForProjectBriefTab;

AwardForProjectBriefTab.propTypes = {
  award: PropTypes.object
};
