import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

const HandleEscapeKey = ({ handleOnEscapeKey }) => {
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      handleOnEscapeKey();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
        <div />
  );
};
export default HandleEscapeKey;

HandleEscapeKey.propTypes = {
  handleOnEscapeKey: PropTypes.func.isRequired
};
