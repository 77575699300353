import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import './ConfirmationModal.scss';
import { X } from 'react-bootstrap-icons';
import signInAlby from '../../Assets/Images/signin-modal-alby.png';
import Button from '../Button/Button';
import HandleEscapeKey from '../Input/HandleEscapeKey';

const ConfirmationModal = (props) => {
  const {
    isShowModal,
    setIsShowModal,
    headerMsg,
    bodyMsg,
    handleSubmit
  } = props;

  const [isRequest, setIsRequest] = useState(true);

  const handleSendRequest = async () => {
    await handleSubmit();
    setIsRequest(false);
    setIsShowModal(false);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
    setIsRequest(true);
  };

  return (
    <div className='confirmation-modal'>
      <Modal
        show={isShowModal}
        dialogClassName='confirmation-modal-box'
        backdrop='static'
      >
        <Modal.Body>
          <div className='confirmation-modal-box-close'>
            <X onClick={handleCloseModal} />
          </div>
          <div className='confirmation-illustration'>
            <Image src={signInAlby} />
          </div>
          <div className='confirmation-modal-box-content'>
            {isRequest && (
              <>
                <h4>Apakah kamu yakin untuk {headerMsg} ?</h4>
                <p>{bodyMsg}</p>
                <Button block={true} onClick={handleSendRequest}>
                  Iya
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <HandleEscapeKey handleOnEscapeKey={handleCloseModal} />
    </div>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  bodyMsg: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  headerMsg: PropTypes.string,
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired
};
