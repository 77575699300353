import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Label from '../Label/Label';
import Button from '../Button/Button';
import { CREATIVE_WORKS_URL } from '../../Constants/Constants';

const LessonRequestCard = ({
  cardIndex, organization, mappedImages, setOrganizationData,
  addToFinalRanking, setShowViewProposalModal, setProposalData,
  setCardIndex
}) => {
  const [tempData, setTempData] = useState({});

  useEffect(() => {
    (async () => {
      setCardIndex(cardIndex);
      if (organization.creativeWork !== undefined) {
        const creativeWorkId = organization.creativeWork.split('/')[2];
        const { data } = await axios.get(`${CREATIVE_WORKS_URL}/${creativeWorkId}`);
        setTempData(data);
      }
    })();
  }, [organization]);

  return (
    <div className='lesson-request-detail-other-lesson-card'>
      <i
        className='fa fa-heart lesson-request-detail-other-lesson-card-heart'
        onClick={() => addToFinalRanking(cardIndex)}
      />
      <span className='lesson-request-detail-other-lesson-card-heading'>
        {organization.name.replace(' and ', ' dan ')}
      </span>
      <div className='lesson-request-detail-other-lesson-card-label' >
        <Label type={organization.status} />
      </div>
      <img
        src={mappedImages[organization.members[0]]}
        alt=""
        className='lesson-request-detail-ranked-team-avatar'
      />
      <span className='lesson-request-detail-ranked-team-avatar span'>
        +{organization.members.length - 1}
      </span>
      <div className='lesson-request-detail-other-lesson-card-btn'>
        <Button onClick={() => {
          setProposalData(tempData && tempData);
          setOrganizationData(organization);
          setShowViewProposalModal(true);
          setCardIndex(cardIndex);
        }}>View Proposal</Button>
      </div>
    </div>
  );
};

export default LessonRequestCard;
