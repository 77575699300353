import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './MyProjects.scss';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import ProjectInvitationsTab from '../../Components/ProjectTabs/ProjectInvitationsTab';
import ProjectListTab from '../../Components/ProjectTabs/ProjectListTab';
import {
  list as projectActionList,
  reset as projectActionReset
} from '../../Generated/actions/project/list';
import {
  list as educationalLevelActionList,
  reset as educationalLevelActionReset
} from '../../Generated/actions/educationallevel/list';
import Loading from '../../Components/Loading/Loading';

const MyProjects = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('projectList');
  const listOfProject = useSelector((state) => state.project.list);
  const organizationList = useSelector(
    (state) => state.organization.searchList
  );

  const listOfEducationalLevel = useSelector(
    (state) => state.educationallevel.list
  );

  // todo connect projects to retrieved organizations
  useEffect(() => {
    dispatch(projectActionList('projects?pagination=false'));
    return () => {
      dispatch(projectActionReset(listOfProject.eventSource));
    };
  }, []);

  useEffect(() => {
    dispatch(
      educationalLevelActionList(
        '/educational_levels?order%5Bordering%5D=asc&pagination=false'
      )
    );
    return () => {
      dispatch(educationalLevelActionReset(listOfEducationalLevel.eventSource));
    };
  }, []);

  const renderTabs = () => {
    return (
      <div className='my-projects-tabs'>
        <div
          onClick={() => setActiveTab('projectList')}
          className={`my-projects-tabs-tab ${activeTab === 'projectList' ? 'active' : ''}`}
        >
          Daftar Proyek
        </div>
        <div
          onClick={() => setActiveTab('projectInvitations')}
          className={`my-projects-tabs-tab ${activeTab === 'projectInvitations' ? 'active' : ''}`}
        >
          Undangan Proyek
        </div>
      </div>
    );
  };

  const renderHeading = () => {
    return (
      <div className='my-projects-heading'>
        <h1 className='my-projects-heading-title'>Papan Pintarku</h1>
        {renderTabs()}
      </div>
    );
  };

  return (
    <div className='my-projects'>
      {listOfProject.loading || organizationList.loading ? <Loading /> : null}
      <NavigationBar />
      {renderHeading()}
      <Container className='my-projects-container'>
        {activeTab === 'projectList' && <ProjectListTab />}
        {activeTab === 'projectInvitations' && <ProjectInvitationsTab />}
      </Container>
    </div>
  );
};

export default MyProjects;
