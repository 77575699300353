import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iconBook from '../../Assets/Images/ico-book.png';
import {
  list as awardListAction,
  reset as resetAwardListAction,
} from '../../Generated/actions/award/list';
import GetThemeAndSubThemeNameFormObject from '../DataHelpers/GetThemeAndSubThemeNameFormObject';
import AwardForProjectBriefTab from '../Award/AwardForProjectBriefTab';
import EducationalLevel from '../EducationalLevel/EducationalLevel';
import ModalGuidelines from '../ModalGuidelines/ModalGuidelines';
import {
  ANIMATION_GUIDELINE_URL, ASSET_GUIDELINE_URL,
  DESIGN_GUIDELINE_URL, TEACHER_GUIDELINE_URL,
  LESSON_GUIDELINE_URL
} from '../../Constants/Constants';

const ProjectBriefTab = ({ projectDetail, showGuidelines = false }) => {
  const dispatch = useDispatch();

  const [showPreview, setShowPreview] = useState(false);
  const [guidelineDetail, setGuidelineDetail] = useState({});
  const awardEvent = useSelector((state) => state.award.list.eventSource);
  const awardList = useSelector((state) => state.award.list.retrieved);
  const requestDetails = projectDetail || {};
  useEffect(() => {
    if (projectDetail) {
      dispatch(
        awardListAction(
          `/awards?order[rank]=asc&project=${projectDetail['@id']}`
        )
      );
    }
    return () => {
      dispatch(resetAwardListAction(awardEvent));
    };
  }, [projectDetail]);

  const handleGuideline = (role) => {
    if (role === 'guru') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Guru',
        fileName: 'Panduan Untuk Guru (Bahasa).mp4',
        url: TEACHER_GUIDELINE_URL
      });
    }
    if (role === 'animasi') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Animasi',
        fileName: 'Pedoman Animasi.mp4',
        url: ANIMATION_GUIDELINE_URL
      });
    }
    if (role === 'desain') {
      window.open(DESIGN_GUIDELINE_URL);
    }
    if (role === 'aset') {
      window.open(ASSET_GUIDELINE_URL);
    }
    if (role === 'lesson') {
      window.open(LESSON_GUIDELINE_URL);
    }
  };

  return (
    <div>
      <Row>
        <Col xs={3}>
          <div className='question-answer-overview-title'>
            <Row>
              <Col xs={12}>
                <Image src={iconBook} fluid />
              </Col>
              <Col className='mt-2' xs={10}>
                <h1>
                  Ringkasan <br /> Pelajaran
                </h1>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={9}>
          <div className='question-answer-overview-content'>
            <div className='question-answer-overview-content-wrapper'>
              <Row>
                <Col xs={2}>
                  <h5>Material</h5>
                </Col>
                <Col xs={10}>
                  <p>
                    <EducationalLevel
                      type='with-text'
                      educationalLevel={
                        projectDetail && projectDetail.educationalLevel
                      }
                    />{' '}
                    &nbsp;
                    {requestDetails.material}
                  </p>
                </Col>
              </Row>
            </div>
            {projectDetail && projectDetail.subtheme && (
              <div className='question-answer-overview-content-wrapper'>
                <Row>
                  <Col xs={2}>
                    <h5>Tema</h5>
                  </Col>
                  <Col xs={10}>
                    <p>
                      <GetThemeAndSubThemeNameFormObject
                        projectDetail={projectDetail}
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            )}
            <div className='question-answer-overview-content-wrapper'>
              <Row>
                <Col xs={2}>
                  <h5>Objektif</h5>
                </Col>
                <Col xs={10}>
                  <p>{requestDetails.objective}</p>
                </Col>
              </Row>
            </div>
            <div className='question-answer-overview-content-wrapper'>
              <Row>
                <Col xs={2}>
                  <h5>Aktivitas</h5>
                </Col>
                <Col xs={10}>
                  <p>{requestDetails.activity}</p>
                </Col>
              </Row>
            </div>
            <div className={'question-answer-overview-content-wrapper'}>
              <Row>
                <Col xs={2}>
                  <h5>Penilaian</h5>
                </Col>
                <Col xs={10}>
                  <p>{requestDetails.assessment}</p>
                </Col>
              </Row>
            </div>
            <div className={'question-answer-overview-content-wrapper'}>
              <Row>
                <Col xs={2}>
                  <h5>Hadiah</h5>
                </Col>
                <Col xs={10}>
                  {awardList && awardList['hydra:member'].map((award) => (
                    <AwardForProjectBriefTab award={award} key={award.id} />
                  ))}
                </Col>
              </Row>
            </div>
            {showGuidelines && (
              <div className={'question-answer-overview-content-wrapper'}>
                <Row>
                  <Col xs={2}>
                    <h5 style={{ color: '#E3B26A' }}>Pedoman</h5>
                  </Col>
                  <Col xs={10}>
                    Lihat Pedoman :{' '}
                    <span
                      onClick={() => handleGuideline('guru')}
                      style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Pedoman Untuk Guru
                    </span>,{' '}
                    <span
                      onClick={() => handleGuideline('desain')}
                      style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Pedoman Untuk Desainer
                    </span>,{' '}
                    <span
                      onClick={() => handleGuideline('animasi')}
                      style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Pedoman Untuk Animator
                    </span>&nbsp;
                    Unduh : <span
                      onClick={() => handleGuideline('lesson')}
                      style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Template Pelajaran
                    </span>, <span
                      onClick={() => handleGuideline('aset')}
                      style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Paket Aset
                    </span>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <ModalGuidelines showModal={showPreview} setShowModal={setShowPreview} guidelineDetail={guidelineDetail} />
    </div>
  );
};

export default ProjectBriefTab;

ProjectBriefTab.propTypes = {
  projectDetail: PropTypes.object,
};
