/* eslint-disable consistent-return */
import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Img from '../../Assets/Images/open-to-anyone.png';
import Button from '../Button/Button';
import './StartProposalAloneConfirmModal.scss';

const StartProposalAloneConfirmModal = ({
  isShowModal,
  setIsShowModal,
  details,
}) => {
  const renderButtons = () => {
    if (!details.isSingleButton) {
      return (
        <>
          <div className='start-proposal-alone-confirm-modal-btn-flex-btn' >
            <Button block onClick={() => details.noClick()} >{details.noText}</Button>
          </div>
          <div className='start-proposal-alone-confirm-modal-btn-flex-btn' >
            <Button type='secondary' disabled={details.loading} block onClick={() => details.yesClick()} >
              {details.yesText}
            </Button>
          </div>
        </>
      );
    }
    return (
      <Button block onClick={() => details.yesClick()} >{details.yesText}</Button>
    );
  };

  return (
    <Modal show={isShowModal}
      dialogClassName='start-proposal-alone-confirm-modal'
      backdrop='static'
      centered
    >
      <i className='fa fa-times start-proposal-alone-confirm-modal-close' onClick={() => setIsShowModal(false)} />
      <img src={Img} alt="" className='mt-3' />
      <p className='start-proposal-alone-confirm-modal-heading' >
        {(details).heading}
      </p>
      <p className='start-proposal-alone-confirm-modal-sub'>
        {(details).sub}
      </p>
      <div className='start-proposal-alone-confirm-modal-btn-flex' >
        {renderButtons()}
      </div>
    </Modal>
  );
};

StartProposalAloneConfirmModal.propTypes = {
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func,
  details: PropTypes.object,
};

StartProposalAloneConfirmModal.defaultProps = {
  details: {},
};

export default StartProposalAloneConfirmModal;
