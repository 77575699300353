import React from 'react';
import { Modal } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import './ContestOptionModal.scss';

const ContestOptionModal = (props) => {
  const {
    show,
    handleClose,
    handleClickAddFriend,
    handleClickOpenToAnyone,
  } = props;

  return (
    <div className='contest-option'>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='contest-option-modal'
        backdrop='static'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Menangkan Kontes Bersama
            <br />
            tim terbaik :)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className='contest-option-modal-content'
            onClick={handleClickAddFriend}
          >
            <div className='contest-option-modal-content-text'>
              <p className='contest-option-modal-content-text-title'>
                Undang anggota
              </p>
              <p className='contest-option-modal-content-text-caption'>
                Temukan anggota yang menurut kamu keren!
              </p>
            </div>
            <div className='contest-option-modal-content-arrow'>
              <ArrowRight />
            </div>
          </div>
          <div
            className='contest-option-modal-content'
            onClick={handleClickOpenToAnyone}
          >
            <div className='contest-option-modal-content-text'>
              <p className='contest-option-modal-content-text-title'>
                Terbuka untuk umum
              </p>
              <p className='contest-option-modal-content-text-caption'>
                Biarkan orang lain bergabung denganmu
              </p>
            </div>
            <div className='contest-option-modal-content-arrow'>
              <ArrowRight />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContestOptionModal;
