import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './InvitationCard.scss';
import { isEqual } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Stopwatch, EyeFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Label from '../Label/Label';
import Button from '../Button/Button';
import StartProposalConfirmModal from '../StartProposalConfirmModal/StartProposalConfirmModal';
import Loading from '../Loading/Loading';
import JoinProjectModal from '../JoinProjectModal/JoinProjectModal';
import MathBg from '../../Assets/Images/math-card-bg.png';
import ScienceBg from '../../Assets/Images/science-card-bg.png';
import {
  acceptInvitation,
  reject as unconfirmedUpdateReject,
  reset as unconfirmedUReset
} from '../../Generated/actions/organizationunconfirmedmembers/update';
import {
  del as unconfirmedMemberDelete
} from '../../Generated/actions/organizationunconfirmedmembers/delete';
import ConfirmationModal from '../Modal/ConfirmationModal';
import { translateSubject } from '../../Helpers/Common';

const InvitationCard = ({ unconfirmedMember, setRefresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(null);
  const [project, setProject] = useState(null);
  const [educationalLevel, setEducationalLevel] = useState(null);
  const [person, setPerson] = useState(null);
  const [mediaObject, setMediaObject] = useState(null);
  const [mappedImages, setMappedImages] = useState([]);
  const [tags, setTags] = useState(null);
  const organizationData = useSelector((state) => state.organization.searchList);
  const projectData = useSelector((state) => state.project.list);
  const personList = useSelector((state) => state.person.list);
  const mediaObjectList = useSelector((state) => state.mediaobject.list);
  const educationalLevelList = useSelector((state) => state.educationallevel.list);
  const tagList = useSelector((state) => state.tags.list);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmStartProposalModal, setShowConfirmStartProposalModal] = useState(false);
  const [showStartedProposalModal, setShowStartedProposalModal] = useState(false);
  const [showJoinProjectModal, setShowJoinProjectModal] = useState(false);
  const [showJoinedProjectModal, setShowJoinedProjectModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const unconfirmedMemberDeleted = useSelector((state) => state.organizationunconfirmedmembers.del);
  const unconfirmedMemberUpdated = useSelector((state) => state.organizationunconfirmedmembers.update);

  useEffect(() => {
    setIsLoading(true);
    if (!projectData.loading && !tagList.loading && !organizationData.loading) {
      setIsLoading(false);
    }
  }, [projectData.loading, tagList.loading, organizationData.loading]);

  useEffect(() => {
    if (organizationData.retrieved && organizationData.retrieved['hydra:totalItems']) {
      setOrganization(organizationData.retrieved
        && organizationData.retrieved['hydra:member'].filter((filteredOrganization) =>
          (filteredOrganization['@id'] === unconfirmedMember.organization)).length > 0
        && organizationData.retrieved['hydra:member'].filter((filteredOrganization) =>
          (filteredOrganization['@id'] === unconfirmedMember.organization)));
    }
  }, [unconfirmedMember, organizationData.retrieved]);

  useEffect(() => {
    if (organization && projectData.retrieved) {
      setProject(projectData.retrieved
        && projectData.retrieved['hydra:member'].filter((filteredProject) =>
          (filteredProject['@id'] === organization[0].project)).length > 0
        && projectData.retrieved['hydra:member'].filter((filteredProject) =>
          (filteredProject['@id'] === organization[0].project)));
    }
  }, [organization, projectData.retrieved]);

  useEffect(() => {
    if (project && educationalLevelList.retrieved) {
      setEducationalLevel(educationalLevelList.retrieved
        && educationalLevelList.retrieved['hydra:member'].filter((filteredLevel) =>
          (filteredLevel['@id'] === project[0].educationalLevel)).length > 0
        && educationalLevelList.retrieved['hydra:member'].filter((filteredLevel) =>
          (filteredLevel['@id'] === project[0].educationalLevel)));
    }
  }, [project, educationalLevelList.retrieved]);

  useEffect(() => {
    if (organization && personList.retrieved) {
      setPerson(personList.retrieved
        && personList.retrieved['hydra:member'].filter((filteredPerson) =>
          (organization[0].members.includes(filteredPerson.user))).length > 0
        && personList.retrieved['hydra:member'].filter((filteredPerson) =>
          (organization[0].members.includes(filteredPerson.user))));
    }
  }, [organization, personList.retrieved]);

  useEffect(() => {
    if (person) {
      const imageArr = person.map((personData) => personData.image);
      setMappedImages(imageArr);
    }
  }, [person]);

  useEffect(() => {
    if (mappedImages && mediaObjectList.retrieved) {
      setMediaObject(mediaObjectList.retrieved
        && mediaObjectList.retrieved['hydra:member'].filter((filteredMedia) =>
          (mappedImages.includes(filteredMedia['@id']))).length > 0
        && mediaObjectList.retrieved['hydra:member'].filter((filteredMedia) =>
          (mappedImages.includes(filteredMedia['@id']))));
    }
  }, [mappedImages, mediaObjectList.retrieved]);

  useEffect(() => {
    if (project && tagList.retrieved) {
      setTags(tagList.retrieved
        && tagList.retrieved['hydra:member'].filter((tag) =>
          (project[0].tags.includes(tag['@id']))).length > 0
        && tagList.retrieved['hydra:member'].filter((tag) =>
          (project[0].tags.includes(tag['@id']))));
    }
  }, [project, tagList.retrieved]);

  useEffect(() => {
    if (unconfirmedMemberDeleted.deleted || unconfirmedMemberUpdated.updated) {
      dispatch(unconfirmedUReset());
      setRefresh(true);
    }
  }, [unconfirmedMemberDeleted.deleted, unconfirmedMemberUpdated.updated]);

  const confirmStartProposal = () => {
    setShowConfirmStartProposalModal(false);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowStartedProposalModal(true);
    }, 1000);
  };

  const joinProject = () => {
    setShowJoinProjectModal(false);
    setShowJoinedProjectModal(true);
    dispatch(acceptInvitation(unconfirmedMember, { accept: true }));
  };

  const labelType = {
    rejectedLabel: <Label type='STATUS_OVERDUE'>Ditolak</Label>,
    waitingTeamLabel: <Label type='STATUS_NEED_PARTNER_TEXT'>Kamu diundang ke dalam tim</Label>,
    waitingYouLabel: <Label type='STATUS_NEED_PARTNER_TEXT'>Menunggu disetujui oleh tim</Label>,
  };

  const rejectInvitation = () => {
    dispatch(unconfirmedUpdateReject(unconfirmedMember, { rejectInvitation: true }));
  };

  const onDelete = () => {
    dispatch(unconfirmedMemberDelete(unconfirmedMember));
  };

  const buttonType = {
    exitProject: <Button type='danger' onClick={() => setShowDeleteModal(true)} block={true}>Hapus</Button>,
    startProposal:
        (<Row>
          <Col xs={6}>
            <Button
                type='secondary'
                block={true}
                onClick={() => setShowRejectModal(true)}
            >
              Jangan Bergabung
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type='main'
              block={true}
              onClick={() => setShowJoinProjectModal(true)}
            >
              Gabung Tim
            </Button>
          </Col>
        </Row>),
    joinProject: (
      <Row>
        <Col xs={6}>
          <Button
            type='secondary'
            block={true}
            onClick={() => setShowRejectModal(true)}
          >
            Batalkan Permintaan
          </Button>
        </Col>
      </Row>
    )
  };

  const renderLabel = () => {
    return (
      <div className='invitation-card__label'>
        {isEqual(unconfirmedMember.status, 'STATUS_INVITED') && labelType.waitingTeamLabel}
        {isEqual(unconfirmedMember.status, 'STATUS_WANTED_TO_JOIN') && labelType.waitingYouLabel}
        {isEqual(unconfirmedMember.status, 'STATUS_REJECTED') && labelType.rejectedLabel}
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className={'invitation-card__title'}>
        <h1>
          {organization && organization[0].name.replace(' and ', ' dan ')}
        </h1>
        <span>{project && project[0].title}</span>
        <h1>
          {tags && 'Pelajaran '}{tags && translateSubject(tags[0].tag)}{tags && ' - '}
          {educationalLevel && educationalLevel[0].level}
        </h1>
      </div>
    );
  };

  const renderViewAndDeadline = () => {
    const todayDate = moment(new Date());
    const endTime = project ? project[0].endTime : null;
    const projectEndTime = moment(endTime);
    const countDeadline = projectEndTime.diff(todayDate, 'days');
    return (
      <>
        <div className='invitation-card__view'>
          <EyeFill /> <Link to='/'>Lihat Pengarahan</Link>
        </div>
        <div className='invitation-card__deadline'>
          <Stopwatch /> {countDeadline} hari lagi
        </div>
      </>
    );
  };

  const renderInvitationMember = () => {
    return (
      <div className='invitation-card__member'>
        {mediaObject && mediaObject.map((image) => (
          <div className='invitation-card__member avatar'>
            <img className='invitation-card__member-img' src={image.contentUrl} alt='' />
          </div>
        ))}
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div className='invitation-card__button'>
        {isEqual(unconfirmedMember.status, 'STATUS_REJECTED') && buttonType.exitProject}
        {isEqual(unconfirmedMember.status, 'STATUS_WANTED_TO_JOIN') && buttonType.joinProject}
        {isEqual(unconfirmedMember.status, 'STATUS_INVITED') && buttonType.startProposal}
      </div>
    );
  };

  const renderConfirmationStartProposalModal = () => {
    const details = {};

    if (showConfirmStartProposalModal) {
      details.show = showConfirmStartProposalModal;
      details.setShow = setShowConfirmStartProposalModal;
      details.heading = 'Mulai mengerjakan proposal bersama anggota timmu !';
      details.sub = 'Kamu tidak bisa mengubah timmu setelah kamu memulai proposal. Apakah kamu yakin?';
      details.isSingleButton = false;
      details.noText = 'Tidak, saya masih berpikir';
      details.yesText = 'Iya, saya yakin!';
      details.yesClick = confirmStartProposal;
    }

    if (showStartedProposalModal) {
      details.show = showStartedProposalModal;
      details.setShow = setShowStartedProposalModal;
      details.heading = 'Sekarang kalian sudah siap. Semoga beruntung dengan timmu!';
      // eslint-disable-next-line max-len
      details.sub = 'Tidak sabar untuk melihat karyamu yang luar biasa. Sampai jumpa di sesi terakhir, dan semoga berhasil!';
      details.isSingleButton = true;
      details.yesText = 'Oke, ayo mulai!';
      details.yesClick = () => {
        setShowStartedProposalModal();
        const creativeWorkData = organization && organization[0].creativeWork
          && organization[0].creativeWork.split('/');
        history.push(`/proposal/${creativeWorkData[2]}`);
      };
    }

    if (showJoinedProjectModal) {
      details.show = showJoinedProjectModal;
      details.setShow = setShowJoinedProjectModal;
      details.heading = 'Selamat! Kamu menemukan timmu :)';
      details.sub = 'Jangan lupa untuk mengatakan hai kepada ketua timmu dan mulai menyiapkan proposal segera.';
      details.isSingleButton = true;
      details.yesText = 'Terima kasih!';
      details.yesClick = () => setShowJoinedProjectModal();
    }

    return (
      <StartProposalConfirmModal
        details={details}
        isShowModal={details.show}
        setIsShowModal={details.setShow}
      />
    );
  };

  const renderJoinProjectModal = () => {
    return (
      <JoinProjectModal
          key={`${unconfirmedMember.id}renderJoinProjectModal`}
        isShowModal={showJoinProjectModal}
        setIsShowModal={setShowJoinProjectModal}
        joinProject={joinProject}
        rejectInvitation={setShowRejectModal}
        organization = { organization && organization[0] }
        project={ project && project[0]}
          renderTitle={renderTitle}
          renderViewAndDeadline={renderViewAndDeadline}
      />
    );
  };

  if (isLoading) {
    return <h1>Sedang memulai...</h1>;
  }

  return (
    <>
      {loading && <Loading />}
      <div className='invitation-card'>
        <div className='invitation-card__wrapper'>
          <img src={tags && tags[0].tag === 'Science' ? ScienceBg : MathBg} alt=""
            className='invitation-card-math-bg' />
          {renderInvitationMember()}
          {renderLabel()}
          {renderTitle()}
          {renderViewAndDeadline()}
          {renderButton()}
          {renderConfirmationStartProposalModal()}
          {renderJoinProjectModal()}
          <ConfirmationModal
              handleSubmit={rejectInvitation}
              setIsShowModal={setShowRejectModal}
              isShowModal={showRejectModal}
              doneMsg={'Tim berhasil ditolak!'}
              headerMsg={'Ditolak'}
          />
          <ConfirmationModal
              handleSubmit={onDelete}
              setIsShowModal={setShowDeleteModal}
              isShowModal={showDeleteModal}
              doneMsg={'Berhasil menghapus!'}
              headerMsg={'Menghapus undangan yang ditolak ini'}
          />
        </div>
      </div>
    </>
  );
};

export default InvitationCard;

InvitationCard.propTypes = {
  unconfirmedMember: PropTypes.object.isRequired
};
