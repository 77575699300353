import axios from 'axios';
import * as jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { encryptData } from '../Helpers/Common';

import {
  API_BASE_URL, FETCH_USERS,
  headerLDJsonConfig, LOGIN_URL,
  PERSON_URL, HASH_DECRYPT
} from '../Constants/Constants';

export const isError = (value) => ({
  type: 'IS_ERROR',
  payload: value
});

export const isSuccessRegister = (value) => ({
  type: 'IS_SUCCESS_REGISTER',
  payload: value
});

// todo make error handle like you will be logged out
export function error(err) {
  return { type: 'AUTH_REFRESH_ERROR', err };
}

export function loading(load) {
  return { type: 'AUTH_REFRESH_LOADING', load };
}

export function success(created) {
  return { type: 'AUTH_REFRESH_SUCCESS', created };
}

// todo change to more secure way of storing data
function saveAppToken(token, refreshingToken) {
  const decodedToken = jwtDecode(token);
  localStorage.setItem('token', token);
  localStorage.setItem('refresh_token', refreshingToken);
  localStorage.setItem('id', decodedToken.uuid);
  localStorage.setItem('user', encryptData(decodedToken, HASH_DECRYPT));
}

export const signIn = (emailAndPassword, history) => {
  return async (dispatch) => {
    try {
      dispatch(isError());
      const { data: { token, refresh_token: userRefreshToken } } = await axios.post(LOGIN_URL, emailAndPassword);
      if (token) {
        saveAppToken(token, userRefreshToken);
        const userId = localStorage.getItem('id');
        const { data } = await axios.get(`${API_BASE_URL}${userId}`);
        const { data: dataPeople } = await axios.get(`${PERSON_URL}?user=${userId}`);
        if (dataPeople['hydra:member'].length > 0) {
          if (dataPeople['hydra:member'][0].phoneNumber) {
            const imageId = dataPeople['hydra:member'][0].image;
            const { data: dataImage } = await axios.get(`${imageId}`);
            localStorage.setItem('avatar', dataImage.contentUrl);
          }
        }
        if (data && !isEmpty(data.tags)) {
          return history.push('/papan-pintar');
        }
        return history.push('/complete-profile');
      }
      return new Error();
    } catch (e) {
      if (e.response.data.message === 'Invalid credentials.') {
        return dispatch(isError(true));
      }
      return dispatch(isError(e.response.data.message));
    }
  };
};

export const signUp = (users, history, setStatus) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(FETCH_USERS, users, headerLDJsonConfig);
      if (data) {
        dispatch(isSuccessRegister(true));
        history.push('/login');
      }
    } catch (err) {
      if (err.response.data) {
        setStatus(err.response.data.violations);
      }
    }
  };
};

export const signOut = () => {
  return () => {
    localStorage.clear();
    window.location.reload();
  };
};

export function refreshToken(dispatch) {
  const value = { refresh_token: localStorage.getItem('refresh_token') };
  const freshTokenPromise = axios.post('/token/refresh', value)
    .then((t) => {
      saveAppToken(t.data.token, t.data.refresh_token);
      window.location.reload(false);
    })
    .catch(() => {
      dispatch(signOut());
    });
  return freshTokenPromise;
}
