import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`organization_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`organization_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={this.renderField}
          name="id"
          type="text"
          placeholder=""
          required={true}
        />
        <Field
          component={this.renderField}
          name="name"
          type="text"
          placeholder="the name of the item"
          required={true}
        />
        <Field
          component={this.renderField}
          name="members"
          type="text"
          placeholder="a member of this organization"
          normalize={v => (v === '' ? [] : v.split(','))}
        />
        <Field
          component={this.renderField}
          name="founder"
          type="text"
          placeholder="a person who founded this organization"
        />
        <Field
          component={this.renderField}
          name="image"
          type="text"
          placeholder="An image of the item. This can be a \[\[URL\]\] or a fully described \[\[ImageObject\]\].
other organizations can use that image"
        />
        <Field
          component={this.renderField}
          name="openForJoin"
          type="checkbox"
          placeholder="is Open To join by people"
        />
        <Field
          component={this.renderField}
          name="locked"
          type="checkbox"
          placeholder="is locked"
        />
        <Field
          component={this.renderField}
          name="project"
          type="text"
          placeholder=""
          required={true}
        />
        <Field
          component={this.renderField}
          name="creativeWork"
          type="text"
          placeholder=""
        />

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'organization',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
