import React, { useState, useEffect } from 'react';
import { Container, Row, /* Col */ } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { isEmpty } from 'lodash';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Recommendation from '../../Components/Recommendation/Recommendation';
// import OrganizationCard from '../../Components/OrganizationCard/OrganizationCard';
import profilePicture from '../../Assets/Images/profile-picture.png';
import Button from '../../Components/Button/Button';
import './Profile.scss';
import stars from '../../Assets/Images/profile-stars.svg';
import trophy from '../../Assets/Images/profile-trophy.svg';
import { useFetch } from '../../Hooks/UseFetch/UseFetch';
import Loading from '../../Components/Loading/Loading';
import {
  OPTION_TAGS_URL
} from '../../Constants/Constants';

const translateTag = {
  COMMUNICATION: 'Komunikasi',
  ORGANIZATION: 'Organisasi',
  FUNCTIONAL_SKILL: 'Keterampilan',
  LEADERSHIP: 'Kepemimpinan',
  TEAMWORK: 'Kerja Sama'
};

const Profile = () => {
  const history = useHistory();
  const { fetchedData: { data: tagData } } = useFetch(`${OPTION_TAGS_URL}?role[]=ROLE_TEACHER&role[]=ROLE_DESIGNER`);
  const { fetchedData: { data: reviewTags } } = useFetch(OPTION_TAGS_URL, {
    role: 'REVIEW_TAG'
  });
  const [isRenderReview, setIsRenderReview] = useState(true);
  const [isRenderContest, setIsRenderContest] = useState(false);
  const [isReceived, setIsReceived] = useState(true);
  const userid = localStorage.getItem('id');
  const { fetchedData: { data: userData } } = useFetch(userid);
  const { fetchedData: { data: personData } } = useFetch(`/people?pagination=false&user[]=${userid}`);
  const { fetchedData: { data: reviewsData } } = useFetch(`/reviews?pagination=false&itemReviewed[]=${userid}`);
  const [loading, setLoading] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [profileData, setProfileData] = useState({
    name: '',
    jobTitle: '',
    worksFor: '',
    rating: 0
  });
  const [imageUrl, setImageUrl] = useState(profilePicture);
  const SpecialityOptions = !isEmpty(tagData) ? tagData
    && tagData['hydra:member'].map((value) => {
      return {
        value: value['@id'],
        label: value.tag
      };
    }) : [];

  const handleSwitchTab = () => {
    setIsRenderReview(!isRenderReview);
    setIsRenderContest(!isRenderContest);
  };

  const handleRecommendation = () => {
    setIsReceived(!isReceived);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (reviewsData && reviewTags) {
        if (reviewsData['hydra:member'].length > 0) {
          const reviewsFrom = reviewsData['hydra:member'].map((r) => r.author).join('&user[]=');
          const { data: userReviewsFrom } = await axios.get(`/users?pagination=false&id[]=${reviewsFrom}`);
          const { data: dataPeople } = await axios.get(`/people?pagination=false&user[]=${reviewsFrom}`);
          const images = dataPeople['hydra:member'].map((p) => p.image).join('&id[]=');
          const { data: dataImages } = await axios.get(`/image_objects?pagination=false&id[]=${images}`);
          const dataReviews = reviewsData['hydra:member']
            .filter((r) => r.isSubmitted)
            .map((r) => {
              const dataUser = userReviewsFrom['hydra:member'].find((u) => u['@id'] === r.author);
              const dataPerson = dataPeople['hydra:member'].find((u) => u.user === r.author);
              const dataTags = reviewTags['hydra:member']
                .filter((t) => r.tags.find((tag) => tag === t['@id']))
                .map((tg) => translateTag[tg.value]);
              const personImage = dataImages['hydra:member'].find((img) => img['@id'] === dataPerson.image);
              return {
                ...r,
                dataUser,
                dataPerson,
                dataTags,
                personImage: personImage.contentUrl
              };
            });
          setRecommendations(dataReviews);
        }
      }
      setLoading(false);
    })();
  }, [reviewsData, reviewTags]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (personData) {
          const mappedMember = personData['hydra:member'][0];
          if (!isEmpty(mappedMember)) {
            setProfileData({
              ...profileData,
              jobTitle: mappedMember.jobTitle,
              worksFor: mappedMember.worksFor,
              rating: mappedMember.aggregateRating || 0
            });
            setLoading(true);
            try {
              const { data: imageData } = await axios.get(`${mappedMember.image}`);
              if (!isEmpty(imageData && imageData.contentUrl)) {
                setImageUrl(imageData.contentUrl);
              }
            } catch (error) {
              setLoading(false);
            }
            setLoading(true);
            try {
              const { data: contactsData } = await axios.get(`/people/${mappedMember.id}/contacts`);
              if (!isEmpty(contactsData)) {
                // setLoading(false);
                const contacts = contactsData['hydra:member'].filter((value, memberIndex) => {
                  if (value.name !== 'other') {
                    contactsData['hydra:member'][memberIndex].url = value.value;
                    contactsData['hydra:member'][memberIndex].isExist = 1;
                    return true;
                  }
                  return false;
                });
                setSocialLinks(contacts);
              }
            } catch (error) {
              setLoading(false);
            }
          }
        }
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [personData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (userData) {
        setLoading(false);
        setProfileData({
          ...profileData,
          name: userData.username,
        });
      }
    })();
  }, [userData]);

  const renderHeader = () => {
    return (
      <div className='profile-header'>
        <div className='' >
          <img
            src={imageUrl || profilePicture}
            alt=""
            className='profile-header-img-container-img'
          />
        </div>
        <div className='profile-header-details'>
          <div className='profile-header-details-name'>
            <h1>{profileData.name}</h1>
            <Button type='secondary' onClick={() => history.push('/profile/edit')} >Edit Profil</Button>
          </div>
          <h5>{userData && SpecialityOptions
            && SpecialityOptions.filter((e) => {
              return userData.tags.some((item) => item === e.value);
            }).map((item, index) => {
              const string = item.label + ((userData.tags.length - 1 - index === 0) ? '' : ', ');
              return string;
            })
          } di {profileData.worksFor}</h5>
          <div className='profile-header-social-media'>
            {
            socialLinks.map((item, i) => {
              return <a href={item.value} key={i} target="_blank"><Button>{item.name}</Button></a>;
            })
          }
          </div>
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className='profile-tabs'>
        <div
          className='profile-tabs-tab'
          id={isRenderReview ? 'active' : ''}
          onClick={handleSwitchTab}
        >
          <div className='profile-tabs-tab-image'>
            <img src={stars} alt='' />
          </div>
          <div
            className='profile-tabs-tab-text'
            id={isRenderReview ? 'text-active' : ''}
          >
            <h1>{profileData.rating}</h1>
            <p>Ulasan Keseluruhan</p>
          </div>
        </div>
        <div
          className='profile-tabs-tab'
          id={isRenderContest ? 'active' : ''}
          onClick={handleSwitchTab}
        >
          <div className='profile-tabs-tab-image'>
            <img src={trophy} alt='' />
          </div>
          <div
            className='profile-tabs-tab-text'
            id={isRenderContest ? 'text-active' : ''}
          >
            <h1>0</h1>
            <p>Menang Kontes</p>
          </div>
        </div>
        {/* <div className='profile-tabs-tab' /> */}
      </div>
    );
  };

  const renderReview = () => {
    return (
      <div className='profile-reviews'>
        <div className='profile-reviews-button'>
          <Button
            type={!isReceived ? 'secondary' : 'primary'}
            onClick={handleRecommendation}
          >
            Rekomendasi Diterima
          </Button>
          <Button
            type={isReceived ? 'secondary' : 'primary'}
            onClick={handleRecommendation}
          >
            Rekomendasi Diberikan
          </Button>
        </div>
        {recommendations.map((item, i) => (
          <Recommendation
            key={i}
            avatar={item.personImage}
            ratingValue={item.reviewRating}
            name={item.dataUser.username}
            job={item.dataPerson.jobTitle}
            company={item.dataPerson.worksFor}
            // date={item.date}
            skills={item.dataTags}
            testimonial={item.reviewBody}
          />
        ))}
      </div>
    );
  };

  const renderContest = () => {
    return (
      <div className='profile-contests'>
        {/* <div className='profile-contests-earnings'>
          <p>Your total earnings since August 2019</p>
          <h1>Rp. 15.000.000</h1>
        </div> */}
        <div className='profile-contests-cards'>
          <Row>
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
            {/* <Col xs={6}> */}
            {/*  <br /> */}
            {/*  <OrganizationCard */}
            {/*    teamName='Christine & Team' */}
            {/*    lessonName='Science Lesson - Grade 4' */}
            {/*    isWinner={true} */}
            {/*    type='completed' */}
            {/*    time='12 Sept 2020' */}
            {/*    organizationId={''} */}
            {/*  /> */}
            {/* </Col> */}
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavigationBar />
      <Container>
        {loading && <Loading />}
        {renderHeader()}
        {renderTab()}
        {isRenderReview && renderReview()}
        {isRenderContest && renderContest()}
      </Container>
    </>
  );
};

export default Profile;
