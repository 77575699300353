import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import PublicRoute from './PublicRoute';
import AdminRoute from './AdminRoute';
import SignIn from '../Pages/SignIn/SignIn';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import SetPassword from '../Pages/ForgotPassword/SetPassword';
import SignUp from '../Pages/SignUp/SignUp';
import CompleteProfile from '../Pages/CompleteProfile/CompleteProfile';
import PapanPintar from '../Pages/PapanPintar/PapanPintar';
import ContestantProposal from '../Pages/ContestantProposal/ContestantProposal';
import Profile from '../Pages/Profile/Profile';
import PlayGround from '../Pages/PlayGround/PlayGround';
import MyProjects from '../Pages/MyProjects/MyProjects';
import QuestionAnswer from '../Pages/QuestionAnswer/QuestionAnswer';
import EditProfile from '../Pages/EditProfile/EditProfile';
import HowItWorks from '../Pages/HowItWorks/HowItWorks';
import organizationunconfirmedmembersRoutes from '../Generated/routes/organizationunconfirmedmembers';
import answerRoutes from '../Generated/routes/answer';
import bankRoutes from '../Generated/routes/bank';
import userresetpasswordRoutes from '../Generated/routes/userresetpassword';
import userconfirmationRoutes from '../Generated/routes/userconfirmation';
import userRoutes from '../Generated/routes/user';
import projectRoutes from '../Generated/routes/project';
import educationallevelRoutes from '../Generated/routes/educationallevel';
import tagsRoutes from '../Generated/routes/tags';
import creativeworkRoutes from '../Generated/routes/creativework';
import personRoutes from '../Generated/routes/person';
import questionRoutes from '../Generated/routes/question';
import mediaobjectRoutes from '../Generated/routes/mediaobject';
import bankaccountRoutes from '../Generated/routes/bankaccount';
import addressregionRoutes from '../Generated/routes/addressregion';
import organizationRoutes from '../Generated/routes/organization';
import reviewRoutes from '../Generated/routes/review';
import contactRoutes from '../Generated/routes/contact';
import PrivateRoute from './PrivateRoute';
import ImitateUser from '../Components/Imaitate/ImeitateUser';
import AdminLessonRequests from '../Pages/AdminLessonRequests/AdminLessonRequests';
import LessonRequestDetail from '../Pages/LessonRequestDetail/LessonRequestDetail';
import AdminCreateLesson from '../Pages/AdminCreateLesson/AdminCreateLesson';
import AdminLessonBank from '../Pages/AdminLessonBank/AdminLessonBank';
import Logout from '../Components/Logout/Logout';
import AdminEditProposal from '../Pages/AdminEditProposal/AdminEditProposal';
import MateriGratis from '../Pages/MateriGratis/MateriGratis';
import MobileView from '../Components/MobileView/MobileView';

const AppRouter = () => {
  const history = createBrowserHistory();

  if (window.innerWidth <= 893 || window.screen.width <= 893) {
    return <MobileView />;
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/' exact>
          <Redirect to='/materi-gratis' />
        </Route>
        <PublicRoute path='/login' component={SignIn} />
        <PublicRoute path='/forgot-password' component={ForgotPassword} />
        <PublicRoute path='/set-password' component={SetPassword} />
        <PublicRoute path='/login/verified-user' component={SignIn} />
        <PrivateRoute path='/logout' component={Logout} />
        <PublicRoute path='/register' component={SignUp} />
        <PrivateRoute path='/complete-profile' component={CompleteProfile} />
        <PublicRoute path='/papan-pintar' component={PapanPintar} />
        <PublicRoute path='/lesson-request/:page' component={ContestantProposal} exact strict key='page' />
        <PublicRoute path='/playground' component={PlayGround} />
        <PrivateRoute path='/profile' exact={true} component={Profile} />
        <PublicRoute path='/profile/edit' exact={true} component={EditProfile} />
        <PrivateRoute path='/my-projects' component={MyProjects} />
        <PrivateRoute path='/profile' component={Profile} />
        <PrivateRoute path='/proposal/:page' component={QuestionAnswer} exact strict key='page' />
        <PublicRoute path='/how-it-works' component={HowItWorks} />
        <PublicRoute path='/materi-gratis' component={MateriGratis} exact strict />
        <PrivateRoute path='/imitate/:email' component={ImitateUser} exact strict key='email' />
        <AdminRoute path='/admin/lesson-requests' component={AdminLessonRequests} exact strict />
        <AdminRoute path='/admin/lesson-requests/:page' component={LessonRequestDetail} exact strict key='page' />
        <AdminRoute path='/admin/lesson-request/:page/edit' component={AdminCreateLesson} exact strict key='page' />
        <AdminRoute path='/admin/lesson-bank' component={AdminLessonBank} exact strict />
        <AdminRoute path='/admin/edit-proposal/:page' component={AdminEditProposal} exact strict key='page' />
        {organizationunconfirmedmembersRoutes}
        {answerRoutes}
        {bankRoutes}
        {userresetpasswordRoutes}
        {userconfirmationRoutes}
        {userRoutes}
        {projectRoutes}
        {educationallevelRoutes}
        {tagsRoutes}
        {creativeworkRoutes}
        {personRoutes}
        {questionRoutes}
        {mediaobjectRoutes}
        {bankaccountRoutes}
        {addressregionRoutes}
        {organizationRoutes}
        {reviewRoutes}
        {contactRoutes}
      </Switch>
    </ConnectedRouter>
  );
};

export default AppRouter;
