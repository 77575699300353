import React from 'react';
import PropTypes from 'prop-types';
import './ToggleButton.scss';

const ToggleButton = ({ checked, onChange }) => {
  return (
    <label className="toggle-button">
    <input type="checkbox" checked={checked} onChange={onChange} />
    <span className="toggle-button-slider round" />
  </label>

  );
};

ToggleButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleButton;
