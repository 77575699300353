import { useState, useEffect } from 'react';
import axios from 'axios';
import UseFetch from '../../Utils/UseFetch/UseFetch';

const {
  doReFetch
} = UseFetch;

export const useFetch = (url, params) => {
  const [loading, setLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  const callReFetch = () => {
    doReFetch(setLoading, setReFetch);
  };

  useEffect(() => {
    const header = {
      params
    };

    if (localStorage.getItem('token')) {
      header.headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    }

    (async () => {
      setReFetch(false);
      try {
        const res = await axios.get(url, header);
        setFetchedData(res);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [reFetch]);

  return { fetchedData, loading, callReFetch };
};
