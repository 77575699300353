/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Button from '../Button/Button';
import ToggleButton from '../ToggleButton/ToggleButton';
import './StartProposalModal.scss';
import HandleEscapeKey from '../Input/HandleEscapeKey';
import {
  update as updateOrganization
} from '../../Generated/actions/organization/update';
import UserListWithProfile from '../User/UserListWithProfile';
import RenderTeamRemovalModal from '../UserRemovalModal/RenderTeamRemovalModal';
import {
  list as organizationunconfirmedmembersActionList,
  reset as organizationunconfirmedmembersActionReset
} from '../../Generated/actions/organizationunconfirmedmembers/list';
import AddFriend from '../AddFriend/AddFriend';
import {
  list as listDesignersActionList,
  reset as listDesignersActionReset
} from '../../Generated/actions/user/listDesigners';
import {
  list as listTeachersActionList,
  reset as listTeachersActionReset
} from '../../Generated/actions/user/listTeachers';
import {
  invite as organizationUnconfirmedMembersActionInvite
} from '../../Generated/actions/organizationunconfirmedmembers/create';
import MathBg from '../../Assets/Images/math-card-bg.png';
import { FETCH_USERS, HASH_DECRYPT } from '../../Constants/Constants';
import { decryptData } from '../../Helpers/Common';

const received = 'received';
const requested = 'requested';

const STATUS_WANTED_TO_JOIN = 'STATUS_WANTED_TO_JOIN';
const STATUS_INVITED = 'STATUS_INVITED';
const StartProposalModal = ({
  isShowModal,
  setIsShowModal,
  startProposal,
  renderTitle,
  renderDeadlineClock,
  organization,
  setRefreshUsersOrg,
  isOwner
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(received);
  const [isOpenToNewMembers, setIsOpenToNewMembers] = useState(organization.openForJoin);
  const [initalStateOfOpenToNewMembers, setInitalStateOfOpenToNewMembers] = useState(organization.openForJoin);
  // const [acceptedProposals, setAcceptedProposals] = useState({});
  // const [rejectedProposals, setRejectedProposals] = useState({});
  // const [withdrawedRequests, setWithdrawedRequests] = useState({});
  const [showTeamRemovalModal, setShowTeamRemovalModal] = useState(false);
  const [showUnconfirmedMemberRemovalModal, setShowUnconfirmedMemberRemovalModal] = useState(false);
  const [listOfUsers, setListOfUsers] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [refreshUnconfirmedMembers, setRefreshUnconfirmedMembers] = useState(true);

  const [receivedRequests, setReceivedRequests] = useState([]);
  const [requestedMembers, setRequestedMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddFriendModal, setIsAddFriendModal] = useState(false);
  const [designer, setDesigner] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [search, setSearch] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [tempData, setTempData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitInvite, setSubmitInvite] = useState(false);

  const listTeachers = useSelector((state) => state.user.listTeachers);
  const listDesigners = useSelector((state) => state.user.listDesigners);
  const listOforganizationunconfirmedmembers = useSelector((state) => state.organizationunconfirmedmembers.list);
  const updateOforganizationunconfirmedmembers = useSelector((state) => state.organizationunconfirmedmembers.update);
  const userList = useSelector((state) => state.user.list);

  useEffect(() => {
    if (isOpenToNewMembers !== initalStateOfOpenToNewMembers) {
      dispatch(updateOrganization(organization, { openForJoin: isOpenToNewMembers }));
      setInitalStateOfOpenToNewMembers(isOpenToNewMembers);
    }
  }, [isOpenToNewMembers]);

  useEffect(() => {
    // todo that's cool but we can refresh redux store instead of sending another requests
    if (!isShowModal && organization.openForJoin !== isOpenToNewMembers) {
      setRefreshUsersOrg(true);
    }
  }, [isShowModal]);

  useEffect(() => {
    dispatch(listDesignersActionList());
    dispatch(listTeachersActionList());
    return () => {
      dispatch(listDesignersActionReset(listDesigners.eventSource));
      dispatch(listTeachersActionReset(listTeachers.eventSource));
    };
  }, []);

  useEffect(() => {
    if (listDesigners.retrieved) {
      setDesigner(listDesigners.retrieved['hydra:member']);
    }
  }, [listDesigners.retrieved]);

  useEffect(() => {
    if (listTeachers.retrieved) {
      setTeacher(listTeachers.retrieved['hydra:member']);
    }
  }, [listTeachers.retrieved]);

  useEffect(() => {
    if (submitInvite) {
      // todo change to forEach
      /* eslint-disable-next-line */
      for (const recipient of recipients) {
        dispatch(organizationUnconfirmedMembersActionInvite(
          {
            id: uuidv4(),
            organization: organization['@id'],
            member: recipient['@id']
          }
        ));
      }
      setSubmitInvite(false);
    }
  }, [submitInvite]);

  useEffect(() => {
    if (submitInvite || (isShowModal && isOwner) || (isShowModal && updateOforganizationunconfirmedmembers.updated)) {
      dispatch(
        organizationunconfirmedmembersActionList(`
        organization_unconfirmed_members?&organization=${organization['@id']}
        `)
      );
      setRefreshUsersOrg(true);
    }
    return () => {
      dispatch(organizationunconfirmedmembersActionReset(listOforganizationunconfirmedmembers.eventSource));
    };
  }, [submitInvite, isShowModal, updateOforganizationunconfirmedmembers.updated]);

  useEffect(() => {
    setIsLoading(true);
    if (listOforganizationunconfirmedmembers.retrieved
      // && listOforganizationunconfirmedmembers.retrieved['hydra:totalItems']
      && userList.retrieved) {
      const element = listOforganizationunconfirmedmembers.retrieved['hydra:member']
        .filter((unMember) => { return unMember; });
      const wantedToJoin = element.filter((unMember) => { return unMember.status === STATUS_WANTED_TO_JOIN; });
      if (wantedToJoin.length > 0) {
        for (let i = 0; i < wantedToJoin.length; i++) {
          wantedToJoin[i].user = userList.retrieved['hydra:member'].find(((x) => x['@id'] === wantedToJoin[i].member));
        }
      }
      setReceivedRequests(wantedToJoin.map((item) => {
        const temp = item.user;
        if (temp) {
          temp.unconfirmedMember = item;
        }
        return temp;
      }));
      const invited = element.filter((unMember) => { return unMember.status === STATUS_INVITED; });
      if (invited.length > 0) {
        for (let i = 0; i < invited.length; i++) {
          invited[i].user = userList.retrieved['hydra:member'].find(((x) => x['@id'] === invited[i].member));
        }
      }
      setRequestedMembers(invited.map((item) => {
        const temp = item.user;
        if (temp) {
          temp.unconfirmedMember = item;
        }
        return temp;
      }));
      setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    }
  }, [listOforganizationunconfirmedmembers.retrieved, userList.retrieved]);

  // setting up organization teammates to user list
  // todo alongside with userList it might be reusable
  useEffect(() => {
    // if (userList.retrieved && organization) {
    //   setListOfUsers(
    //     userList.retrieved['hydra:member'].filter(
    //       (user) => {
    //         return organization.members.includes(user['@id']);
    //       }
    //     )
    //   );
    // }
    const getMembers = async () => {
      if (userList.retrieved && organization && isShowModal) {
        const { data } = await axios.get(organization['@id']);
        const memberArray = data.members.join('&id[]=');
        const { data: dataUsers } = await axios.get(`${FETCH_USERS}/?id[]=${memberArray}`);
        // const listMember = userList.retrieved['hydra:member'].filter(
        //   (user) => {
        //     return data.members.includes(user['@id']);
        //   }
        // );
        const loggedUser = dataUsers['hydra:member'].find((d) => d['@id'] === localStorage.getItem('id'));
        const notLoggedUser = dataUsers['hydra:member'].filter((d) => d['@id'] !== localStorage.getItem('id'));
        setListOfUsers([loggedUser, ...notLoggedUser]);
      }
    };
    getMembers();
  }, [userList.retrieved, organization, isShowModal]);

  // const acceptProposal = (proposalId) => {
  //   const obj = { ...acceptedProposals };
  //   obj[proposalId] = {};
  //   return setAcceptedProposals(obj);
  // };
  //
  // const rejectProposal = (proposalId) => {
  //   const obj = { ...rejectedProposals };
  //   obj[proposalId] = {};
  //   return setRejectedProposals(obj);
  // };
  //
  // const undoProposal = (proposalId) => {
  //   if (acceptedProposals[proposalId]) {
  //     const object = { ...acceptedProposals };
  //     delete object[proposalId];
  //     return setAcceptedProposals(object);
  //   }
  //
  //   if (rejectedProposals[proposalId]) {
  //     const object = { ...rejectedProposals };
  //     delete object[proposalId];
  //     return setRejectedProposals(object);
  //   }
  // };
  //
  // const withdrawRequest = (proposalId) => {
  //   const obj = { ...withdrawedRequests };
  //   obj[proposalId] = {};
  //   return setWithdrawedRequests(obj);
  // };
  //
  // const undoRequest = (proposalId) => {
  //   if (withdrawedRequests[proposalId]) {
  //     const obj = { ...withdrawedRequests };
  //     delete obj[proposalId];
  //     return setWithdrawedRequests(obj);
  //   }
  // };

  const renderHeader = () => {
    return (
      <div className='start-proposal-modal-header' >
        <i
          className='fa fa-times start-proposal-modal-header-close'
          onClick={() => setIsShowModal(false)}
        />
        <img src={MathBg} alt='' className='start-proposal-modal-header-img-scope' />
        {renderTitle(true)}
        {renderDeadlineClock()}
      </div>
    );
  };

  const handleIsOpenToNewMembers = async () => {
    setIsOpenToNewMembers((bool) => !bool);
  };

  const renderNewMembersToggle = () => {
    return (
      <div className='start-proposal-modal-toggle' >
        Saya menerima anggota tim baru
        <div className='start-proposal-modal-toggle-btn' >
          <ToggleButton
            checked={isOpenToNewMembers}
            onChange={handleIsOpenToNewMembers}
          />
        </div>
      </div>
    );
  };

  const renderTeamMembers = () => {
    return (
      <div className='join-project-modal-member'>
        {listOfUsers
          && listOfUsers.map((item, i) => {
            return <UserListWithProfile
              key={i}
              item={item}
              organization={organization}
              handleClick={() => setShowTeamRemovalModal(item)}
              removeTeamButton={true}
            />;
          })}
      </div>
    );
  };

  // const renderTeamMembers = () => {
  //   return (
  //     <div className='start-proposal-modal-member'>
  //       {teamMembers.map((member) => (
  //         <div
  //           className='start-proposal-modal-member-profile'
  //           key={member.id}
  //         >
  //           <div className='add-friend-modal-member-profile-avatar'>
  //             <img src={Avatar} alt='' />
  //           </div>
  //           <div className='start-proposal-modal-member-profile-info'>
  //             <div className='start-proposal-modal-member-profile-info-flex' >
  //               <h2>{member.name}</h2>
  //               {member.isOwner && <Label type='with-text'>TEAM OWNER</Label>}
  //             </div>
  //             <p className='start-proposal-modal-member-profile-info-sub' >
  //               {member.designation} <div className='middle-dot' />{' '}
  //               {member.tags.join(', ')}
  //             </p>
  //             <div className='add-friend-modal-member-profile-info-other'>
  //               <StarFill /> 4.9 &nbsp; <Trophy /> 25
  //             </div>
  //           </div>
  //           {member.isWhatsapp && <button
  //             className='start-proposal-modal-member-profile-whatsapp-btn'
  //           >
  //             <i className='fa fa-whatsapp' />
  //               WhatsApp
  //             </button>}
  //           {!member.isOwner && <i
  //             className='fa fa-times start-proposal-modal-member-profile-close'
  //             onClick={() => setShowTeamRemovalModal(true)}
  //           />}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const renderTabs = () => {
    return (
      <div className='start-proposal-modal-tabs'>
        <div
          onClick={() => setActiveTab(received)}
          className={`start-proposal-modal-tabs-tab ${activeTab === received ? 'active' : ''}`}
        >
          Diterima({receivedRequests.length})
        </div>
        <div
          onClick={() => setActiveTab(requested)}
          className={`start-proposal-modal-tabs-tab ${activeTab === requested ? 'active' : ''}`}
        >
          Permintaan({requestedMembers.length})
        </div>
      </div>
    );
  };

  const ReceivedTab = () => {
    return (
      <>
        {isLoading
          ? <div className='start-proposal-modal-loading'>
            <Spinner animation='grow' />
          </div>
          : <div className='add-friend-modal-member'>
            {receivedRequests
              && receivedRequests.length > 0
              && receivedRequests.map((user) => {
                return user && <UserListWithProfile
                  organization={organization}
                  key={user.id}
                  item={user}
                  handleClick={() => {
                    return setShowUnconfirmedMemberRemovalModal(user);
                  }}
                  acceptInvitationButton={true}
                />;
              })}
            {/*  // .map((request) => ( */}
            {/* //   <div */}
            {/* //     className='start-proposal-modal-member-profile' */}
            {/* //   > */}
            {/* //     <div className='start-proposal-modal-member-profile-avatar'> */}
            {/* //       <img src={Avatar} className='start-proposal-modal-member-profile-avatar-img' alt='' /> */}
            {/* //       {acceptedProposals[request.id] */}
            {/* //         && <img */}
            {/* //           src={Succeed} */}
            {/* //           alt="" */}
            {/* //           className='start-proposal-modal-member-profile-avatar-tick' */}
            {/* //         /> */}
            {/* //       } */}
            {/* //       {rejectedProposals[request.id] */}
            {/* //         && <img */}
            {/* //           src={Ignored} */}
            {/* //           alt="" */}
            {/* //           className='start-proposal-modal-member-profile-avatar-tick' */}
            {/* //         /> */}
            {/* //       } */}
            {/* //     </div> */}
            {/* //     <div className='add-friend-modal-member-profile-info'> */}
            {/* //       {!acceptedProposals[request.id] && !rejectedProposals[request.id] && ( */}
            {/* //         <> */}
            {/* //           <h2>{request.name} {request.isOwner
            && <Label type='with-text'>TEAM OWNER</Label>} </h2> */}
            {/* //           <p> */}
            {/* //             {request.designation} <div className='middle-dot' />{' '} */}
            {/* //             {request.tags.join(', ')} */}
            {/* //           </p> */}
            {/* //           <div className='add-friend-modal-member-profile-info-other'> */}
            {/* //             <StarFill /> {request.stars} &nbsp; <Trophy /> {request.trophies} */}
            {/* //           </div> */}
            {/* //         </> */}
            {/* //       )} */}
            {/* //       {acceptedProposals[request.id] */}
            {/* //         && <p className='start-proposal-modal-member-profile-info-accept-text' > */}
            {/* //           You've just added {' '} */}
            {/* //           <span className='start-proposal-modal-member-profile-info-name' > */}
            {/* //             {request.name} */}
            {/* //           </span> */}
            {/* //           {' '} to your team! */}
            {/* //             </p> */}
            {/* //       } */}
            {/* //       {rejectedProposals[request.id] */}
            {/* //         && <p className='start-proposal-modal-member-profile-info-accept-text' > */}
            {/* //           You've just declined {' '} */}
            {/* //           <span className='start-proposal-modal-member-profile-info-name' > */}
            {/* //             {request.name} */}
            {/* //           </span> */}
            {/* //           {' '}to your team! */}
            {/* //             </p> */}
            {/* //       } */}
            {/* //     </div> */}
            {/* //     {(!acceptedProposals[request.id] && !rejectedProposals[request.id]) ? <button */}
            {/* //       onClick={() => acceptProposal(request.id)} */}
            {/* //       className='start-proposal-modal-tabs-tab-received-btn' */}
            {/* //     > */}
            {/* //       Accept */}
            {/* //     </button> */}
            {/* //       : <span */}
            {/* //         className='start-proposal-modal-undo' */}
            {/* //         onClick={() => undoProposal(request.id)} */}
            {/* //       > */}
            {/* //         UNDO */}
            {/* //         </span>} */}
            {/* //     {!rejectedProposals[request.id] && !acceptedProposals[request.id] && <i */}
            {/* //       className='fa fa-times start-proposal-modal-member-profile-close' */}
            {/* //       onClick={() => rejectProposal(request.id)} */}
            {/* //     />} */}
            {/* //   </div> */}
            {/* // ))} */}

            {(!receivedRequests || receivedRequests.length === 0)
              && <div className='start-proposal-modal-tabs-tab-received-empty' >Kamu tidak mempunyai permintaan</div>
            }
          </div>}
      </>
    );
  };

  const RequestedTab = () => {
    return (
      <div>
        <div
          className='start-proposal-modal-tabs-tab-requested-invite'
          onClick={() => setIsAddFriendModal(true)}
        >
          <i className='fa fa-user-plus' /> Undang Orang Lain Untuk Bergabung
        </div>
        {isLoading
          ? <div className='start-proposal-modal-loading'>
            <Spinner animation='grow' />
          </div>
          : <div className='add-friend-modal-member'>
            {requestedMembers
              && requestedMembers.length > 0
              && requestedMembers.map((user) => {
                return user && <UserListWithProfile
                  key={user.id}
                  item={user}
                  handleClick={() => {
                    return setShowUnconfirmedMemberRemovalModal(user);
                  }}
                  unconfirmedMember={true}
                  withdrawButton={true}
                  organization={organization}
                />;
              })}
            {/*  .map((member) => ( */}
            {/*  <div */}
            {/*    className='start-proposal-modal-member-profile' */}
            {/*  > */}
            {/*    <div className='start-proposal-modal-member-profile-avatar'> */}
            {/*      <img src={Avatar} alt='' className='start-proposal-modal-member-profile-avatar-img' /> */}
            {/*      {withdrawedRequests[member.id] */}
            {/*        && <img */}
            {/*          src={Ignored} */}
            {/*          alt="" */}
            {/*          className='start-proposal-modal-member-profile-avatar-tick' */}
            {/*        /> */}
            {/*      } */}
            {/*    </div> */}
            {/*    <div className='add-friend-modal-member-profile-info'> */}
            {/*      {!withdrawedRequests[member.id] && ( */}
            {/*        <> */}
            {/*          <h2>{member.name} {member.isOwner && <Label type='with-text'>TEAM OWNER</Label>} </h2> */}
            {/*          <p> */}
            {/*            {member.designation} <div className='middle-dot' />{' '} */}
            {/*            {member.tags.join(', ')} */}
            {/*          </p> */}
            {/*          <div className='add-friend-modal-member-profile-info-other'> */}
            {/*            <StarFill /> {member.stars} &nbsp; <Trophy /> {member.trophies} */}
            {/*          </div> */}

            {/*        </> */}
            {/*      ) */}
            {/*      } */}

            {/*      {withdrawedRequests[member.id] */}
            {/*        && <p */}
            {/*          className='start-proposal-modal-member-profile-info-accept-text' */}
            {/*        > */}
            {/*          You've just cancelled your request to {' '} */}
            {/*          <span className='start-proposal-modal-member-profile-info-name' > */}
            {/*            {member.name} */}
            {/*          </span> */}
            {/*        </p> */}
            {/*      } */}
            {/*    </div> */}
            {/*    {!withdrawedRequests[member.id] ? <button */}
            {/*      className='start-proposal-modal-tabs-tab-requested-btn' */}
            {/*      onClick={() => withdrawRequest(member.id)} */}
            {/*    > */}
            {/*      Withdraw */}
            {/*    </button> : <span */}
            {/*        className='start-proposal-modal-undo' */}
            {/*        onClick={() => undoRequest(member.id)} */}
            {/*      > */}
            {/*        UNDO */}
            {/*      </span>} */}
            {/*    {withdrawedRequests[member.id] */}
            {/*      && <i */}
            {/*        className='fa fa-times start-proposal-modal-tabs-tab-requested-close' */}
            {/*      /> */}
            {/*    } */}
            {/*  </div> */}
            {/* ))} */}
          </div>}
      </div>
    );
  };

  const handleStartProposal = () => {
    startProposal(listOfUsers.length);
  };

  const renderDeadline = () => {
    return (
      <div className='start-proposal-modal-deadline' >
        {isOwner
          ? <div className='start-proposal-modal-deadline-btn' >
            <Button disabled={listOfUsers === false} block onClick={handleStartProposal}>Mulai Proposal</Button>
          </div>
          : <div className='start-proposal-modal-deadline-btn' >
            <Button
              type={'secondary'}
              block
              onClick={() => setShowTeamRemovalModal(decryptData(localStorage.getItem('user'), HASH_DECRYPT))}
            >
              Exit Project
            </Button>
          </div>}
        {isOwner && <div
          className='start-proposal-modal-deadline-text'
        >
          Tidak yakin ingin melanjutkan proyek ini?
          <span> Batalkan proyek</span>
        </div>}
      </div>
    );
  };

  const handleCloseModal = () => {
    setIsAddFriendModal(false);
    setSearch('');
  };

  const removeDuplicate = (array, key) => {
    return array.reduce((arr, item) => {
      const removed = arr.filter((i) => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  };

  const newRecipients = removeDuplicate(recipients, '@id');

  const dataFilteredDesigner = designer.filter((item) => {
    if (item.username.toLowerCase()
      .includes(search.toLowerCase())) {
      return item;
    }
    return null;
  });

  const dataFilteredTeacher = teacher.filter((item) => {
    if (item.username.toLowerCase()
      .includes(search.toLowerCase())) {
      return item;
    }
    return null;
  });

  const handleClickAddDesignerRecipient = (recipient, id) => {
    setRecipients([...recipients, {
      '@id': id,
      recipient,
      type: 'designer'
    }]);
    const newData = dataFilteredDesigner.filter((item) => item['@id'] === id);
    setTempData([...tempData, ...newData]);
    const resultData = dataFilteredDesigner.filter((item) => item['@id'] !== id);
    return setDesigner(resultData);
  };

  const handleClickAddTeacherRecipient = (recipient, id) => {
    setRecipients([...recipients, {
      '@id': id,
      recipient,
      type: 'teacher'
    }]);
    const newData = dataFilteredTeacher.filter((item) => item['@id'] === id);
    setTempData([...tempData, ...newData]);
    const resultData = dataFilteredTeacher.filter((item) => item['@id'] !== id);
    return setTeacher(resultData);
  };

  const handleCancelRecipient = (id, type) => {
    const restoreData = tempData.filter((item) => item['@id'] === id)
      .shift();
    if (type === 'designer') {
      setDesigner([...dataFilteredDesigner, restoreData]);
    }
    if (type === 'teacher') {
      setTeacher([...dataFilteredTeacher, restoreData]);
    }
    const resultData = recipients.filter((recipient) => recipient['@id'] !== id);
    return setRecipients(resultData);
  };

  const handleSubmitRecipients = () => {
    setSubmitInvite(true);
    setIsAddFriendModal(false);
    setIsShowModal(false);
  };

  const handleGoBackFromAddFriendModal = () => {
    setIsAddFriendModal(false);
  };

  return (
    <div className='add-friend'>
      <Modal
        show={isShowModal}
        dialogClassName='start-proposal-modal'
        onHide={() => setIsShowModal(false)}
        centered
      >
        {renderHeader()}
        <div className='start-proposal-modal-scrollable' >
          {activeTab === received && renderNewMembersToggle()}
          {activeTab === received && renderTeamMembers()}
          {isOwner && renderTabs()}
          {isOwner && activeTab === 'received' && <ReceivedTab />}
          {isOwner && activeTab === 'requested' && <RequestedTab />}
        </div>
        {renderDeadline()}
        {showTeamRemovalModal && <RenderTeamRemovalModal
          setShowTeamRemovalModal={setShowTeamRemovalModal}
          showTeamRemovalModal={showTeamRemovalModal}
          organization={organization}
          setRefreshUsersOrg={setRefreshUsersOrg}
          isOwner={isOwner}
          setIsShowModal={setIsShowModal}
        />}
        {showUnconfirmedMemberRemovalModal && <RenderTeamRemovalModal
          setShowTeamRemovalModal={setShowUnconfirmedMemberRemovalModal}
          showTeamRemovalModal={showUnconfirmedMemberRemovalModal}
          organization={organization}
          setRefreshUsersOrg={setRefreshUnconfirmedMembers}
          isOwner={isOwner}
          setIsShowModal={() => { }}
          removeUnconfirmedMember={true}
        />}
        {isAddFriendModal && (
          <div>
            <AddFriend
              show={isAddFriendModal}
              handleClose={handleCloseModal}
              dataFilteredDesigner={dataFilteredDesigner}
              dataFilteredTeacher={dataFilteredTeacher}
              handleChange={setSearch}
              search={search}
              recipients={newRecipients}
              handleClickAddTeacherRecipient={handleClickAddTeacherRecipient}
              handleClickAddDesignerRecipient={handleClickAddDesignerRecipient}
              handleCancelRecipient={handleCancelRecipient}
              counter={newRecipients.length}
              handleSubmitRecipients={handleSubmitRecipients}
              isSubmit={isSubmit}
              handleGoBack={handleGoBackFromAddFriendModal}
            />
          </div>
        )}
      </Modal>
      <HandleEscapeKey handleOnEscapeKey={() => { setIsShowModal(false); }} />
    </div>
  );
};

StartProposalModal.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  isShowModal: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  renderDeadlineClock: PropTypes.func.isRequired,
  renderLabel: PropTypes.func,
  renderTitle: PropTypes.func.isRequired,
  setIsShowModal: PropTypes.func,
  setRefreshUsersOrg: PropTypes.func.isRequired,
  startProposal: PropTypes.func
};

export default StartProposalModal;
