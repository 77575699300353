import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../Components/Button/Button';
import FreeMaterialModal from '../../Components/FreeMaterialModal/FreeMaterialModal';
import StartProposalConfirmModal from '../../Components/StartProposalConfirmModal/StartProposalConfirmModal';
import PhoneInput from '../../Components/PhoneInput/PhoneInput';
import Input from '../../Components/Input/Input';
import './PlayGround.scss';

const PlayGround = () => {
  const [isShowMaterial, setIsShowMaterial] = useState(false);

  const [showTextLinkModal, setShowTextLinkModal] = useState(false);

  const [showEmailLinkModal, setShowEmailLinkModal] = useState(false);

  const handleShowMatrerial = () => {
    setIsShowMaterial(true);
  };

  const onDownloadClick = () => {
    setIsShowMaterial(false);
    setShowTextLinkModal(true);
  };

  const onEmailTextClick = () => {
    setShowTextLinkModal(false);
    setShowEmailLinkModal(true);
  };

  const onTextLinkClick = () => {
    setShowEmailLinkModal(false);
    setShowTextLinkModal(true);
  };

  const renderTextLinkModal = () => {
    const details = {
      show: showTextLinkModal,
      setShow: setShowTextLinkModal,
      heading: 'Teaching just got easier with happy students!',
      sub: <>
        <p>
          You can always find the best and updated materials <br/>
          on <span className='bold-text' >Titik Pintar apps</span> and motivate students to learn in a fun way
        </p>
        <PhoneInput />
        <div className='small-gold-text' onClick={() => onEmailTextClick()} >OR EMAIL ME THE LINK</div>
      </>,
      isSingleButton: false,
      yesText: 'Text Me The Link',
      yesClick: () => setShowTextLinkModal(),
      noText: "Let's do it Later"
    };
    return (
      <StartProposalConfirmModal
        isShowModal={showTextLinkModal}
        setIsShowModal={setShowTextLinkModal}
        details={details}
      />);
  };

  const renderEmailLinkModal = () => {
    const details = {
      show: showEmailLinkModal,
      setShow: setShowEmailLinkModal,
      heading: 'Teaching just got easier with happy students!',
      sub: <>
        <p>
          You can always find the best and updated materials <br/>
          on <span className='bold-text' >Titik Pintar apps</span> and motivate students to learn in a fun way
        </p>
        <Input placeholder='Enter your email address' />
        <div className='small-gold-text' onClick={() => onTextLinkClick()} >OR TEXT ME THE LINK</div>
      </>,
      isSingleButton: false,
      yesText: 'Email Me The Link',
      yesClick: () => setShowEmailLinkModal(),
      noText: "Let's do it Later"
    };
    return (
      <StartProposalConfirmModal
        isShowModal={showEmailLinkModal}
        setIsShowModal={setShowEmailLinkModal}
        details={details}
      />);
  };

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <br />
            <Button type='main' onClick={handleShowMatrerial}>
              Show Free Material
            </Button>
            <FreeMaterialModal
              isShowMaterial={isShowMaterial}
              setIsShowMaterial={setIsShowMaterial}
              onDownloadClick={onDownloadClick}
            />
            {renderTextLinkModal()}
            {renderEmailLinkModal()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PlayGround;
