import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label/Label';
import './TeamCard.scss';
import avatarImage from '../../Assets/Images/avatar-image.png';
import TeamDetailCard from '../TeamDetailCard/TeamDetailCard';
import ScienceCardBg from '../../Assets/Images/science-card-bg.png';
import MathCardBg from '../../Assets/Images/math-card-bg.png';

const TeamCard = ({
  organization,
  mappedImages,
  mappedMembers,
  lessonTagValues
}) => {
  const [isActiveDetail, setIsActiveDetail] = useState(false);

  const handleTeamCard = () => {
    setIsActiveDetail(!isActiveDetail);
  };

  const getImgBg = () => {
    if (lessonTagValues && lessonTagValues.length > 0) {
      if (lessonTagValues.includes('SCIENCE')) return ScienceCardBg;
      if (lessonTagValues.includes('MATH')) return MathCardBg;
    }
    return ScienceCardBg;
  };

  return (
    <>
      <div
        className={`team-card ${isActiveDetail ? 'team-card-active' : ''}`}
        onClick={handleTeamCard}
      >
        <img src={getImgBg()} alt="" className='team-card-img-bg' />
        <div className='team-card-headline'>
          <h1>{organization.name.replace(' and ', ' dan ')}</h1>
        </div>
        <div className='team-card-label'>
          <Label type={organization.status} />
        </div>
        <div className='team-card-member-preview'>
          {organization.members.map((member) => (
            <div key={member} className='team-card-member-preview-avatar'>
              <img src={mappedImages[member] || avatarImage} alt='user avatar' />
            </div>
          ))}
        </div>
      </div>
      <div className='team-card-detail-component'>
        <TeamDetailCard
          isActiveDetail={isActiveDetail}
          setIsActiveDetail={setIsActiveDetail}
          organization={organization}
          mappedMembers={mappedMembers}
        />
      </div>
    </>
  );
};

TeamCard.propTypes = {
  organization: PropTypes.object.isRequired,
  mappedImages: PropTypes.object.isRequired,
  mappedMembers: PropTypes.object.isRequired,
  lessonTagValues: PropTypes.arrayOf(PropTypes.string),
};

TeamCard.defaultProps = {
  organization: [],
};

export default TeamCard;
