/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Image, Row, ProgressBar } from 'react-bootstrap';
import './QuestionAnswer.scss';
import { ArrowLeft, CardImage, PlusCircleFill, TrashFill, CloudArrowDownFill } from 'react-bootstrap-icons';
import { isEmpty } from 'lodash';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Dot } from 'react-animated-dots';
import CharacterCounter from 'react-character-counter';
import { toast } from 'react-toastify';
import Button from '../../Components/Button/Button';
import QuestionForm from '../../Components/QuestionForm/QuestionForm';
import questionPreview from '../../Assets/Images/question-preview.png';
import cancelPreview from '../../Assets/Images/cancel-preview.png';
import iconPreview from '../../Assets/Images/icon_preview.png';
import { submitQuestion, uploadToS3 } from '../../Actions/Question';
import {
  answerField,
  dropdownCreateQuestion,
  fileUploadField,
  generateAnswerMultipleChoice,
  generateAnswerTrueOrFalse,
  generateQuestion,
  generateQuestionData,
  multipleAnswerField,
} from '../../Helpers/StaticField';
import Loading from '../../Components/Loading/Loading';
import correctIcon from '../../Assets/Images/correct-icon.png';
import iconMP4 from '../../Assets/Images/ico-mp4.png';
import iconFile from '../../Assets/Images/ico-file.png';
import iconZIP from '../../Assets/Images/ico-zip.png';
import iconPDF from '../../Assets/Images/ico-pdf.png';
import iconRar from '../../Assets/Images/rar_icon.png';
import ProposalSummary from '../../Components/ProposalSummary/ProposalSummary';
import {
  reset as creativeWorkActionRetrieveReset,
  retrieve as creativeWorkActionRetrieve,
} from '../../Generated/actions/creativework/show';
import {
  reset as projectActionRetrieveReset,
  retrieve as projectActionRetrieve,
} from '../../Generated/actions/project/show';
import {
  reset as organizationActionRetrieveReset,
  retrieve as organizationActionRetrieve,
} from '../../Generated/actions/organization/show';
import {
  list as creativeWorkActionList,
  reset as creativeWorkActionListReset,
} from '../../Generated/actions/creativework/list';
import {
  ANSWERS_URL,
  ARCHIVE_OBJECTS,
  CREATIVE_WORKS_URL,
  IMAGE_OBJECTS,
  QUESTIONS_URL,
  VIDEO_OBJECTS,
  DOCUMENT_OBJECTS,
  TEACHER_GUIDELINE_URL,
  ANIMATION_GUIDELINE_URL,
  DESIGN_GUIDELINE_URL,
  ASSET_GUIDELINE_URL,
  LESSON_GUIDELINE_URL,
} from '../../Constants/Constants';
import { list as personActionList, reset as personActionReset } from '../../Generated/actions/person/list';
import ProposalOverview from '../../Components/ProposalOverview/ProposalOverview';
import WarningModal from '../../Components/Modal/WarningModal';
import LessonScenes from '../../Components/LessonScenes/LessonScenes';
import ModalTutorial from '../../Components/ModalTutorial/ModalTutorial';
import ModalGuidelines from '../../Components/ModalGuidelines/ModalGuidelines';
import { getFileExt } from '../../Helpers/Common';
import InputDropZone from '../../Components/InputDropZone/InputDropZone';

const QuestionAnswer = (props) => {
  const [previewContent, setPreviewContent] = useState(0);
  const [previewCorrectAnswer, setPreviewCorrectAnswer] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const questions = useSelector((state) => state.questions);
  const creativeWorkDetail = useSelector((state) => state.creativework.show);
  const projectDetail = useSelector((state) => state.project.show);
  const organizationDetail = useSelector((state) => state.organization.show);
  const creativeWorkList = useSelector((state) => state.creativework.list);
  const personList = useSelector((state) => state.person.list);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingType, setLoadingType] = useState();
  const [isShowSummary, setIsShowSummary] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState();
  const [errorUploadFile, setErrorUploadFile] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [mappedMembers, setMappedMembers] = useState(null);
  const [mappedImages, setMappedImages] = useState(null);
  const [proposalFile, setProposalFile] = useState(fileUploadField);
  const [voiceOverText, setVoiceOverText] = useState('');
  const [lessonScenes, setLessonScenes] = useState([{ name: '', contentUrl: '' }]);
  const [showPreview, setShowPreview] = useState(false);
  const [guidelineDetail, setGuidelineDetail] = useState({});
  const [fileProgress, setFileProgress] = useState(0);
  const mp4InputFile = useRef();
  const zipInputFile = useRef();
  const pdfInputFile = useRef();
  const audioInputFile = useRef();
  const [projectVideo, setProjectVideo] = useState();

  useEffect(() => {
    dispatch(creativeWorkActionRetrieve(decodeURIComponent(`/creative_works/${props.match.params.page}`)));
    return () => {
      dispatch(creativeWorkActionRetrieveReset(creativeWorkDetail.eventSource));
    };
  }, []);

  useEffect(() => {
    const projectId = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.createdFor;
    if (projectId) {
      dispatch(projectActionRetrieve(projectId));
    }
    return () => {
      dispatch(projectActionRetrieveReset(projectDetail.eventSource));
    };
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    const organizationId = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.creator;
    if (organizationId) {
      dispatch(organizationActionRetrieve(organizationId));
    }
    return () => {
      dispatch(organizationActionRetrieveReset(organizationDetail.eventSource));
    };
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    if (organizationDetail.retrieved) {
      const membersArray = [];
      organizationDetail.retrieved.members.map((organization) => {
        membersArray.push(organization);
        return null;
      });
      const membersArrayString = membersArray.join('&user[]=');
      dispatch(personActionList(`/people?pagination=false&user[]=${membersArrayString}`));
    }
    return () => {
      dispatch(personActionReset(personList.eventSource));
    };
  }, [organizationDetail]);

  useEffect(() => {
    (async () => {
      if (personList.retrieved) {
        let imagesArray = [];
        const mappedMembersArr = [];
        personList.retrieved['hydra:member'].map((member) => {
          imagesArray = [...imagesArray, member.image];
          mappedMembersArr.push(member);
          return null;
        });
        setMappedMembers(mappedMembersArr);
        const mediaArrayString = imagesArray.filter(Boolean).join('&id[]=');
        if (mediaArrayString) {
          const { data: memberImages } = await axios.get(`${IMAGE_OBJECTS}?id[]=${mediaArrayString}`);
          setMappedImages(memberImages);
        }
      }
    })();
  }, [personList.retrieved]);

  useEffect(() => {
    dispatch(creativeWorkActionList(`/creative_works/${props.match.params.page}/has_parts?order%5Bordering%5D=asc`));
    return () => {
      dispatch(creativeWorkActionListReset(creativeWorkList.eventSource));
    };
  }, []);

  useEffect(() => {
    if (creativeWorkList.retrieved) {
      const completeQuestionData =
        creativeWorkList.retrieved &&
        creativeWorkList.retrieved['hydra:member'].map((filteredQuestion) => ({
          ...filteredQuestion,
          description: filteredQuestion.description || '',
          answerKey: filteredQuestion.answerKey || '',
          visualBrief: filteredQuestion.visualBrief || '',
          answers: [],
          answerPreview: multipleAnswerField,
          image: filteredQuestion.image || null,
        }));
      dispatch(submitQuestion(completeQuestionData));
    }
  }, [creativeWorkList.retrieved]);

  useEffect(() => {
    const getLessonScenes = async () => {
      if (creativeWorkDetail.retrieved.images.length > 0) {
        const imageArray = creativeWorkDetail.retrieved.images.join('&id[]=');
        const { data } = await axios.get(`${IMAGE_OBJECTS}?id[]=${imageArray}`);
        setLessonScenes(data['hydra:member']);
      }
    };
    if (creativeWorkDetail.retrieved) {
      getLessonScenes();
    }
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (questions[previewContent] && questions[previewContent].image) {
        try {
          const getImageId = questions[previewContent].image.split('/');
          const { data } = await axios.get(`${IMAGE_OBJECTS}/${getImageId[2]}`);
          setPreviewImage(data.contentUrl);
        } catch (err) {
          if (err.response.status === 401) {
            // eslint-disable-next-line no-alert
            alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
            window.location.reload();
          }
          if (err.response.status === 500) {
            toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
          }
        }
      } else {
        setPreviewImage(null);
      }
    })();
  }, [previewContent, questions]);

  useEffect(() => {
    (async () => {
      if (creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.archive) {
        try {
          const archiveURL = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.archive.split('/')[2];
          const { data: archiveData } = await axios.get(`${ARCHIVE_OBJECTS}/${archiveURL}`);
          setProposalFile((prevState) => ({
            ...prevState,
            zipFile: {
              name: (archiveData && archiveData.name) || '',
              contentUrl: (archiveData && archiveData.contentUrl) || '',
            },
          }));
        } catch (e) {
          throw new Error(e);
        }
      }
    })();
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.video) {
        try {
          const videoURL = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.video.split('/')[2];
          const { data: mp4Data } = await axios.get(`${VIDEO_OBJECTS}/${videoURL}`);
          setProposalFile((prevState) => ({
            ...prevState,
            mp4File: {
              name: (mp4Data && mp4Data.name) || '',
              contentUrl: (mp4Data && mp4Data.contentUrl) || '',
            },
          }));
        } catch (e) {
          throw new Error(e);
        }
      }
    })();
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.document) {
        try {
          const docURL = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.document.split('/')[2];
          const { data: pdfData } = await axios.get(`${DOCUMENT_OBJECTS}/${docURL}`);
          setProposalFile((prevState) => ({
            ...prevState,
            pdfFile: {
              name: (pdfData && pdfData.name) || '',
              contentUrl: (pdfData && pdfData.contentUrl) || '',
            },
          }));
        } catch (e) {
          throw new Error(e);
        }
      }
    })();
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.audio.length > 0) {
        try {
          const audioURL = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.audio[0];
          const { data: audioData } = await axios.get(audioURL);
          setProposalFile((prevState) => ({
            ...prevState,
            audioFile: {
              name: (audioData && audioData.name) || '',
              contentUrl: (audioData && audioData.contentUrl) || '',
            },
          }));
        } catch (e) {
          throw new Error(e);
        }
      }
    })();
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.transcript) {
        setVoiceOverText(creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.transcript);
      }
    })();
  }, [creativeWorkDetail.retrieved]);

  useEffect(() => {
    (async () => {
      if (projectDetail.retrieved && projectDetail.retrieved.lessonType === 3) {
        const { data } = await axios.get(projectDetail.retrieved.video);
        setProjectVideo(data.contentUrl);
      }
    })();
  }, [projectDetail.retrieved]);

  useEffect(() => {
    const previewSection = document.getElementById('fixed-wrapper');
    const stickyPreview = previewSection.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      if (window.pageYOffset >= stickyPreview + 2650) {
        previewSection.classList.add('fixed');
      }
      if (window.pageYOffset <= stickyPreview + 2650) {
        previewSection.classList.remove('fixed');
      }
    });
    setTimeout(() => {
      setIsLoading(false);

      // Show tutorial
      const userId = localStorage.getItem('id').split('/')[2];
      const creativeWorkId = props.match.params.page;
      const checkId = `${creativeWorkId}-${userId}`;
      if (!localStorage.getItem(checkId)) {
        setShowTutorial(true);
        localStorage.setItem(checkId, 'Tutorial Done');
      }
    }, 1500);
    return () => {
      window.removeEventListener('scroll', () => scrollCallBack);
    };
  }, []);

  const handleGuideline = (role) => {
    if (role === 'guru') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Guru',
        fileName: 'Panduan Untuk Guru (Bahasa).mp4',
        url: TEACHER_GUIDELINE_URL,
      });
    }
    if (role === 'animasi') {
      setShowPreview(true);
      setGuidelineDetail({
        heading: 'Pedoman Animasi',
        fileName: 'Pedoman Animasi.mp4',
        url: ANIMATION_GUIDELINE_URL,
      });
    }
    if (role === 'desain') {
      window.open(DESIGN_GUIDELINE_URL);
    }
    if (role === 'aset') {
      window.open(ASSET_GUIDELINE_URL);
    }
    if (role === 'lesson') {
      window.open(LESSON_GUIDELINE_URL);
    }
  };

  const handleGenerateTrueOrFalseAnswer = async (creator, questionId) => {
    const generatedAnswer = generateAnswerTrueOrFalse(creator, questionId);
    try {
      const { data: firstAnswer } = await axios.post(ANSWERS_URL, generatedAnswer[0]);
      const { data: secondAnswer } = await axios.post(ANSWERS_URL, generatedAnswer[1]);
      const firstAnswerData = firstAnswer['@id'];
      const secondAnswerData = secondAnswer['@id'];
      return {
        firstAnswerData,
        secondAnswerData,
      };
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleGenerateMultipleChoiceAnswer = async (creator, questionId) => {
    const generatedAnswer = generateAnswerMultipleChoice(creator, questionId);
    try {
      const { data: firstAnswer } = await axios.post(`${ANSWERS_URL}`, generatedAnswer[0]);
      const { data: secondAnswer } = await axios.post(`${ANSWERS_URL}`, generatedAnswer[1]);
      const { data: thirdAnswer } = await axios.post(`${ANSWERS_URL}`, generatedAnswer[2]);
      const { data: fourthAnswer } = await axios.post(`${ANSWERS_URL}`, generatedAnswer[3]);
      const firstAnswerData = firstAnswer['@id'];
      const secondAnswerData = secondAnswer['@id'];
      const thirdAnswerData = thirdAnswer['@id'];
      const fourthAnswerData = fourthAnswer['@id'];
      return {
        firstAnswerData,
        secondAnswerData,
        thirdAnswerData,
        fourthAnswerData,
      };
    } catch (error) {
      throw new Error(error);
    }
  };

  const generateTrueOrFalse = async (createdQuestion, creator, questionId) => {
    const { firstAnswerData, secondAnswerData } = await handleGenerateTrueOrFalseAnswer(creator, questionId);
    return {
      ...createdQuestion,
      connectedAnswers: [firstAnswerData, secondAnswerData],
    };
  };

  const generateMultipleAnswer = async (createdQuestion, creator, questionId) => {
    const { firstAnswerData, secondAnswerData, thirdAnswerData, fourthAnswerData } =
      await handleGenerateMultipleChoiceAnswer(creator, questionId);
    return {
      ...createdQuestion,
      connectedAnswers: [firstAnswerData, secondAnswerData, thirdAnswerData, fourthAnswerData],
    };
  };

  const generateAnswer = async (type, createdQuestion, generatedQuestionData) => {
    if (type === 'TYPE_TRUE_OR_FALSE') {
      return await generateTrueOrFalse(createdQuestion, generatedQuestionData.creator, generatedQuestionData.id);
    }
    if (type === 'TYPE_MULTIPLE_CHOICE') {
      return await generateMultipleAnswer(createdQuestion, generatedQuestionData.creator, generatedQuestionData.id);
    }
    if (type === 'TYPE_FILL_THE_BLANK') {
      return {
        ...createdQuestion,
      };
    }
    return null;
  };

  const handleGenerateQuestion = async (type) => {
    let listOfQuestion = [...questions];
    const creatorId = creativeWorkDetail.retrieved && creativeWorkDetail.retrieved.creator;
    const isPartOfId = `/creative_works/${props.match.params.page}`;
    const createdQuestion = generateQuestion(creatorId, isPartOfId, type);
    const generatedQuestionData = generateQuestionData(createdQuestion, type, listOfQuestion.length + 1);
    try {
      await axios.post(QUESTIONS_URL, generatedQuestionData);
      const questionData = await generateAnswer(type, createdQuestion, generatedQuestionData);
      listOfQuestion = [...listOfQuestion, questionData];
      dispatch(submitQuestion(listOfQuestion));
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
  };

  const handleProposalSummary = () => {
    setIsShowSummary(true);
  };

  const handlePreviewQI = () => {
    window.open(previewImage);
  };

  const countDeadline = () => {
    const todayDate = moment(new Date());
    const endTime = projectDetail.retrieved ? projectDetail.retrieved.endTime : null;
    const projectEndTime = moment(endTime);
    const deadline = projectEndTime.diff(todayDate, 'days');
    return deadline;
  };

  const renderDeadline = () => {
    let deadline = '';
    if (countDeadline() === 0) {
      deadline = 'Hari ini';
    }
    if (countDeadline() < 0) {
      deadline = 'Sudah berlalu';
    }
    if (countDeadline() > 0) {
      deadline = `${countDeadline()} hari lagi`;
    }
    return deadline;
  };

  const renderHeaderProposal = () => {
    return (
      <header>
        <Container>
          <div className='question-answer-header'>
            <div className='question-answer-header-back' onClick={() => history.push('/my-projects')}>
              <ArrowLeft />
            </div>
            <div className='question-answer-header-title'>
              <h1>{projectDetail.retrieved && projectDetail.retrieved.title}</h1>
              <span className='text-danger font-weight-bold'>Deadline : {renderDeadline()}</span>
            </div>
            <div className='question-answer-header-submit'>
              <Button type='main' onClick={handleProposalSummary}>
                Kumpul Proposal
              </Button>
              <ProposalSummary
                isShowSummary={isShowSummary}
                setIsShowSummary={setIsShowSummary}
                answerField={answerField}
                proposalFile={proposalFile}
                creativeWorkId={props.match.params.page}
                setShowWarningModal={setShowWarningModal}
              />
              <WarningModal showModal={showWarningModal} setShowModal={setShowWarningModal}>
                <h4 className='text-danger'>{showWarningModal}</h4>
              </WarningModal>
            </div>
          </div>
        </Container>
      </header>
    );
  };

  const renderCorrectAnswerPreview = () => {
    return (
      questions.length !== 0 &&
      previewCorrectAnswer !== undefined && (
        <div className='question-answer-content-preview-mask' id='question-answer-content-preview-mask'>
          <div className='question-answer-content-preview-mask-text'>
            <h1>Yay</h1>
          </div>
          <div className='question-answer-content-preview-mask-icon'>
            <Image src={correctIcon} />
          </div>
          <div className='question-answer-content-preview-mask-description'>
            <h1>Correct</h1>
            <div className='question-answer-content-preview-mask-key'>
              <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={70} autoHide={false}>
                <p className='display-line-break'>{questions[previewContent].answerKey}</p>
              </Scrollbars>
            </div>
            <div
              className='question-answer-content-preview-mask-button'
              onClick={() => setPreviewCorrectAnswer(undefined)}
            >
              <div className='question-answer-content-preview-mask-button-image'>Close</div>
            </div>
            <span>Report to Us</span>
          </div>
        </div>
      )
    );
  };

  const renderQuestionPreview = () => {
    return (
      <div id='fixed-wrapper'>
        <div
          className='question-answer-content-preview'
          id='question-answer-content-preview'
          style={{ backgroundImage: `url(${previewImage && previewImage})` }}
        >
          <div className='question-answer-content-wrapper'>
            {questions.length !== 0 && (
              <div className='question-answer-content-preview-header'>
                <div className='question-answer-content-preview-header-ask'>
                  <Image src={questionPreview} />
                </div>
                <div className='question-answer-content-preview-header-progress'>
                  <span style={{ color: '#FF9908', marginBottom: '8px' }}>Questions Answered</span>
                  <span style={{ color: '#000', background: '#BDBDBD' }}>
                    {previewContent + 1}/{questions.length}
                  </span>
                </div>
                <div className='question-answer-content-preview-header-cancel'>
                  <Image src={cancelPreview} />
                </div>
              </div>
            )}
            {questions.length !== 0 && (
              <div className='question-answer-content-preview-content'>
                <h1 className='display-line-break'>{questions[previewContent].description}</h1>
              </div>
            )}
            <div className='question-answer-content-preview-footer'>
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_TRUE_OR_FALSE' &&
                answerField(questions, previewContent).renderTrueOrFalse}
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_MULTIPLE_CHOICE' &&
                answerField(questions, previewContent).renderMultipleChoice}
              {questions.length !== 0 &&
                questions[previewContent].type === 'TYPE_FILL_THE_BLANK' &&
                answerField(questions, previewContent).renderFillTheBlank}
            </div>
          </div>
        </div>
        {renderCorrectAnswerPreview()}
        {previewImage && (
          <Button onClick={handlePreviewQI} block type='text-button'>
            <Image src={iconPreview} height='100%' /> Full Size Question Image
          </Button>
        )}
      </div>
    );
  };

  const renderContentProposal = () => {
    return (
      <div className='question-answer-content' id='question-answer-content'>
        <Container>
          <Row>
            <Col xs={12}>
              <div className='question-answer-content-desc'>
                <h2>#2. Tanya & Jawab</h2>
                <p>
                  Buat 15 pertanyaan untuk menguji pemahaman murid-murid tentang pelajaran yang kamu buat. <br />{' '}
                  Berikan kunci jawaban untuk membantu mereka belajar dari kesalahan.
                </p>
                <p>
                  Coba ikuti pedoman dari kami untuk mempermudah proses kamu. Lihat{' '}
                  <span
                    onClick={() => handleGuideline('desain')}
                    style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    Pedoman Untuk Desainer
                  </span>
                  . <br />
                  Dan Unduh{' '}
                  <span
                    onClick={() => handleGuideline('aset')}
                    style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    Paket Aset
                  </span>
                  .
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <div className='question-answer-content-box'>
                {!isEmpty(questions) &&
                  questions.map((question, index) => {
                    return (
                      <QuestionForm
                        key={question.id}
                        questionData={question}
                        questionIndex={index}
                        previewContent={previewContent}
                        setPreviewContent={setPreviewContent}
                        setPreviewCorrectAnswer={setPreviewCorrectAnswer}
                        setLoading={setIsLoading}
                      />
                    );
                  })}
              </div>
              <div className='question-answer-content-create'>
                <Button
                  type='dropdown'
                  icon={<PlusCircleFill />}
                  dropdownItem={dropdownCreateQuestion(handleGenerateQuestion)}
                >
                  Buat Soal Baru
                </Button>
              </div>
            </Col>
            <Col xs={4}>{renderQuestionPreview()}</Col>
          </Row>
        </Container>
      </div>
    );
  };

  const generateBlobURL = (data, fileName) => {
    const downloadFile = window.URL.createObjectURL(new Blob([data]));
    const fileURL = document.createElement('a');
    fileURL.href = downloadFile;
    fileURL.setAttribute('download', fileName);
    document.body.appendChild(fileURL);
    return fileURL.click();
  };

  const handleGetFile = async (fileURL, fileName, ref) => {
    try {
      if (fileURL !== '') {
        const data = await fetch(fileURL, {
          method: 'get',
        });
        const blob = await data.blob();
        return generateBlobURL(blob, fileName);
      }
      return ref.current.click();
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const handleUploadFile = async (type) => {
    try {
      if (type === 'video') {
        const { contentUrl, name } = proposalFile.mp4File;
        await handleGetFile(contentUrl, name, mp4InputFile);
      }
      if (type === 'archive') {
        const { contentUrl, name } = proposalFile.zipFile;
        await handleGetFile(contentUrl, name, zipInputFile);
      }
      if (type === 'pdf') {
        const { contentUrl, name } = proposalFile.pdfFile;
        await handleGetFile(contentUrl, name, pdfInputFile);
      }
      if (type === 'audio') {
        const { contentUrl, name } = proposalFile.audioFile;
        await handleGetFile(contentUrl, name, audioInputFile);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleUpdateUpload = async (typeUpload, uploadedData, files) => {
    const updatedState = {
      name: files[0].name,
      contentUrl: uploadedData.contentUrl || '',
    };
    try {
      if (typeUpload === 'pdf') {
        setProposalFile((prevState) => ({
          ...prevState,
          pdfFile: updatedState,
        }));
        const updatedData = {
          document: uploadedData['@id'],
        };
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, updatedData);
      }
      if (typeUpload === 'archive') {
        setProposalFile((prevState) => ({
          ...prevState,
          zipFile: updatedState,
        }));
        const updatedData = {
          archive: uploadedData['@id'],
        };
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, updatedData);
      }
      if (typeUpload === 'video') {
        setProposalFile((prevState) => ({
          ...prevState,
          mp4File: updatedState,
        }));
        const updatedData = {
          video: uploadedData['@id'],
        };
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, updatedData);
      }
      if (typeUpload === 'audio') {
        setProposalFile((prevState) => ({
          ...prevState,
          audioFile: updatedState,
        }));
        const updatedData = {
          audio: [uploadedData['@id']],
        };
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, updatedData);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const validateExtensions = (ext, type) => {
    if (type === 'archive') {
      return ext === 'zip' || ext === 'rar';
    }
    if (type === 'video') {
      return ext === 'mp4';
    }
    if (type === 'pdf') {
      return ext === 'pdf' || ext === 'docx';
    }
    if (type === 'audio') {
      return ext === 'zip' || ext === 'rar';
    }
    return false;
  };

  const uploadFile = async (files, typeUpload) => {
    try {
      setLoadingType();
      setLoadingFile(true);
      setErrorUploadFile('');
      let fileType;
      if (!isEmpty(files)) {
        const fileExt = getFileExt(files[0].name);
        fileType = {
          fileExtension: fileExt,
        };
      }
      if (validateExtensions(fileType.fileExtension, typeUpload)) {
        setLoadingType(typeUpload);
        const uploadedData = await dispatch(uploadToS3(typeUpload, fileType, files[0], setFileProgress));
        if (uploadedData !== null) {
          await handleUpdateUpload(typeUpload, uploadedData, files);
        }
      } else {
        setErrorUploadFile(typeUpload);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      setErrorUploadFile(typeUpload);
    }
    setLoadingFile(false);
    setLoadingType();
  };

  const handleOnChangeFile = async (event, typeUpload) => {
    event.preventDefault();
    event.persist();
    event.stopPropagation();
    const {
      target: { files },
    } = event;
    await uploadFile(files, typeUpload);
  };

  const handleDeleteUpload = async (typeUpload) => {
    const emptyState = {
      name: '',
      contentUrl: '',
    };
    setLoadingFile(true);
    try {
      if (typeUpload === 'pdf') {
        await axios.delete(creativeWorkDetail.retrieved.document);
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, { document: null });
        setProposalFile((prevState) => ({
          ...prevState,
          pdfFile: emptyState,
        }));
      }
      if (typeUpload === 'archive') {
        await axios.delete(creativeWorkDetail.retrieved.archive);
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, { archive: null });
        setProposalFile((prevState) => ({
          ...prevState,
          zipFile: emptyState,
        }));
      }
      if (typeUpload === 'video') {
        await axios.delete(creativeWorkDetail.retrieved.video);
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, { video: null });
        setProposalFile((prevState) => ({
          ...prevState,
          mp4File: emptyState,
        }));
      }
      if (typeUpload === 'audio') {
        await axios.delete(creativeWorkDetail.retrieved.audio[0]);
        await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, { audio: [] });
        setProposalFile((prevState) => ({
          ...prevState,
          audioFile: emptyState,
        }));
      }
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
    }
    setLoadingFile(false);
  };

  const renderDownloadButton = (type, file) => {
    if (file.name !== '') {
      return (
        <div className='question-answer-lesson-content-wrapper-button-download' onClick={() => handleUploadFile(type)}>
          <CloudArrowDownFill />
        </div>
      );
    }
    return null;
  };

  const renderUploadButton = (type, file) => {
    return (
      <div style={{ width: '100%' }}>
        {fileProgress !== 0 && fileProgress !== 100 && loadingType === `${type}` && (
          <ProgressBar animated now={fileProgress} variant='danger' />
        )}
        <Button block={true} disabled={loadingFile} onClick={() => handleUploadFile(type)}>
          {file.name !== '' ? (
            file.name
          ) : loadingFile ? (
            <>
              Sedang memulai <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </>
          ) : (
            <>
              <CardImage /> Pilih file
            </>
          )}
        </Button>
      </div>
    );
  };

  const renderDeleteButton = (type, file) => {
    if (projectDetail.retrieved && projectDetail.retrieved.lessonType === 3 && type !== 'pdf') {
      return null;
    }
    if (file.name !== '') {
      return (
        <div className='question-answer-lesson-content-wrapper-button-trash' onClick={() => handleDeleteUpload(type)}>
          <TrashFill />
        </div>
      );
    }
    return null;
  };

  const renderDropZone = (type, file, text) => {
    if (file.name === '') {
      return (
        <Row>
          <InputDropZone uploadFiles={uploadFile} type={type} text={text} loading={loadingFile} />
        </Row>
      );
    }
    return null;
  };

  const handleSubmitTranscript = async () => {
    try {
      const transcriptData = {
        transcript: voiceOverText,
      };
      await axios.put(`${CREATIVE_WORKS_URL}/${props.match.params.page}`, transcriptData);
    } catch (error) {
      if (error.response.status === 401) {
        // eslint-disable-next-line no-alert
        alert('Sesi kamu sudah habis. Akan memuat ulang halaman untuk memuat ulang sesi.');
        window.location.reload();
      }
      if (error.response.status === 500) {
        toast.error('Terjadi Kesalahan saat membuat pertanyaan. Silahkan muat ulang halaman.');
      }
      throw new Error(error);
    }
  };

  const renderLessonToLearnt = () => {
    return (
      <Container>
        <div className='question-answer-lesson'>
          <Row>
            <Col xs={8}>
              <div className='question-answer-lesson-content'>
                <Row>
                  <Col xs={12}>
                    <div className='question-answer-lesson-content-desc'>
                      <div>
                        <h2>#3. Detail Pelajaran</h2> <div className='question-answer-lesson-content-desc-line' />
                      </div>
                      <p>
                        Buat 1-2 menit soal animasi untuk membantu murid-murid belajar dengan menyenangkan.
                        <span className='bold-text'> Unduh Template kami </span>
                        dan ikuti <span className='bold-text'>contohnya</span>, kemudian unggah karya animasimu.
                      </p>
                      <p>
                        Coba ikuti pedoman dari kami untuk mempermudah proses kamu. Lihat{' '}
                        <span
                          onClick={() => handleGuideline('animasi')}
                          style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          Pedoman Untuk Animator
                        </span>
                        .
                      </p>
                      <label className='question-answer-lesson-content-textarea-label'>Teks Suara</label>
                      <div className='question-answer-lesson-content-textarea-container'>
                        <CharacterCounter value={voiceOverText || ''} maxLength={10000}>
                          <textarea
                            rows='10'
                            className='question-answer-lesson-content-textarea'
                            placeholder='Tulis skrip suara atau narasi animasi. Minimal 300 karakter.'
                            onChange={(e) => setVoiceOverText(e.target.value)}
                            onBlur={handleSubmitTranscript}
                            value={voiceOverText}
                            maxLength={10000}
                          />
                        </CharacterCounter>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='question-answer-lesson-content-wrapper'></div>
                <div className='question-answer-lesson-content-wrapper'>
                  <Row>
                    <input
                      type='file'
                      id='upload-file-zip'
                      ref={audioInputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => handleOnChangeFile(event, 'audio')}
                    />
                    <Col xs={1}>
                      <Image src={iconRar} width='40' />
                    </Col>
                    <Col xs={4}>
                      <h3>Berkas Suara Guru</h3>
                      <p>File Zip atau Rar</p>
                    </Col>
                    <Col xs={7}>
                      <div
                        className={`question-answer-lesson-content-wrapper-button 
                      ${proposalFile.audioFile.name !== '' && 'filled-button '}`}
                      >
                        {renderDownloadButton('audio', proposalFile.audioFile)}
                        {renderUploadButton('audio', proposalFile.audioFile)}
                        {renderDeleteButton('audio', proposalFile.audioFile)}
                      </div>
                      {errorUploadFile === 'audio' && <p className='text-center'>Gagal Upload!</p>}
                    </Col>
                  </Row>
                  {renderDropZone('audio', proposalFile.audioFile, 'ZIP atau RAR')}
                </div>
                <div className='question-answer-lesson-content-wrapper'>
                  <Row>
                    <input
                      type='file'
                      id='upload-file-zip'
                      ref={zipInputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => handleOnChangeFile(event, 'archive')}
                    />
                    <Col xs={1}>
                      <Image src={iconZIP} width='40' />
                    </Col>
                    <Col xs={4}>
                      <h3>Berkas After Effect</h3>
                      <p>File Zip atau Rar</p>
                    </Col>
                    <Col xs={7}>
                      <div
                        className={`question-answer-lesson-content-wrapper-button 
                      ${proposalFile.zipFile.name !== '' && 'filled-button '}`}
                      >
                        {renderDownloadButton('archive', proposalFile.zipFile)}
                        {renderUploadButton('archive', proposalFile.zipFile)}
                        {renderDeleteButton('archive', proposalFile.zipFile)}
                      </div>
                      {errorUploadFile === 'archive' && <p className='text-center'>Gagal Upload!</p>}
                    </Col>
                  </Row>
                  {renderDropZone('archive', proposalFile.zipFile, 'ZIP atau RAR')}
                </div>
                <div className='question-answer-lesson-content-wrapper'>
                  <Row>
                    <input
                      type='file'
                      id='upload-file-mp4'
                      ref={mp4InputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => handleOnChangeFile(event, 'video')}
                    />
                    <Col xs={1}>
                      <Image src={iconMP4} width='40' />
                    </Col>
                    <Col xs={4}>
                      <h3>Video Final</h3>
                      <p>MP4 atau Mov</p>
                    </Col>
                    <Col xs={7}>
                      <div
                        className={`question-answer-lesson-content-wrapper-button 
                      ${proposalFile.mp4File.name !== '' && 'filled-button '}`}
                      >
                        {renderDownloadButton('video', proposalFile.mp4File)}
                        {renderUploadButton('video', proposalFile.mp4File)}
                        {renderDeleteButton('video', proposalFile.mp4File)}
                      </div>
                      {errorUploadFile === 'video' && <p className='text-center'>Gagal Upload!</p>}
                    </Col>
                  </Row>
                  {renderDropZone('video', proposalFile.mp4File, 'MP4 atau MOV')}
                </div>
                <div className='my-3'>
                  <Button type='main' onClick={handleProposalSummary}>
                    Kumpul Proposal
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  };

  const renderAnimationBriefing = () => {
    return (
      <Container>
        <div className='question-answer-animation'>
          <Row>
            <Col xs={8}>
              <div className='question-answer-animation-title'>
                <Row>
                  <Col xs={2}>
                    <Image src={iconFile} fluid />
                  </Col>
                  <Col xs={10}>
                    <h1>
                      Proposal <br /> Pelajaran
                    </h1>
                  </Col>
                </Row>
              </div>
              <div className='question-answer-animation-content'>
                <Row>
                  <Col xs={12}>
                    <div className='question-answer-animation-content-desc'>
                      <div>
                        <h2>#1. Pengarahan Animasi</h2> <div className='question-answer-animation-content-desc-line' />
                      </div>
                      <p>
                        Video animasi punya lebih banyak tahapan dari video biasa. Hal ini dikarenakan pengerjaan dalam
                        pembuatan animasi berbeda dari video biasa. Membagi animasi perlangkah akan mempermudah
                        pembuatannya. Sesi ini akan menolong kami untuk mengerti bagaimana setiap langkah dibuat olehmu.
                      </p>
                      <p>
                        Coba ikuti pedoman dari kami untuk mempermudah proses kamu. Lihat{' '}
                        <span
                          onClick={() => handleGuideline('guru')}
                          style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          Pedoman Untuk Guru
                        </span>{' '}
                        dan Unduh{' '}
                        <span
                          onClick={() => handleGuideline('lesson')}
                          style={{ color: '#f2767e', cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          Template Pelajaran
                        </span>
                        .
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className='question-answer-animation-content-wrapper'>
                  <Row>
                    <input
                      type='file'
                      id='upload-file-pdf'
                      ref={pdfInputFile}
                      style={{ display: 'none' }}
                      onChange={(event) => handleOnChangeFile(event, 'pdf')}
                    />
                    <Col xs={1}>
                      <Image src={iconPDF} width='40' />
                    </Col>
                    <Col xs={4}>
                      <h3>Teks Panduan</h3>
                      <p>Docx atau PDF</p>
                    </Col>
                    <Col xs={7}>
                      <div
                        className={`question-answer-animation-content-wrapper-button 
                          ${proposalFile.pdfFile.name !== '' && 'filled-button '}`}
                      >
                        {renderDownloadButton('pdf', proposalFile.pdfFile)}
                        {renderUploadButton('pdf', proposalFile.pdfFile)}
                        {renderDeleteButton('pdf', proposalFile.pdfFile)}
                      </div>
                      {errorUploadFile === 'pdf' && <p className='text-center'>Gagal Upload!</p>}
                    </Col>
                  </Row>
                  {renderDropZone('pdf', proposalFile.pdfFile, 'DOCX atau PDF')}
                </div>
                {projectDetail.retrieved && projectDetail.retrieved.lessonType === 3 ? (
                  <>
                    <ReactPlayer url={projectVideo} width='100%' height='100%' className='react-player' controls />
                    <p className='question-answer-animation-content-video-note'>
                      <span>Catatan:</span> Animasi akan disediakan oleh Tim Sahabat Pintar.
                    </p>
                  </>
                ) : (
                  <>
                    {lessonScenes.map((les, i) => (
                      <LessonScenes
                        key={i}
                        image={les}
                        idImg={i}
                        creativeWorkId={props.match.params.page}
                        images={creativeWorkDetail.retrieved}
                        setLessonScenes={setLessonScenes}
                        lessonScenes={lessonScenes}
                      />
                    ))}
                    <Button
                      disabled={lessonScenes.length === 0}
                      onClick={() => setLessonScenes((prevState) => [...prevState, { name: '', contentUrl: '' }])}
                    >
                      Buat Scene Baru
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  };

  return (
    <div className='question-answer'>
      {isLoading && <Loading />}
      {renderHeaderProposal()}
      <ProposalOverview
        projectDetail={projectDetail.retrieved && projectDetail.retrieved}
        mappedImages={mappedImages && mappedImages}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        mappedMembers={mappedMembers && mappedMembers}
        showGuidelines={true}
      />
      <ModalTutorial
        isShowModal={showTutorial}
        setIsShowModal={setShowTutorial}
        mappedMembers={mappedMembers && mappedMembers}
      />
      {renderAnimationBriefing()}
      {renderContentProposal()}
      {projectDetail.retrieved && projectDetail.retrieved.lessonType !== 3 && renderLessonToLearnt()}
      <ModalGuidelines showModal={showPreview} setShowModal={setShowPreview} guidelineDetail={guidelineDetail} />
    </div>
  );
};

export default QuestionAnswer;
