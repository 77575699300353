import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Input from '../Input/Input';
import submitPreview from '../../Assets/Images/submit-preview.png';
import correctIcon from '../../Assets/Images/correct-icon.png';
import questionPreview from '../../Assets/Images/question-preview.png';
import cancelPreview from '../../Assets/Images/cancel-preview.png';
import './SmallQuestionPreviewMateriGratis.scss';

const SmallQuestionPreviewMateriGratis = (props) => {
  const {
    questionData,
    questionIndex,
    questionLength,
  } = props;
  const [multipeChoiceAnswer, setMultipleChoiceAnswer] = useState();

  const compare = (a, b) => {
    let comparison = 0;
    if (a.ordering > b.ordering) {
      comparison = 1;
    } else if (a.ordering < b.ordering) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    if (questionData && questionData.type === 'TYPE_MULTIPLE_CHOICE') {
      setMultipleChoiceAnswer(questionData.connectedAnswers.sort(compare));
    }
  }, [questionData]);

  const renderAnswerField = (question) => ({
    trueOrFalse: (
      <div className='footer__answer margin-top--80'>
        <div className='footer__answer-button'>
          <h1>True</h1>
        </div>
        <div className='footer__answer-button'>
          <h1>False</h1>
        </div>
      </div>
    ),
    multipleChoice: (
      <>
        <div className='footer__answer margin-top--50'>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE' && multipeChoiceAnswer
              && multipeChoiceAnswer[0].description}</h1>
          </div>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE' && multipeChoiceAnswer
              && multipeChoiceAnswer[1].description}</h1>
          </div>
        </div>
        <div className='footer__answer'>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE' && multipeChoiceAnswer
              && multipeChoiceAnswer[2].description}</h1>
          </div>
          <div className='footer__answer-button'>
            <h1>{question.type === 'TYPE_MULTIPLE_CHOICE' && multipeChoiceAnswer
              && multipeChoiceAnswer[3].description}</h1>
          </div>
        </div>
      </>
    ),
    fillTheBlank: (
      <div className='footer__answer margin-top--80'>
        <Input placeholder='KETIK JAWABAN DI SINI'/>
        <Image src={submitPreview}/>
      </div>
    )
  });

  const renderCorrectAnswerPreview = (answerKey) => {
    return (
      <div className='mask'>
        <div className='mask__text'>
          <h1>Hore</h1>
        </div>
        <div className='mask__icon'>
          <Image src={correctIcon}/>
        </div>
        <div className='mask__description'>
          <h1>Benar</h1>
          <div className='mask__key'>
            <Scrollbars
              autoHeight
              autoHeightMin={0}
              autoHeightMax={55}
              autoHide={false}
            >
              <p className="display-line-break">{answerKey}</p>
            </Scrollbars>
          </div>
          <div className='mask__button'>
            <div className='mask__button image'>
              Tutup
            </div>
          </div>
          {/* <span>Laporkan ke Kami</span> */}
        </div>
      </div>
    );
  };

  const renderQuestionPreviewWithAnswer = (question, index) => {
    return (
      <div className='wrapper'>
        <div
          className='small-question__preview'
          style={{ backgroundImage: `url(${questionData.image.contentUrl})` }}
        >
          <div className='wrapper'>
            <div className='header'>
              <div className='header__ask'>
                <Image src={questionPreview}/>
              </div>
              <div className='header__progress'>
                <span className='question-answered--style'>Pertanyaan Dijawab</span>
                <span className='question-answered--count'>{index + 1}/{questionLength}</span>
              </div>
              <div className='header__cancel'>
                <Image src={cancelPreview}/>
              </div>
            </div>
            <div className='content'>
              <h1 className='display-line-break'>
                {question.description}
              </h1>
            </div>
            <div className='footer'>
              {question.type === 'TYPE_TRUE_OR_FALSE' && renderAnswerField(question).trueOrFalse}
              {question.type === 'TYPE_MULTIPLE_CHOICE' && renderAnswerField(question).multipleChoice}
              {question.type === 'TYPE_FILL_THE_BLANK' && renderAnswerField(question).fillTheBlank}
            </div>
          </div>
        </div>
        {renderCorrectAnswerPreview(question.answerKey)}
      </div>
    );
  };

  const renderQuestionPreviewWithoutAnswer = (question, index) => {
    return (
      <>
        <div
          className='small-question__preview'
          style={{ backgroundImage: `url(${questionData.image.contentUrl})` }}
        >
          <div className='wrapper'>
            <div className='header'>
              <div className='header__ask'>
                <Image src={questionPreview}/>
              </div>
              <div className='header__progress'>
                <span className='question-answered--style'>Pertanyaan Dijawab</span>
                <span className='question-answered--count'>{index + 1}/{questionLength}</span>
              </div>
              <div className='header__cancel'>
                <Image src={cancelPreview}/>
              </div>
            </div>
            <div className='content'>
              <h1 className='display-line-break'>
                {question.description}
              </h1>
            </div>
            <div className='footer'>
              {question.type === 'TYPE_TRUE_OR_FALSE' && renderAnswerField(question).trueOrFalse}
              {question.type === 'TYPE_MULTIPLE_CHOICE' && renderAnswerField(question).multipleChoice}
              {question.type === 'TYPE_FILL_THE_BLANK' && renderAnswerField(question).fillTheBlank}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderQuestions = (question, index) => {
    return (
      <div className='small-question__wrapper'>
        <div className='small-question__content'>
          <div className='small-question__question-indicator'>
          </div>
          <Row>
            <Col xs={6}>
              {renderQuestionPreviewWithoutAnswer(question, index)}
            </Col>
            <Col xs={6}>
              {renderQuestionPreviewWithAnswer(question, index)}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div className='small-question'>
      {renderQuestions(questionData, questionIndex)}
    </div>
  );
};

export default SmallQuestionPreviewMateriGratis;
