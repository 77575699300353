import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import './Label.scss';
import { noop } from '../../Constants/Constants';

const Label = (props) => {
  const { children, type, onClick } = props;

  if (type === 'STATUS_WITH-CANCEL') {
    return (
      <div
        className='label-badge'
        onClick={onClick}
      >
        <Badge variant='light'>
          {children} <X />
        </Badge>
      </div>
    );
  }

  if (type === 'with-text') {
    return (
      <div className='label-badge'>
        <Badge variant='light'>{children}</Badge>
      </div>
    );
  }

  if (type === 'STATUS_OVERDUE') {
    return (
      <div className='label-badge'>
        <Badge variant='danger'>{children || 'Overdue'}</Badge>
      </div>
    );
  }

  if (type === 'STATUS_NEED_PARTNER') {
    return (
      <div className='label-badge'>
        <Badge variant='warning'>{children || 'Butuh Anggota'}</Badge>
        {/* <Badge className='label-badge-need-partner'>Need Partner</Badge> */}
      </div>
    );
  }

  if (type === 'STATUS_NEED_PARTNER_TEXT') {
    return (
      <div className='label-badge'>
        <Badge variant='warning'>{children || 'Butuh Anggota'}</Badge>
        {/* <Badge className='label-badge-need-partner'>Need Partner</Badge> */}
      </div>
    );
  }

  if (type === 'STATUS_COMPLETED') {
    return (
      <div className='label-badge'>
        <Badge variant='secondary'>{children || 'Selesai'}</Badge>
      </div>
    );
  }

  if (type === 'STATUS_IN_PROGRESS') {
    return (
      <div className='label-badge'>
        <Badge variant='primary'>{children || 'Dikerjakan'}</Badge>
      </div>
    );
  }

  return null;
};

Label.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

Label.defaultProps = {
  type: 'with-text',
  onClick: noop
};

export default Label;
