import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

const HandleEnterKey = ({ handleOnEnterKey }) => {
  const escFunction = useCallback((event) => {
    if (event.keyCode === 13) {
      handleOnEnterKey();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
        <div />
  );
};
export default HandleEnterKey;

HandleEnterKey.propTypes = {
  handleOnEnterKey: PropTypes.func.isRequired
};
